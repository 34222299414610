import { EWSList } from "./EWSList";
import React from "react";
import { SharepointList } from "./SharepointList";

export interface IServiceAccountListProps {
  serviceName: string;
  organisationId: string;
}

export const ServiceAccountsList: React.FC<IServiceAccountListProps> = ({
  serviceName,
  organisationId,
}) => {
  if (serviceName === "mailbox") {
    return <EWSList organisationId={organisationId} />;
  } else if (serviceName === "sharepoint" || serviceName === "onedrive") {
    return (
      <SharepointList
        organisationId={organisationId}
        serviceName={serviceName}
      />
    );
  } else {
    return <></>;
  }
};
