import {
  IStyle,
  Pivot,
  PivotItem,
  PivotLinkSize,
  classNamesFunction,
  styled,
} from "office-ui-fabric-react";
import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

const itemKeys = [
  "overview",
  "customers",
  "mailbox",
  "sharepoint",
  "onedrive",
  "reporting",
  "people",
] as const;

export type TItemKey = typeof itemKeys[number];

export const isItemKey = (val: any): val is TItemKey => itemKeys.includes(val);

interface IItem {
  key: TItemKey;
  header: string;
}

export interface IServicesPivotProps {
  selectedKey: TItemKey;
  includedItems: TItemKey[];
}

interface IServicesPivotStyles {
  scroll: IStyle;
}

const servicesPivotStyles: IServicesPivotStyles = {
  scroll: {
    overflow: "hidden",
    paddingTop: 12,
    paddingBottom: 12,
  },
};

const getClassNames = classNamesFunction<{}, IServicesPivotStyles>();

interface IProps extends IServicesPivotProps {
  styles?: IServicesPivotStyles;
}

const BaseServicesPivot: React.FC<IProps> = ({
  selectedKey,
  includedItems,
  styles,
}) => {
  const { organisationId } = useParams();
  const history = useHistory();

  const items: IItem[] = [
    {
      key: "overview",
      header: "Overview",
    },
    {
      key: "customers",
      header: "Customers",
    },
    {
      key: "mailbox",
      header: "Mailbox",
    },
    {
      key: "sharepoint",
      header: "SharePoint",
    },
    {
      key: "onedrive",
      header: "OneDrive",
    },
    {
      key: "reporting",
      header: "Reporting",
    },
    {
      key: "people",
      header: "People",
    },
  ];

  const filteredItems = items.filter(item => {
    return includedItems.includes(item.key);
  });

  const onSelect = useCallback(
    (key: TItemKey) => {
      if (key === "overview" || key === "customers") {
        history.push(`/customers/${organisationId}`);
      } else if (key === "reporting") {
        history.push(`/customers/${organisationId}/reporting`);
      } else if (key === "people") {
        history.push(`/customers/${organisationId}/people`);
      } else {
        history.push(`/customers/${organisationId}/services/${key}`);
      }
    },
    [history, organisationId]
  );

  const onClick = (item?: PivotItem) => {
    const itemKey = item?.props.itemKey;

    if (itemKey) {
      onSelect(itemKey as TItemKey);
    }
  };

  const classNames = getClassNames(styles);

  return (
    <div className={classNames.scroll}>
      <Pivot
        linkSize={PivotLinkSize.large}
        headersOnly
        selectedKey={selectedKey}
        onLinkClick={onClick}
        styles={() => ({
          link: { display: "block", float: "left" },
          linkIsSelected: { display: "block", float: "left" },
        })}
      >
        {filteredItems.map(item => (
          <PivotItem
            key={item.key}
            itemKey={item.key}
            headerText={item.header}
          />
        ))}
      </Pivot>
    </div>
  );
};

export const ServicesPivot = styled(BaseServicesPivot, servicesPivotStyles);
