import {
  DetailsListLayoutMode,
  IColumn,
  IStyle,
  IconButton,
  MessageBarType,
  SelectionMode,
  Shimmer,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
  Text,
  getTheme,
  mergeStyles,
} from "office-ui-fabric-react";
import React, { useCallback, useEffect, useState } from "react";

import { IInvite } from "../../providers/ApiProvider/ApiClient/models/invites";
import { SubmitDialog } from "./submitDialog";
import { useApiClient } from "../../providers/ApiProvider";
import { useMessageBar } from "../../providers/messageBarProvider";

export const itemStyle: IStyle = { width: "30%", minWidth: 300 };
const spaceStyle: IStyle = { marginBottom: 20 };

export interface IInviteRoleConfig {
  userId: string;
  setDidAccept: (didAccept: boolean) => void;
}

const CustomerInvitesView: React.FC<IInviteRoleConfig> = ({
  userId,
  setDidAccept,
}) => {
  const api = useApiClient();
  const [invites, setInvites] = useState<IInvite[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInnerLoading, setInnerLoading] = useState(false);
  const [dialogHidden, setDialogHidden] = useState(true);
  const [selectedInvite, setSelectedInvite] = useState<IInvite>();

  let space = "";
  if (isLoading) {
    space = mergeStyles(spaceStyle);
  }

  const container = mergeStyles({
    maxHeight: "55vh",
    overflowY: "scroll",
  });

  const { sendMessage } = useMessageBar();

  const fetchData = useCallback(async () => {
    let invites: IInvite[] = [];
    let cursor: string | undefined;

    do {
      const inviteResp = await api.invites.getInvitesByUserId({
        inviteeUserId: userId,
        cursor,
      });
      if (inviteResp.isOk()) {
        invites = [...invites, ...inviteResp.value.nodes];
        cursor = inviteResp?.value.pageInfo.cursor;
      } else {
        cursor = undefined;
      }
    } while (cursor);
    setInvites(invites);
  }, [api, setInvites, userId]);

  const acceptInvite = async ({ invite }: { invite: IInvite }) => {
    setSelectedInvite(invite);
    setInnerLoading(true);
    const request = await api.invites.acceptInvite({
      inviteId: invite.inviteId,
      organisationId: invite.organisationId,
    });
    await fetchData();
    setInnerLoading(false);
    setDidAccept(true);
    if (request.isOk()) {
      await api.helpers.refreshLogin();

      sendMessage({
        messageType: MessageBarType.success,
        text: "Invite accepted successfully.",
      });
    } else {
      sendMessage({
        messageType: MessageBarType.error,
        text: request.message,
      });
    }
  };

  const rejectInvite = async ({
    inviteId,
    organisationId,
  }: {
    inviteId: string;
    organisationId: string;
  }) => {
    setDialogHidden(true);
    setInnerLoading(true);
    const request = await api.invites.cancelInvite({
      inviteId,
      organisationId,
    });
    await fetchData();
    setInnerLoading(false);
    if (request.isOk()) {
      sendMessage({
        messageType: MessageBarType.success,
        text: "Invite rejected successfully.",
      });
    } else {
      sendMessage({
        messageType: MessageBarType.error,
        text: request.message,
      });
    }
  };

  useEffect(() => {
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [api, setIsLoading, fetchData]);

  const items = prepareItems({
    invites,
    acceptInvite,
    selectedInvite,
    setSelectedInvite,
    dialogHidden,
    setDialogHidden,
    isLoading: isInnerLoading,
  });

  let invitesComponent = (
    <ShimmeredDetailsList
      className={container}
      items={items}
      enableShimmer={isLoading}
      columns={defaultColumns()}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
    />
  );

  if (invites.length === 0) {
    invitesComponent = (
      <div>
        <Text variant="medium">You have no pending invites at this time. </Text>
      </div>
    );
  }

  let submitFunction = () => {};
  if (selectedInvite) {
    submitFunction = () =>
      rejectInvite({
        inviteId: selectedInvite.inviteId,
        organisationId: selectedInvite.organisationId,
      });
  }

  return (
    <>
      <SubmitDialog
        hidden={dialogHidden}
        displayTitle={"Are you sure you want to reject this invite request?"}
        submit={submitFunction}
        setIsOpen={() => {
          setDialogHidden(!dialogHidden);
        }}
      ></SubmitDialog>
      <div className={space}>
        <Text variant="xLarge">Pending Invites</Text>
      </div>
      <Shimmer isDataLoaded={!isLoading}>{invitesComponent}</Shimmer>
    </>
  );
};

const defaultColumns = (): IColumn[] => {
  return [
    {
      key: "organisationName",
      name: "Customer",
      fieldName: "organisationName",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
    },
    {
      key: "inviterEmail",
      name: "Invited By",
      fieldName: "inviterEmail",
      minWidth: 100,
      isResizable: true,
    },
    {
      key: "accept",
      name: "Accept",
      fieldName: "accept",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "reject",
      name: "Reject",
      fieldName: "reject",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "loading",
      name: "",
      fieldName: "loading",
      minWidth: 20,
      maxWidth: 20,
      isResizable: true,
    },
  ];
};

const prepareItems = ({
  invites,
  isLoading,
  selectedInvite,
  setSelectedInvite,
  setDialogHidden,
  dialogHidden,
  acceptInvite,
}: {
  invites: IInvite[];
  isLoading: boolean;
  dialogHidden: boolean;
  selectedInvite: IInvite | undefined;
  setSelectedInvite: React.Dispatch<IInvite | undefined>;
  setDialogHidden: React.Dispatch<boolean>;
  acceptInvite: ({ invite }: { invite: IInvite }) => void;
}) => {
  const theme = getTheme();
  const handleSelectedInvite = ({ invite }: { invite: IInvite }) => {
    setDialogHidden(!dialogHidden);
    setSelectedInvite(invite);
  };

  const finalInvites = invites.map(invite => {
    let loading = <></>;
    let accept = (
      <IconButton
        iconProps={{ iconName: "Accept" }}
        onClick={() => {
          acceptInvite({
            invite,
          });
        }}
        id={`accept-${invite.inviteId}`}
        styles={{
          root: {
            padding: 0,
            marginTop: -5,
          },
          icon: {
            fontSize: theme.fonts.xLarge.fontSize,
            color: theme.palette.themePrimary,
          },
        }}
      />
    );
    let reject = (
      <IconButton
        iconProps={{ iconName: "Cancel" }}
        onClick={() => handleSelectedInvite({ invite })}
        id={`reject-${invite.inviteId}`}
        styles={{
          root: {
            padding: 0,
            marginTop: -5,
          },
          icon: {
            fontSize: theme.fonts.xLarge.fontSize,
            color: theme.palette.red,
          },
        }}
      />
    );

    if (isLoading && invite.inviteId === selectedInvite?.inviteId) {
      reject = <></>;
      accept = <></>;
      loading = <Spinner size={SpinnerSize.small} />;
    }

    return {
      ...invite,
      accept,
      reject,
      loading,
    };
  });

  return finalInvites;
};

export default CustomerInvitesView;
