import React, { useCallback } from "react";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { statusCodeToString } from "./utils";
import { SHAREPOINT_SERVICE_ID } from "../../constants";
import { BaseList, IListColumn } from "./BaseList";
import { useHistory } from "react-router-dom";
import { useLatest } from "../../hooks/useLatest";

interface IItem {
  id: string;
  siteTitle: string;
  path: string;
  status?: string;
  enabled?: boolean;
  isLoading?: boolean;
}

const serviceAccountToItem = (serviceAccount: IServiceAccount): IItem => {
  const id = serviceAccount.id;

  const pathParts = serviceAccount.identifier.split("/");
  const path = pathParts[pathParts.length - 1];

  const siteTitle = serviceAccount.displayName ?? path;

  const { status, enabled } = statusCodeToString(serviceAccount.status);

  return {
    id,
    siteTitle,
    path,
    status,
    enabled
  };
};

const filterService = (serviceName: "sharepoint" | "onedrive") => (
  serviceAccount: IServiceAccount
): boolean => {
  // We use this heuristic to identify if this is a OneDrive site
  if (serviceAccount.identifier.includes("-my.sharepoint.com/personal/")) {
    return serviceName === "onedrive";
  } else {
    return serviceName === "sharepoint";
  }
};

export interface ISharepointListProps {
  organisationId: string;
  serviceName: "sharepoint" | "onedrive";
}

export const SharepointList: React.FC<ISharepointListProps> = ({
  organisationId,
  serviceName
}) => {
  const history = useHistory();
  const serviceAccountFilter = useCallback(filterService(serviceName), [
    serviceName
  ]);

  const serviceNameRef = useLatest(serviceName);

  const onClick = useCallback(
    (item: IItem) => {
      history.push(
        `/customers/${organisationId}/services/${serviceNameRef.current}/${item.id}`
      );
    },
    [organisationId, history, serviceNameRef]
  );

  const columns: IListColumn<IItem>[] = [
    {
      key: "siteTitle",
      name: "Site Title",
      fieldName: "siteTitle",
      minWidth: 150,
      maxWidth: 250,
      onClick
    },
    {
      key: "path",
      name: "Path",
      fieldName: "path",
      minWidth: 250
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 60
    }
  ];

  return (
    <BaseList
      serviceId={SHAREPOINT_SERVICE_ID}
      organisationId={organisationId}
      columns={columns}
      serviceAccountToItem={serviceAccountToItem}
      serviceAccountFilter={serviceAccountFilter}
    />
  );
};
