import React from "react";
import { Modal } from "../../Modal";
import { IReprSearchResultValue } from "../types";
import { IStyle, mergeStyleSets, Stack } from "office-ui-fabric-react";
import { Depths } from "@uifabric/fluent-theme";
import { ResultDetail } from "./ResultDetail";

export interface IResultModalProps {
  selectedItem?: IReprSearchResultValue;
  isOpen: boolean;
  onDismiss?: () => void;
}

export const ResultModal: React.FC<IResultModalProps> = ({
  selectedItem,
  isOpen,
  onDismiss
}) => {
  const containerStyles: IStyle = {
    boxShadow: Depths.depth8,
    padding: 12,
    marginTop: 12,
    overflowY: "scroll"
  };

  const classNames = mergeStyleSets({
    html: containerStyles,
    text: {
      ...containerStyles,
      whiteSpace: "pre-wrap"
    }
  });

  return (
    <Modal header="Preview" isOpen={isOpen} onDismiss={onDismiss}>
      <Stack>
        <ResultDetail label="From" value={selectedItem?.from?.join(", ")} />
        <ResultDetail label="To" value={selectedItem?.to?.join(", ")} />
        <ResultDetail label="Date" value={selectedItem?.reprDate} />
        <ResultDetail label="Subject" value={selectedItem?.subject} />
        <ResultDetail label="Cc" value={selectedItem?.cc?.join(", ")} />
        <ResultDetail label="Bcc" value={selectedItem?.bcc?.join(", ")} />
        <ResultDetail
          label="Reply-To"
          value={selectedItem?.replyTo?.join(", ")}
        />
        <ResultDetail
          label="Message Size"
          value={selectedItem?.reprMessageSize}
        />
      </Stack>
      {selectedItem?.textBody ? (
        <div
          className={classNames.text}
          dangerouslySetInnerHTML={{ __html: selectedItem.textBody }}
        />
      ) : (
        undefined
      )}
      {selectedItem?.htmlBody ? (
        <div
          className={classNames.html}
          dangerouslySetInnerHTML={{ __html: selectedItem.htmlBody }}
        />
      ) : (
        undefined
      )}
    </Modal>
  );
};
