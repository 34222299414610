import React from "react";
import {
  IStackItemStyles,
  getTheme,
  Stack,
  IStyle,
  IStyleFunctionOrObject,
  IStyleFunction,
  classNamesFunction,
  styled
} from "office-ui-fabric-react";

interface IMenuItemStyles {
  root?: IStyle;
}

interface IMenuItemStyleProps {
  selected?: boolean;
}

interface IMenuItemProps extends IMenuItemStyleProps {
  styles?: IStyleFunctionOrObject<IMenuItemStyleProps, IMenuItemStyles>;
  itemStyles?: IStackItemStyles;
  onClick?: () => void;
}

const menuItemStyles: IStyleFunction<IMenuItemStyleProps, IMenuItemStyles> = ({
  selected
}) => {
  const theme = getTheme();

  return {
    root: {
      background: selected ? theme.palette.themeLighterAlt : undefined,
      selectors: {
        ":hover": {
          background: theme.palette.themeLighter,
          cursor: "pointer"
        }
      }
    }
  };
};

const getClassNames = classNamesFunction<
  IMenuItemStyleProps,
  IMenuItemStyles
>();

const BaseMenuItem: React.FC<IMenuItemProps> = ({
  children,
  onClick,
  selected,
  styles,
  itemStyles
}) => {
  const classNames = getClassNames(styles, { selected });

  const stackItemStyles: IStackItemStyles = itemStyles ?? {
    root: {
      alignItems: "center",
      display: "flex",
      height: 50,
      width: 50,
      justifyContent: "center"
    }
  };

  return (
    <div className={classNames.root} onClick={onClick}>
      <Stack.Item styles={stackItemStyles}>{children}</Stack.Item>
    </div>
  );
};

export const MenuItem = styled(BaseMenuItem, menuItemStyles);
