import React from "react";
import { useWindowDimensions } from "../../providers/windowDimensionsProvider";

interface IResponsiveLayoutProps {
  renderLarge: () => React.ReactElement;
  renderSmall: () => React.ReactElement;
  breakpoint?: number;
}

const defaultBreakpoint = 640;

export const ResponsiveLayout: React.FC<IResponsiveLayoutProps> = ({
  renderLarge,
  renderSmall,
  breakpoint = defaultBreakpoint
}) => {
  return useSmallLayout(breakpoint) ? renderSmall() : renderLarge();
};

export const useSmallLayout = (breakpoint: number = defaultBreakpoint) => {
  // If the width is undefined, render the large component
  const width = useWindowDimensions()?.width ?? breakpoint;

  return width < breakpoint;
};
