import {
  IStyle,
  Text,
  classNamesFunction,
  getTheme,
  styled,
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";

import { ResponsiveLayout } from "../components/ResponsiveLayout";
import { allStoreActions } from "../store";
import { useApiClient } from "../providers/ApiProvider";
import { useDispatch } from "react-redux";
import { useWindowDimensions } from "../providers/windowDimensionsProvider";

interface IWelcomePageStyles {
  innerWrapper: IStyle;
  innerWrapperSmall: IStyle;
  outerWrapper: IStyle;
  spacer: IStyle;
  signIn: IStyle;
  outerGrid: IStyle;
  outGridWide: IStyle;
}

interface IWelcomePageProps {
  styles: IWelcomePageStyles;
}

const welcomePageStyles = (): IWelcomePageStyles => {
  const theme = getTheme();
  const outerGrid: IStyle = {
    padding: 0,
    paddingLeft: "44px !important",
    paddingRight: "44px !important",
    width: "100%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    maxWidth: 440,
    minWidth: 440,
    boxShadow: `3px 3px 8px ${theme.palette.neutralLight}`,
    border: `1px solid ${theme.palette.themeDarkAlt}`,
    backgroundColor: theme.palette.white,
  };
  return {
    innerWrapper: {},
    spacer: {
      width: "100%",
      height: 32,
      display: "inline-block",
    },
    outerGrid,
    outGridWide: {
      ...outerGrid,
      position: "relative",
      top: "80%",
    },
    innerWrapperSmall: {
      padding: 44,
      paddingLeft: "44px !important",
      paddingRight: "44px !important",
      backgroundColor: theme.palette.white,
      height: "100vh",
    },
    signIn: {
      fontSize: 16,
      fontWeight: 700,
    },
    outerWrapper: {
      backgroundColor: theme.palette.white,
      height: "100vh",
      width: "calc(100% - 40px)",
      minWidth: "100%",
    },
  };
};

const getClassNames = classNamesFunction<{}, IWelcomePageStyles>();

export const BaseWelcomePage: React.FC<IWelcomePageProps> = ({ styles }) => {
  const dispatch = useDispatch();

  const apiClient = useApiClient();

  const [isLoading, setIsLoading] = useState(true);
  const [isBranded, setIsBranded] = useState(false);
  const [partnerUrl, setPartnerUrl] = useState<string>();
  const [productName, setProductName] = useState<string>();

  const classNames = getClassNames(styles);

  const client = useApiClient();

  const dimensions = useWindowDimensions();
  const height = dimensions?.height;
  const width = dimensions?.width;
  const innerClassName =
    width && width < 440
      ? classNames.innerWrapperSmall
      : classNames.innerWrapper;

  const outerGridName =
    height && height < 520 ? classNames.outGridWide : classNames.outerGrid;

  const [logoSrc, setLogoSrc] = useState<string>();
  const spacer = <div className={classNames.spacer} />;

  useEffect(() => {
    client.brandingAssets.getLogoWhiteSrc().then(resp => {
      if (resp.isOk()) {
        setLogoSrc(resp.value);
      } else {
        setLogoSrc("/images/Backup365LoginLogo.png");
      }
    });
  }, [client, logoSrc]);

  useEffect(() => {
    apiClient.branding.getPartnerDetails().then(resp => {
      if (resp.isOk()) {
        setPartnerUrl(resp.value.websiteUrl);
        let respProductName = resp.value.productName;
        if (!respProductName) {
          respProductName = `${resp.value.partnerName} Backup365`;
        }
        setProductName(respProductName);
        setIsBranded(true);
      }
      setIsLoading(false);
    });
  }, [apiClient, setIsLoading, setPartnerUrl, setProductName, setIsBranded]);

  if (isLoading) {
    return <></>;
  }

  dispatch(allStoreActions.auth.setIsLoggedOut());

  if (!isBranded) {
    window.location.replace("https://backup365.io/badlogin/");
    return null;
  }

  const welcomeFormInner = (
    <div className="ms-Grid-row">
      <div className={`ms-Grid-col ms-sm12 ${innerClassName}`}>
        {spacer}
        <img src={logoSrc} alt="" width="100%" />
        {spacer}
        <div className={classNames.signIn}>Welcome</div>
        {spacer}
        <div className="ms-Grid-col ms-sm12">
          <Text variant="large">
            Thanks for your interest in {productName}.
          </Text>
          <br />
          <br />
          <Text variant="large">
            It seems that the Microsoft 365 account you used to login does not
            yet have permission to access our portal.
          </Text>
          <br />
          <br />
          <Text variant="large">
            If you have received an invitation to access our portal, please
            verify that you are logging in with the same account the invitation
            was sent to, or contact your administrator for further assistance.
          </Text>
          <br />
          <br />
          <Text variant="large">
            If you are new to {productName} and interested in finding out more
            about this service please contact us at{" "}
            <a href={partnerUrl} target="_blank" rel="noopener noreferrer">
              {partnerUrl}
            </a>
            .
          </Text>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames.outerWrapper}>
      <ResponsiveLayout
        breakpoint={440}
        renderLarge={() => (
          <div className={`ms-Grid ${outerGridName}`} dir="ltr">
            {welcomeFormInner}
          </div>
        )}
        renderSmall={() => (
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">{welcomeFormInner}</div>
          </div>
        )}
      />
    </div>
  );
};

export const WelcomePage = styled(BaseWelcomePage, welcomePageStyles);
