import { IAuthActions } from "./auth/types";
import { authActions } from "./auth/actions";
import { IAccountsActions } from "./accounts/types";
import { accountsActions } from "./accounts/actions";

export interface IStoreActions {
  auth: IAuthActions;
  accounts: IAccountsActions;
}

export const allStoreActions: IStoreActions = {
  auth: authActions,
  accounts: accountsActions
};
