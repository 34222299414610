import { AxiosResponse } from "axios";
import { BaseModel } from "./base";
import { IPaginate } from "./utils";

export interface IRestoreBase {
  organisationId: string;
  serviceAccountId: string;
  serviceId: string;
  config: ISharepointRestore["config"] | IEWSRestore["config"];
}

export interface ISharepointRestore {
  config: {
    [key: string]: string | undefined;
    restoreType: "FULL_RESTORE";
    dstServiceAccountId: string;
    restoreTime: string;
  };
}

export interface IEWSRestore {
  config: {
    [key: string]: string | undefined;
    restoreType: "FULL_RESTORE";
    dstServiceAccountId: string;
    destinationFolder: string;
    restoreTime: string;
  };
}

export interface IRestoreRequest extends IRestoreBase {
  startTime: string;
  restoreId: string;
  status: TStatus;
  restoreAttempts: number;
  endTime?: string;
}

export type TRestoreConfig =
  | ISharepointRestore["config"]
  | IEWSRestore["config"];

export type TStatus =
  | "PENDING"
  | "QUEUED"
  | "IN_PROGRESS"
  | "COMPLETE"
  | "FAILED";

export interface IRestoreOutput {
  serviceId: string;
  serviceAccountId: string;
  restoreId: string;
  identifier: string;
  startTime: Date;
  restoreAttempts: number;
  endTime: Date | undefined;
  lastResumeTime: Date | undefined;
  // The service specific restore config
  config: any;
  status: TStatus;
}

export class RestoreModel extends BaseModel {
  public async ewsPartialRestore(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    dstServiceAccountId: string;
    itemIds: string[];
  }): Promise<IRestoreOutput | undefined> {
    const config = {
      restoreType: "PARTIAL_RESTORE",
      dstServiceAccountId: opts.dstServiceAccountId,
      itemIds: opts.itemIds,
    };

    const response = await this.restore({
      serviceId: opts.serviceId,
      organisationId: opts.organisationId,
      serviceAccountId: opts.serviceAccountId,
      config,
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async spPartialRestore(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    dstServiceAccountId: string;
    toListId?: string;
    fileIds: string[];
    folderIds: string[];
    timestamp?: string;
    download?: boolean;
  }): Promise<IRestoreOutput | undefined> {

     const config = {
      restoreType: opts.download ? "PARTIAL_RESTORE_DOWNLOAD_LINK" : "PARTIAL_RESTORE",
      dstServiceAccountId: opts.dstServiceAccountId,
      toListId: opts.toListId,
    };

    let typeOfResource = {};

    if(!opts.timestamp){
      opts.timestamp = new Date().toISOString();
    }

    if(opts.folderIds.length > 0) {
        typeOfResource = {folder: {path: opts.folderIds[0], recursive: true,  timestamp: opts.timestamp},};
    }
    else {
      typeOfResource = {itemKeys: opts.fileIds,};
    }

    const response = await this.restore({
      serviceId: opts.serviceId,
      organisationId: opts.organisationId,
      serviceAccountId: opts.serviceAccountId,
      config: {...config, ...typeOfResource},
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async resume(opts: {
    organisationId: string;
    serviceId: string;
    serviceAccountId: string;
    restoreId: string;
  }) {
    const url = `restore/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}/resume`;

    const data = {
      restoreId: opts.restoreId,
      requestType: 'CREATE_NEW_DOWNLOAD_LINK',
    };

    const response = await this.request({
      url,
      method: "post",
      data,
    });

    if (response.status === 200) {
      return undefined;
    } else {
      return response.data;
    }
  }

  public async listRestores(opts: {
    organisationId: string;
    serviceId: string;
    serviceAccountId: string;
    cursor?: string;
  }): Promise<IPaginate<IRestoreRequest> | undefined> {
    let params: { cursor?: string } = {};
    if (opts.cursor) {
      params.cursor = opts.cursor;
    }

    const url = `restore/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}`;

    const response = await this.request({
      url,
      method: "get",
      params,
    });

    if (response.status !== 200) {
      return undefined;
    } else {
      return response.data;
    }
  }

  public async fullRestore(opts: IRestoreBase) {
    const response = await this.restore(opts);

    if (response.status === 200) {
      return undefined;
    } else {
      return response.data;
    }
  }

  private async restore(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    config: any;
  }): Promise<AxiosResponse> {
    const response = await this.request({
      url: `/restore/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}`,
      method: "post",
      data: {
        config: opts.config,
      },
    });

    return response;
  }
}
