import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../constants";

import { Browse } from "../components/Browse";
import { NotFound } from "./notFound";
import React from "react";
import { ServiceAccountBreadCrumbs } from "../components/ServiceAccountBreadCrumbs";
import { Text } from "office-ui-fabric-react";
import { useParams } from "react-router-dom";

export const BrowsePage: React.FC = () => {
  const { organisationId, serviceName, serviceAccountId, drive } = useParams();

  let serviceId: string = SHAREPOINT_SERVICE_ID;
  let rootFolder: string;
  if (serviceName === "mailbox") {
    // Browse is not yet supported for mailbox service=
    serviceId = EWS_SERVICE_ID;
    return <NotFound />;
  } else if (serviceName === "sharepoint") {
      rootFolder = drive || "Shared Documents";
  } else if(serviceName === "onedrive") {
      rootFolder = 'Documents';
  } else {
    return <NotFound />;
  }

  if (organisationId === undefined || serviceAccountId === undefined) {
    // TODO handle this properly
    throw new Error("Missing required params");
  }

  return (
    <>
      <ServiceAccountBreadCrumbs
        serviceName={serviceName}
        organisationId={organisationId}
        serviceId={serviceId}
        serviceAccountId={serviceAccountId}
        linkServiceAccount
      />
      <Text variant="xxLarge">Browse Drive</Text>
      <Browse
        serviceId={serviceId}
        organisationId={organisationId}
        serviceAccountId={serviceAccountId}
        rootFolder={rootFolder}
      />
    </>
  );
};
