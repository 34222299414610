import { IStackStyles, Icon, Stack, getTheme } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { SidePanel, TPanelState } from "../SidePanel";

import { MenuActions } from "./MenuActions";
import { MenuItem } from "./MenuItem";
import { ResponsiveLayout } from "../ResponsiveLayout";
import { UnauthedMenuAcions } from "./UnauthedMenuActions";
import { useApiClient } from "../../providers/ApiProvider";

interface ITopMenuProps {
  handleHamburger?: () => void;
  isAuthed?: boolean;
}

const BaseTopMenu: React.FC<ITopMenuProps> = ({
  handleHamburger,
  isAuthed = true,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [panelState, setPanelState] = useState<TPanelState | undefined>(
    undefined
  );
  const theme = getTheme();

  const outerStackStyles: IStackStyles = {
    root: {
      height: 50,
      background: theme.palette.themePrimary,
    },
  };

  const client = useApiClient();

  const b3HelpUrl = "https://backup365.atlassian.net/wiki/spaces/SUP/overview";

  const [logoSrc, setLogoSrc] = useState<string>();
  const [partnerUrl, setPartnerUrl] = useState<string>();
  const [partnerHelpUrl, setPartnerHelpUrl] = useState<string | undefined>(
    b3HelpUrl
  );

  useEffect(() => {
    client.brandingAssets.getLogoPrimarySrc().then(resp => {
      if (resp.isOk()) {
        setLogoSrc(resp.value);
      } else {
        setLogoSrc("/images/Backup365LogoBlack.png");
      }
    });
    client.branding.getPartnerDetails().then(resp => {
      if (resp.isOk()) {
        setPartnerUrl(resp.value.websiteUrl);
        setPartnerHelpUrl(resp.value.helpUrl);
      } else {
        setPartnerUrl("https://backup365.io");
      }
    });
  }, [client, logoSrc]);

  const stackLeft = (
    <Stack horizontal>
      {isAuthed ? (
        <MenuItem onClick={handleHamburger}>
          <Icon iconName="GlobalNavButton" />
        </MenuItem>
      ) : (
        undefined
      )}

      <a
        style={{ padding: 12 }}
        href={partnerUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logoSrc} alt="" height={24} />
      </a>
    </Stack>
  );

  const menuActions = isAuthed ? (
    <MenuActions
      setPanelState={setPanelState}
      panelState={panelState}
      helpUrl={partnerHelpUrl}
    />
  ) : (
    <UnauthedMenuAcions helpUrl={partnerHelpUrl} />
  );

  const stackRight = <Stack horizontal>{menuActions}</Stack>;

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={outerStackStyles}
      >
        <ResponsiveLayout
          renderLarge={() => (
            <>
              {stackLeft}
              {stackRight}
            </>
          )}
          renderSmall={() => (
            <>
              {showMore ? (
                <>
                  <MenuItem onClick={() => setShowMore(false)}>
                    <Icon iconName="Cancel" />
                  </MenuItem>
                  {stackRight}
                </>
              ) : (
                <>
                  {stackLeft}
                  <MenuItem onClick={() => setShowMore(true)}>
                    <Icon iconName="More" />
                  </MenuItem>
                </>
              )}
            </>
          )}
        />
      </Stack>
      <SidePanel
        panelState={panelState}
        dismissPanel={() => setPanelState(undefined)}
      />
    </>
  );
};

export const TopMenu = BaseTopMenu;
