import { IPageInfo, IPaginate } from "./utils";
import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";

export interface IOrganisation {
  id: string;
  name: string;
  parentOrganisationId?: string;
  publicNotes?: string;
  iul?: boolean;
  nfp?: boolean;
  stats?: {
    billedUnits: number;
    enabledEWSMailboxes: number;
    enabledSharePointSites: number;
    enabledOneDriveSites: number;
    syncSuccess48h: number;
    manuallyDisabledEWSMailboxes: number;
    manuallyDisabledSharePointSites: number;
    manuallyDisabledOneDriveSites: number;
  };
}

export interface IServiceAccount {
  serviceId: string;
  id: string;
  identifier: string;
  displayName?: string;
  serviceConfigId: string;
  organisationId: string;
  status: string;
  accountStatusUpdated?: string;
  createdTime?: Date;
  updatedTime?: Date;
  disabled: boolean;
  invalidAccountReason?: string;
  tags?: Array<{
    value: string;
    description?: string;
  }>;
  drives?:any;
}

export interface IServiceConfiguration {
  id: string;
  serviceId: string;
  organisationId?: string;
  description?: string;
  createdTime?: Date;
  updatedTime?: Date;
  lastUpdated?: string;
  disabled?: boolean;
  disabledFlagChanged?: Date;
  config: {
    tenantId?: string;
    credentials?: {
      email: string;
      password: string;
    };
    accountType?: string;
  };
}

export interface IOrganisationStats {
  licencedMailboxes?: number;
  unlicencedMailboxes?: number;
  onedriveSites?: number;
  sharepointSites?: number;
}

export interface IOrganisationHierarchy {
  organisationId: string;
  stats?: IOrganisationStats;
  childOrganisations: {
    organisationId: string;
    name: string;
    stats?: IOrganisationStats;
  }[];
}

export interface IOrganisationErrors {
  organisationId: string;
  errors: IServiceAccountError[];
}

export interface IServiceAccountError {
  serviceAccountId: string;
  serviceId: string;
  lastSyncTime: string;
  identifier: string;
  errorType: TErrors;
  organisationId: string;
}

export type TErrors = "ACCOUNT_NOT_SYNCED";

export class AccountsModel extends BaseModel {
  public async listOrganisations(opts: {
    parentOrganisationId?: string;
    cursor?: string;
    limit?: number;
  }): Promise<IPaginate<IOrganisation> | undefined> {
    let limit = opts.limit;
    if (limit && limit > 100) {
      limit = 100;
    }

    const response = await this.request({
      url: "/accounts/organisations",
      method: "get",
      params: {
        parentOrganisationId: opts.parentOrganisationId,
        cursor: opts.cursor,
        limit,
      },
    });

    if (response.status === 405) {
      // Validation errors
      return undefined;
    } else if (response.status === 401) {
      // User not authorized
      return undefined;
    } else if (response.status !== 200) {
      // Some other unexpected error
      return undefined;
    }

    let organisations: IOrganisation[] = response.data.nodes;
    const pageInfo: IPageInfo = response.data.pageInfo;

    if (opts.parentOrganisationId === undefined) {
      // We remove any parentOrganisationIds if this is the root accessible list
      organisations = organisations.map(org => ({
        id: org.id,
        name: org.name,
      }));
    }

    return {
      nodes: organisations,
      pageInfo,
    };
  }

  public async listParents(opts: {
    organisationId: string;
  }): Promise<IOrganisation[] | undefined> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/listParents`,
      method: "get",
    });

    if (response.status === 401) {
      // User not authorized
      return undefined;
    } else if (response.status === 404) {
      // Org does not exist
      return undefined;
    } else if (response.status !== 200) {
      // Some other unexpected error
      return undefined;
    }

    const organisations: IOrganisation[] = response.data;

    // Reverse the response so that the highest parent is first in the array
    return organisations.reverse();
  }

  public async getOrganisation(opts: {
    organisationId: string;
    stats?: boolean;
  }): Promise<IOrganisation | undefined> {
    const { organisationId, stats } = opts;
    let params = stats ? { stats } : undefined;

    const response = await this.request({
      url: `/accounts/organisations/${organisationId}`,
      method: "get",
      params,
    });

    if (response.status === 401) {
      // User not authorized
      return undefined;
    } else if (response.status === 404) {
      // Org does not exist
      return undefined;
    } else if (response.status !== 200) {
      // Some other unexpected error
      return undefined;
    }

    return response.data;
  }

  public async getOrganisationHierarchy(opts: {
    organisationId: string;
    stats?: boolean;
  }): Promise<IOrganisationHierarchy | undefined> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/hierarchy`,
      method: "get",
      params: opts.stats ? { stats: opts.stats } : undefined,
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async getOrganisationErrors(opts: {
    organisationId: string;
    stats?: boolean;
  }): Promise<IOrganisationErrors | undefined> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/errors`,
      method: "get",
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async createOrganisation(opts: {
    name: string;
    parentOrganisationId: string;
    publicNotes?: string;
    iul?: boolean;
    nfp?: boolean;
  }): Promise<Result<IOrganisation>> {
    const response = await this.request({
      url: `/accounts/organisations`,
      method: "post",
      data: {
        name: opts.name,
        parentOrganisationId: opts.parentOrganisationId,
        publicNotes: opts.publicNotes,
        iul: opts.iul,
        nfp: opts.nfp,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status !== 201) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async updateOrganisation(opts: {
    id: string;
    name: string;
    parentOrganisationId: string;
    publicNotes?: string;
    iul?: boolean;
    nfp?: boolean;
  }): Promise<Result<IOrganisation>> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.id}`,
      method: "put",
      data: {
        name: opts.name,
        parentOrganisationId: opts.parentOrganisationId,
        publicNotes: opts.publicNotes,
        iul: opts.iul,
        nfp: opts.nfp,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async listServiceAccounts(opts: {
    organisationId: string;
    serviceId: string;
    cursor?: string;
    limit?: number;
  }): Promise<IPaginate<IServiceAccount> | undefined> {
    let limit = opts.limit;
    if (limit && limit > 100) {
      limit = 100;
    }

    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts`,
      method: "get",
      params: {
        cursor: opts.cursor,
        limit,
      },
    });

    if (response.status === 401) {
      // User not authorized
      return undefined;
    } else if (response.status === 404) {
      // Org does not exist
      return undefined;
    } else if (response.status !== 200) {
      // Some other unexpected error
      return undefined;
    }

    return response.data;
  }

  public async getServiceAccount(opts: {
    organisationId: string;
    serviceId: string;
    serviceAccountId: string;
  }): Promise<IServiceAccount | undefined> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}`,
      method: "get",
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async updatedServiceAccount(opts: {
    organisationId: string;
    serviceId: string;
    serviceAccountId: string;
    enabled: boolean;
  }): Promise<IServiceAccount | undefined> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}`,
      method: "put",
      data: {
        enabled: opts.enabled,
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async getServiceConfiguration(opts: {
    organisationId: string;
    serviceId: string;
    serviceConfigId: string;
  }): Promise<IServiceConfiguration | undefined> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceConfigurations/${opts.serviceConfigId}`,
      method: "get",
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async getServiceConfigurations(opts: {
    organisationId: string;
    serviceId: string;
  }): Promise<Result<IPaginate<IServiceConfiguration>>> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceConfigurations`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", JSON.stringify(response.data));
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND");
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", JSON.stringify(response.data));
    }

    return ok(response.data);
  }

  public async cancelOrganisation(opts: {
    organisationId: string;
  }): Promise<Result<undefined>> {
    const response = await this.request({
      url: `/accounts/organisations/${opts.organisationId}/cancel`,
      method: "post",
      data: {},
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", JSON.stringify(response.data));
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND");
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", JSON.stringify(response.data));
    }

    return ok(undefined);
  }
}
