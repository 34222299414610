import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";
import { IPaginate } from "./utils";
import { TPermissionAction } from "../../../validatePermissionsProvider";

export interface IRole {
  roleId: string;
  organisationId: string;
  name?: string;
  description?: string;
  users: string[]; // Array of user ids
  usersWithEmail: {
    userId: string;
    userEmail?: string;
  }[];
}

export interface IUserOrgs {
  userId: string;
  organisations: {
    organisationId: string;
    permissions: {
      serviceId?: string;
      resource: string;
      actions: TPermissionAction[] | "*";
    }[];
  }[];
  updatedTime: Date;
}

export class AuthModel extends BaseModel {
  public async listRolesByUser(opts: {
    userId: string;
  }): Promise<Result<IRole[]>> {
    const response = await this.request({
      url: `/auth/users/${opts.userId}/roles`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async listRolesByOrg(opts: {
    organisationId: string;
    cursor?: string;
    limit?: number;
  }): Promise<Result<IPaginate<IRole>>> {
    const response = await this.request({
      url: `/auth/organisations/${opts.organisationId}/roles`,
      method: "get",
      params: {
        cursor: opts.cursor,
        limit: opts.limit,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async removeRoles(opts: {
    userId: string;
    roleIds?: string[];
  }): Promise<Result<IPaginate<IRole>>> {
    const response = await this.request({
      url: `/auth/users/${opts.userId}/removeRoles`,
      method: "post",
      data: {
        roleIds: opts.roleIds,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async getUserPhoto({
    userId,
  }: {
    userId: string;
  }): Promise<Result<ArrayBuffer>> {
    const response = await this.request({
      url: `/auth/users/${userId}/photo`,
      method: "get",
      binary: true,
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async getUserPermissions({
    userId,
  }: {
    userId: string;
  }): Promise<Result<IUserOrgs>> {
    const response = await this.request({
      url: `/auth/users/${userId}/permissions`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }
}
