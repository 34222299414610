import React from "react";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { statusCodeToString, getLicensing } from "./utils";
import { EWS_SERVICE_ID } from "../../constants";
import { BaseList, IListColumn } from "./BaseList";
import { useHistory } from "react-router-dom";

interface IItem {
  id: string;
  displayName: string;
  email: string;
  licensing?: string;
  status?: string;
  enabled?: boolean;
  isLoading?: boolean;
}

const serviceAccountToItem = (serviceAccount: IServiceAccount): IItem => {
  const id = serviceAccount.id;
  const email = serviceAccount.identifier;
  const displayName = serviceAccount.displayName ?? email;

  const { status, enabled } = statusCodeToString(serviceAccount.status);

  const licensing = getLicensing(serviceAccount);

  return {
    id,
    displayName,
    email,
    licensing,
    status,
    enabled
  };
};

export interface IEWSListProps {
  organisationId: string;
}

export const EWSList: React.FC<IEWSListProps> = ({ organisationId }) => {
  const history = useHistory();

  const columns: IListColumn<IItem>[] = [
    {
      key: "displayName",
      name: "Display Name",
      fieldName: "displayName",
      minWidth: 100,
      maxWidth: 200,
      onClick: item => {
        history.push(
          `/customers/${organisationId}/services/mailbox/${item.id}`
        );
      }
    },
    {
      key: "email",
      name: "Email",
      fieldName: "email",
      minWidth: 250
    },
    {
      key: "licensing",
      name: "Licensing",
      fieldName: "licensing",
      minWidth: 60
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 60
    }
  ];

  return (
    <BaseList
      serviceId={EWS_SERVICE_ID}
      organisationId={organisationId}
      columns={columns}
      serviceAccountToItem={serviceAccountToItem}
    />
  );
};
