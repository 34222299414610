import { IRootState, allStoreActions } from "../../store";
import {
  IStackTokens,
  IStyle,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  classNamesFunction,
  styled,
} from "office-ui-fabric-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Redirect } from "react-router-dom";
import { useApiClient } from "../../providers/ApiProvider";

interface ILoginFormStyles {
  loginButton: IStyle;
  formOuter: IStyle;
  errorMessage: IStyle;
  input: IStyle;
  verticalGapStackTokens: IStackTokens;
}

interface ILoginFormProps {
  styles?: ILoginFormStyles;
}

const loginFormStyles: ILoginFormStyles = {
  loginButton: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
    height: 44,
  },
  formOuter: {
    marginBottom: 50,
    width: "100%",
    display: "inline-block",
  },
  errorMessage: {
    color: "red",
  },
  verticalGapStackTokens: {
    childrenGap: 20,
  },
  input: {
    padding: 0,
  },
};

const getClassNames = classNamesFunction<{}, ILoginFormStyles>();

const BaseLoginForm: React.FC<ILoginFormProps> = ({ styles }) => {
  let empty: string | undefined;
  const [username, setUsername] = useState(empty);
  const [password, setPassword] = useState(empty);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(empty);
  const client = useApiClient();
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => {
    return state.auth;
  });

  if (state.loggedIn) {
    const nextUrl = localStorage.getItem("backup365_next_url");
    if (nextUrl) {
      localStorage.removeItem("backup365_next_url");
      return <Redirect to={{ pathname: nextUrl }} />;
    }
    return <Redirect to={{ pathname: "/" }} />;
  }

  const handleUsernameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => setUsername(newValue);

  const handlePasswordChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => setPassword(newValue);

  const handleLogin = () => {
    setErrorMessage(empty);
    if (!username || !password) {
      setErrorMessage("Please enter your username and password first");
      return;
    }

    setIsLoading(true);

    client.public
      .login({ username, password })
      .then(loginResult => {
        if (loginResult.idToken) {
          const authActions = allStoreActions.auth;
          dispatch(authActions.setTokens(loginResult));
        } else {
          setIsLoading(false);
          setErrorMessage(loginResult.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage(err.message);
      });
  };

  const keyPressed = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const classNames = getClassNames(styles);

  let spinnerContent = <div></div>;
  let loginButtonDisabled = false;
  if (isLoading) {
    spinnerContent = <Spinner size={SpinnerSize.large} />;
    loginButtonDisabled = true;
  }

  return (
    <Stack
      className={classNames.formOuter}
      tokens={loginFormStyles.verticalGapStackTokens}
    >
      {spinnerContent}
      <TextField
        underlined
        placeholder="Username"
        onChange={handleUsernameChange}
        inputClassName={classNames.input}
        onKeyDown={keyPressed}
        value={username}
      />
      <TextField
        underlined
        placeholder="Password"
        type="password"
        inputClassName={classNames.input}
        onChange={handlePasswordChange}
        onKeyDown={keyPressed}
        value={password}
      />
      <div className={classNames.errorMessage}>{errorMessage}</div>
      <div className="">
        <PrimaryButton
          disabled={loginButtonDisabled}
          text="Login"
          onClick={handleLogin}
          className={classNames.loginButton}
        />
      </div>
    </Stack>
  );
};

export const LoginForm = styled(BaseLoginForm, loginFormStyles);
