import {
  IAuthActions,
  IAuthTokens,
  ISetAuthTokens,
  ISetIsLoggedOut,
  ISetUserPermissions,
  SET_AUTH_TOKENS,
  SET_IS_LOGGED_OUT,
  SET_USER_PERMISSIONS,
} from "./types";

import { IUserOrgs } from "../../providers/ApiProvider/ApiClient/models/auth";

export const setTokens = (tokens: IAuthTokens): ISetAuthTokens => {
  return {
    type: SET_AUTH_TOKENS,
    payload: tokens,
  };
};

export const setIsLoggedOut = (): ISetIsLoggedOut => {
  return {
    type: SET_IS_LOGGED_OUT,
  };
};

export const setUserPermissions = (
  userPermissions: IUserOrgs
): ISetUserPermissions => {
  return {
    type: SET_USER_PERMISSIONS,
    payload: userPermissions,
  };
};

export const authActions: IAuthActions = {
  setTokens,
  setIsLoggedOut,
  setUserPermissions,
};
