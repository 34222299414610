import React, { useCallback, useEffect, useState } from "react";

import { EwsStats } from "./EwsStats";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { SharepointStats } from "./SharepointStats";
import { useApiClient } from "../../providers/ApiProvider";

export interface IServiceAccountStatsProps {
  serviceId: string;
  serviceName: "mailbox" | "onedrive" | "sharepoint";
  organisationId: string;
  serviceAccountId: string;
}

export const ServiceAccountStats: React.FC<IServiceAccountStatsProps> = ({
  serviceId,
  serviceName,
  organisationId,
  serviceAccountId,
}) => {
  const api = useApiClient();

  const [serviceAccount, setServiceAccount] = useState<IServiceAccount>();
  const [isServiceAccountLoading, setServiceAccountLoading] = useState(true);
  const [stats, setStats] = useState<{ [k: string]: number }>();
  const [firstSync, setFirstSync] = useState<Date>();
  const [lastSync, setLastSync] = useState<Date>();
  const [isStatsLoading, setStatsLoading] = useState(true);
  const [firstSyncLoading, setFirstSyncLoading] = useState(true);
  const [lastSyncLoading, setLastSyncLoading] = useState(true);
  const [isEnabledLoading, setEnabledLoading] = useState(false);

  useEffect(() => {
    api.accounts
      .getServiceAccount({
        organisationId,
        serviceId,
        serviceAccountId,
      })
      .then(res => {
        if (res === undefined) {
          // TODO redirect to 404
        }

        setServiceAccount(res);
        setServiceAccountLoading(false);
      });

    api.reporting
      .lifetimeStats({
        organisationId,
        serviceId,
        serviceAccountId,
      })
      .then(res => {
        setStats(res);
        setStatsLoading(false);
      });

    api.reporting
      .firstSyncTime({
        organisationId,
        serviceId,
        serviceAccountId,
      })
      .then(res => {
        setFirstSync(res);
        setFirstSyncLoading(false);
      });

    api.reporting
      .lastSyncTime({
        organisationId,
        serviceId,
        serviceAccountId,
      })
      .then(res => {
        setLastSync(res);
        setLastSyncLoading(false);
      });
  }, [api, serviceId, organisationId, serviceAccountId]);

  const toggleEnabled = useCallback(
    (checked: boolean) => {
      setEnabledLoading(true);

      api.accounts
        .updatedServiceAccount({
          serviceId,
          organisationId,
          serviceAccountId,
          enabled: checked,
        })
        .then(res => {
          if (res !== undefined) {
            setServiceAccount(res);
          }

          setEnabledLoading(false);
        })
        .catch(() => setEnabledLoading(false));
    },
    [serviceAccountId, serviceId, organisationId, api.accounts]
  );

  if (serviceName === "sharepoint" || serviceName === "onedrive") {
    return (
      <SharepointStats
        serviceType={serviceName}
        serviceAccount={serviceAccount}
        isServiceAccountLoading={isServiceAccountLoading}
        stats={stats}
        isStatsLoading={isStatsLoading}
        firstBackup={firstSync}
        lastBackup={lastSync}
        isBackupTimeLoading={firstSyncLoading || lastSyncLoading}
        toggleEnabled={toggleEnabled}
        isEnabledLoading={isEnabledLoading}
        organisationId={organisationId}
        serviceId={serviceId}
      />
    );
  } else if (serviceName === "mailbox") {
    return (
      <EwsStats
        serviceAccount={serviceAccount}
        isServiceAccountLoading={isServiceAccountLoading}
        stats={stats}
        isStatsLoading={isStatsLoading}
        firstBackup={firstSync}
        lastBackup={lastSync}
        isBackupTimeLoading={firstSyncLoading || lastSyncLoading}
        toggleEnabled={toggleEnabled}
        isEnabledLoading={isEnabledLoading}
        organisationId={organisationId}
        serviceId={serviceId}
      />
    );
  } else {
    return <></>;
  }
};
