import React from "react";
import { TopMenu } from "./TopMenu";
import { mergeStyleSets } from "office-ui-fabric-react";
import { useSmallLayout } from "./ResponsiveLayout";

export interface IUnauthedPageLayoutProps {}

export const UnauthedPageLayout: React.FC<IUnauthedPageLayoutProps> = ({
  children,
}) => {
  const classNames = mergeStyleSets({
    app: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    topMenu: {
      width: "100%",
    },
    body: {
      display: "flex",
      height: "100%",
    },
    content: {
      width: "100%",
      overflowY: "scroll",
      padding: 48,
    },
    contentSmall: {
      width: "100%",
      overflowY: "scroll",
      padding: 20,
    },
  });

  const renderSmall = useSmallLayout();
  const contentWrapper = renderSmall
    ? classNames.contentSmall
    : classNames.content;

  return (
    <div className={classNames.app}>
      <div className={classNames.topMenu}>
        <TopMenu isAuthed={false} />
      </div>
      <div className={classNames.body}>
        <div className={contentWrapper}>{children}</div>
      </div>
    </div>
  );
};
