import React, { createContext, useContext, useEffect, useState } from 'react';

interface WebSocketContextProps {
    children: React.ReactNode;
}

interface WebSocketContextValue {
    socket: WebSocket | null;
}

const WebSocketContext = createContext<WebSocketContextValue | undefined>(undefined);

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};

const WebSocketProvider: React.FC<WebSocketContextProps> = ({ children }) => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    useEffect(() => {
        const newSocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_ENDPOINT || '');

        setSocket(newSocket);

        setInterval(() => {
            if (newSocket.readyState === WebSocket.OPEN) {
                console.log('ping');
                newSocket.send('ping');
            }
        }, 30000);

        return () => {
            newSocket.close();
        };
    }, []);


    return <WebSocketContext.Provider value={{ socket }}>{children}</WebSocketContext.Provider>;
};

export default WebSocketProvider;