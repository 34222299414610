import { BaseListView, IListViewColumn } from "./BaseListView";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Link,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Toggle,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import EnforcePermissions from "../Permissions";
import { IPaginate } from "../../providers/ApiProvider/ApiClient/models/utils";
import { IProvisioningRequest } from "../../providers/ApiProvider/ApiClient/models/provisioning";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { Provision } from "./Provision";
import { useApiClient } from "../../providers/ApiProvider";

export interface IBaseItem extends Record<string, any> {
  id: string;
  enabled?: boolean;
  isLoading?: boolean;
}

export interface IListColumn<TItem extends IBaseItem> {
  key: string;
  name: string;
  fieldName: string;
  minWidth: number;
  maxWidth?: number;
  onClick?: (item: TItem) => void;
}

type TItemMap<TItem extends IBaseItem> = { [k: string]: TItem };

interface IItemState<TItem extends IBaseItem> {
  itemMap?: TItemMap<TItem>;
  cursor?: string;
  hasNextPage?: boolean;
}

type TAction<TItem extends IBaseItem> =
  | { type: "MERGE_RESPONSE"; payload: IPaginate<IServiceAccount> }
  | { type: "UPDATE_ITEM"; payload: TItem }
  | { type: "UPDATE_SERVICE_ACCOUNT"; payload: IServiceAccount }
  | { type: "CLEAR_STATE" };

type TServiceAccountToItemFn<TItem extends IBaseItem> = (
  serviceAccount: IServiceAccount
) => TItem;

type TServiceAccountFilterFn = (serviceAccount: IServiceAccount) => boolean;

const reducerFactory = <TItem extends IBaseItem>(
  serviceAccountToItem: TServiceAccountToItemFn<TItem>,
  serviceAccountFilter: TServiceAccountFilterFn = () => true
): React.Reducer<IItemState<TItem>, TAction<TItem>> => {
  const itemStateReducer: React.Reducer<IItemState<TItem>, TAction<TItem>> = (
    prevState,
    action
  ) => {
    let item: TItem;
    switch (action.type) {
      case "MERGE_RESPONSE":
        const items = action.payload.nodes
          .filter(serviceAccountFilter)
          .map(serviceAccountToItem);
        const newItemMap: TItemMap<TItem> = items.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr,
          };
        }, {});

        return {
          itemMap: {
            ...prevState.itemMap,
            ...newItemMap,
          },
          cursor: action.payload.pageInfo.cursor,
          hasNextPage: action.payload.pageInfo.hasNextPage,
        };
      case "UPDATE_ITEM":
        item = action.payload;

        return {
          ...prevState,
          itemMap: {
            ...prevState.itemMap,
            [item.id]: item,
          },
        };
      case "UPDATE_SERVICE_ACCOUNT":
        item = serviceAccountToItem(action.payload);

        return {
          ...prevState,
          itemMap: {
            ...prevState.itemMap,
            [item.id]: item,
          },
        };
      case "CLEAR_STATE":
        return {};
      default:
        return prevState;
    }
  };

  return itemStateReducer;
};

interface IDialogState {
  title: string;
  subText: string;
  hidden: boolean;
  onSubmit: () => void;
}

export interface IBaseListProps<TItem extends IBaseItem> {
  serviceId: string;
  organisationId: string;
  columns: IListColumn<TItem>[];
  serviceAccountToItem: TServiceAccountToItemFn<TItem>;
  serviceAccountFilter?: TServiceAccountFilterFn;
}

export const BaseList = <TItem extends IBaseItem>({
  serviceId,
  organisationId,
  columns,
  serviceAccountToItem,
  serviceAccountFilter,
}: IBaseListProps<TItem>): React.ReactElement => {
  const api = useApiClient();
  const shouldSetState = useRef(true);

  const [{ itemMap, cursor, hasNextPage }, dispatch] = useReducer(
    reducerFactory(serviceAccountToItem, serviceAccountFilter),
    {}
  );

  const [isLoading, setLoading] = useState(true);

  const [permissionDisabled, setPermissionDisabled] = useState<boolean>(true);

  const [dialogState, setDialogState] = useState<IDialogState>();
  const clearDialog = useCallback(() => {
    setDialogState(undefined);
  }, [setDialogState]);

  const fetchServiceAccounts = useCallback(
    async (cursor?: string) => {
      const response = await api.accounts.listServiceAccounts({
        serviceId,
        organisationId,
        cursor,
      });

      return response;
    },
    [api.accounts, serviceId, organisationId]
  );

  useEffect(() => {
    shouldSetState.current = true;
    dispatch({ type: "CLEAR_STATE" });
    setLoading(true);

    fetchServiceAccounts().then(response => {
      if (shouldSetState.current) {
        if (response) {
          dispatch({ type: "MERGE_RESPONSE", payload: response });
        }

        setLoading(false);
      }
    });

    return () => {
      shouldSetState.current = false;
    };
  }, [api, fetchServiceAccounts, serviceAccountFilter]);

  const loadPage = async () => {
    if (!isLoading) {
      setLoading(true);

      const response = await fetchServiceAccounts(cursor);

      if (shouldSetState.current) {
        if (response) {
          dispatch({ type: "MERGE_RESPONSE", payload: response });
        }

        setLoading(false);
      }
    }
  };

  const [provisioningRequests, setProvisioningRequests] = useState<
    IProvisioningRequest[]
  >();

  const loadProvisioningRequests = useCallback(
    ({
      serviceId,
      organisationId,
    }: {
      serviceId: string;
      organisationId: string;
    }) => {
      api.provisioning
        .listProvisioningRequests({
          serviceId,
          organisationId,
          status: [
            "PENDING",
            "IN_PROGRESS",
            "COMPLETE",
            "AWAITING_CONSENT",
            "FAILED",
          ],
        })
        .then(res => {
          if (res.isOk()) {
            setProvisioningRequests(res.value.nodes);
          }
        });
    },
    [api.provisioning]
  );

  // Fetch Provisioning Requests
  useEffect(() => {
    if (serviceId !== undefined) {
      loadProvisioningRequests({ serviceId, organisationId });
    } else {
      setProvisioningRequests([]);
    }
  }, [serviceId, organisationId, loadProvisioningRequests]);

  const items = itemMap ? Object.values(itemMap) : [];

  const toggleEnabled = useCallback(
    (item: TItem) => {
      dispatch({ type: "UPDATE_ITEM", payload: { ...item, isLoading: true } });

      api.accounts
        .updatedServiceAccount({
          serviceId,
          organisationId,
          serviceAccountId: item.id,
          enabled: !item.enabled,
        })
        .then(res => {
          if (res) {
            dispatch({ type: "UPDATE_SERVICE_ACCOUNT", payload: res });
          } else {
            dispatch({ type: "UPDATE_ITEM", payload: item });
          }
        })
        .catch(() => {
          dispatch({ type: "UPDATE_ITEM", payload: item });
        });
    },
    [api, organisationId, serviceId]
  );

  const mappedColumns: IListViewColumn[] = useMemo(() => {
    const results: IListViewColumn[] = columns.map((col, i) => {
      const mappedCol: IListViewColumn = {
        key: col.key,
        name: col.name,
        fieldName: col.fieldName,
        minWidth: col.minWidth,
        maxWidth: col.maxWidth,
        isResizable: true,
        isPadded: true,
        isSortable: true,
      };

      if (i === 0) {
        mappedCol.isSorted = true;
      } else {
        mappedCol.isCollapsible = true;
      }

      if (col.onClick) {
        mappedCol.onRender = (item: TItem) => (
          <Link key={item[col.fieldName]} onClick={() => col.onClick?.(item)}>
            {item[col.fieldName]}
          </Link>
        );
      }

      return mappedCol;
    });

    results.push({
      key: "enabled",
      name: "Enabled",
      minWidth: 40,
      maxWidth: 40,
      isResizable: true,
      isPadded: true,
      onRender: item =>
        item.isLoading ? (
          <Spinner size={SpinnerSize.medium} />
        ) : (
          <Toggle
            onClick={() => {
              let serviceAccountName: string;
              let serviceAccountType: string;

              if (serviceId === EWS_SERVICE_ID) {
                serviceAccountName = item.email;
                serviceAccountType = "mailbox";
              } else if (serviceId === SHAREPOINT_SERVICE_ID) {
                serviceAccountName = item.siteTitle;
                serviceAccountType = "site";
              } else {
                throw new Error(`Invalid serviceId: ${serviceId}`);
              }

              if (item.enabled) {
                setDialogState({
                  hidden: false,
                  title: `Disable ${serviceAccountName} Backups?`,
                  subText: `Please be aware when this ${serviceAccountType} is disabled it will no longer backup any\
                            new data. All data that has been backed up to date, will remain and be searchable. You\
                            will also continue to be billed for all Microsoft Licensed users.`,
                  onSubmit: () => {
                    toggleEnabled(item);
                  },
                });
              } else {
                toggleEnabled(item);
              }
            }}
            checked={item.enabled}
            disabled={permissionDisabled === true || item.enabled === undefined}
          />
        ),
    });

    return results;
  }, [columns, serviceId, toggleEnabled, permissionDisabled]);

  const isDataLoading =
    itemMap === undefined ||
    (items.length === 0 && provisioningRequests === undefined);

  return (
    <>
      <Dialog
        hidden={dialogState?.hidden ?? true}
        onDismiss={clearDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: dialogState?.title,
          subText: dialogState?.subText,
        }}
      >
        <DialogFooter>
          <PrimaryButton
            text="Ok"
            onClick={() => {
              dialogState?.onSubmit();
              clearDialog();
            }}
          />
          <DefaultButton text="Cancel" onClick={clearDialog} />
        </DialogFooter>
      </Dialog>
      {!isDataLoading &&
      items.length === 0 &&
      provisioningRequests !== undefined &&
      provisioningRequests.filter(req => req.status === "COMPLETE").length ===
        0 ? (
        <Provision
          serviceId={serviceId}
          organisationId={organisationId}
          provisioningRequests={provisioningRequests}
          reloadRequests={() => {
            setProvisioningRequests(undefined);
            loadProvisioningRequests({ serviceId, organisationId });
          }}
        />
      ) : (
        <>
          <EnforcePermissions
            organisationId={organisationId}
            serviceId={serviceId}
            action="Update"
            resource="accounts:serviceAccounts"
            enforceType="DISABLE"
            setDisabled={setPermissionDisabled}
          ></EnforcePermissions>

          <BaseListView
            items={items}
            columns={mappedColumns}
            isDataLoading={isDataLoading}
            hasNextPage={hasNextPage}
            loadNextPage={loadPage}
          />
        </>
      )}
    </>
  );
};
