import { IHistoryItem, IRestoreHistoryColumn } from "./RestoreHistoryView";

import { DateTime } from "luxon";
import {IRestoreRequest} from "../../providers/ApiProvider/ApiClient/models/restore";

export const prepareColumns = (): IRestoreHistoryColumn[] => [
  {
    key: "startDate",
    name: "Start Time",
    fieldName: "startDate",
    minWidth: 100,
    maxWidth: 300,
    isResizable: true,
    isSortable: true,
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: "endDate",
    name: "End Time",
    fieldName: "endDate",
    minWidth: 100,
    maxWidth: 300,
    isSortable: true,
    isResizable: true,
    isCollapsable: true,
  },
  {
    key: "friendlyStatus",
    name: "Status",
    fieldName: "friendlyStatus",
    minWidth: 100,
    maxWidth: 300,
    isSortable: true,
    isResizable: true,
  },
  {
    key: "restoreType",
    name: "Restore Type",
    fieldName: "restoreType",
    minWidth: 100,
    maxWidth: 300,
    isCollapsable: true,
    isSortable: true,
    isResizable: true,
  },
];

export const prepareItems = ({
                               filterQuery,
                               restoreHistories,
                               columns,
                             }: {
  restoreHistories: IRestoreRequest[];
  filterQuery?: string;
  columns?: IRestoreHistoryColumn[];
}) => {
  let items: IHistoryItem[] = restoreHistories.map(restoreHistory => {
    let endDate = "";
    if (restoreHistory.endTime) {
      endDate = DateTime.fromISO(restoreHistory.endTime).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
    }
    return {
      ...restoreHistory,
      key: restoreHistory.restoreId,
      startDate: DateTime.fromISO(restoreHistory.startTime).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      }),
      rawStartDate: restoreHistory.startTime,
      endDate,
      rawEndDate: restoreHistory.endTime,
      friendlyStatus: makeTitleCase(
          restoreHistory.status.toLowerCase().replace("_", " ")
      ),
      restoreType: makeTitleCase(
          restoreHistory.config.restoreType.toLowerCase().replace("_", " ")
      ),
    };
  });

  if (columns) {
    for (const column of columns) {
      if (column.isSorted) {
        items = items.sort((a, b) => {
          const aStr: string | undefined = column.fieldName
              ? a[column.fieldName]
              : undefined;
          const bStr: string | undefined = column.fieldName
              ? b[column.fieldName]
              : undefined;

          let res = 0;
          if (column.fieldName === "startDate") {
            res =
                new Date(a.rawStartDate).getTime() -
                new Date(b.rawStartDate).getTime();
          } else if (column.fieldName === "endDate") {
            if (a.rawEndDate !== undefined && b.rawEndDate !== undefined) {
              res =
                  new Date(a.rawEndDate).getTime() -
                  new Date(b.rawEndDate).getTime();
            } else if (a.rawEndDate !== undefined) {
              return -1;
            } else if (b.rawEndDate !== undefined) {
              return 1;
            }
          } else {
            if (aStr !== undefined && bStr !== undefined) {
              res = aStr.localeCompare(bStr);
            } else if (aStr !== undefined) {
              res = -1;
            } else if (bStr !== undefined) {
              res = 1;
            }
          }

          if (column.isSortedDescending) {
            res *= -1;
          }

          return res;
        });
      }
    }
  }

  if (filterQuery) {
    items = items.filter(item =>
        Object.values(item)
            .map(i => {
              return (
                  typeof i === "string" &&
                  i.toLowerCase().includes(filterQuery.toLowerCase())
              );
            })
            .includes(true)
    );
  }

  return items;
};

export const makeTitleCase = (str: string) => {
  return str
      .split(" ")
      .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(" ");
};
