import { IStyle, Text, mergeStyles } from "office-ui-fabric-react";
import React, { useState } from "react";

import CustomerInvitesView from "./CustomerInvitesView";
import CustomerRolesView from "./CustomerRolesView";
import { IRootState } from "../../store";
import { useSelector } from "react-redux";

const headerStyle: IStyle = { marginBottom: 50 };

export interface IInviteRoleConfig {}

const CustomerRoles: React.FC<IInviteRoleConfig> = () => {
  const [didAccept, setDidAccept] = useState(false);
  const header = mergeStyles(headerStyle);

  const state = useSelector((state: IRootState) => {
    return state.auth;
  });

  const userId = state.userId;
  if (!userId) {
    return <></>;
  }

  return (
    <>
      <div className={header}>
        <Text variant="xxLarge">Your Customers Roles</Text>
      </div>
      <div className={header}>
        <CustomerInvitesView userId={userId} setDidAccept={setDidAccept} />
      </div>
      <CustomerRolesView
        userId={userId}
        setDidAccept={setDidAccept}
        didAccept={didAccept}
      />
    </>
  );
};

export default CustomerRoles;
