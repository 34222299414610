import React, { useState, useCallback } from "react";
import { IItem } from "../BrowseView";
import { IServiceAccount } from "../../../providers/ApiProvider/ApiClient/models/accounts";
import { RestoreItemsModalView } from "./RestoreItemsModalView";
import { TPerformRestoreFn } from "..";

export interface IRestoreItemsModalProps {
  isOpen: boolean;
  onDismiss?: (result?: boolean) => void;
  selectedFiles?: IItem[];
  defaultServiceAccount: string;
  serviceAccounts: IServiceAccount[];
  isServiceAccountsLoading?: boolean;
  performRestore: TPerformRestoreFn;
  timestamp?: string;
}

export const RestoreItemsModal: React.FC<IRestoreItemsModalProps> = ({
  isOpen,
  onDismiss,
  selectedFiles,
  serviceAccounts,
  defaultServiceAccount,
  isServiceAccountsLoading,
  performRestore,
  timestamp,
}) => {
  const count = selectedFiles?.length ?? 0;
  const [download, setDownload] = useState(true);
  const [selectedServiceAccountId, setSelectedServiceAccount] = useState<
    string | undefined
  >(defaultServiceAccount);
  const [isLoadingRestore, setLoadingRestore] = useState(false);

  const restore = useCallback(async () => {
    if (selectedFiles !== undefined && selectedServiceAccountId !== undefined) {
      setLoadingRestore(true);

      const folders = selectedFiles.filter(item => {
         return item.itemType === 'folder'
      })

      const files = selectedFiles.filter(item => {
         return item.itemType === 'file'
      })

      const fileIds = files.map(file => file.itemId);
      const folderIds = folders.map(file => file.itemId);

      const result = await performRestore({
        sourceServiceAccountId: defaultServiceAccount,
        destinationServiceAccountId: selectedServiceAccountId,
        fileIds,
        folderIds,
        timestamp,
        download
      });

      setLoadingRestore(false);
      onDismiss?.(result);
    }
  }, [
    selectedFiles,
    defaultServiceAccount,
    selectedServiceAccountId,
    performRestore,
    onDismiss,
    timestamp,
    download
  ]);

  return (
    <RestoreItemsModalView
      isOpen={isOpen}
      onDismiss={onDismiss}
      selectedFileCount={count}
      serviceAccounts={serviceAccounts}
      isServiceAccountsLoading={isServiceAccountsLoading}
      selectedServiceAccountId={selectedServiceAccountId}
      setSelectedServiceAccount={setSelectedServiceAccount}
      isRestoreLoading={isLoadingRestore}
      restore={restore}
      setDownload={setDownload}
    />
  );
};
