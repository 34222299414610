import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IStackTokens,
  IconButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TagPicker,
  Text,
  Toggle,
  getTheme,
  mergeStyles,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, { useCallback, useReducer, useState } from "react";

import { CREATE_REPORT_ID } from ".";
import { FormField } from "./FormField";
import { IComboBoxOption } from "office-ui-fabric-react";
import { IDropdownOption } from "office-ui-fabric-react";
import { IScheduledReportConfig } from "../../providers/ApiProvider/ApiClient/models/reporting";
import { timezones } from "../../utils/timezones";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../utils/validateEmail";

export interface IScheduledConfigViewProps {
  organisationName?: string;
  config: IScheduledReportConfig;
  updateConfig: (config: IScheduledReportConfig) => Promise<void>;
  deleteConfig: (opts: {
    ownerId: string;
    reportConfigId: string;
  }) => Promise<void>;
}

const numberToOrdinal = (n: number): string => {
  const nStr = n.toString();
  const digits = nStr.split("");

  if (digits.length === 2 && digits[0] === "1") {
    // The ordinals for 10-19 all end in 'th'
    return nStr + "th";
  }

  const lastDigit = digits[digits.length - 1];
  let suffix: string;
  if (lastDigit === "1") {
    suffix = "st";
  } else if (lastDigit === "2") {
    suffix = "nd";
  } else if (lastDigit === "3") {
    suffix = "rd";
  } else {
    suffix = "th";
  }

  return nStr + suffix;
};

interface IFormState {
  serviceIds: string[];
  reportPeriod: string;
  daysOfWeek: number[];
  daysOfMonth: number[];
  timeOfDay: string;
  timezone: string;
  recipientAddresses: string[];
  disabled: boolean;
}

type TAction = {
  type: "UPDATE_FORM";
  payload: { field: keyof IFormState; value: IFormState[keyof IFormState] };
};

const createInitialState = (config: IScheduledReportConfig): IFormState => ({
  serviceIds: config.serviceIds,
  reportPeriod: config.reportPeriod,
  daysOfWeek: config.daysOfWeek ?? [],
  daysOfMonth: config.daysOfMonth ?? [],
  timeOfDay: config.timeOfDay,
  timezone: config.timezone,
  recipientAddresses: config.recipientAddresses,
  disabled: config.disabled,
});

const formReducer: React.Reducer<IFormState, TAction> = (prevState, action) => {
  switch (action.type) {
    case "UPDATE_FORM":
      return {
        ...prevState,
        [action.payload.field]: action.payload.value,
      };

    default:
      return prevState;
  }
};

export const ScheduledConfigView: React.FC<IScheduledConfigViewProps> = ({
  organisationName,
  config,
  updateConfig,
  deleteConfig,
}) => {
  const [showConfirmDialog, setConfirmDialog] = useState(false);

  const updateConfigFromForm = useCallback(
    async (form: IFormState) => {
      const updatedConfig: IScheduledReportConfig = {
        ownerId: config.ownerId,
        ownerType: config.ownerType,
        reportConfigId: config.reportConfigId,
        // This isn't changed because we don't support changing it in the UI
        serviceAccountIds: config.serviceAccountIds,
        serviceIds: form.serviceIds,
        // We can safely cast this because we control the values of the keys
        // in the reportPeriod ChoiceGroup.
        reportPeriod: form.reportPeriod as IScheduledReportConfig["reportPeriod"],
        daysOfWeek: form.daysOfWeek.length !== 0 ? form.daysOfWeek : undefined,
        daysOfMonth:
          form.daysOfMonth.length !== 0 ? form.daysOfMonth : undefined,
        timeOfDay: form.timeOfDay,
        timezone: form.timezone,
        recipientAddresses: form.recipientAddresses,
        disabled: form.disabled,
      };

      await updateConfig(updatedConfig);
    },
    [updateConfig, config]
  );

  const history = useHistory();
  const theme = getTheme();

  const navigateBack = useCallback(() => {
    const pathnameParts = history.location.pathname.split("/");

    // Remove the last 2 parts from the pathname.
    pathnameParts.pop();
    pathnameParts.pop();

    const pathname = pathnameParts.join("/");

    history.push(pathname);
  }, [history]);

  const [formState, dispatch] = useReducer(
    formReducer,
    createInitialState(config)
  );

  const updateForm = useCallback(
    (payload: TAction["payload"]) => {
      dispatch({ type: "UPDATE_FORM", payload });
    },
    [dispatch]
  );

  const [isUpdating, setUpdating] = useState(false);

  const daysOfWeek: IDropdownOption[] = [
    { key: 1, text: "Monday" },
    { key: 2, text: "Tuesday" },
    { key: 3, text: "Wednesday" },
    { key: 4, text: "Thursday" },
    { key: 5, text: "Friday" },
    { key: 6, text: "Saturday" },
    { key: 7, text: "Sunday" },
  ];

  const daysOfMonth: IDropdownOption[] = Array(31)
    .fill(null)
    // Add 1 to get the range 1 - 31 rather than 0 - 30
    .map((_, i) => i + 1)
    .map(i => ({
      key: i,
      text: numberToOrdinal(i),
    }));

  const times: IDropdownOption[] = Array(48)
    .fill(null)
    .map((_, i) => {
      const time = i / 2;

      const hours = Math.floor(time);
      const minutes = (time - hours) * 60;

      let meridiem: string;
      let hrStr: string;
      if (hours < 12) {
        meridiem = "am";
        if (hours === 0) {
          hrStr = "12";
        } else {
          hrStr = hours.toString();
        }
      } else {
        meridiem = "pm";
        let hr;
        if (hours === 12) {
          hr = 12;
        } else {
          hr = hours - 12;
        }

        hrStr = hr.toString();
      }

      const minStr = minutes.toString().padStart(2, "0");
      const hrKey = hours.toString().padStart(2, "0");

      const key = `${hrKey}:${minStr}`;
      const text = `${hrStr}:${minStr}${meridiem}`;

      return { key, text };
    });

  const tzs: IComboBoxOption[] = timezones.map(tz => ({
    key: tz.key,
    text: tz.displayName,
  }));

  const tokens: IStackTokens = {
    childrenGap: "m",
  };

  const container = mergeStyles({ padding: 10 });

  let servicesError: string | undefined;
  if (formState.serviceIds.length === 0) {
    servicesError = "At least one service is required";
  }

  let daysOfWeekError: string | undefined;
  if (config.daysOfWeek !== undefined && formState.daysOfWeek.length === 0) {
    daysOfWeekError = "At least one day of the week is required";
  }

  let daysOfMonthError: string | undefined;
  if (config.daysOfMonth !== undefined && formState.daysOfMonth.length === 0) {
    daysOfMonthError = "At least one day of the month is required";
  }

  if (config.daysOfWeek === undefined && config.daysOfMonth === undefined) {
    let msg: string | undefined;
    if (
      formState.daysOfWeek.length === 0 &&
      formState.daysOfMonth.length === 0
    ) {
      msg = "Either a day of week or a day of month is required";
    } else if (
      formState.daysOfWeek.length > 0 &&
      formState.daysOfMonth.length > 0
    ) {
      msg = `Only one of "Days of Week" and "Days of Month" is allowed`;
    }

    daysOfWeekError = msg;
    daysOfMonthError = msg;
  }

  let recipientsError: string | undefined;
  if (
    formState.recipientAddresses.length === 0 ||
    (formState.recipientAddresses.length === 1 &&
      formState.recipientAddresses[0] === "betareports@backup365.io")
  ) {
    recipientsError = "At least one recipient is required";
  } else {
    const validEmails = formState.recipientAddresses.map(validateEmail);
    if (validEmails.includes(false)) {
      recipientsError = "Must be a valid email address";
    }
  }

  const noErrors =
    servicesError === undefined &&
    daysOfWeekError === undefined &&
    daysOfMonthError === undefined &&
    recipientsError === undefined;

  const toggleStyles = mergeStyles({
    paddingTop: 10,
    paddingLeft: 10,
  });

  return (
    <>
      <Dialog
        hidden={!showConfirmDialog}
        onDismiss={() => {
          setConfirmDialog(false);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Are you sure you want to delete this report?",
          subText: "This cannot be undone",
        }}
      >
        <DialogFooter>
          <PrimaryButton
            text="Ok"
            onClick={() => {
              setUpdating(true);
              setConfirmDialog(false);
              deleteConfig({
                ownerId: config.ownerId,
                reportConfigId: config.reportConfigId,
              }).then(() => {
                navigateBack();
              });
            }}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              setConfirmDialog(false);
            }}
          />
        </DialogFooter>
      </Dialog>
      <Text variant="xxLarge">{organisationName}</Text>
      <Stack horizontal tokens={{ childrenGap: "l2" }} verticalAlign="center">
        <Toggle
          className={toggleStyles}
          label="Enabled"
          inlineLabel
          checked={!formState.disabled}
          onChange={(ev, checked) => {
            if (checked !== undefined) {
              updateForm({ field: "disabled", value: !checked });
            }
          }}
        />
        {config.reportConfigId !== CREATE_REPORT_ID ? (
          <IconButton
            onClick={() => {
              setConfirmDialog(true);
            }}
            iconProps={{ iconName: "Delete" }}
            styles={{
              icon: { color: theme.palette.red },
            }}
          />
        ) : (
          undefined
        )}
      </Stack>
      <Stack className={container} tokens={tokens} horizontal wrap>
        <FormField title="Services" errorMessage={servicesError}>
          <Checkbox
            label="Mailbox"
            checked={formState.serviceIds.includes(EWS_SERVICE_ID)}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                const value = checked
                  ? [...formState.serviceIds, EWS_SERVICE_ID]
                  : formState.serviceIds.filter(
                      serviceId => serviceId !== EWS_SERVICE_ID
                    );

                updateForm({ field: "serviceIds", value });
              }
            }}
          />
          <Checkbox
            label="SharePoint/OneDrive"
            checked={formState.serviceIds.includes(SHAREPOINT_SERVICE_ID)}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                const value = checked
                  ? [...formState.serviceIds, SHAREPOINT_SERVICE_ID]
                  : formState.serviceIds.filter(
                      serviceId => serviceId !== SHAREPOINT_SERVICE_ID
                    );

                updateForm({ field: "serviceIds", value });
              }
            }}
          />
        </FormField>
        <FormField title="Period">
          <ChoiceGroup
            required
            options={[
              { key: "DAILY", text: "Daily" },
              { key: "WEEKLY", text: "Weekly" },
              { key: "MONTHLY", text: "Monthly" },
            ]}
            selectedKey={formState.reportPeriod}
            onChange={(ev, option) => {
              if (option) {
                updateForm({ field: "reportPeriod", value: option.key });
              }
            }}
          />
        </FormField>
        {/* Only include the daysOfWeek field if the field exists on the config. */
        config.daysOfWeek !== undefined ||
        (config.daysOfWeek === undefined &&
          config.daysOfMonth === undefined) ? (
          <FormField title="Days of Week">
            <Dropdown
              multiSelect
              options={daysOfWeek}
              selectedKeys={formState.daysOfWeek.sort()}
              onChange={(ev, item) => {
                if (item) {
                  const value = item.selected
                    ? [...formState.daysOfWeek, item.key as number]
                    : formState.daysOfWeek.filter(key => key !== item.key);

                  updateForm({ field: "daysOfWeek", value });
                }
              }}
              errorMessage={daysOfWeekError}
            />
          </FormField>
        ) : (
          undefined
        )}
        {/* Only include the daysOfMonth field if the field exists in the config. */
        config.daysOfMonth !== undefined ||
        (config.daysOfWeek === undefined &&
          config.daysOfMonth === undefined) ? (
          <FormField title="Days of Month">
            <Dropdown
              multiSelect
              options={daysOfMonth}
              selectedKeys={formState.daysOfMonth.sort((a, b) => a - b)}
              onChange={(ev, item) => {
                if (item) {
                  const value = item.selected
                    ? [...formState.daysOfMonth, item.key as number]
                    : formState.daysOfMonth.filter(key => key !== item.key);

                  updateForm({ field: "daysOfMonth", value });
                }
              }}
              errorMessage={daysOfMonthError}
            />
          </FormField>
        ) : (
          undefined
        )}

        <FormField title="Time">
          <Dropdown
            options={times}
            selectedKey={formState.timeOfDay}
            onChange={(ev, item) => {
              if (item) {
                updateForm({ field: "timeOfDay", value: item.key as string });
              }
            }}
          />
        </FormField>
        <FormField title="Timezone">
          <Dropdown
            options={tzs}
            selectedKey={formState.timezone}
            onChange={(ev, option) => {
              if (option) {
                updateForm({ field: "timezone", value: option.key as string });
              }
            }}
          />
        </FormField>
        <FormField title="Recipients" errorMessage={recipientsError}>
          <TagPicker
            selectedItems={formState.recipientAddresses
              .filter(recipient => recipient !== "betareports@backup365.io")
              .map(recipient => ({
                key: recipient,
                name: recipient,
              }))}
            onChange={items => {
              if (items) {
                updateForm({
                  field: "recipientAddresses",
                  value: [
                    ...items.map(item => item.key + ''),
                    "betareports@backup365.io",
                  ],
                });
              }
            }}
            onResolveSuggestions={(filter, selectedItems) => {
              return [{ key: filter, name: filter }];
            }}
          />
        </FormField>
        <FormField horizontal>
          <PrimaryButton
            disabled={!noErrors || isUpdating}
            onClick={() => {
              setUpdating(true);
              updateConfigFromForm(formState).then(() => {
                setUpdating(false);
                navigateBack();
              });
            }}
          >
            {isUpdating ? <Spinner size={SpinnerSize.medium} /> : "Save"}
          </PrimaryButton>
          <DefaultButton
            disabled={isUpdating}
            onClick={() => {
              navigateBack();
            }}
          >
            Cancel
          </DefaultButton>
        </FormField>
      </Stack>
    </>
  );
};
