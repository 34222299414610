import React, { createContext, useCallback, useContext } from "react";

import store from "../store";

interface IValidatePermissions {
  validate: (permission: IPermission) => boolean;
}

export type TPermissionAction =
  | "List"
  | "Create"
  | "Read"
  | "Update"
  | "Delete"
  | "Cancel"
  | "Perform"
  | "Apply";

export interface IPermission {
  userId: string;
  action: TPermissionAction | "*";
  resource: string;
  scope: {
    organisationId: string;
    serviceAccountId?: string;
    serviceId?: string;
  };
  parentOrganisationIds: string[];
}

const PermissionsContext = createContext<IValidatePermissions | null>(null);

export const PermissionProvider: React.FC = ({ children }) => {
  const validate = useCallback((permission: IPermission) => {
    const { resource, scope, action, parentOrganisationIds } = permission;
    const serviceId = scope.serviceId;
    const state = store.getState();
    const userOrgs = state.auth.userPermissions;
    if (!userOrgs) {
      return false;
    }

    // Filter only the orgs where the user has permission to access this resource
    const filteredOrgs = userOrgs.organisations.filter(org => {
      const hasPermission = org.permissions.reduce((prev, curr) => {
        if (curr.resource === resource) {
          if (serviceId && curr.serviceId !== serviceId) {
            // If the serviceId is included but does not match the one in this resource, move on
            return prev;
          }

          // If action is included, check that the permission allows for it
          if (action) {
            if (curr.actions === "*") {
              return true;
            } else if (action === "*") {
              // If the requested action is * but this resource does not have *, then exlude this permission
              return prev;
            } else if (curr.actions.includes(action)) {
              return true;
            } else {
              return prev;
            }
          } else {
            // If action is not included then we include this org
            return true;
          }
        }

        return prev;
      }, false);

      return hasPermission;
    });

    if (filteredOrgs.length === 0) {
      return false;
    }

    if (parentOrganisationIds) {
      const grantedOrgIds = userOrgs.organisations.map(
        org => org.organisationId
      );

      return parentOrganisationIds.some(parentOrgId =>
        grantedOrgIds.includes(parentOrgId)
      );
    }

    return false;
  }, []);

  const value: IValidatePermissions = {
    validate,
  };

  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const useValidatePermissions = () => {
  const context = useContext(PermissionsContext);

  if (context === null) {
    throw new Error("Missing PermissionsProvider");
  }

  return context;
};
