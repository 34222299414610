/* eslint-disable array-callback-return */
import { BaseModel } from "./base";
import { IPageInfo } from "./utils";

export interface IFileItem {
  /**
   * The id of the service that this item is indexed under.
   */
  serviceId: string;

  /**
   * The id of the serviceAccount that this item is indexed under.
   */
  serviceAccountId: string;

  /**
   * The key of the item.
   */
  key: string;

  /**
   * The unique id for this version of the item.
   * Will be undefined if the requested version of the item was deleted.
   */
  itemId?: string;

  /**
   * The timestamp of the item.
   */
  timestamp: string;

  /**
   * The filePath that the item was indexed with.
   */
  filePath: string;

  /**
   * The content of the item.
   * Will be undefined if the requested version of the item was deleted.
   */
  item?: any;
}

export interface IBrowseResponse {
  /**
   * The folders under the given parentId.
   */
  folders: Array<{
    /**
     * Id of the folder. Use this as the parentId in subsequent requests
     * to navigate further down the folder hierarchy.
     */
    folderId: string;

    /**
     * The decoded folderPath identifying this folder.
     */
    folderPath: string;
  }>;

  /**
   * Keys of items under the given parentId.
   */
  items: IFileItem[];

  /**
   * An object containing pagination information which can be used to request subsequent pages.
   */
  pageInfo: IPageInfo;
}

export interface IVersion {
  /**
   * The item key.
   */
  key: string;

  /**
   * Version Id for this item version.
   */
  versionId: string;

  /**
   * The item id for this version of the item. Will be undefined if this version was deleted.
   */
  itemId?: string;

  /**
   * The timestamp of this item version
   */
  timestamp: string;
}

export interface IItemVersionsResponse {
  /**
   * An array of versions of the selected item.
   */
  versions: Array<IVersion>;

  /**
   * An object containing pagination information which can be used to request subsequent pages.
   */
  pageInfo: IPageInfo;
}

export class BrowseModel extends BaseModel {
  public async browse(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    folderPath?: string;
    parentId?: string;
    timestamp?: string;
    cursor?: string;
  }): Promise<IBrowseResponse | undefined> {
    const response = await this.request({
      url: `/search/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}/browse`,
      method: "post",
      data: {
        timestamp: opts.timestamp,
        folderPath: opts.folderPath,
        serviceId: opts.serviceId,
        cursor: opts.cursor,
        limit: 30,
      },
    });

    let items: any = [];
    let folders: any = [];
    response.data.nodes.map((item: any) => {
      if (item != null && item.itemType === "FILE") {
        items = [...items, item];
      }

      if (item != null && item?.itemType === "FOLDER") {
        folders = [...folders, item];
      }
    });

    if (response.status !== 200) {
      return undefined;
    }

    return {
      items,
      folders,
      pageInfo: response.data.pageInfo,
    };
  }

  public async itemVersions(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    key: string;
    cursor?: string;
  }): Promise<IItemVersionsResponse | undefined> {
    const response = await this.request({
      url: `/search/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}/browse/itemVersions`,
      method: "get",
      params: {
        key: opts.key,
        cursor: opts.cursor,
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async itemVersion(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    key: string;
    versionId: string;
  }): Promise<IFileItem | undefined> {
    const response = await this.request({
      url: `/search/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}/browse/items`,
      method: "get",
      params: {
        key: opts.key,
        versionId: opts.versionId,
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }
}
