import { Actions, IAction } from "./Actions";
import { IStat, Stats } from "./Stats";
import React, {useCallback, useState} from "react";
import { humanReadableBytes, humanReadableNumber } from "./utils";

import { Details } from "./Details";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { TimeStats } from "./TimeStats";
import { statusCodeToString } from "../ServiceAccounts/utils";
import { useHistory } from "react-router-dom";
import {DrivesModalView} from "./DrivesModal/DrivesModalView";

export interface ISharepointStatsProps {
  serviceType: "onedrive" | "sharepoint";
  serviceAccount?: IServiceAccount;
  isServiceAccountLoading?: boolean;
  stats?: Record<string, number>;
  isStatsLoading?: boolean;
  firstBackup?: Date;
  lastBackup?: Date;
  isBackupTimeLoading?: boolean;
  toggleEnabled?: (checked: boolean) => void;
  isEnabledLoading?: boolean;
  organisationId: string;
  serviceId: string;
}

export const SharepointStats: React.FC<ISharepointStatsProps> = ({
  serviceType,
  serviceAccount,
  isServiceAccountLoading,
  stats,
  isStatsLoading,
  firstBackup,
  lastBackup,
  isBackupTimeLoading,
  toggleEnabled,
  isEnabledLoading,
  organisationId,
  serviceId,
}) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { status, enabled } = serviceAccount
    ? statusCodeToString(serviceAccount?.status)
    : { status: undefined, enabled: undefined };

  const totalSize = stats
    ? Object.keys(stats).reduce((sum, key) => {
        let val = 0;
        if (key.endsWith("Size") && !key.includes("Deleted")) {
          val = stats[key];
        }

        return sum + val;
      }, 0)
    : 0;

  const hrBytes = humanReadableBytes(totalSize);
  const hrFileCount = stats?.fileCount
    ? humanReadableNumber(stats.fileCount)
    : undefined;
  const hrFolderCount = stats?.folderCount
    ? humanReadableNumber(stats.folderCount)
    : undefined;

  const fileCountHover =
    hrFileCount && hrFileCount !== stats?.fileCount.toString()
      ? stats?.fileCount.toString()
      : undefined;
  const folderCountHover =
    hrFolderCount && hrFolderCount !== stats?.folderCount.toString()
      ? stats?.folderCount.toString()
      : undefined;

  const details: Record<string, string | undefined> = {
    Title: serviceAccount?.displayName,
    Path: serviceAccount?.identifier,
    Status: status,
  };

  const displayStats: IStat[] = [
    {
      title: "File Count",
      metric: hrFileCount,
      hover: fileCountHover,
      iconName: "TextDocument",
    },
    {
      title: "Folder Count",
      metric: hrFolderCount,
      hover: folderCountHover,
      iconName: "FolderHorizontal",
    },
    {
      title: "Total Size",
      metric: hrBytes,
      iconName: "PageData",
    },
  ];

  const serviceAccountTypeMap = {
    onedrive: "Drive",
    sharepoint: "Site",
  } as const;

  const performAction = useCallback(
    (action: string) => {
      if (serviceAccount) {
        history.push(
          `/customers/${serviceAccount.organisationId}/services/${serviceType}/${serviceAccount.id}/${action}`
        );
      }
    },
    [serviceAccount, serviceType, history]
  );

  const actions: IAction[] = [
    {
      label: `Browse ${serviceAccountTypeMap[serviceType]}`,
      onClick: () => {
        setOpenModal(true);
      },
    },
    {
      label: "Restore",
      onClick: () => {
        performAction("restore");
      },
    },
  ];

  return (
    <>
      <Details
        serviceAccountType={serviceAccountTypeMap[serviceType]}
        details={details}
        enabled={enabled}
        isLoadingDetails={isServiceAccountLoading}
        toggleEnabled={toggleEnabled}
        isEnabledLoading={isEnabledLoading}
        organisationId={organisationId}
        serviceId={serviceId}
      />
      <Actions actions={actions} />
      <TimeStats
        initialBackup={firstBackup}
        recentBackup={lastBackup}
        isLoading={isBackupTimeLoading}
      />
      <Stats stats={displayStats} isLoading={isStatsLoading} />
      <DrivesModalView
          drives={serviceAccount?.drives }
          isOpen={openModal}
          onDismiss={() => setOpenModal(false)}
          serviceAccount={serviceAccount}
          serviceType={serviceType}

      />
    </>
  );
};
