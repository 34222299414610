import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";
import { IPaginate } from "./utils";

export interface IProvisioningRequest {
  organisationId: string;
  provisioningId: string;
  serviceId: string;
  createdTime?: string;
  updatedTime?: string;
  status:
    | "PENDING"
    | "IN_PROGRESS"
    | "COMPLETE"
    | "FAILED"
    | "AWAITING_CONSENT";
  results: {
    [k: string]: string;
  };
  error?: {
    timestamp: string;
    errorType: string;
    message?: string;
  };
  data?: any;
}

export class ProvisioningModel extends BaseModel {
  public async listProvisioningRequests(opts: {
    serviceId: string;
    status: Array<IProvisioningRequest["status"]>;
    organisationId?: string;
  }): Promise<Result<IPaginate<IProvisioningRequest>>> {
    const response = await this.request({
      url: `/provisioning/services/${opts.serviceId}/request`,
      method: "post",
      data: {
        status: opts.status,
        organisationId: opts.organisationId,
      },
    });

    if (response.status === 401) {
      return new PortalError("UNAUTHORIZED");
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async requestProvisioning(opts: {
    serviceId: string;
    organisationId: string;
    tenantId?: string;
    tenantUuid?: string;
    reportingConfig: {
      recipients: string[];
      weekly: boolean;
      daily: boolean;
      events: boolean;
    };
    credentials?: {
      email: string;
      password: string;
    };
  }): Promise<Result<IProvisioningRequest>> {
    const response = await this.request({
      url: `/provisioning/organisations/${opts.organisationId}/services/${opts.serviceId}/request`,
      method: "post",
      data: {
        tenantId: opts.tenantId,
        tenantUuid: opts.tenantUuid,
        reportingConfig: opts.reportingConfig,
        credentials: opts.credentials,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", JSON.stringify(response.data));
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }
}
