import React from "react";
import { Text, Separator } from "office-ui-fabric-react";
import { RestoreView } from "./RestoreView";

export interface IServiceAccountRestoreProps {
  organisationId: string;
  serviceAccountId: string;
  serviceId: string;
  serviceName: string;
}

export const ServiceAccountRestore: React.FC<IServiceAccountRestoreProps> = ({
  organisationId,
  serviceAccountId,
  serviceId,
  serviceName
}) => {
  return (
    <div>
      <Text variant="xxLarge">Restore</Text>
      <Separator />
      <RestoreView
        organisationId={organisationId}
        serviceAccountId={serviceAccountId}
        serviceId={serviceId}
        serviceName={serviceName}
      />
    </div>
  );
};

export default ServiceAccountRestore;
