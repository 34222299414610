import { INavLink, Nav, Text } from "office-ui-fabric-react";

import { BasePanel } from "./BasePanel";
import React from "react";
import { useHistory } from "react-router-dom";

export type TPanelState = "notifications" | "account";

interface ISidePanelProps {
  panelState?: TPanelState;
  dismissPanel?: () => void;
}

export const SidePanel: React.FC<ISidePanelProps> = ({
  panelState,
  dismissPanel,
}) => {
  const history = useHistory();

  let panelTitle: string = "";
  let panelContent: JSX.Element | undefined;
  if (panelState === "notifications") {
    panelTitle = "Notifications";
    panelContent = <Text>No new notifications</Text>;
  } else if (panelState === "account") {
    const handleClick = (
      ev?: React.MouseEvent<HTMLElement>,
      item?: INavLink
    ) => {
      if (item) {
        ev?.preventDefault();
        history.push(item.url);
        dismissPanel?.();
      }
    };

    panelTitle = "My Account";
    panelContent = (
      <Nav
        onLinkClick={handleClick}
        groups={[
          {
            links: [
              {
                name: "Audit Logs",
                url: "/audit",
              },
              {
                name: "Your Customer Roles",
                url: "/settings/roles",
              },
              {
                name: "Sign Out",
                url: "/signout",
              },
            ],
          },
        ]}
      />
    );
  }

  return (
    <BasePanel
      isOpen={panelState !== undefined}
      title={panelTitle}
      dismissPanel={dismissPanel}
    >
      {panelContent}
    </BasePanel>
  );
};
