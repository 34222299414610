import React from "react";
import { TermsAndConditions } from "../components/TermsAndConditions";
import { UnauthedPageLayout } from "../components/UnauthedPageLayout";
import { useParams } from "react-router-dom";

export interface ITermsAndConditionsPageProps {}

export const TermsAndConditionsPage: React.FC<ITermsAndConditionsPageProps> = () => {
  const { organisationId } = useParams();

  if (organisationId === undefined) {
    throw new Error("Misconfigured router");
  }

  return (
    <UnauthedPageLayout>
      <TermsAndConditions organisationId={organisationId} />
    </UnauthedPageLayout>
  );
};
