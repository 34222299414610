import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { v4 as uuid } from "uuid";

const MessageBarContext = createContext<IMessageBar | null>(null);

export interface IMessage {
  messageType: MessageBarType;
  text: string;
}

type TAction =
  | { type: "ADD_MESSAGE"; payload: IMessage }
  | { type: "REMOVE_MESSAGE"; payload: { id: string } };

interface IMessageState {
  messages: Record<string, IMessage>;
}

const initialState: IMessageState = {
  messages: {},
};

const messageReducer: React.Reducer<IMessageState, TAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      const id = uuid();

      return {
        ...prevState,
        messages: {
          ...prevState.messages,
          [id]: action.payload,
        },
      };

    case "REMOVE_MESSAGE":
      const { [action.payload.id]: _, ...messages } = prevState.messages;

      return {
        ...prevState,
        messages,
      };

    default:
      return prevState;
  }
};

interface IMessageBar {
  messages: JSX.Element[];
  sendMessage: (message: IMessage) => void;
}

export const MessageBarProvider: React.FC = ({ children }) => {
  const [messageState, dispatch] = useReducer(messageReducer, initialState);
  const sendMessage = useCallback((message: IMessage) => {
    dispatch({ type: "ADD_MESSAGE", payload: message });
  }, []);

  const messages = Object.keys(messageState.messages).map(key => {
    const message = messageState.messages[key];

    return (
      <MessageBar
        key={key}
        messageBarType={message.messageType}
        onDismiss={() => {
          dispatch({ type: "REMOVE_MESSAGE", payload: { id: key } });
        }}
      >
        {message.text}
      </MessageBar>
    );
  });

  const value: IMessageBar = {
    messages,
    sendMessage,
  };

  return (
    <MessageBarContext.Provider value={value}>
      {children}
    </MessageBarContext.Provider>
  );
};

export const useMessageBar = () => {
  const context = useContext(MessageBarContext);

  if (context === null) {
    throw new Error("Missing MessageBarProvider");
  }

  return context;
};
