import {
  IStackTokens,
  IStyle,
  Stack,
  classNamesFunction,
  styled,
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { ResponsiveLayout, useSmallLayout } from "./ResponsiveLayout";

import { SideMenu } from "./SideMenu";
import { TopMenu } from "./TopMenu";
import { useMessageBar } from "../providers/messageBarProvider";

interface IPageLayoutStyles {
  app?: IStyle;
  topMenu?: IStyle;
  sideMenu?: IStyle;
  body?: IStyle;
  content?: IStyle;
  contentSmall: IStyle;
  messageBarContainer?: IStyle;
}

const pageLayoutStyles: IPageLayoutStyles = {
  app: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  topMenu: {
    width: "100%",
  },
  body: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    overflowY: "scroll",
    padding: 48,
  },
  contentSmall: {
    width: "100%",
    overflowY: "scroll",
    padding: 20,
  },
  messageBarContainer: {
    paddingBottom: 16,
  },
};

interface IPageLayoutProps {
  styles?: IPageLayoutStyles;
}

const getClassNames = classNamesFunction<{}, IPageLayoutStyles>();

const BasePageLayout: React.FC<IPageLayoutProps> = ({ children, styles }) => {
  const classNames = getClassNames(styles);
  const renderSmall = useSmallLayout();
  const { messages } = useMessageBar();

  // Set the sidebar to be collapsed if rendering a small layout
  const [collapsed, setCollapsed] = useState(renderSmall);

  // Set the collapsed state when changing between small and large layouts
  useEffect(() => {
    setCollapsed(renderSmall);
  }, [renderSmall]);

  const contentWrapper = renderSmall
    ? classNames.contentSmall
    : classNames.content;

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const tokens: IStackTokens = {
    childrenGap: "s1",
  };

  return (
    <div className={classNames.app}>
      <div className={classNames.topMenu}>
        <TopMenu handleHamburger={toggleCollapsed} />
      </div>
      <div className={classNames.body}>
        <ResponsiveLayout
          renderLarge={() => (
            <SideMenu collapsed={collapsed} renderSmall={false} />
          )}
          renderSmall={() => (
            <>
              {!collapsed ? (
                <SideMenu
                  collapsed={false}
                  renderSmall
                  onSelect={() => setCollapsed(true)}
                />
              ) : (
                <></>
              )}
            </>
          )}
        />

        <div className={contentWrapper}>
          {messages.length ? (
            <Stack className={classNames.messageBarContainer} tokens={tokens}>
              {messages}
            </Stack>
          ) : (
            undefined
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export const PageLayout = styled(BasePageLayout, pageLayoutStyles);
