import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";
import { IClientOpts } from "..";
import { IPartialTheme } from "office-ui-fabric-react";

export class BrandingAssetsModel extends BaseModel {
  private hostname: string;
  public brandingUrl: string;

  constructor(opts: IClientOpts) {
    super({ ...opts, baseUrl: opts.brandingUrl });
    this.hostname = opts.hostname;
    this.brandingUrl = opts.brandingUrl;
  }

  public async getTheme(): Promise<Result<IPartialTheme["palette"]>> {
    const response = await this.request({
      url: `${this.hostname}/theme.json`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async getLogoWhiteSrc(): Promise<Result<string>> {
    const response = await this.request({
      url: `${this.hostname}/logo-white.png`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(`${this.brandingUrl}/${this.hostname}/logo-white.png`);
  }

  public async getLogoPrimarySrc(): Promise<Result<string>> {
    const response = await this.request({
      url: `${this.hostname}/logo-primary.png`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(`${this.brandingUrl}/${this.hostname}/logo-primary.png`);
  }
}
