import {
  ButtonType,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "office-ui-fabric-react";

import React from "react";

export interface ISubmitDialogProps {
  hidden: boolean;
  displayText?: string;
  displayTitle?: string;
  setIsOpen?: () => void;
  submit?: () => void;
}

export const SubmitDialog: React.FC<ISubmitDialogProps> = ({
  hidden,
  displayText,
  displayTitle,
  setIsOpen,
  submit,
}: ISubmitDialogProps) => {
  return (
    <Dialog
      hidden={hidden}
      onDismiss={setIsOpen}
      title={displayTitle ?? "Are you sure?"}
      modalProps={{ isBlocking: false }}
      dialogContentProps={{
        type: DialogType.close,
        subText: displayText ?? "This action can't be undone.",
      }}
      closeButtonAriaLabel="Close"
    >
      <DialogFooter>
        <PrimaryButton buttonType={ButtonType.primary} onClick={submit}>
          OK
        </PrimaryButton>
        <DefaultButton buttonType={ButtonType.normal} onClick={setIsOpen}>
          Cancel
        </DefaultButton>
      </DialogFooter>
    </Dialog>
  );
};
