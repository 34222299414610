import {
  IStyle,
  Separator,
  classNamesFunction,
  getTheme,
  styled
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";

import { LoginForm } from "../components/Login/LoginFormView";
import { LoginProviders } from "../components/Login/LoginProviders";
import { ResponsiveLayout } from "../components/ResponsiveLayout";
import { useApiClient } from "../providers/ApiProvider";
import { useWindowDimensions } from "../providers/windowDimensionsProvider";

interface ILoginPageStyles {
  innerWrapper: IStyle;
  innerWrapperSmall: IStyle;
  outerWrapper: IStyle;
  spacer: IStyle;
  signIn: IStyle;
  outerGrid: IStyle;
  outGridWide: IStyle;
}

interface ILoginPageProps {
  styles: ILoginPageStyles;
}

const loginPageStyles = (): ILoginPageStyles => {
  const theme = getTheme();
  const outerGrid: IStyle = {
    padding: 0,
    paddingLeft: "44px !important",
    paddingRight: "44px !important",
    width: "100%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    maxWidth: 440,
    minWidth: 440,
    boxShadow: `3px 3px 8px ${theme.palette.neutralLight}`,
    border: `1px solid ${theme.palette.themeDarkAlt}`,
    backgroundColor: theme.palette.white
  };
  return {
    innerWrapper: {},
    spacer: {
      width: "100%",
      height: 32,
      display: "inline-block"
    },
    outerGrid,
    outGridWide: {
      ...outerGrid,
      position: "relative",
      top: "80%"
    },
    innerWrapperSmall: {
      padding: 44,
      paddingLeft: "44px !important",
      paddingRight: "44px !important",
      backgroundColor: theme.palette.white,
      height: "100vh"
    },
    signIn: {
      fontSize: 16,
      fontWeight: 700,
    },
    outerWrapper: {
      backgroundColor: theme.palette.white,
      height: "100vh",
      width: "calc(100% - 40px)",
      minWidth: "100%"
    }
  };
};

const getClassNames = classNamesFunction<{}, ILoginPageStyles>();

const BaseLoginPage: React.FC<ILoginPageProps> = ({ styles }) => {
  const classNames = getClassNames(styles);

  const client = useApiClient()

  const dimensions = useWindowDimensions();
  const height = dimensions?.height;
  const width = dimensions?.width;
  const innerClassName =
    width && width < 440
      ? classNames.innerWrapperSmall
      : classNames.innerWrapper;

  const outerGridName =
    height && height < 520 ? classNames.outGridWide : classNames.outerGrid;

  const spacer = <div className={classNames.spacer} />;


  const [logoSrc, setLogoSrc] = useState<string>()

  useEffect(() => {
    client.brandingAssets.getLogoWhiteSrc().then(resp => {
      if (resp.isOk()){
        setLogoSrc(resp.value)
      }else{
        setLogoSrc('/images/Backup365LoginLogo.png')
      }
    })
  }, [client, logoSrc])

  const loginFormInner = (
    <div className="ms-Grid-row">
      <div className={`ms-Grid-col ms-sm12 ${innerClassName}`}>
        {spacer}
        <img src={logoSrc} alt="" width="100%" />
        {spacer}
        <div className={classNames.signIn}>Sign In</div>
        {spacer}
        <div className="ms-Grid-col ms-sm12">
          <LoginProviders />
        </div>
        {spacer}
        <Separator>OR</Separator>
        <div className="ms-Grid-col ms-sm12">
          <LoginForm />
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames.outerWrapper}>
      <ResponsiveLayout
        breakpoint={440}
        renderLarge={() => (
          <div className={`ms-Grid ${outerGridName}`} dir="ltr">
            {loginFormInner}
          </div>
        )}
        renderSmall={() => (
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">{loginFormInner}</div>
          </div>
        )}
      />
    </div>
  );
};

export const LoginPage = styled(BaseLoginPage, loginPageStyles);
