import { AxiosError } from "axios";

export const isAxiosError = (err: any): err is AxiosError => err.isAxiosError;

export interface IPageInfo {
  cursor?: string;
  hasNextPage: boolean;
}

export interface IPaginate<T> {
  nodes: T[];
  pageInfo: IPageInfo;
}
