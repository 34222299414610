import {
  CustomersPeopleView,
  IInviteView,
  IUserRoleView,
} from "./CustomersPeopleView";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import { CustomersPeoplePanel } from "./CustomersPeoplePanel";
import { IInvite } from "../../providers/ApiProvider/ApiClient/models/invites";
import { IPaginate } from "../../providers/ApiProvider/ApiClient/models/utils";
import { IRole } from "../../providers/ApiProvider/ApiClient/models/auth";
import { MessageBarType } from "office-ui-fabric-react";
import { useApiClient } from "../../providers/ApiProvider";
import { useMessageBar } from "../../providers/messageBarProvider";
import { useParams } from "react-router-dom";

export interface ICustomersPeopleProps {}

type TLoadable<T> = T & { isLoading?: boolean };

interface IState {
  invites: {
    nodes: Record<string, TLoadable<IInvite>>;
    cursor?: string;
    hasNextPage: boolean;
  };
  roles: {
    nodes: Record<string, IRole>;
    cursor?: string;
    hasNextPage: boolean;
  };
  updatingUserRoles: Record<string, boolean | undefined>;
}

type TActions =
  | { type: "MERGE_INVITES"; payload: IPaginate<IInvite> }
  | { type: "MERGE_ROLES"; payload: IPaginate<IRole> }
  | {
      type: "SET_INVITE_LOADING";
      payload: {
        inviteId: string;
        organisationId: string;
        isLoading?: boolean;
      };
    }
  | {
      type: "SET_USER_ROLE_LOADING";
      payload: { roleId: string; userId: string; isLoading?: boolean };
    }
  | { type: "CLEAR_INVITES" }
  | { type: "CLEAR_ROLES" };

const initialState: IState = {
  invites: {
    nodes: {},
    hasNextPage: true,
  },
  roles: {
    nodes: {},
    hasNextPage: true,
  },
  updatingUserRoles: {},
};

const stateReducer: React.Reducer<IState, TActions> = (prevState, action) => {
  switch (action.type) {
    case "MERGE_INVITES":
      const inviteNodes = action.payload.nodes.reduce((prev, curr) => {
        const id = `${curr.organisationId}:${curr.inviteId}`;

        return {
          ...prev,
          [id]: curr,
        };
      }, {});

      return {
        ...prevState,
        invites: {
          nodes: {
            ...prevState.invites.nodes,
            ...inviteNodes,
          },
          cursor: action.payload.pageInfo.cursor,
          hasNextPage: action.payload.pageInfo.hasNextPage,
        },
      };
    case "MERGE_ROLES":
      const roleNodes = action.payload.nodes.reduce((prev, curr) => {
        const id = curr.roleId;

        return {
          ...prev,
          [id]: curr,
        };
      }, {});
      return {
        ...prevState,
        roles: {
          nodes: {
            ...prevState.roles.nodes,
            ...roleNodes,
          },
          cursor: action.payload.pageInfo.cursor,
          hasNextPage: action.payload.pageInfo.hasNextPage,
        },
      };
    case "SET_INVITE_LOADING":
      const inviteId = `${action.payload.organisationId}:${action.payload.inviteId}`;

      return {
        ...prevState,
        invites: {
          ...prevState.invites,
          nodes: {
            ...prevState.invites.nodes,
            [inviteId]: {
              ...prevState.invites.nodes[inviteId],
              isLoading: action.payload.isLoading,
            },
          },
        },
      };
    case "SET_USER_ROLE_LOADING":
      const userRoleId = `${action.payload.roleId}:${action.payload.userId}`;

      return {
        ...prevState,
        updatingUserRoles: {
          ...prevState.updatingUserRoles,
          [userRoleId]: action.payload.isLoading,
        },
      };
    case "CLEAR_INVITES":
      return {
        ...prevState,
        invites: initialState.invites,
      };
    case "CLEAR_ROLES":
      return {
        ...prevState,
        roles: initialState.roles,
        updatingUserRoles: initialState.updatingUserRoles,
      };
    default:
      return prevState;
  }
};

export const CustomersPeople: React.FC<ICustomersPeopleProps> = () => {
  const { organisationId } = useParams();
  const api = useApiClient();
  const { sendMessage } = useMessageBar();

  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [isCreateInviteOpen, setCreateInviteOpen] = useState(false);

  // List invites
  useEffect(() => {
    if (organisationId && state.invites.hasNextPage) {
      api.invites
        .getInvitesByOrgId({
          organisationId,
          cursor: state.invites.cursor,
        })
        .then(res => {
          if (res.isOk()) {
            dispatch({
              type: "MERGE_INVITES",
              payload: res.value,
            });
          } else {
            // Dispatch and empty result with `hasNextPage: false` to stop
            // repeated requests from being sent.
            dispatch({
              type: "MERGE_INVITES",
              payload: {
                nodes: [],
                pageInfo: { hasNextPage: false },
              },
            });

            sendMessage({
              messageType: MessageBarType.error,
              text: res.message,
            });
          }
        });
    }
  });

  // List roles
  useEffect(() => {
    if (organisationId && state.roles.hasNextPage) {
      api.auth
        .listRolesByOrg({ organisationId, cursor: state.roles.cursor })
        .then(res => {
          if (res.isOk()) {
            dispatch({
              type: "MERGE_ROLES",
              payload: res.value,
            });
          } else {
            // Dispatch and empty result with `hasNextPage: false` to stop
            // repeated requests from being sent.
            dispatch({
              type: "MERGE_ROLES",
              payload: {
                nodes: [],
                pageInfo: { hasNextPage: false },
              },
            });

            sendMessage({
              messageType: MessageBarType.error,
              text: res.message,
            });
          }
        });
    }
  });

  const invites: IInviteView[] = Object.values(state.invites.nodes).map(
    node => ({
      inviteId: node.inviteId,
      organisationId: node.organisationId,
      inviteeUserEmail: node.inviteeEmail,
      inviterUserEmail: node.inviterEmail,
      roleName: node.roleName,
      isLoading: node.isLoading,
    })
  );

  const userRoles: IUserRoleView[] = Object.values(state.roles.nodes).reduce(
    (prev, curr) => {
      const roleDetail = {
        roleId: curr.roleId,
        organisationId: curr.organisationId,
        roleName: curr.name,
      };

      const users: IUserRoleView[] = curr.usersWithEmail.map(user => ({
        ...roleDetail,
        userId: user.userId,
        userEmail: user.userEmail,
        isLoading: state.updatingUserRoles[`${curr.roleId}:${user.userId}`],
      }));

      return [...prev, ...users];
    },
    [] as IUserRoleView[]
  );

  const roles = Object.values(state.roles.nodes);

  const cancelInvite = useCallback(
    async (opts: { inviteId: string; organisationId: string }) => {
      dispatch({
        type: "SET_INVITE_LOADING",
        payload: { ...opts, isLoading: true },
      });

      const response = await api.invites.cancelInvite(opts);

      if (response.isErr()) {
        sendMessage({
          messageType: MessageBarType.error,
          text: response.message,
        });
      } else {
        // Clear invites so that they are reloaded
        dispatch({ type: "CLEAR_INVITES" });
      }
    },
    [api.invites, dispatch, sendMessage]
  );

  const removeAccess = useCallback(
    async (opts: { roleId: string; userId: string }) => {
      dispatch({
        type: "SET_USER_ROLE_LOADING",
        payload: { ...opts, isLoading: true },
      });

      const response = await api.auth.removeRoles({
        userId: opts.userId,
        roleIds: [opts.roleId],
      });

      if (response.isErr()) {
        sendMessage({
          messageType: MessageBarType.error,
          text: response.message,
        });
      } else {
        // Clear roles so that they are reloaded
        dispatch({ type: "CLEAR_ROLES" });
      }
    },
    [api.auth, dispatch, sendMessage]
  );

  if (organisationId === undefined) {
    throw new Error("Invalid Routing");
  }

  return (
    <>
      <CustomersPeopleView
        invites={invites}
        isInvitesLoading={state.invites.hasNextPage}
        userRoles={userRoles}
        isUserRolesLoading={state.roles.hasNextPage}
        onCreateInvite={() => {
          setCreateInviteOpen(true);
        }}
        onCancelInvite={cancelInvite}
        onRemoveAccess={removeAccess}
      />
      <CustomersPeoplePanel
        isOpen={isCreateInviteOpen}
        onDismiss={(shouldReload?: boolean) => {
          setCreateInviteOpen(false);

          if (shouldReload) {
            dispatch({ type: "CLEAR_INVITES" });
          }
        }}
        organisationId={organisationId}
        roles={roles}
      />
    </>
  );
};
