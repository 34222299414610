import store, { allStoreActions } from "../../../../store";

import { BaseModel } from "./base";

export class HelperModel extends BaseModel {
  public async refreshLogin(): Promise<void> {
    const state = store.getState();
    const refreshToken = state.auth.tokens.refreshToken;

    if (refreshToken) {
      const response = await super.refreshLoginTokens({ refreshToken });

      store.dispatch(allStoreActions.auth.setTokens(response));
    }
  }
}
