import {
  IAccountsState,
  SET_ORGANISATIONS,
  APPEND_ORGANISATION,
  TAccountActions,
  MERGE_ORGANISATIONS,
  SET_SERVICE_ACCOUNTS,
  MERGE_SERVICE_ACCOUNTS,
  APPEND_SERVICE_ACCOUNT
} from "./types";

const initialState = (): IAccountsState => {
  return {
    organisations: undefined,
    serviceAccounts: undefined
  };
};

export const accountsReducer = (
  state = initialState(),
  action: TAccountActions
) => {
  switch (action.type) {
    case SET_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload
      };
    }
    case MERGE_ORGANISATIONS: {
      const organisations = state.organisations
        ? [...state.organisations, ...action.payload]
        : action.payload;

      return {
        ...state,
        organisations
      };
    }
    case APPEND_ORGANISATION: {
      const organisations = state.organisations
        ? [...state.organisations, action.payload]
        : [action.payload];

      return {
        ...state,
        organisations
      };
    }
    case SET_SERVICE_ACCOUNTS: {
      return {
        ...state,
        serviceAccounts: action.payload
      };
    }
    case MERGE_SERVICE_ACCOUNTS: {
      const serviceAccounts = state.serviceAccounts
        ? [...state.serviceAccounts, ...action.payload]
        : [...action.payload];

      return {
        ...state,
        serviceAccounts
      };
    }
    case APPEND_SERVICE_ACCOUNT: {
      const serviceAccounts = state.serviceAccounts
        ? [...state.serviceAccounts, action.payload]
        : [action.payload];

      return {
        ...state,
        serviceAccounts
      };
    }
    default:
      return state;
  }
};
