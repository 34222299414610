import { IUserOrgs } from "../../providers/ApiProvider/ApiClient/models/auth";

export interface IAuthTokens {
  idToken?: string | null;
  refreshToken?: string | null;
  expiresIn?: number;
  idTokenExpiresAt?: Date;
}

export interface IUserDetails {
  firstName?: string;
  lastName?: string;
}

export interface IAuthState {
  tokens: IAuthTokens;
  loggedIn: boolean;
  userId?: string;
  userDetails?: IUserDetails;
  userPermissions?: IUserOrgs;
}

export interface IAuthActions {
  setTokens: (tokens: IAuthTokens) => ISetAuthTokens;
  setUserPermissions: (userOrgs: IUserOrgs) => ISetUserPermissions;
  setIsLoggedOut: () => ISetIsLoggedOut;
}

export const SET_AUTH_TOKENS = "SET_AUTH_TOKENS";
export const SET_IS_LOGGED_OUT = "SET_IS_LOGGED_OUT";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

export interface ISetAuthTokens {
  type: typeof SET_AUTH_TOKENS;
  payload: IAuthTokens;
}

export interface ISetIsLoggedOut {
  type: typeof SET_IS_LOGGED_OUT;
}

export interface ISetUserPermissions {
  type: typeof SET_USER_PERMISSIONS;
  payload: IUserOrgs;
}

export type TAuthActions =
  | ISetAuthTokens
  | ISetIsLoggedOut
  | ISetUserPermissions;
