import React from "react";
import {
  Modal as FabricModal,
  Text,
  getTheme,
  mergeStyleSets,
  IconButton
} from "office-ui-fabric-react";

export interface IModalProps {
  header?: string;
  isOpen: boolean;
  onDismiss?: () => void;
}

export const Modal: React.FC<IModalProps> = ({
  header,
  isOpen,
  onDismiss,
  children
}) => {
  const theme = getTheme();
  const classNames = mergeStyleSets({
    container: {
      display: "flex"
    },
    header: {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      background: theme.palette.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 12px 14px 24px",
      position: "sticky",
      top: 0
    },
    body: {
      padding: "0 24px 24px 24px"
    }
  });

  return (
    <FabricModal
      isOpen={isOpen}
      onDismiss={() => onDismiss?.()}
      containerClassName={classNames.container}
    >
      <div className={classNames.header}>
        {header ? (
          <>
            <Text variant="xLargePlus">{header}</Text>
            <IconButton
              iconProps={{ iconName: "Cancel" }}
              onClick={() => onDismiss?.()}
            />
          </>
        ) : (
          undefined
        )}
      </div>
      <div className={classNames.body}>{children}</div>
    </FabricModal>
  );
};
