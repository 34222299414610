import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBarType,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
} from "office-ui-fabric-react";
import React, { useCallback, useState } from "react";

import { AccountErrors } from "../Dashboard/AccountErrorsView";
import EnforcePermissions from "../Permissions";
import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { ServiceAccountsList } from "../ServiceAccounts";
import { StatsTiles } from "../Dashboard/StatsView";
import { UpdateOrganisationPanel } from "../UpdateOrganisationPanel";
import { useApiClient } from "../../providers/ApiProvider";
import { useMessageBar } from "../../providers/messageBarProvider";

export interface ICustomerViewProps {
  organisation: IOrganisation;
  serviceName: string | undefined;
  updateOrganisation?: () => void;
}

interface IDialogState {
  title: string;
  subText: string;
  hidden: boolean;
  onSubmit: () => void;
}

export const CustomerView: React.FC<ICustomerViewProps> = ({
  organisation,
  serviceName,
  updateOrganisation,
}) => {
  const api = useApiClient();
  const [isUpdateOpen, setUpdateOpen] = useState(false);
  const { sendMessage } = useMessageBar();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [dialogState, setDialogState] = useState<IDialogState>();
  const clearDialog = useCallback(() => {
    setDialogState(undefined);
  }, [setDialogState]);

  let component: JSX.Element;
  if (serviceName === undefined) {
    component = (
      <>
        <UpdateOrganisationPanel
          isOpen={isUpdateOpen}
          organisation={organisation}
          onDismiss={(shouldReload?: boolean) => {
            setUpdateOpen(false);
            if (shouldReload) {
              updateOrganisation?.();
            }
          }}
        />
        <StatsTiles organisations={[organisation]}></StatsTiles>
        <Separator />
        <AccountErrors organisations={[organisation]}></AccountErrors>
        <Dialog
          hidden={dialogState?.hidden ?? true}
          onDismiss={clearDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: dialogState?.title,
            subText: dialogState?.subText,
          }}
        >
          <DialogFooter>
            <PrimaryButton
              text="Ok"
              onClick={() => {
                dialogState?.onSubmit();
                clearDialog();
              }}
            />
            <DefaultButton text="Cancel" onClick={clearDialog} />
          </DialogFooter>
        </Dialog>
        <Stack horizontal tokens={{ childrenGap: "s1" }}>
          <EnforcePermissions
            organisationId={organisation.id}
            action="Update"
            resource="accounts:organisations"
            enforceType="HIDE"
          >
            <PrimaryButton
              onClick={() => {
                setUpdateOpen(true);
              }}
            >
              Update Customer
            </PrimaryButton>
          </EnforcePermissions>
          <EnforcePermissions
            organisationId={organisation.id}
            action="Cancel"
            resource="accounts:organisations"
            enforceType="HIDE"
          >
            <DefaultButton
              onClick={() => {
                setDialogState({
                  hidden: false,
                  title: "Are you sure you want to cancel this customer?",
                  subText: "This action cannot be undone.",
                  onSubmit: () => {
                    setCancelLoading(true);
                    api.accounts
                      .cancelOrganisation({ organisationId: organisation.id })
                      .then(res => {
                        if (res.isOk()) {
                          sendMessage({
                            text: "Customer successfully cancelled.",
                            messageType: MessageBarType.success,
                          });
                        } else {
                          sendMessage({
                            text: "Failed to cancel customer.",
                            messageType: MessageBarType.error,
                          });
                        }

                        setCancelLoading(false);
                      });
                  },
                });
              }}
            >
              {cancelLoading ? (
                <Spinner size={SpinnerSize.medium} />
              ) : (
                "Cancel Customer"
              )}
            </DefaultButton>
          </EnforcePermissions>
        </Stack>
      </>
    );
  } else {
    component = (
      <ServiceAccountsList
        serviceName={serviceName}
        organisationId={organisation.id}
      />
    );
  }

  return component;
};
