import { ApiClient, IClientOpts } from "../../ApiProvider/ApiClient";

import { AccountsModel } from "./models/accounts";

export interface IRequesterClient {
  accounts: AccountsModel;
}

export const createRequesterClient = ({
  hostname,
}: {
  hostname: string;
}): IRequesterClient => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const brandingUrl = process.env.REACT_APP_BRANDING_URL;

  if (baseUrl === undefined) {
    throw new Error("Missing baseUrl env var");
  }

  if (brandingUrl === undefined) {
    throw new Error("Missing brandingUrl env var");
  }

  const client = new RequesterClient({ baseUrl, brandingUrl, hostname });

  return client;
};

export class RequesterClient implements IRequesterClient {
  public accounts: AccountsModel;

  constructor(opts: IClientOpts) {
    const api = new ApiClient(opts);

    this.accounts = new AccountsModel({ api });
  }
}
