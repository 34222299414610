import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store, { allStoreActions } from "../../../../store";

import { IClientOpts } from "..";
import { ILoginResponse } from "./public";
import { isAxiosError } from "./utils";

export class BaseModel {
  private baseUrl: string;

  constructor(opts: IClientOpts) {
    this.baseUrl = opts.baseUrl;
  }

  protected async request<T extends any>(opts: {
    url: string;
    method: AxiosRequestConfig["method"];
    headers?: Record<string, string>;
    params?: Record<string, any>;
    body?: Record<string, any>;
    data?: any;
    binary?: boolean;
  }): Promise<AxiosResponse<T>> {
    // TODO need a better system of getting this from localStorage

    let response: AxiosResponse<T>;

    const state = store.getState();
    const refreshToken = state.auth.tokens.refreshToken;
    let idToken = state.auth.tokens.idToken;

    let responseType: "arraybuffer" | undefined;
    if (opts.binary) {
      responseType = "arraybuffer";
    }

    let retryCount = 2;
    do {
      response = await axios.request<T>({
        url: opts.url,
        baseURL: this.baseUrl,
        method: opts.method,
        headers: {
          Authorization: `Bearer ${idToken}`,
          ...opts.headers,
        },
        params: opts.params,
        data: opts.data,
        responseType,
        validateStatus: () => true,
      });
      if (
        response.status === 401 &&
        response.data.message &&
        response.data.message === "Unauthorized"
      ) {
        if (refreshToken) {
          const tokenResponse = await this.refreshLoginTokens({ refreshToken });
          if (tokenResponse.idToken) {
            idToken = tokenResponse.idToken;
            store.dispatch(allStoreActions.auth.setTokens(tokenResponse));
          }
        }
        retryCount -= 1;
      } else {
        return response;
      }
    } while (retryCount > 0);

    store.dispatch(allStoreActions.auth.setIsLoggedOut());

    return response;
  }

  public async refreshLoginTokens({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<ILoginResponse> {
    try {
      const response = await this.request({
        url: "/refreshTokens",
        method: "post",
        data: {
          refreshToken,
        },
      });

      response.data.refreshToken = refreshToken;
      return response.data;
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        return err.response.data.message;
      } else {
        return err.message;
      }
    }
  }
}
