import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IComboBoxOption,
  IStackTokens,
  IconButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TagPicker,
  Text,
  Toggle,
  getTheme,
  mergeStyles,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, { useCallback, useReducer, useState } from "react";

import { CREATE_REPORT_ID } from ".";
import { FormField } from "./FormField";
import { IEventReportConfig } from "../../providers/ApiProvider/ApiClient/models/reporting";
import { timezones } from "../../utils/timezones";
import { toFirstLetterUpper } from "../../utils/strings";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../utils/validateEmail";

export interface IEventConfigViewProps {
  organisationName?: string;
  config: IEventReportConfig;
  updateConfig: (config: IEventReportConfig) => Promise<void>;
  deleteConfig: (opts: {
    ownerId: string;
    reportConfigId: string;
  }) => Promise<void>;
}

interface IFormState {
  serviceIds: string[];
  eventTypes: string[];
  timezone: string;
  recipientAddresses: string[];
  disabled: boolean;
}

type TAction = {
  type: "UPDATE_FORM";
  payload: { field: keyof IFormState; value: IFormState[keyof IFormState] };
};

const createInitialState = (config: IEventReportConfig): IFormState => ({
  serviceIds: config.serviceIds,
  eventTypes: config.eventTypes,
  timezone: config.timezone,
  recipientAddresses: config.recipientAddresses,
  disabled: config.disabled,
});

const formReducer: React.Reducer<IFormState, TAction> = (prevState, action) => {
  switch (action.type) {
    case "UPDATE_FORM":
      return {
        ...prevState,
        [action.payload.field]: action.payload.value,
      };

    default:
      return prevState;
  }
};

export const EventConfigView: React.FC<IEventConfigViewProps> = ({
  organisationName,
  config,
  updateConfig,
  deleteConfig,
}) => {
  const [showConfirmDialog, setConfirmDialog] = useState(false);

  const updateConfigFromForm = useCallback(
    async (form: IFormState) => {
      const updatedConfig: IEventReportConfig = {
        ownerId: config.ownerId,
        ownerType: config.ownerType,
        reportConfigId: config.reportConfigId,
        serviceIds: form.serviceIds,
        // We can safely cast this because we control the values of the keys
        // in the eventTypes CheckBox.
        eventTypes: form.eventTypes as IEventReportConfig["eventTypes"],
        timezone: form.timezone,
        recipientAddresses: form.recipientAddresses,
        disabled: form.disabled,
      };

      await updateConfig(updatedConfig);
    },
    [updateConfig, config]
  );

  const history = useHistory();
  const theme = getTheme();

  const navigateBack = useCallback(() => {
    const pathnameParts = history.location.pathname.split("/");

    // Remove the last 2 parts from the pathname.
    pathnameParts.pop();
    pathnameParts.pop();

    const pathname = pathnameParts.join("/");

    history.push(pathname);
  }, [history]);

  const [formState, dispatch] = useReducer(
    formReducer,
    createInitialState(config)
  );

  const updateForm = useCallback(
    (payload: TAction["payload"]) => {
      dispatch({ type: "UPDATE_FORM", payload });
    },
    [dispatch]
  );

  const [isUpdating, setUpdating] = useState(false);

  const tzs: IComboBoxOption[] = timezones.map(tz => ({
    key: tz.key,
    text: tz.displayName,
  }));

  let servicesError: string | undefined;
  if (formState.serviceIds.length === 0) {
    servicesError = "At least one service is required";
  }

  let eventTypeError: string | undefined;
  if (formState.eventTypes.length === 0) {
    eventTypeError = "At least one event type is required";
  }

  let recipientsError: string | undefined;
  if (
    formState.recipientAddresses.length === 0 ||
    (formState.recipientAddresses.length === 1 &&
      formState.recipientAddresses[0] === "betareports@backup365.io")
  ) {
    recipientsError = "At least one recipient is required";
  } else {
    const validEmails = formState.recipientAddresses.map(validateEmail);
    if (validEmails.includes(false)) {
      recipientsError = "Must be a valid email address";
    }
  }

  const noErrors =
    servicesError === undefined &&
    eventTypeError === undefined &&
    recipientsError === undefined;

  const tokens: IStackTokens = {
    childrenGap: "m",
  };

  const container = mergeStyles({ padding: 10 });

  const toggleStyles = mergeStyles({
    paddingTop: 10,
    paddingLeft: 10,
  });

  return (
    <>
      <Dialog
        hidden={!showConfirmDialog}
        onDismiss={() => {
          setConfirmDialog(false);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Are you sure you want to delete this report?",
          subText: "This cannot be undone",
        }}
      >
        <DialogFooter>
          <PrimaryButton
            text="Ok"
            onClick={() => {
              setUpdating(true);
              setConfirmDialog(false);
              deleteConfig({
                ownerId: config.ownerId,
                reportConfigId: config.reportConfigId,
              }).then(() => {
                navigateBack();
              });
            }}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              setConfirmDialog(false);
            }}
          />
        </DialogFooter>
      </Dialog>
      <Text variant="xxLarge">{organisationName}</Text>
      <Stack horizontal tokens={{ childrenGap: "l2" }} verticalAlign="center">
        <Toggle
          className={toggleStyles}
          label="Enabled"
          inlineLabel
          checked={!formState.disabled}
          onChange={(ev, checked) => {
            if (checked !== undefined) {
              updateForm({ field: "disabled", value: !checked });
            }
          }}
        />
        {config.reportConfigId !== CREATE_REPORT_ID ? (
          <IconButton
            onClick={() => {
              setConfirmDialog(true);
            }}
            iconProps={{ iconName: "Delete" }}
            styles={{
              icon: { color: theme.palette.red },
            }}
          />
        ) : (
          undefined
        )}
      </Stack>
      <Stack className={container} tokens={tokens} horizontal wrap>
        <FormField title="Services" errorMessage={servicesError}>
          <Checkbox
            label="Mailbox"
            checked={formState.serviceIds.includes(EWS_SERVICE_ID)}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                const value = checked
                  ? [...formState.serviceIds, EWS_SERVICE_ID]
                  : formState.serviceIds.filter(
                      serviceId => serviceId !== EWS_SERVICE_ID
                    );

                updateForm({ field: "serviceIds", value });
              }
            }}
          />
          <Checkbox
            label="SharePoint/OneDrive"
            checked={formState.serviceIds.includes(SHAREPOINT_SERVICE_ID)}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                const value = checked
                  ? [...formState.serviceIds, SHAREPOINT_SERVICE_ID]
                  : formState.serviceIds.filter(
                      serviceId => serviceId !== SHAREPOINT_SERVICE_ID
                    );

                updateForm({ field: "serviceIds", value });
              }
            }}
          />
        </FormField>
        <FormField title="Event Types" errorMessage={eventTypeError}>
          {["PROVISION", "DISCOVER", "INITIAL_BACKUP", "RESTORE"].map(
            eventType => {
              const event = eventType
                .replace("_", " ")
                .split(" ")
                .map(toFirstLetterUpper)
                .join(" ");

              return (
                <Checkbox
                  key={eventType}
                  label={event}
                  checked={formState.eventTypes.includes(eventType)}
                  onChange={(ev, checked) => {
                    if (checked !== undefined) {
                      const value = checked
                        ? [...formState.eventTypes, eventType]
                        : formState.eventTypes.filter(e => e !== eventType);

                      updateForm({ field: "eventTypes", value });
                    }
                  }}
                />
              );
            }
          )}
        </FormField>
        <FormField title="Timezone">
          <Dropdown
            options={tzs}
            selectedKey={formState.timezone}
            onChange={(ev, option) => {
              if (option) {
                updateForm({ field: "timezone", value: option.key as string });
              }
            }}
          />
        </FormField>
        <FormField title="Recipients" errorMessage={recipientsError}>
          <TagPicker
            selectedItems={formState.recipientAddresses
              .filter(recipient => recipient !== "betareports@backup365.io")
              .map(recipient => ({
                key: recipient,
                name: recipient,
              }))}
            onChange={items => {
              if (items) {
                updateForm({
                  field: "recipientAddresses",
                  value: [
                    ...items.map(item => item.key + ''),
                    "betareports@backup365.io",
                  ],
                });
              }
            }}
            onResolveSuggestions={(filter, selectedItems) => {
              return [{ key: filter, name: filter }];
            }}
          />
        </FormField>
        <FormField horizontal>
          <PrimaryButton
            disabled={!noErrors || isUpdating}
            onClick={() => {
              setUpdating(true);
              updateConfigFromForm(formState).then(() => {
                setUpdating(false);
                navigateBack();
              });
            }}
          >
            {isUpdating ? <Spinner size={SpinnerSize.medium} /> : "Save"}
          </PrimaryButton>
          <DefaultButton
            disabled={isUpdating}
            onClick={() => {
              navigateBack();
            }}
          >
            Cancel
          </DefaultButton>
        </FormField>
      </Stack>
    </>
  );
};
