import {
  Checkbox,
  ComboBox,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
} from "office-ui-fabric-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { useApiClient } from "../../providers/ApiProvider";
import { useForm } from "../../hooks/useForm";
import { useMessageBar } from "../../providers/messageBarProvider";
import { useOrganisations } from "./useOrganisations";

export interface IUpdateOrganisationPanelProps {
  organisation: IOrganisation;
  isOpen: boolean;
  onDismiss: (shouldReload?: boolean) => void;
}

interface IFormState {
  name?: string;
  parentOrganisationId?: string;
  publicNotes?: string;
  iul?: boolean;
  nfp?: boolean;
}

export const UpdateOrganisationPanel: React.FC<IUpdateOrganisationPanelProps> = ({
  organisation,
  isOpen,
  onDismiss,
}) => {
  const api = useApiClient();
  const { sendMessage } = useMessageBar();
  const [isUpdateLoading, setUpdateLoading] = useState(false);

  const [
    { data: form, validation: errorMessages },
    updateForm,
    clearForm,
  ] = useForm<IFormState>({
    initialState: {
      ...organisation,
    },
    validation: {
      name: val => {
        if (val === undefined || val.trim().length === 0) {
          return "Customer Name is required";
        }
      },
      parentOrganisationId: val => {
        if (val === undefined) {
          return "Parent Customer is required";
        }
      },
    },
  });

  // Clear the form when the organisation changes
  useEffect(() => {
    clearForm();
  }, [organisation, clearForm]);

  const { organisations } = useOrganisations();
  const parentOrganisationOptions = useMemo(() => {
    return (
      organisations?.map(org => ({
        key: org.organisationId,
        text: org.displayName,
      })) ?? []
    );
  }, [organisations]);

  const handleUpdateOrganisation = useCallback(
    async (org: IFormState) => {
      if (org.name !== undefined && org.parentOrganisationId !== undefined) {
        const response = await api.accounts.updateOrganisation({
          id: organisation.id,
          name: org.name,
          parentOrganisationId: org.parentOrganisationId,
          ...org,
        });

        if (response.isOk()) {
          sendMessage({
            messageType: MessageBarType.success,
            text: "Successfully updated ",
          });
          onDismiss(true);
        } else {
          sendMessage({
            messageType: MessageBarType.error,
            text: response.message,
          });
          onDismiss();
        }

        clearForm();
      }
    },
    [api, organisation.id, sendMessage, clearForm, onDismiss]
  );

  const isErrors =
    errorMessages.name !== undefined ||
    errorMessages.parentOrganisationId !== undefined;

  return (
    <Panel
      headerText="Update Customer"
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={() => {
        clearForm();
        onDismiss();
      }}
    >
      <Stack tokens={{ childrenGap: "s2", padding: "s1" }}>
        <TextField
          label="Customer Name"
          required
          value={form.name}
          onChange={(ev, newValue) => {
            let value = newValue;
            if (newValue === "") {
              value = undefined;
            }
            updateForm({ field: "name", value });
          }}
          errorMessage={errorMessages.name}
          disabled={isUpdateLoading}
        />
        <ComboBox
          allowFreeform
          required
          label="Parent Customer"
          options={parentOrganisationOptions}
          selectedKey={form.parentOrganisationId}
          onChange={(ev, option) => {
            if (option !== undefined) {
              updateForm({
                field: "parentOrganisationId",
                value: option.key as string,
              });
            }
          }}
          errorMessage={errorMessages.name}
          disabled={isUpdateLoading}
        />
        <TextField
          multiline
          label="Public Notes"
          value={form.publicNotes}
          onChange={(ev, newValue) => {
            let value = newValue;
            if (value === "") {
              value = undefined;
            }

            updateForm({ field: "publicNotes", value });
          }}
          disabled={isUpdateLoading}
        />
        <Checkbox
          styles={{ root: { paddingTop: 8 } }}
          label="IUL"
          checked={form.iul}
          onChange={(ev, checked) => {
            if (checked !== undefined) {
              updateForm({ field: "iul", value: checked });
            }
          }}
          disabled={isUpdateLoading}
        />
        <Checkbox
          styles={{ root: { paddingTop: 8 } }}
          label="NFP"
          checked={form.nfp}
          onChange={(ev, checked) => {
            if (checked !== undefined) {
              updateForm({ field: "nfp", value: checked });
            }
          }}
          disabled={isUpdateLoading}
        />
        <Stack.Item align="end">
          <PrimaryButton
            disabled={isErrors || isUpdateLoading}
            onClick={() => {
              setUpdateLoading(true);
              handleUpdateOrganisation(form).then(() => {
                setUpdateLoading(false);
              });
            }}
          >
            {isUpdateLoading ? <Spinner size={SpinnerSize.medium} /> : "Update"}
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </Panel>
  );
};
