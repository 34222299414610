import React from "react";
import { Text, Separator } from "office-ui-fabric-react";
import { RestoreHistory } from "./RestoreHistoryView";

export interface IServiceAccountRestoreProps {
  organisationId: string;
  serviceAccountId: string;
  serviceId: string;
}

export const ServiceAccountRestoreHistory: React.FC<IServiceAccountRestoreProps> = ({
  organisationId,
  serviceAccountId,
  serviceId
}) => {
  return (
    <div>
      <Text variant="xxLarge">Restore History</Text>
      <Separator />
      <RestoreHistory
        organisationId={organisationId}
        serviceAccountId={serviceAccountId}
        serviceId={serviceId}
      />
    </div>
  );
};

export default ServiceAccountRestoreHistory;
