import React from "react";
import { Stack, Text, mergeStyles } from "office-ui-fabric-react";
import { DateTime } from "luxon";
import { Stat } from "./Stat";
import { pluralise } from "./utils";

export interface ITimeStatsProps {
  initialBackup?: Date;
  recentBackup?: Date;
  isLoading?: boolean;
}

const humanReadableDate = (
  date?: Date
): { diff?: string; fullDate?: string } => {
  if (date === undefined) {
    return {};
  } else if (isNaN(date.valueOf())) {
    // Protect against invalid dates
    return {};
  }

  const dt = DateTime.fromJSDate(date);
  const diff = dt.diffNow();

  const fullDate = dt.toLocaleString({
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });

  let diffStr: string | undefined;

  const year = Math.abs(diff.as("year"));
  if (year > 1) {
    const diffAmount = Math.floor(year);
    diffStr = `${diffAmount} ${pluralise("year", diffAmount)} ago`;
  }

  const times = ["year", "month", "week", "day", "hour", "minute"] as const;
  for (const time of times) {
    const t = Math.abs(diff.as(time));
    if (t > 1) {
      const diffAmount = Math.floor(t);
      diffStr = `${diffAmount} ${pluralise(time, diffAmount)} ago`;

      break;
    }
  }

  if (diffStr === undefined) {
    diffStr = "Moments ago";
  }

  return { diff: diffStr, fullDate };
};

export const TimeStats: React.FC<ITimeStatsProps> = ({
  initialBackup,
  recentBackup,
  isLoading
}) => {
  const hrInitialBackup = humanReadableDate(initialBackup);
  const hrRecentBackup = humanReadableDate(recentBackup);

  const stack = mergeStyles({
    paddingTop: 16,
    paddingBottom: 16
  });

  return (
    <>
      <Text variant="xxLarge">Backup Stats</Text>
      <Stack className={stack} horizontal wrap>
        <Stat
          title="Initial Backup"
          metric={hrInitialBackup?.diff}
          hover={hrInitialBackup?.fullDate}
          iconName="Upload"
          variant="xLarge"
          isLoading={isLoading}
        />
        <Stat
          title="Most Recent Backup"
          metric={hrRecentBackup?.diff}
          hover={hrRecentBackup?.fullDate}
          iconName="Clock"
          variant="xLarge"
          isLoading={isLoading}
        />
      </Stack>
    </>
  );
};
