export const getUserId = (idToken: string) => {
  try {
    const data = JSON.parse(atob(idToken.split(".")[1]));
    return data.userId;
  } catch (e) {
    return undefined;
  }
};

export const getUserDetails = (idToken: string) => {
  try {
    const data = JSON.parse(atob(idToken.split(".")[1]));
    const firstName = data.given_name;
    const lastName = data.family_name;
    return { firstName, lastName };
  } catch (e) {
    return undefined;
  }
};

export const hasRoles = (idToken: string) => {
  try {
    const data = JSON.parse(atob(idToken.split(".")[1]));
    return data.hasRoles === "true";
  } catch (e) {
    return undefined;
  }
};
