import React from "react";
import { useParams } from "react-router-dom";
import { Text } from "office-ui-fabric-react";
import { NotFound } from "./notFound";
import { EWS_SERVICE_ID } from "../constants";
import { Search } from "../components/Search";
import { ServiceAccountBreadCrumbs } from "../components/ServiceAccountBreadCrumbs";

export const SearchPage: React.FC = () => {
  const { organisationId, serviceName, serviceAccountId } = useParams();

  let serviceId: string;
  if (serviceName !== "mailbox") {
    // Search is not yet supported for sharepoint and onedrive services

    return <NotFound />;
  } else {
    serviceId = EWS_SERVICE_ID;
  }

  if (organisationId === undefined || serviceAccountId === undefined) {
    throw new Error("Missing required params");
  }

  return (
    <>
      <ServiceAccountBreadCrumbs
        serviceName={serviceName}
        organisationId={organisationId}
        serviceId={serviceId}
        serviceAccountId={serviceAccountId}
        linkServiceAccount
      />
      <Text variant="xxLarge">Search Mailbox</Text>
      <Search
        serviceId={serviceId}
        organisationId={organisationId}
        serviceAccountId={serviceAccountId}
      />
    </>
  );
};
