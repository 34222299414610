import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import {
  IEventReportConfig,
  IScheduledReportConfig,
} from "../../providers/ApiProvider/ApiClient/models/reporting";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
} from "office-ui-fabric-react";

import { EventConfigView } from "./EventConfigView";
import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { ScheduledConfigView } from "./ScheduledConfigView";
import { mergeStyles } from "office-ui-fabric-react";
import { useApiClient } from "../../providers/ApiProvider";
import { useParams } from "react-router-dom";

export interface IReportingConfigProps {}

export const CREATE_REPORT_ID = "create";

export const ReportingConfig: React.FC<IReportingConfigProps> = () => {
  const { organisationId, reportType, reportConfigId } = useParams();
  const api = useApiClient();

  const [scheduledConfig, setScheduledConfig] = useState<
    IScheduledReportConfig
  >();

  const [eventConfig, setEventConfig] = useState<IEventReportConfig>();

  const [organisation, setOrganisation] = useState<IOrganisation>();

  const defaultScheduled = useMemo<IScheduledReportConfig>(
    () => ({
      ownerId: organisationId ?? "",
      ownerType: "ORGANISATION",
      reportConfigId: CREATE_REPORT_ID,
      reportPeriod: "DAILY",
      serviceIds: [EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID],
      serviceAccountIds: [],
      timeOfDay: "09:00",
      timezone: "Australia/Sydney",
      recipientAddresses: [],
      disabled: false,
    }),
    [organisationId]
  );

  const defaultEvent = useMemo<IEventReportConfig>(
    () => ({
      ownerId: organisationId ?? "",
      ownerType: "ORGANISATION",
      reportConfigId: CREATE_REPORT_ID,
      serviceIds: [EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID],
      eventTypes: ["DISCOVER", "INITIAL_BACKUP", "PROVISION", "RESTORE"],
      recipientAddresses: [],
      timezone: "Australia/Sydney",
      disabled: false,
    }),
    [organisationId]
  );

  // Fetch the reporting config
  useEffect(() => {
    if (organisationId && reportType && reportConfigId) {
      if (reportType === "scheduled") {
        if (reportConfigId === CREATE_REPORT_ID) {
          setScheduledConfig(defaultScheduled);
        } else {
          api.reporting
            .getScheduledConfig({ organisationId, reportConfigId })
            .then(res => {
              if (res === undefined) {
                // TODO - NOT FOUND
              } else {
                setScheduledConfig(res);
              }
            });
        }
      } else if (reportType === "event") {
        if (reportConfigId === CREATE_REPORT_ID) {
          setEventConfig(defaultEvent);
        } else {
          api.reporting
            .getEventConfig({ organisationId, reportConfigId })
            .then(res => {
              if (res === undefined) {
                // TODO - NOT FOUND
              } else {
                setEventConfig(res);
              }
            });
        }
      } else {
        // TODO - NOT FOUND
      }
    }
  }, [
    organisationId,
    reportConfigId,
    reportType,
    api,
    defaultScheduled,
    defaultEvent,
  ]);

  // Fetch the organisation to display the name
  useEffect(() => {
    if (organisationId) {
      api.accounts.getOrganisation({ organisationId }).then(res => {
        if (res) {
          setOrganisation(res);
        } else {
          // TODO - NOT FOUND
        }
      });
    }
  }, [organisationId, api]);

  const updateScheduledConfig = useCallback(
    async (config: IScheduledReportConfig) => {
      if (config.reportConfigId === CREATE_REPORT_ID) {
        await api.reporting.createScheduledConfig({
          ownerId: config.ownerId,
          config,
        });
      } else {
        await api.reporting.updateScheduledConfig({
          ownerId: config.ownerId,
          ownerType: config.ownerType,
          reportConfigId: config.reportConfigId,
          config,
        });
      }
    },
    [api]
  );

  const updateEventConfig = useCallback(
    async (config: IEventReportConfig) => {
      if (config.reportConfigId === CREATE_REPORT_ID) {
        await api.reporting.createEventConfig({
          ownerId: config.ownerId,
          config,
        });
      } else {
        await api.reporting.updateEventConfig({
          ownerId: config.ownerId,
          ownerType: config.ownerType,
          reportConfigId: config.reportConfigId,
          config,
        });
      }
    },
    [api]
  );

  if (!organisationId || !reportType || !reportConfigId) {
    throw new Error("Invalid Routing");
  }

  const shimmerStyle = mergeStyles({
    display: "flex",
  });

  const shimmerElements = (
    <div className={shimmerStyle}>
      <ShimmerElementsGroup
        flexWrap
        shimmerElements={[
          { type: ShimmerElementType.line, width: 180, height: 40 },
          { type: ShimmerElementType.gap, width: 120, height: 40 },
          { type: ShimmerElementType.line, width: 70, height: 20 },
          { type: ShimmerElementType.gap, width: 230, height: 20 },
          { type: ShimmerElementType.line, width: 130, height: 20 },
          { type: ShimmerElementType.gap, width: 170, height: 20 },
          { type: ShimmerElementType.line, width: 220, height: 20 },
          { type: ShimmerElementType.gap, width: 80, height: 20 },
          { type: ShimmerElementType.line, width: 300, height: 20 },
        ]}
      />
      />
    </div>
  );

  return (
    <Shimmer
      width={300}
      customElementsGroup={shimmerElements}
      isDataLoaded={
        organisation !== undefined &&
        (eventConfig !== undefined || scheduledConfig !== undefined)
      }
    >
      {scheduledConfig ? (
        <ScheduledConfigView
          organisationName={organisation?.name}
          config={scheduledConfig}
          updateConfig={updateScheduledConfig}
          deleteConfig={async ({ ownerId, reportConfigId }) => {
            await api.reporting.deleteScheduledConfig({
              ownerId,
              reportConfigId,
            });
          }}
        />
      ) : (
        undefined
      )}
      {eventConfig ? (
        <EventConfigView
          organisationName={organisation?.name}
          config={eventConfig}
          updateConfig={updateEventConfig}
          deleteConfig={async ({ ownerId, reportConfigId }) => {
            await api.reporting.deleteEventConfig({
              ownerId,
              reportConfigId,
            });
          }}
        />
      ) : (
        undefined
      )}
    </Shimmer>
  );
};
