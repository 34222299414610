import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IStyle,
  IconButton,
  Label,
  MessageBarType,
  Modal,
  PrimaryButton,
  Separator,
  Spinner,
  Text,
  TextField,
  classNamesFunction,
  mergeStyleSets,
  styled, Checkbox,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { prepareComboOptions, prepareRestoreConfig } from "./serviceMapping";

import { IItem } from "./RestoreView";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { useApiClient } from "../../providers/ApiProvider";
import { useMessageBar } from "../../providers/messageBarProvider";

interface IPerformRestoreProps {
  organisationId: string;
  shouldShowModal: boolean;
  selectedRestorePoint: IItem;
  serviceId: string;
  toggleModal: (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  serviceAccountId: string;
  styles?: IRestoreModalStyles;
}

interface IRestoreModalStyles {
  fullWidth: IStyle;
  rightFloat: IStyle;
  modal: IStyle;
}

const restoreHistoryModalStyles = mergeStyleSets({
  fullWidth: {
    width: "100%",
  },
  rightFloat: {
    float: "right",
  },
  modal: {
    padding: 20,
    maxWidth: 440,
  },
});

const getClassNames = classNamesFunction<{}, IRestoreModalStyles>();

const additionalFields = (
  serviceId: string,
  dispatch: (ev: any, newValue: string | undefined) => void
) => {
  // The input "name" will be used as the additionalField key.
  if (serviceId === EWS_SERVICE_ID) {
    return [
      <TextField
        label="Restore Folder Name"
        onChange={dispatch}
        name="destinationFolder"
        defaultValue="Backup Data"
        required
      />,
    ];
  }

  return [<></>];
};

interface IAdditionalFieldState {
  [key: string]: string | undefined;
  destinationFolder?: string;
}

type TAdditionalFieldActions = {
  type: "setFields";
  payload: IAdditionalFieldState;
};

const additionalFieldReducer: React.Reducer<
  IAdditionalFieldState,
  TAdditionalFieldActions
> = (prevState: IAdditionalFieldState, action: TAdditionalFieldActions) => {
  switch (action.type) {
    case "setFields":
      return { ...prevState, ...action.payload };
    default:
      return prevState;
  }
};

export const BaseRestoreModal: React.FC<IPerformRestoreProps> = ({
  styles,
  organisationId,
  serviceId,
  serviceAccountId,
  selectedRestorePoint,
  shouldShowModal,
  toggleModal,
}) => {
  const { sendMessage } = useMessageBar();
  const [isLoading, setIsLoading] = useState(true);
  const [download, setDownload] = useState(true);
  const [serviceAccount, setServiceAccount] = useState<IServiceAccount>();
  const [destination, setDestination] = useState<string | undefined>(
    serviceAccountId
  );
  const [allServiceAccounts, setAllServiceAccounts] = useState<
    IServiceAccount[]
  >();
  const [selected, setSelected] = useState<IItem>();
  const [additionalFieldsState, dispatch] = useReducer(
    additionalFieldReducer,
    {}
  );

  const _onChangeDownload = useCallback((e, val)=>{
    console.log(val);
    setDownload(val)
  }, [setDownload])

  const api = useApiClient();

  const classNames = getClassNames(styles);

  const getServiceAccount = useCallback(async () => {
    const resp = await api.accounts.getServiceAccount({
      serviceAccountId,
      serviceId,
      organisationId,
    });

    setIsLoading(false);
    setServiceAccount(resp);
  }, [api, organisationId, serviceAccountId, serviceId]);

  const getAllServiceAccounts = useCallback(async () => {
    let cursor: string | undefined;
    let serviceAccounts: IServiceAccount[] = [];
    do {
      const serviceAccResp = await api.accounts.listServiceAccounts({
        organisationId,
        serviceId,
        cursor,
      });
      if (serviceAccResp?.nodes) {
        serviceAccounts = [...serviceAccounts, ...serviceAccResp.nodes];
      }
      cursor = serviceAccResp?.pageInfo.cursor;
    } while (cursor);

    if (serviceId === SHAREPOINT_SERVICE_ID) {
      // Disallow restoring 1D > SP and SP > 1D until we support it.
      const serviceAccount = await api.accounts.getServiceAccount({
        organisationId,
        serviceAccountId,
        serviceId,
      });
      const filterString = "-my.sharepoint.com/personal/";
      if (serviceAccount?.identifier.includes(filterString)) {
        serviceAccounts = serviceAccounts.filter(servAcc =>
          servAcc.identifier.includes(filterString)
        );
      } else {
        serviceAccounts = serviceAccounts.filter(
          servAcc => !servAcc.identifier.includes(filterString)
        );
      }
    }

    return serviceAccounts;
  }, [api, organisationId, serviceId, serviceAccountId]);

  useEffect(() => {
    if (selectedRestorePoint) {
      setSelected(selectedRestorePoint);
    }

    if (!serviceAccount) {
      getServiceAccount();
    }
  }, [selectedRestorePoint, getServiceAccount, api, serviceAccount]);

  useEffect(() => {
    if (!allServiceAccounts) {
      getAllServiceAccounts().then(serviceAccounts => {
        setAllServiceAccounts(serviceAccounts);
      });
    }
  }, [
    allServiceAccounts,
    api,
    serviceAccountId,
    organisationId,
    serviceId,
    getAllServiceAccounts,
  ]);

  if (!selected) {
    return <></>;
  }

  const dispatchFields = (
    ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string | undefined
  ) => {
    if (!value?.length) {
      value = undefined;
    }
    const target = ev.target;
    // For some reason Fabric doesn't expose the input tags
    // even though they will clearly always exist
    const hasName = (x: any): x is { name: string } => {
      if (x.name && x.name.length) {
        return true;
      }
      return false;
    };
    if (hasName(target)) {
      dispatch({
        type: "setFields",
        payload: { [target.name]: value },
      });
    } else {
      throw new Error(`Error, field is missing name tag ${target}`);
    }
  };

  if (!allServiceAccounts || !serviceAccount) {
    return (
      <Modal
        styles={{ main: restoreHistoryModalStyles.modal }}
        isOpen={shouldShowModal || false}
        onDismiss={toggleModal}
        isBlocking={false}
      >
        <Spinner />
      </Modal>
    );
  }

  const onComboChange = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option !== undefined) {
      setDestination(option.key as string | undefined);
    }
  };

  const closeModal = () => {
    toggleModal();
  };

  const performRestore = async () => {
    console.log(`download=${download}`);
    setIsLoading(true);
    const toServiceAccount = allServiceAccounts.filter(servAcc => {
      if (destination) {
        return servAcc.id === destination;
      }
      return false;
    })[0];
    if (!toServiceAccount) {
      // Error
    }
    const config = prepareRestoreConfig({
      serviceId,
      toServiceAccount: toServiceAccount,
      restoreTime: new Date(selected.rawDate),
      additionalFields: additionalFieldsState,
    });

    let response;
    if (serviceId === SHAREPOINT_SERVICE_ID && download) {
      response = await api.restore.spPartialRestore({
        serviceId,
        organisationId,
        serviceAccountId: config.dstServiceAccountId,
        dstServiceAccountId: config.dstServiceAccountId,
        fileIds: [],
        folderIds: ['ROOT'],
        timestamp: config.restoreTime,
        download: true
      });
    } else {
      response = await api.restore.fullRestore({
        organisationId,
        serviceId,
        serviceAccountId: serviceAccount.id,
        config,
      });
    }

    let messageText =
      "Your restore has been queued. You will receive an email once it is complete";
    let messageType = MessageBarType.success;
    if (response) {
      messageText = response;
      messageType = MessageBarType.error;
    }

    sendMessage({ text: messageText, messageType });

    setIsLoading(false);
    closeModal();
  };

  const restoreButton = isLoading ? (
    <Spinner />
  ) : (
    <PrimaryButton className={classNames.fullWidth} onClick={performRestore}>
      Restore
    </PrimaryButton>
  );

  return (
    <>
      <Modal
        styles={{
          main: restoreHistoryModalStyles.modal,
        }}
        isOpen={shouldShowModal || false}
        onDismiss={closeModal}
        isBlocking={false}
      >
        <div className="ms-Grid-col ms-sm12">
          <IconButton
            className={classNames.rightFloat}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close popup modal"
            onClick={closeModal as any}
          />
          <Text variant="large">
            Restore Point: <b>{selected.date}</b>
          </Text>
          <Separator />
          {(serviceId === SHAREPOINT_SERVICE_ID) ? <Checkbox label="Restore to a downloadable file." onChange={_onChangeDownload} defaultChecked={true} disabled={true}/> : ''}
        </div>
        <div className="ms-Grid-col ms-sm12">
          <Separator />
        </div>

        {additionalFields(serviceId, dispatchFields).map(field => {
          return <div className="ms-Grid-col ms-sm12">{field}</div>;
        })}
        {(serviceId === EWS_SERVICE_ID) ?
            <div className="ms-Grid-col ms-sm12">
              <ComboBox
                allowFreeform
                autoComplete="on"
                options={prepareComboOptions(allServiceAccounts, serviceId)}
                label="Destination"
                onChange={onComboChange}
                selectedKey={destination}
              />
            </div> :""
        }
        <div className="ms-Grid-col ms-sm12">
          <Label>
            <>&nbsp;</>
          </Label>
          {restoreButton}
        </div>
      </Modal>
    </>
  );
};

export const RestoreModal = styled(
    BaseRestoreModal,
    (): IRestoreModalStyles => {
      return restoreHistoryModalStyles;
    }
);
