import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  List,
  PrimaryButton,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Toggle,
  getTheme,
} from "office-ui-fabric-react";
import {
  IEventReportConfig,
  IScheduledReportConfig,
} from "../../providers/ApiProvider/ApiClient/models/reporting";
import React, { useCallback, useState } from "react";
import { TDeleteFunction, TUpdatable, TUpdateFunction } from ".";

import { CREATE_REPORT_ID } from "../ReportingConfig";
import { mergeStyles } from "office-ui-fabric-react";
import { timezones } from "../../utils/timezones";
import { toFirstLetterUpper } from "../../utils/strings";
import { useHistory } from "react-router-dom";

export interface IReportingSummaryViewProps {
  scheduledConfigs: TUpdatable<IScheduledReportConfig>[];
  isScheduledLoading?: boolean;
  eventConfigs: TUpdatable<IEventReportConfig>[];
  isEventLoading?: boolean;
  updateConfig: TUpdateFunction;
  deleteConfig: TDeleteFunction;
}

const timezoneToString = (str: string): string | undefined => {
  const tz = timezones.find(t => t.key === str);

  return tz?.displayName;
};

export const ReportingSummaryView: React.FC<IReportingSummaryViewProps> = ({
  scheduledConfigs,
  isScheduledLoading,
  eventConfigs,
  isEventLoading,
  updateConfig,
  deleteConfig,
}) => {
  const history = useHistory();

  const theme = getTheme();
  const { semanticColors, palette } = theme;

  const [selectedConfig, setSelectedConfig] = useState<{
    configType: "EVENT" | "SCHEDULED";
    ownerId: string;
    reportConfigId: string;
  }>();

  const itemCell = mergeStyles({
    padding: 10,
    boxSizing: "border-box",
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    selectors: {
      "&:hover": { background: palette.neutralLight },
    },
  });

  const onRenderScheduled = useCallback(
    (item?: TUpdatable<IScheduledReportConfig>): JSX.Element => {
      if (!item) {
        return <></>;
      }

      const servicesMap: Record<string, string> = {
        "5e33cff5-d458-42af-97c3-77096b4179e7": "Mailbox",
        "123df61b-7b40-41bd-b962-dc8bf6a1f214": "SharePoint/OneDrive",
      };

      const services = item.serviceIds.map(sId => servicesMap[sId]).join(", ");

      const period = toFirstLetterUpper(item.reportPeriod);

      const weekDayMap: Record<number, string> = {
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
        7: "Sun",
      };

      let days: JSX.Element | undefined;
      if (item.daysOfWeek) {
        const daysOfWeek = item.daysOfWeek.sort().map(day => weekDayMap[day]);
        days = (
          <Text>
            <b>Days of Week:</b> {daysOfWeek.join(", ")}
          </Text>
        );
      } else if (item.daysOfMonth) {
        days = (
          <Text>
            <b>Days of Month:</b> {item.daysOfMonth.sort().join(", ")}
          </Text>
        );
      }

      const container = mergeStyles({
        width: "45%",
        minWidth: 400,
      });

      return (
        <div
          className={itemCell}
          onClick={() => {
            history.push(
              `${history.location.pathname}/scheduled/${item.reportConfigId}`
            );
          }}
        >
          <Stack
            horizontal
            wrap
            tokens={{ childrenGap: "s2" }}
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack tokens={{ childrenGap: "s2" }} className={container}>
              <Text>
                <b>Services:</b> {services}
              </Text>
              <Text>
                <b>Period:</b> {period}
              </Text>
              <Text>
                <b>Time:</b> {item.timeOfDay}
              </Text>
              <Text>
                <b>Timezone:</b> {timezoneToString(item.timezone)}
              </Text>
            </Stack>
            <Stack tokens={{ childrenGap: "s2" }} className={container}>
              {days}
              <Text>
                <b>Recipients:</b>{" "}
                {item.recipientAddresses
                  .filter(recipient => recipient !== "betareports@backup365.io")
                  .join(", ")}
              </Text>
              <Stack horizontal tokens={{ childrenGap: "s1" }}>
                <Text>
                  <b>Enabled:</b>
                </Text>
                {item.isUpdating ? (
                  <Spinner size={SpinnerSize.medium} />
                ) : (
                  <Toggle
                    checked={!item.disabled}
                    onChange={(ev, checked) => {
                      ev.stopPropagation();
                      if (checked !== undefined) {
                        updateConfig({
                          configType: "SCHEDULED",
                          ownerType: item.ownerType,
                          ownerId: item.ownerId,
                          reportConfigId: item.reportConfigId,
                          disabled: !checked,
                        });
                      }
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack.Item align="center">
              <IconButton
                onClick={ev => {
                  ev.stopPropagation();
                  setSelectedConfig({
                    configType: "SCHEDULED",
                    ownerId: item.ownerId,
                    reportConfigId: item.reportConfigId,
                  });
                }}
                iconProps={{ iconName: "Delete" }}
                styles={{
                  root: { padding: 0 },
                  icon: { color: theme.palette.red },
                }}
              />
            </Stack.Item>
          </Stack>
        </div>
      );
    },
    [history, itemCell, theme, updateConfig]
  );

  const onRenderEvent = useCallback(
    (item?: TUpdatable<IEventReportConfig>): JSX.Element => {
      if (!item) {
        return <></>;
      }

      const servicesMap: Record<string, string> = {
        "5e33cff5-d458-42af-97c3-77096b4179e7": "Mailbox",
        "123df61b-7b40-41bd-b962-dc8bf6a1f214": "SharePoint/OneDrive",
      };

      const services = item.serviceIds.map(sId => servicesMap[sId]).join(", ");
      const events = item.eventTypes
        .map(str => str.replace("_", " "))
        .map(str => {
          const parts = str.split(" ");
          const upperFirstParts = parts.map(toFirstLetterUpper);

          return upperFirstParts.join(" ");
        })
        .join(", ");

      const container = mergeStyles({
        width: "45%",
        minWidth: 400,
      });

      return (
        <div
          className={itemCell}
          onClick={() => {
            history.push(
              `${history.location.pathname}/event/${item.reportConfigId}`
            );
          }}
        >
          <Stack
            horizontal
            wrap
            tokens={{ childrenGap: "s2" }}
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack tokens={{ childrenGap: "s2" }} className={container}>
              <Text>
                <b>Services:</b> {services}
              </Text>
              <Text>
                <b>Events:</b> {events}
              </Text>
            </Stack>
            <Stack tokens={{ childrenGap: "s2" }} className={container}>
              <Text>
                <b>Recipients:</b>{" "}
                {item.recipientAddresses
                  .filter(recipient => recipient !== "betareports@backup365.io")
                  .join(", ")}
              </Text>
              <Stack horizontal tokens={{ childrenGap: "s1" }}>
                <Text>
                  <b>Enabled:</b>
                </Text>
                {item.isUpdating ? (
                  <Spinner size={SpinnerSize.medium} />
                ) : (
                  <Toggle
                    checked={!item.disabled}
                    onChange={(ev, checked) => {
                      ev.stopPropagation();
                      if (checked !== undefined) {
                        updateConfig({
                          configType: "EVENT",
                          ownerType: item.ownerType,
                          ownerId: item.ownerId,
                          reportConfigId: item.reportConfigId,
                          disabled: !checked,
                        });
                      }
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack.Item align="center">
              <IconButton
                onClick={ev => {
                  ev.stopPropagation();
                  setSelectedConfig({
                    configType: "EVENT",
                    ownerId: item.ownerId,
                    reportConfigId: item.reportConfigId,
                  });
                }}
                iconProps={{ iconName: "Delete" }}
                styles={{
                  root: { padding: 0 },
                  icon: { color: theme.palette.red },
                }}
              />
            </Stack.Item>
          </Stack>
        </div>
      );
    },
    [history, itemCell, theme, updateConfig]
  );

  const shimmerStyle = mergeStyles({
    display: "flex",
  });

  const shimmerElements = (
    <div className={shimmerStyle}>
      <ShimmerElementsGroup
        flexWrap
        shimmerElements={[
          { type: ShimmerElementType.gap, width: 300, height: 10 },
          { type: ShimmerElementType.line, width: 300, height: 16 },
          { type: ShimmerElementType.line, width: 220, height: 16 },
          { type: ShimmerElementType.gap, width: 80, height: 24 },
          { type: ShimmerElementType.line, width: 130, height: 16 },
          { type: ShimmerElementType.gap, width: 170, height: 24 },
          { type: ShimmerElementType.line, width: 70, height: 16 },
          { type: ShimmerElementType.gap, width: 230, height: 24 },
        ]}
      />
      />
    </div>
  );

  return (
    <>
      <Dialog
        hidden={selectedConfig === undefined}
        onDismiss={() => {
          setSelectedConfig(undefined);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Are you sure you want to delete this report?",
          subText: "This cannot be undone",
        }}
      >
        <DialogFooter>
          <PrimaryButton
            text="Ok"
            onClick={() => {
              if (selectedConfig !== undefined) {
                deleteConfig(selectedConfig);
                setSelectedConfig(undefined);
              }
            }}
          />
          <DefaultButton
            text="Cancel"
            onClick={() => {
              setSelectedConfig(undefined);
            }}
          />
        </DialogFooter>
      </Dialog>
      <Stack tokens={{ childrenGap: "m" }}>
        <Stack.Item>
          <Stack
            tokens={{ childrenGap: "s1", padding: "s1" }}
            horizontal
            horizontalAlign="space-between"
            wrap
          >
            <Text variant="xLarge">Scheduled Reports</Text>
            <PrimaryButton
              onClick={() => {
                history.push(
                  `${history.location.pathname}/scheduled/${CREATE_REPORT_ID}`
                );
              }}
            >
              Create Scheduled Report
            </PrimaryButton>
          </Stack>
          <Shimmer
            width={300}
            isDataLoaded={!isScheduledLoading}
            customElementsGroup={shimmerElements}
          >
            <List items={scheduledConfigs} onRenderCell={onRenderScheduled} />
          </Shimmer>
        </Stack.Item>
        <Stack.Item>
          <Stack
            tokens={{ childrenGap: "s1", padding: "s1" }}
            horizontal
            horizontalAlign="space-between"
            wrap
          >
            <Text variant="xLarge">Event Reports</Text>
            <PrimaryButton
              onClick={() => {
                history.push(
                  `${history.location.pathname}/event/${CREATE_REPORT_ID}`
                );
              }}
            >
              Create Event Report
            </PrimaryButton>
          </Stack>
          <Shimmer
            width={300}
            isDataLoaded={!isEventLoading}
            customElementsGroup={shimmerElements}
          >
            <List items={eventConfigs} onRenderCell={onRenderEvent} />
          </Shimmer>
        </Stack.Item>
      </Stack>
    </>
  );
};
