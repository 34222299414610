import {
  Link,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  mergeStyleSets,
} from "office-ui-fabric-react";
import React, { useState } from "react";

import { TermsAndConditionsContent } from "./content";
import { useForm } from "../../hooks/useForm";
import { validateEmail } from "../../utils/validateEmail";

export interface ITermsAndConditionsViewProps {
  onAccept?: (opts: { name: string; email: string }) => void;
  isAcceptLoading?: boolean;
}

interface IFormState {
  name?: string;
  email?: string;
}

export const TermsAndConditionsView: React.FC<ITermsAndConditionsViewProps> = ({
  onAccept,
  isAcceptLoading = false,
}) => {
  const classNames = mergeStyleSets({
    container: {
      maxWidth: 960,
      margin: "auto",
    },
    content: {
      maxHeight: "75vh",
      overflowY: "scroll",
    },
    input: {
      width: "100%",
    },
    textField: {
      minWidth: 300,
    },
  });

  const [{ data: form, validation: errorMessages }, updateForm] = useForm<
    IFormState
  >({
    initialState: {},
    validation: {
      email: val => {
        if (val !== undefined && !validateEmail(val.trim())) {
          return "Must be a valid email address";
        }
      },
    },
  });

  const [viewedAUP, setViewedAUP] = useState<boolean>();

  console.log(viewedAUP);

  const formValid =
    form.name !== undefined &&
    form.name.trim().length > 0 &&
    form.email !== undefined &&
    errorMessages.email === undefined;

  return (
    <div className={classNames.container}>
      <div className={classNames.content}>
        <TermsAndConditionsContent />
      </div>
      <Stack
        horizontal
        wrap
        style={{ paddingTop: 15, paddingBottom: 15 }}
        tokens={{
          maxWidth: 960,
          childrenGap: "s1",
        }}
      >
        <Stack.Item>
          <Link
            href="https://backup365.atlassian.net/wiki/spaces/SUP/pages/1605697542/Backup365+Acceptable+Use+Policy+AUP"
            target="blank"
            onClick={() => setViewedAUP(true)}
            style={{ width: "100%", marginTop: 30, marginBottom: 30 }}
          >
            Please click here to read our AUP before accepting the terms and
            conditions
          </Link>
        </Stack.Item>
      </Stack>
      <Stack horizontal wrap tokens={{ maxWidth: 960, childrenGap: "s1" }}>
        <Stack.Item className={classNames.textField} grow>
          <TextField
            label="Name"
            required
            value={form.name ?? ""}
            onChange={(ev, newValue) => {
              let value = newValue;
              if (newValue === "") {
                value = undefined;
              }

              updateForm({ field: "name", value });
            }}
          />
        </Stack.Item>
        <Stack.Item className={classNames.textField} grow>
          <TextField
            label="Email"
            required
            value={form.email ?? ""}
            onChange={(ev, newValue) => {
              let value = newValue;
              if (newValue === "") {
                value = undefined;
              }

              updateForm({ field: "email", value });
            }}
            errorMessage={errorMessages.email}
          />
        </Stack.Item>
        <Stack.Item styles={{ root: { paddingTop: 28 } }} align="start">
          <PrimaryButton
            disabled={!formValid || isAcceptLoading || !viewedAUP}
            onClick={() => {
              if (form.name !== undefined && form.email !== undefined) {
                onAccept?.({
                  name: form.name.trim(),
                  email: form.email.trim(),
                });
              }
            }}
          >
            {isAcceptLoading ? <Spinner size={SpinnerSize.medium} /> : "Accept"}
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </div>
  );
};
