import React from "react";
import { Text } from "office-ui-fabric-react";

export interface IResultDetailProps {
  label: string;
  value?: string | null | undefined;
}

export const ResultDetail: React.FC<IResultDetailProps> = ({
  label,
  value
}) => {
  return (
    <>
      {value ? (
        <Text>
          <b>{label}:</b> {value}
        </Text>
      ) : (
        undefined
      )}
    </>
  );
};
