import {
  IOrganisation,
  IServiceAccount
} from "../../providers/ApiProvider/ApiClient/models/accounts";

export interface IAccountsState {
  organisations: IOrganisation[] | undefined;
  serviceAccounts: IServiceAccount[] | undefined;
}

export interface IAccountsActions {
  setOrganisations: (organisations: IOrganisation[]) => ISetOrganisations;
  mergeOrganisations: (organisations: IOrganisation[]) => IMergeOrganisations;
  appendOrganisation: (organisation: IOrganisation) => IAppendOrganisation;
  setServiceAccounts: (
    serviceAccounts: IServiceAccount[]
  ) => ISetServiceAccounts;
  mergeServiceAccounts: (
    serviceAccounts: IServiceAccount[]
  ) => IMergeServiceAccounts;
  appendServiceAccount: (
    serviceAccounts: IServiceAccount
  ) => IAppendServiceAccount;
}

export const SET_ORGANISATIONS = "SET_ORGANISATIONS";
export const MERGE_ORGANISATIONS = "SET_ORGANISATIONS";
export const APPEND_ORGANISATION = "APPEND_ORGANISATION";

export const SET_SERVICE_ACCOUNTS = "SET_SERVICE_ACCOUNTS";
export const MERGE_SERVICE_ACCOUNTS = "MERGE_SERVICE_ACCOUNTS";
export const APPEND_SERVICE_ACCOUNT = "APPEND_SERVICE_ACCOUNT";

export interface ISetOrganisations {
  type: typeof SET_ORGANISATIONS;
  payload: IOrganisation[];
}

export interface IMergeOrganisations {
  type: typeof MERGE_ORGANISATIONS;
  payload: IOrganisation[];
}

export interface IAppendOrganisation {
  type: typeof APPEND_ORGANISATION;
  payload: IOrganisation;
}

export interface ISetServiceAccounts {
  type: typeof SET_SERVICE_ACCOUNTS;
  payload: IServiceAccount[];
}

export interface IMergeServiceAccounts {
  type: typeof MERGE_SERVICE_ACCOUNTS;
  payload: IServiceAccount[];
}

export interface IAppendServiceAccount {
  type: typeof APPEND_SERVICE_ACCOUNT;
  payload: IServiceAccount;
}

export type TAccountActions =
  | ISetOrganisations
  | IMergeOrganisations
  | IAppendOrganisation
  | ISetServiceAccounts
  | IMergeServiceAccounts
  | IAppendServiceAccount;
