/* An overly simple function used to validate that the input is an email address.
 * Email is complicated to validate correctly, so this regex just ensures that
 * it matches the format <username>@<domain>.<tld> with no restrictions on what
 * each of those parts contain.
 */
const emailRegex = /^.+@.+\..+$/;

export const validateEmail = (input: string): boolean => {
  return input.match(emailRegex) !== null;
};
