import React, { createContext, useContext } from "react";
import { IApiClient, ApiClient } from "./ApiClient";

const ApiClientContext = createContext<IApiClient | undefined>(undefined);

export interface IApiClientProviderProps {
  client: IApiClient;
}

export const ApiClientProvider: React.FC<IApiClientProviderProps> = ({
  client,
  children
}) => {
  return (
    <ApiClientContext.Provider value={client}>
      {children}
    </ApiClientContext.Provider>
  );
};

export const useApiClient = (): ApiClient => {
  const client = useContext(ApiClientContext);

  if (client === undefined) {
    throw new Error("ApiClient has not been configured");
  }

  return client;
};
