import { BrowseView, IItem } from "./BrowseView";
import {
  IBrowseResponse,
  IFileItem,
} from "../../providers/ApiProvider/ApiClient/models/browse";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { humanReadableBytes, pluralise } from "../ServiceAccountStats/utils";

import { BrowseBreadCrumbs } from "./BrowseBreadCrumbs";
import { BrowseDatePicker } from "./BrowseDatePicker";
import { DateTime } from "luxon";
import { FileModal } from "./FileModal";
import { IPaginate } from "../../providers/ApiProvider/ApiClient/models/utils";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { MessageBarType } from "office-ui-fabric-react";
import { RestoreItemsModal } from "./RestoreItemsModal";
import { setTimezoneOffset } from "../../utils/timezones";
import { useApiClient } from "../../providers/ApiProvider";
import { useHistory } from "react-router-dom";
import { useMessageBar } from "../../providers/messageBarProvider";
import { useQuery } from "../../hooks/useQuery";

export interface IBrowseProps {
  serviceId: string;
  organisationId: string;
  serviceAccountId: string;
  rootFolder: string;
}

export type TPerformRestoreFn = (opts: {
  sourceServiceAccountId: string;
  destinationServiceAccountId: string;
  fileIds: string[];
  folderIds: string[];
  timestamp?: string;
  download?: boolean;
}) => Promise<boolean>;

const extractNameFromPath = (path: string): string => {
  if(path === undefined){
    return ''
  }
  const parts = path.split("/");

  let name: string = parts[parts.length - 1];
  if (name === "") {
    name = parts[parts.length - 2];
  }

  return name;
};

const parseParentId = (
  parentId: string
): Array<{ name: string; parentId: string }> => {
  const parentParts = parentId.split("/").filter(part => part !== "");

  const parentNames = parentParts.map(part =>
    Buffer.from(part, "base64").toString()
  );

  const parents = parentNames.map((name, index) => {
    const parts = parentParts.slice(0, index);
    const id = parts.join("/");

    return { name, parentId: id };
  });

  return parents;
};

const hrDate = (date?: string): string | undefined => {
  if (date === undefined) {
    return undefined;
  }

  const dt = DateTime.fromISO(date);

  const fullDate = dt.toLocaleString({
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return fullDate;
};

const isDefined = <T extends any>(val: any): val is NonNullable<T> => {
  return val !== undefined && val !== null;
};

type TServiceAccountAction =
  | {
      type: "MERGE_SERVICE_ACCOUNTS";
      payload: IPaginate<IServiceAccount>;
    }
  | { type: "CLEAR_STATE" };

interface IServiceAccountState {
  serviceAccounts: IServiceAccount[];
  cursor?: string;
  hasNextPage: boolean;
}

const initialServiceAccountState: IServiceAccountState = {
  serviceAccounts: [],
  hasNextPage: true,
};

const serviceAccountReducer: React.Reducer<
  IServiceAccountState,
  TServiceAccountAction
> = (prevState, action) => {
  switch (action.type) {
    case "MERGE_SERVICE_ACCOUNTS":
      return {
        ...prevState,
        serviceAccounts: [
          ...prevState.serviceAccounts,
          ...action.payload.nodes,
        ],
        cursor: action.payload.pageInfo.cursor,
        hasNextPage: action.payload.pageInfo.hasNextPage,
      };
    case "CLEAR_STATE":
      return initialServiceAccountState;
    default:
      return prevState;
  }
};

const initialBrowseState: IBrowseResponse = {
  items: [],
  folders: [],
  pageInfo: { hasNextPage: false },
};

type TBrowseAction =
  | {
      type: "MERGE_BROWSE_RESPONSE";
      payload: IBrowseResponse;
    }
  | { type: "CLEAR_STATE" };

const browseReducer: React.Reducer<IBrowseResponse, TBrowseAction> = (
  prevState,
  action: any
) => {
  //console.log(`prevState ${JSON.stringify(prevState)}`);
  console.log(`prevState.items ${prevState.items}`);
  console.log(`action.payload?.items ${action.payload?.items}`);
  switch (action.type) {
    case "MERGE_BROWSE_RESPONSE":
      return {
        items: [...prevState.items, ...action.payload?.items],
        folders: [...prevState.folders, ...action.payload?.folders],
        pageInfo: action.payload?.pageInfo,
      };
    case "CLEAR_STATE":
      console.log('CLEAR_STATE');
      return initialBrowseState;
    default:
      return prevState;
  }
};

export const Browse: React.FC<IBrowseProps> = ({
  serviceId,
  serviceAccountId,
  organisationId,
  rootFolder,
}) => {
  const api = useApiClient();
  const history = useHistory();
  const query = useQuery();
  const { sendMessage } = useMessageBar();

  const parentId = query.get("parentId") ?? undefined;
  const parents = parentId ? parseParentId(parentId) : undefined;

  let timestamp = query.get("timestamp") ?? undefined;

  // TODO check against invalid (incl. empty) parent ids
  const [serviceAccountState, dispatchServiceAccount] = useReducer(
    serviceAccountReducer,
    initialServiceAccountState
  );

  const [browseResults, dispatchBrowse] = useReducer(
    browseReducer,
    initialBrowseState
  );

  // Clear the serviceAccounts if the organisationId or serviceId changes
  useEffect(() => {
    dispatchServiceAccount({ type: "CLEAR_STATE" });
  }, [organisationId, serviceId]);

  // Load the serviceAccounts for the organisation
  useEffect(() => {
    if (serviceAccountState.hasNextPage) {
      api.accounts
        .listServiceAccounts({
          organisationId,
          serviceId,
          cursor: serviceAccountState.cursor,
        })
        .then(res => {
          if (res !== undefined) {
            dispatchServiceAccount({
              type: "MERGE_SERVICE_ACCOUNTS",
              payload: res,
            });
          }
        });
    }
  }, [
    api.accounts,
    organisationId,
    serviceId,
    serviceAccountState.cursor,
    serviceAccountState.hasNextPage,
  ]);

  const [isResultsLoading, setResultsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openFile, setOpenFile] = useState<IFileItem>();
  const [selectedFiles, setSelectedFiles] = useState<IItem[]>();
  const [fileFolderPath, setFileFolderPath] = useState<string | undefined>("");

  const asyncFunction = async (isOnClick: Boolean) => {
    setResultsLoading(true);
    setIsLoading(true);
    dispatchBrowse({ type: "CLEAR_STATE" });

    const serviceAccount = await api.accounts.getServiceAccount({
      organisationId,
      serviceId,
      serviceAccountId,
    });

    const identifier = serviceAccount?.identifier;
    let pathName = new URL(identifier!).pathname
    if(pathName === '/') {
      pathName = '';
    }
    if (timestamp === undefined) {
      const date = new Date();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timestamp = date.toISOString();
    }
    await api.browse
      .browse({
        serviceId,
        folderPath:
          fileFolderPath || `${serviceAccountId}:${pathName}/${rootFolder}`,
        organisationId,
        serviceAccountId,
        parentId,
        timestamp,
      })
      .then(res => {
        if (res) {
          dispatchBrowse({ type: "MERGE_BROWSE_RESPONSE", payload: res });
        }
        setIsLoading(false);
        setResultsLoading(false);
      });
  };
  useEffect(() => {
    asyncFunction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serviceId,
    serviceAccountId,
    timestamp,
    organisationId,
    fileFolderPath,
    api,
    parentId,
    setIsLoading,
  ]);

  const loadNextPage = useCallback(async () => {
    if (timestamp === undefined) {
      const date = new Date();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timestamp = date.toISOString();
    }
    if (!isLoading && !isResultsLoading && browseResults.pageInfo.hasNextPage) {
      console.log(`pageInfo ${browseResults.pageInfo}`)
      console.log(`pageInfo.cursor ${browseResults.pageInfo.cursor}`)
      setIsLoading(true);
      const decodedCursor = Buffer.from(browseResults.pageInfo.cursor || '', 'base64').toString('utf-8');
      let cursor = JSON.parse(decodedCursor);
      const folderPath = cursor.parentFolderId.S;
      console.log(`folderPath ${folderPath}`)

      const res = await api.browse.browse({
        serviceId,
        organisationId,
        serviceAccountId,
        folderPath,
        timestamp,
        cursor: browseResults.pageInfo.cursor,
      });

      if (res) {
        dispatchBrowse({ type: "MERGE_BROWSE_RESPONSE", payload: res });
      }
      setIsLoading(false);
    }
  }, [
    serviceId,
    serviceAccountId,
    organisationId,
    timestamp,
    isResultsLoading,
    isLoading,
    setIsLoading,
    browseResults,
    api,
  ]);

  const folders: IItem[] | any =
    browseResults &&
    browseResults.folders.map((folder: any): IItem[] | any => ({
      itemId: folder.itemPath,
      itemType: "folder",
      name: extractNameFromPath(folder.itemId),
      onClick: () => {
        setFileFolderPath(folder.itemId);
      },
    }));

  const files: IItem[] | undefined =
    browseResults &&
    browseResults.items
      .map((file: any) => {
        if (file.itemId === undefined) {
          return undefined;
        }
        const item: any = {
          itemId: file.restoreKey,
          itemType: "file",
          name: file.itemName,
          created: hrDate(file.timeCreated),
          modified: hrDate(file.timeLastModified),
          fileSize: humanReadableBytes(parseInt(file.itemSize, 10)),
          onClick: () => setOpenFile(file),
        };
        return item;
      })
      .filter(isDefined);

  const dateValue = timestamp ? new Date(timestamp) : undefined;
  const setDateValue = useCallback(
    (date?: Date) => {
      if (date === undefined) {
        query.delete("timestamp");
      } else {
        setTimezoneOffset(date);
        query.set("timestamp", date.toISOString());
      }

      history.push({ search: query.toString() });
    },
    [history, query]
  );

  const performRestore: TPerformRestoreFn = useCallback(
    async ({
      sourceServiceAccountId,
      destinationServiceAccountId,
      fileIds, folderIds,
      timestamp: string,
      download
    }) => {
      const sourceServiceAccount = serviceAccountState.serviceAccounts.find(
        serviceAccount => serviceAccount.id === sourceServiceAccountId
      );

      const destinationServiceAccount = serviceAccountState.serviceAccounts.find(
        serviceAccount => serviceAccount.id === destinationServiceAccountId
      );

      if (
        destinationServiceAccount === undefined ||
        sourceServiceAccount === undefined
      ) {
        return false;
      }

      const response = await api.restore.spPartialRestore({
        serviceId,
        organisationId,
        serviceAccountId: sourceServiceAccountId,
        dstServiceAccountId: destinationServiceAccountId,
        fileIds,
        folderIds,
        timestamp,
        download
      });

      return response !== undefined;
    },
    [
      api.restore,
      serviceAccountState.serviceAccounts,
      organisationId,
      serviceId,
      timestamp,
    ]
  );

  return (
    <>
      <BrowseBreadCrumbs parents={parents ?? []} />
      <BrowseDatePicker value={dateValue} onSelectValue={setDateValue} />
      <BrowseView
        isLoading={isLoading}
        folders={folders}
        files={files}
        restoreItems={items => setSelectedFiles(items)}
        loadNextPage={loadNextPage}
        hasNextPage={browseResults.pageInfo.hasNextPage}
      />
      <FileModal
        organisationId={organisationId}
        file={openFile}
        onDismiss={(result?: boolean) => {
          if (result === true) {
            sendMessage({
              messageType: MessageBarType.success,
              text: "Restore scheduled for 1 file",
            });
          } else if (result === false) {
            sendMessage({
              messageType: MessageBarType.error,
              text: "Failed to schedule restore",
            });
          }
          setOpenFile(undefined);
        }}
        isOpen={openFile !== undefined}
        serviceAccounts={serviceAccountState.serviceAccounts}
        isServiceAccountsLoading={serviceAccountState.hasNextPage}
        performRestore={performRestore}
      />
      <RestoreItemsModal
        isOpen={selectedFiles !== undefined}
        onDismiss={result => {
          if (result === true) {
            sendMessage({
              messageType: MessageBarType.success,
              text: `Restore scheduled for ${selectedFiles?.length ??
                0} ${pluralise("file", selectedFiles?.length ?? 0)}`,
            });
          } else if (result === false) {
            sendMessage({
              messageType: MessageBarType.error,
              text: "Failed to schedule restore",
            });
          }

          setSelectedFiles(undefined);
        }}
        selectedFiles={selectedFiles}
        defaultServiceAccount={serviceAccountId}
        serviceAccounts={serviceAccountState.serviceAccounts}
        isServiceAccountsLoading={serviceAccountState.hasNextPage}
        performRestore={performRestore}
        timestamp={timestamp}
      />
    </>
  );
};
