import React from "react";
import {
  Stack,
  Text,
  mergeStyles,
  FontIcon,
  getTheme,
  TooltipHost,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup
} from "office-ui-fabric-react";
import { ITextProps } from "office-ui-fabric-react";

export interface IStatProps {
  title: string;
  metric?: string | number;
  iconName?: string;
  variant?: ITextProps["variant"];
  hover?: string;
  isLoading?: boolean;
}

export const Stat: React.FC<IStatProps> = ({
  title,
  metric,
  iconName,
  hover,
  isLoading,
  ...props
}) => {
  const theme = getTheme();

  const statsStack = mergeStyles({
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10
  });

  const iconBackground = mergeStyles({
    background: theme.palette.themePrimary,
    borderRadius: "50%",
    height: 56,
    width: 56,
    margin: "0 16px",
    textAlign: "center"
  });

  const iconClass = mergeStyles({
    fontSize: 32,
    position: "relative",
    top: 12
  });

  const variant = props.variant ?? "xxLargePlus";

  const metricEl = <Text variant={variant}>{metric ?? "—"}</Text>;

  const customShimmerElements: JSX.Element = (
    <div style={{ display: "flex" }}>
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.circle, height: 56 },
          { type: ShimmerElementType.gap, width: 16, height: 56 }
        ]}
      />
      <ShimmerElementsGroup
        flexWrap={true}
        shimmerElements={[
          {
            type: ShimmerElementType.line,
            width: 100,
            height: 10
          },
          {
            type: ShimmerElementType.line,
            width: "70%",
            height: 28,
            verticalAlign: "top"
          },
          { type: ShimmerElementType.gap, width: "30%", height: 28 }
        ]}
      />
      <ShimmerElementsGroup
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 56, width: 20 }
        ]}
      />
    </div>
  );

  return (
    <Shimmer
      className={statsStack}
      isDataLoaded={!isLoading}
      customElementsGroup={customShimmerElements}
    >
      <Stack horizontal>
        <div className={iconBackground}>
          <FontIcon className={iconClass} iconName={iconName} />
        </div>
        <Stack>
          <Text variant="medium">{title}</Text>
          {hover ? (
            <TooltipHost content={hover}>{metricEl}</TooltipHost>
          ) : (
            metricEl
          )}
        </Stack>
      </Stack>
    </Shimmer>
  );
};
