import {
  IStackTokens,
  IStyle,
  Separator,
  Stack,
  Text,
  mergeStyles,
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";

import { AccountErrors } from "./AccountErrorsView";
import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { Provisioning } from "./ProvisioningView";
import { StatsTiles } from "./StatsView";
import { useApiClient } from "../../providers/ApiProvider";

export const itemStyle: IStyle = { width: "30%", minWidth: 300 };

export interface IDashboardConfig {}

const Dashboard: React.FC<IDashboardConfig> = () => {
  const api = useApiClient();
  const [organisations, setOrganisations] = useState<IOrganisation[]>();

  useEffect(() => {
    api.accounts.listOrganisations({}).then(orgResponse => {
      setOrganisations(orgResponse?.nodes);
    });
  }, [api]);

  const tokens: IStackTokens = { childrenGap: 50 };

  const item = mergeStyles(itemStyle);

  return (
    <>
      <Text variant="xxLarge">Dashboard</Text>
      <StatsTiles organisations={organisations} />
      <Separator />
      <Stack horizontal tokens={tokens} grow wrap>
        <Stack.Item className={item} grow>
          <AccountErrors organisations={organisations} />
        </Stack.Item>
        <Stack.Item className={item} grow>
          <Provisioning />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default Dashboard;
