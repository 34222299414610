import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";
import { IPaginate } from "./utils";

export type TOwnerType = "ORGANISATION" | "USER";
export interface IAuditLog {
  /**
   * The id of the "owner" of the resource the action was performed on.
   * The owner can be either an organisation or user.
   */
  ownerId: string;

  /**
   * Type of the owner.
   */
  ownerType: TOwnerType;

  /**
   * The time that the action was performed.
   */
  time: string;

  /**
   * The name of the resource that the action was performed on. This should be
   * in camelCase.
   * e.g. 'organisation', 'provisioningRequest'
   */
  resource: string;

  /**
   * The database key of the resource that the action was performed on.
   * e.g. for resource = 'serviceAccount'
   * {
   *   serviceId: '5e33cff5-d458-42af-97c3-77096b4179e7',
   *   serviceAccountId: '7fd8712d-11d2-400e-80da-4c6ea77623cb'
   * }
   */
  resourceId?: Record<string, string>;

  /**
   * The action that was performed. This should be in camelCase.
   * e.g. 'create', 'confirmResetPassword'
   */
  action: string;

  /**
   * The data before the action was performed. Represented as a JSON string.
   */
  oldData?: string;

  /**
   * The data after the action was performed. Represented as a JSON string.
   */
  newData?: string;

  /**
   * The id of the user who performed the action.
   */
  userId?: string;

  /**
   * The subsystem that the audit log originated from. Should be in PascalCase.
   * e.g. 'Accounts'
   */
  subsystem: string;

  /**
   * A boolean indicating whether the operation completed successfully.
   */
  success?: boolean;

  /**
   * If success is false, optionally include a reason for the failure.
   */
  failureReason?: string;

  /**
   * The IP address that the request originated from.
   */
  ipAddress?: string;
}

export interface IAuditQuery {
  ownerId: string;
  ownerType: TOwnerType;
  startTime?: Date;
  endTime: Date;
  resource?: string;
  action?: string;
  userId?: string;
  subsystem?: string;
  ipAddress?: string;
  // Filter out all log entries where userId != null
  isUserAction?: boolean;

  // Pagination
  cursor?: string;
  limit?: number;
  reverse?: boolean;
  [key: string]: any;
}

export class AuditModel extends BaseModel {
  public async query(
    opts: IAuditQuery,
    reverse: boolean,
    cursor?: string
  ): Promise<Result<IPaginate<IAuditLog>>> {
    const params: { limit: number; cursor?: string; reverse: boolean } = {
      limit: 100,
      reverse,
    };
    if (cursor) {
      params.cursor = cursor;
    }
    const response = await this.request({
      url: `/audit/query`,
      method: "post",
      params,
      data: {
        ...opts,
        endTime: opts.endTime.toISOString(),
        startTime: opts.startTime?.toISOString(),
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }
}
