import {
  DefaultButton,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  getTheme,
} from "office-ui-fabric-react";
import React, { useCallback, useMemo, useState } from "react";

import { IColumn } from "office-ui-fabric-react";

export interface IInviteView {
  inviteId: string;
  organisationId: string;
  inviteeUserEmail: string;
  inviterUserEmail?: string;
  roleName?: string;
  isLoading?: boolean;
}

export interface IUserRoleView {
  userId: string;
  userEmail?: string;
  roleId: string;
  organisationId: string;
  roleName?: string;
  isLoading?: boolean;
}

interface IDialogState {
  title: string;
  subText: string;
  hidden: boolean;
  onSubmit: () => void;
}

export interface ICustomersPeopleViewProps {
  invites: IInviteView[];
  isInvitesLoading?: boolean;
  userRoles: IUserRoleView[];
  isUserRolesLoading?: boolean;
  onCreateInvite?: () => void;
  onCancelInvite?: (opts: { inviteId: string; organisationId: string }) => void;
  onRemoveAccess?: (opts: { roleId: string; userId: string }) => void;
}

export const CustomersPeopleView: React.FC<ICustomersPeopleViewProps> = ({
  invites,
  isInvitesLoading,
  userRoles,
  isUserRolesLoading,
  onCreateInvite,
  onCancelInvite,
  onRemoveAccess,
}) => {
  const theme = getTheme();

  const [dialogState, setDialogState] = useState<IDialogState>();
  const clearDialog = useCallback(() => {
    setDialogState(undefined);
  }, [setDialogState]);

  const initialInviteColumns: IColumn[] = useMemo(
    () => [
      {
        key: "inviteeUserEmail",
        name: "Invitee",
        fieldName: "inviteeUserEmail",
        isSorted: true,
        minWidth: 200,
        isResizable: true,
      },
      {
        key: "inviterUserEmail",
        name: "Invited by",
        fieldName: "inviterUserEmail",
        minWidth: 200,
        isResizable: true,
      },
      {
        key: "roleName",
        name: "Role",
        fieldName: "roleName",
        minWidth: 200,
        isResizable: true,
      },
      {
        key: "cancel",
        name: "Cancel Invite",
        minWidth: 80,
        isPadded: true,
        isResizable: true,
        onRender: (item: IInviteView) =>
          item.isLoading ? (
            <Spinner size={SpinnerSize.small} />
          ) : (
            <IconButton
              style={{ color: theme.palette.red }}
              iconProps={{ iconName: "cancel" }}
              onClick={() => {
                setDialogState({
                  hidden: false,
                  title: "Are you sure you want to cancel this invite?",
                  subText: "This action cannot be undone.",
                  onSubmit: () => {
                    onCancelInvite?.({
                      inviteId: item.inviteId,
                      organisationId: item.organisationId,
                    });
                  },
                });
              }}
            />
          ),
      },
    ],
    [onCancelInvite, theme]
  );

  const initialUserRoleColumns: IColumn[] = useMemo(
    () => [
      {
        key: "userEmail",
        name: "User Email",
        fieldName: "userEmail",
        minWidth: 200,
        isResizable: true,
        isSorted: true,
      },
      {
        key: "roleName",
        name: "Role",
        fieldName: "roleName",
        minWidth: 200,
        isResizable: true,
      },
      {
        key: "cancel",
        name: "Remove Access",
        minWidth: 80,
        isPadded: true,
        isResizable: true,
        onRender: (item: IUserRoleView) =>
          item.isLoading ? (
            <Spinner size={SpinnerSize.small} />
          ) : (
            <IconButton
              style={{ color: theme.palette.red }}
              iconProps={{ iconName: "cancel" }}
              onClick={() => {
                setDialogState({
                  hidden: false,
                  title: "Are you sure you want to remove access?",
                  subText: "The user may lose access to this customer.",
                  onSubmit: () => {
                    onRemoveAccess?.({
                      roleId: item.roleId,
                      userId: item.userId,
                    });
                  },
                });
              }}
            />
          ),
      },
    ],
    [onRemoveAccess, theme]
  );

  const [inviteColumns, setInviteColumns] = useState(initialInviteColumns);
  const [userRoleColumns, setUserRoleColumns] = useState(
    initialUserRoleColumns
  );

  const sortColumns = useCallback(
    (column: IColumn) => (col: IColumn) => {
      if (col.key === column.key) {
        const isSortedDescending = column.isSorted
          ? !column.isSortedDescending
          : false;

        return {
          ...col,
          isSorted: true,
          isSortedDescending,
        };
      }

      return {
        ...col,
        isSorted: false,
        isSortedDescending: false,
      };
    },
    []
  );

  const onSelectInviteColumn = (ev?: any, column?: IColumn) => {
    if (column && column.key !== "cancel") {
      const newColumns = inviteColumns.map(sortColumns(column));

      setInviteColumns(newColumns);
    }
  };

  const onSelectUserRolesColumn = (ev?: any, column?: IColumn) => {
    if (column && column.key !== "cancel") {
      const newColumns = userRoleColumns.map(sortColumns(column));

      setUserRoleColumns(newColumns);
    }
  };

  const sortItems = useCallback(
    (column: IColumn) => (a: any, b: any) => {
      const aStr: string | undefined = column.fieldName
        ? a[column.fieldName]
        : undefined;
      const bStr: string | undefined = column.fieldName
        ? b[column.fieldName]
        : undefined;

      let res = 0;
      if (aStr !== undefined && bStr !== undefined) {
        res = aStr.localeCompare(bStr);
      } else if (aStr !== undefined) {
        res = -1;
      } else if (bStr !== undefined) {
        res = 1;
      }

      if (column.isSortedDescending) {
        res *= -1;
      }

      return res;
    },
    []
  );

  let sortedInvites = invites;
  for (const column of inviteColumns) {
    if (column.isSorted) {
      sortedInvites = sortedInvites.sort(sortItems(column));
    }
  }

  let sortedUserRoles = userRoles;
  for (const column of userRoleColumns) {
    if (column.isSorted) {
      sortedUserRoles = sortedUserRoles.sort(sortItems(column));
    }
  }

  return (
    <>
      <Dialog
        hidden={dialogState?.hidden ?? true}
        onDismiss={clearDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: dialogState?.title,
          subText: dialogState?.subText,
        }}
      >
        <DialogFooter>
          <PrimaryButton
            text="Ok"
            onClick={() => {
              dialogState?.onSubmit();
              clearDialog();
            }}
          />
          <DefaultButton text="Cancel" onClick={clearDialog} />
        </DialogFooter>
      </Dialog>
      <Stack tokens={{ childrenGap: "m", padding: "s1" }}>
        <Stack>
          <Stack horizontal horizontalAlign="space-between">
            <Text variant="xLarge">Invitations</Text>
            <PrimaryButton
              onClick={() => {
                onCreateInvite?.();
              }}
            >
              Create Invite
            </PrimaryButton>
          </Stack>
          {!isInvitesLoading && sortedInvites.length === 0 ? (
            <Text variant="medium" styles={{ root: { paddingTop: 8 } }}>
              No Pending Invitations
            </Text>
          ) : (
            <ShimmeredDetailsList
              columns={inviteColumns}
              items={sortedInvites}
              enableShimmer={isInvitesLoading}
              onColumnHeaderClick={onSelectInviteColumn}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
            />
          )}
        </Stack>
        <Stack>
          <Text variant="xLarge">People</Text>
          {!isUserRolesLoading && sortedUserRoles.length === 0 ? (
            <Text variant="medium" styles={{ root: { paddingTop: 12 } }}>
              No Existing Users
            </Text>
          ) : (
            <ShimmeredDetailsList
              columns={userRoleColumns}
              items={sortedUserRoles}
              enableShimmer={isUserRolesLoading}
              onColumnHeaderClick={onSelectUserRolesColumn}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
