import {
  IEventReportConfig,
  IScheduledReportConfig,
} from "../../providers/ApiProvider/ApiClient/models/reporting";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import { IPaginate } from "../../providers/ApiProvider/ApiClient/models/utils";
import { ReportingSummaryView } from "./ReportingSummaryView";
import { restoreCompareFn } from "./utils";
import { useApiClient } from "../../providers/ApiProvider";
import { useParams } from "react-router-dom";

export type TUpdatable<T> = T & { isUpdating?: boolean };

export type TUpdateFunction = (opts: {
  configType: "SCHEDULED" | "EVENT";
  ownerType: IScheduledReportConfig["ownerType"];
  ownerId: string;
  reportConfigId: string;
  disabled: boolean;
}) => Promise<void>;

export type TDeleteFunction = (opts: {
  ownerId: string;
  reportConfigId: string;
  configType: "SCHEDULED" | "EVENT";
}) => Promise<void>;

interface IReportingSummaryProps {}

interface IState {
  scheduled: {
    configs: Record<string, TUpdatable<IScheduledReportConfig>>;
    cursor?: string;
    hasNextPage: boolean;
  };
  event: {
    configs: Record<string, TUpdatable<IEventReportConfig>>;
    cursor?: string;
    hasNextPage: boolean;
  };
}

type TActions =
  | {
      type: "MERGE_SCHEDULED_CONFIGS";
      payload: IPaginate<IScheduledReportConfig>;
    }
  | { type: "MERGE_EVENT_CONFIGS"; payload: IPaginate<IEventReportConfig> }
  | { type: "CLEAR_ALL_CONFIGS" }
  | {
      type: "UPDATE_CONFIG";
      payload: {
        configType: "SCHEDULED" | "EVENT";
        ownerType: IScheduledReportConfig["ownerType"];
        ownerId: string;
        reportConfigId: string;
        scheduledConfig?: IScheduledReportConfig;
        eventConfig?: IEventReportConfig;
        isUpdating: boolean;
      };
    };

const initialState: IState = {
  scheduled: {
    configs: {},
    hasNextPage: true,
  },
  event: {
    configs: {},
    hasNextPage: true,
  },
};

const configReducer: React.Reducer<IState, TActions> = (prevState, action) => {
  switch (action.type) {
    case "MERGE_EVENT_CONFIGS":
      const newEventConfigs = action.payload.nodes.reduce((prev, curr) => {
        const id = `${curr.ownerType}:${curr.ownerId}:${curr.reportConfigId}`;

        return {
          ...prev,
          [id]: curr,
        };
      }, {});

      return {
        ...prevState,
        event: {
          configs: {
            ...prevState.event.configs,
            ...newEventConfigs,
          },
          cursor: action.payload.pageInfo.cursor,
          hasNextPage: action.payload.pageInfo.hasNextPage,
        },
      };
    case "MERGE_SCHEDULED_CONFIGS":
      const newScheduledConfigs = action.payload.nodes.reduce((prev, curr) => {
        const id = `${curr.ownerType}:${curr.ownerId}:${curr.reportConfigId}`;

        return {
          ...prev,
          [id]: curr,
        };
      }, {});

      return {
        ...prevState,
        scheduled: {
          configs: {
            ...prevState.scheduled.configs,
            ...newScheduledConfigs,
          },
          cursor: action.payload.pageInfo.cursor,
          hasNextPage: action.payload.pageInfo.hasNextPage,
        },
      };
    case "CLEAR_ALL_CONFIGS":
      return {
        ...prevState,
        scheduled: initialState.scheduled,
        event: initialState.event,
      };

    case "UPDATE_CONFIG":
      const id = `${action.payload.ownerType}:${action.payload.ownerId}:${action.payload.reportConfigId}`;
      if (action.payload.configType === "SCHEDULED") {
        return {
          ...prevState,
          scheduled: {
            ...prevState.scheduled,
            configs: {
              ...prevState.scheduled.configs,
              [id]: {
                ...prevState.scheduled.configs[id],
                ...action.payload.scheduledConfig,
                isUpdating: action.payload.isUpdating,
              },
            },
          },
        };
      } else if (action.payload.configType === "EVENT") {
        return {
          ...prevState,
          event: {
            ...prevState.event,
            configs: {
              ...prevState.event.configs,
              [id]: {
                ...prevState.event.configs[id],
                ...action.payload.eventConfig,
                isUpdating: action.payload.isUpdating,
              },
            },
          },
        };
      } else {
        return prevState;
      }

    default:
      return prevState;
  }
};

export const ReportingSummary: React.FC<IReportingSummaryProps> = () => {
  const { organisationId } = useParams();
  const api = useApiClient();

  const [configState, dispatch] = useReducer(configReducer, initialState);

  // Clear the configs when the organisationId changes
  useEffect(() => {
    dispatch({ type: "CLEAR_ALL_CONFIGS" });
  }, [organisationId]);

  // Get all scheduled configs
  useEffect(() => {
    if (organisationId && configState.scheduled.hasNextPage) {
      api.reporting
        .getScheduledConfigs({
          organisationId,
          cursor: configState.scheduled.cursor,
        })
        .then(res => {
          if (res === undefined) {
            // No response, so we will stop pagination
            dispatch({
              type: "MERGE_SCHEDULED_CONFIGS",
              payload: {
                nodes: [],
                pageInfo: { hasNextPage: false },
              },
            });
          } else {
            dispatch({
              type: "MERGE_SCHEDULED_CONFIGS",
              payload: res,
            });
          }
        });
    }
  }, [
    configState.scheduled.cursor,
    configState.scheduled.hasNextPage,
    organisationId,
    api,
  ]);

  // Get all event configs
  useEffect(() => {
    if (organisationId && configState.event.hasNextPage) {
      api.reporting
        .getEventConfigs({
          organisationId,
          cursor: configState.event.cursor,
        })
        .then(res => {
          if (res === undefined) {
            // No response, so we will stop pagination
            dispatch({
              type: "MERGE_EVENT_CONFIGS",
              payload: {
                nodes: [],
                pageInfo: { hasNextPage: false },
              },
            });
          } else {
            dispatch({
              type: "MERGE_EVENT_CONFIGS",
              payload: res,
            });
          }
        });
    }
  }, [
    configState.event.cursor,
    configState.event.hasNextPage,
    organisationId,
    api,
  ]);

  const updateConfig: TUpdateFunction = useCallback(
    async ({ configType, ownerType, ownerId, reportConfigId, disabled }) => {
      dispatch({
        type: "UPDATE_CONFIG",
        payload: {
          configType,
          ownerType,
          ownerId,
          reportConfigId,
          isUpdating: true,
        },
      });

      if (configType === "SCHEDULED") {
        const scheduledConfig = await api.reporting.updateScheduledConfig({
          ownerId,
          ownerType,
          reportConfigId,
          config: { disabled },
        });

        dispatch({
          type: "UPDATE_CONFIG",
          payload: {
            configType,
            ownerType,
            ownerId,
            reportConfigId,
            scheduledConfig,
            isUpdating: false,
          },
        });
      } else if (configType === "EVENT") {
        const eventConfig = await api.reporting.updateEventConfig({
          ownerId,
          ownerType,
          reportConfigId,
          config: { disabled },
        });

        dispatch({
          type: "UPDATE_CONFIG",
          payload: {
            configType,
            ownerType,
            ownerId,
            reportConfigId,
            eventConfig,
            isUpdating: false,
          },
        });
      }
    },
    [api]
  );

  const [isReloading, setReloading] = useState(false);

  const deleteConfig: TDeleteFunction = useCallback(
    async ({ configType, ownerId, reportConfigId }) => {
      setReloading(true);
      if (configType === "SCHEDULED") {
        await api.reporting.deleteScheduledConfig({
          ownerId,
          reportConfigId,
        });
      } else if (configType === "EVENT") {
        await api.reporting.deleteEventConfig({
          ownerId,
          reportConfigId,
        });
      }

      dispatch({ type: "CLEAR_ALL_CONFIGS" });
      setReloading(false);
    },
    [api]
  );

  if (organisationId === undefined) {
    throw new Error("Invalid Routing");
  }

  return (
    <ReportingSummaryView
      scheduledConfigs={Object.values(configState.scheduled.configs).sort(
        restoreCompareFn
      )}
      isScheduledLoading={configState.scheduled.hasNextPage || isReloading}
      eventConfigs={Object.values(configState.event.configs).sort(
        restoreCompareFn
      )}
      isEventLoading={configState.event.hasNextPage || isReloading}
      updateConfig={updateConfig}
      deleteConfig={deleteConfig}
    />
  );
};
