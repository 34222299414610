import store, { allStoreActions } from "../../../../store";
import {
  IOrganisation,
  IServiceAccount
} from "../../../ApiProvider/ApiClient/models/accounts";
import { IAccountsState } from "../../../../store/accounts/types";
import { ApiClient } from "../../../ApiProvider/ApiClient";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../../../constants";
// import { flatten } from "office-ui-fabric-react";

export class AccountsModel {
  private api: ApiClient;

  constructor(config: { api: ApiClient }) {
    this.api = config.api;
  }

  private listOrganisations = async (parentOrganisationId?: string) => {
    let allOrganisations: IOrganisation[] = [];

    let cursor: string | undefined;
    do {
      const response = await this.api.accounts.listOrganisations({
        parentOrganisationId,
        cursor
      });

      if (response) {
        allOrganisations.push(...response.nodes);
      }

      cursor = response?.pageInfo.cursor;
    } while (cursor !== undefined);

    return allOrganisations;
  };

  public async getOrganisations() {
    const state = store.getState();

    const accountState: IAccountsState = state.accounts;

    let allOrganisations: IOrganisation[] = [];

    if (accountState.organisations !== undefined) {
      return accountState.organisations;
    }

    // Temporarily only process the first organisation until the stats are optimised.

    // const processOrganisations = async (orgs: IOrganisation[]) => {
    //   const allResp = await Promise.all(
    //     orgs.map(async org => {
    //       return await this.listOrganisations(org.id);
    //     })
    //   );
    //   return flatten(allResp);
    // };

    let organisationsResponse = await this.listOrganisations();
    allOrganisations = allOrganisations.concat(organisationsResponse);

    // do {
    //   organisationsResponse = await processOrganisations(organisationsResponse);
    //   allOrganisations = allOrganisations.concat(organisationsResponse);
    // } while (organisationsResponse.length > 0);

    store.dispatch(allStoreActions.accounts.setOrganisations(allOrganisations));

    return allOrganisations;
  }

  public async getOrganisation({
    organisationId
  }: {
    organisationId: string;
  }): Promise<IOrganisation | undefined> {
    const state = store.getState();
    const storeOrgs = state.accounts.organisations;

    if (storeOrgs !== undefined) {
      const organisation = storeOrgs.find(org => org.id === organisationId);

      if (organisation !== undefined) {
        return organisation;
      }
    }

    // Cache miss
    const organisation = await this.api.accounts.getOrganisation({
      organisationId
    });

    if (organisation !== undefined) {
      store.dispatch(allStoreActions.accounts.appendOrganisation(organisation));
    }

    return organisation;
  }

  public getServiceAccounts = async (opts: {
    serviceId: string;
    organisationId: string;
    cursor?: string;
    limit?: number;
  }) => {
    // TODO load from redux
    const response = await this.api.accounts.listServiceAccounts(opts);

    return response;
  };

  /**
   * Gets all serviceAccounts that the user has access to
   */
  public getAllServiceAccounts = async () => {
    const state = store.getState();
    const accountState: IAccountsState = state.accounts;

    const serviceIds = [EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID];

    if (accountState.serviceAccounts !== undefined) {
      return accountState.serviceAccounts;
    }

    if (accountState.organisations !== undefined) {
      const serviceAccountsResponse = await Promise.all(
        accountState.organisations.map(async organisation => {
          const organisationId = organisation.id;
          const orgServiceAccounts = await Promise.all(
            serviceIds.map(async serviceId => {
              let cursor: string | undefined;
              const serviceAccounts: IServiceAccount[] = [];

              do {
                const response = await this.api.accounts.listServiceAccounts({
                  organisationId,
                  serviceId,
                  cursor
                });

                if (response !== undefined) {
                  serviceAccounts.push(...response.nodes);
                }

                cursor = response?.pageInfo.cursor;
              } while (cursor !== undefined);

              return serviceAccounts;
            })
          );

          return orgServiceAccounts.reduce(
            (prev, curr) => prev.concat(curr),
            []
          );
        })
      );

      const allServiceAccounts = serviceAccountsResponse.reduce(
        (prev, curr) => prev.concat(curr),
        []
      );

      store.dispatch(
        allStoreActions.accounts.setServiceAccounts(allServiceAccounts)
      );

      return allServiceAccounts;
    }
  };
}
