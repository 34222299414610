import { AccountsModel } from "./models/accounts";
import { AuditModel } from "./models/audit";
import { AuthModel } from "./models/auth";
import { BrandingAssetsModel } from "./models/brandingAssets";
import { BrandingModel } from "./models/branding";
import { BrowseModel } from "./models/browse";
import { HelperModel } from "./models/helpers";
import { InvitesModel } from "./models/invites";
import { LegalModel } from "./models/legal";
import { ProvisioningModel } from "./models/provisioning";
import { PublicModel } from "./models/public";
import { ReportingModel } from "./models/reporting";
import { RestoreModel } from "./models/restore";
import { SearchModel } from "./models/search";

export interface IApiClient {
  accounts: AccountsModel;
  auth: AuthModel;
  browse: BrowseModel;
  helpers: HelperModel;
  invites: InvitesModel;
  legal: LegalModel;
  provisioning: ProvisioningModel;
  public: PublicModel;
  reporting: ReportingModel;
  restore: RestoreModel;
  search: SearchModel;
  audit: AuditModel;
  branding: BrandingModel;
  brandingAssets: BrandingAssetsModel;
}

export const createApiClient = ({
  hostname,
}: {
  hostname: string;
}): IApiClient => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const brandingUrl = process.env.REACT_APP_BRANDING_URL;

  if (baseUrl === undefined) {
    throw new Error("Missing baseUrl env var");
  }

  if (brandingUrl === undefined) {
    throw new Error("Missing brandingUrl env var");
  }

  const client = new ApiClient({ baseUrl, brandingUrl, hostname });

  return client;
};

export interface IClientOpts {
  baseUrl: string;
  hostname: string;
  brandingUrl: string;
}

export class ApiClient implements IApiClient {
  public accounts: AccountsModel;
  public auth: AuthModel;
  public browse: BrowseModel;
  public helpers: HelperModel;
  public invites: InvitesModel;
  public legal: LegalModel;
  public provisioning: ProvisioningModel;
  public public: PublicModel;
  public reporting: ReportingModel;
  public restore: RestoreModel;
  public search: SearchModel;
  public audit: AuditModel;
  public brandingAssets: BrandingAssetsModel;
  public branding: BrandingModel;

  constructor(opts: IClientOpts) {
    this.accounts = new AccountsModel(opts);
    this.auth = new AuthModel(opts);
    this.browse = new BrowseModel(opts);
    this.helpers = new HelperModel(opts);
    this.invites = new InvitesModel(opts);
    this.legal = new LegalModel(opts);
    this.provisioning = new ProvisioningModel(opts);
    this.public = new PublicModel(opts);
    this.reporting = new ReportingModel(opts);
    this.restore = new RestoreModel(opts);
    this.search = new SearchModel(opts);
    this.provisioning = new ProvisioningModel(opts);
    this.audit = new AuditModel(opts);
    this.brandingAssets = new BrandingAssetsModel(opts);
    this.branding = new BrandingModel(opts);
  }
}
