import { PrimaryButton, Text } from "office-ui-fabric-react";
import React, { useMemo, useState } from "react";

import EnforcePermissions from "../Permissions";
import { IProvisioningRequest } from "../../providers/ApiProvider/ApiClient/models/provisioning";
import { ProvisionPanel } from "./ProvisionPanel";

export interface IProvisionProps {
  serviceId: string;
  organisationId: string;
  provisioningRequests?: IProvisioningRequest[];
  reloadRequests?: () => void;
}

export const Provision: React.FC<IProvisionProps> = ({
  serviceId,
  organisationId,
  provisioningRequests,
  reloadRequests,
}) => {
  const [isOpen, setOpen] = useState(false);

  const provisionButton = useMemo(
    () => (
      <PrimaryButton
        onClick={() => {
          setOpen(true);
        }}
      >
        Provision Service
      </PrimaryButton>
    ),
    []
  );

  /*
   * Note: this string is really just a placeholder until we implement a
   * proper solution for resuming provisioning requests
   */
  let provisioningString: string | undefined;
  if (provisioningRequests?.filter(req => req.status === "FAILED")) {
    provisioningString = "Provisioning Request Failed";
  }

  if (
    provisioningRequests?.filter(
      req => req.status === "PENDING" || req.status === "IN_PROGRESS"
    ).length !== 0
  ) {
    provisioningString = "Provisioning Request In Progress";
  } else if (
    provisioningRequests?.filter(req => req.status === "AWAITING_CONSENT")
      .length !== 0
  ) {
    provisioningString = "Provisioning Request Awaiting Consent";
  }
  return (
    <>
      {provisioningRequests?.length === 0 ? (
        <EnforcePermissions
          organisationId={organisationId}
          action="Create"
          resource="provisioning:request"
          enforceType="HIDE"
        >
          {provisionButton}
        </EnforcePermissions>
      ) : (
        <Text>{provisioningString}</Text>
      )}
      <ProvisionPanel
        isOpen={isOpen}
        onDismiss={shouldReload => {
          setOpen(false);

          if (shouldReload) {
            reloadRequests?.();
          }
        }}
        serviceId={serviceId}
        organisationId={organisationId}
      />
    </>
  );
};
