import {
  ISetOrganisations,
  SET_ORGANISATIONS,
  APPEND_ORGANISATION,
  IAppendOrganisation,
  IAccountsActions,
  APPEND_SERVICE_ACCOUNT,
  MERGE_ORGANISATIONS,
  SET_SERVICE_ACCOUNTS,
  ISetServiceAccounts,
  IMergeOrganisations,
  IMergeServiceAccounts,
  IAppendServiceAccount,
  MERGE_SERVICE_ACCOUNTS
} from "./types";
import {
  IOrganisation,
  IServiceAccount
} from "../../providers/ApiProvider/ApiClient/models/accounts";

export const setOrganisations = (
  organisations: IOrganisation[]
): ISetOrganisations => {
  return {
    type: SET_ORGANISATIONS,
    payload: organisations
  };
};

export const mergeOrganisations = (
  organisations: IOrganisation[]
): IMergeOrganisations => {
  return {
    type: MERGE_ORGANISATIONS,
    payload: organisations
  };
};

export const appendOrganisation = (
  organisation: IOrganisation
): IAppendOrganisation => {
  return {
    type: APPEND_ORGANISATION,
    payload: organisation
  };
};

export const setServiceAccounts = (
  serviceAccounts: IServiceAccount[]
): ISetServiceAccounts => {
  return {
    type: SET_SERVICE_ACCOUNTS,
    payload: serviceAccounts
  };
};

export const mergeServiceAccounts = (
  serviceAccounts: IServiceAccount[]
): IMergeServiceAccounts => {
  return {
    type: MERGE_SERVICE_ACCOUNTS,
    payload: serviceAccounts
  };
};

export const appendServiceAccount = (
  serviceAccount: IServiceAccount
): IAppendServiceAccount => {
  return {
    type: APPEND_SERVICE_ACCOUNT,
    payload: serviceAccount
  };
};

export const accountsActions: IAccountsActions = {
  setOrganisations,
  mergeOrganisations,
  appendOrganisation,
  setServiceAccounts,
  mergeServiceAccounts,
  appendServiceAccount
};
