import {
  ComboBox,
  DatePicker,
  DefaultButton,
  IDropdownOption,
  IStackTokens,
  IStyle,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Toggle,
  mergeStyleSets,
} from "office-ui-fabric-react";
import React, { useState } from "react";

import { IAuditQuery } from "../../providers/ApiProvider/ApiClient/models/audit";

export interface IAuditQueryProps {
  updateQuery: (payload: {
    fieldName: keyof IAuditQuery;
    value: IAuditQuery[keyof IAuditQuery];
  }) => void;
  query: IAuditQuery;
  performQuery: () => void;
  clearQuery: () => void;
  isRequestLoading: boolean;
}

export const itemStyle: IStyle = { width: "30%", minWidth: 250 };

export const AuditQuery: React.FC<IAuditQueryProps> = ({
  updateQuery,
  query,
  performQuery,
  clearQuery,
  isRequestLoading,
}) => {
  const clearForm = () => {
    clearQuery();
    setSelectionAction(undefined);
    setSelectedResource(undefined);
  };

  const [selectedAction, setSelectionAction] = useState<IDropdownOption>();
  const [selectedResource, setSelectedResource] = useState<IDropdownOption>();

  const actionOptions: IDropdownOption[] = [
    { key: "update", text: "Update" },
    { key: "create", text: "Create" },
    { key: "delete", text: "Delete" },
    { key: "accept", text: "Accept" },
    { key: "cancel", text: "Cancel" },
    { key: "signup", text: "Sign Up" },
    { key: "login", text: "Login" },
    { key: "resetPassword", text: "Reset Password" },
    { key: "resume", text: "Resume" },
    { key: "provisioningRequest", text: "Provisioning" },
  ];
  const resourceOptions: IDropdownOption[] = [
    { key: "organisation", text: "Organisation" },
    { key: "serviceAccount", text: "Service Account" },
    { key: "serviceConfiguration", text: "Service Configuration" },
    { key: "role", text: "Role" },
    { key: "permission", text: "Permission" },
    { key: "scheduledReport", text: "Scheduled Report" },
    { key: "eventReport", text: "Event Report" },
    { key: "onDemandReport", text: "On Demand Report" },
    { key: "search", text: "Search" },
    { key: "restore", text: "Restore" },
    { key: "user", text: "User" },
    { key: "termsAndCondition", text: "Terms and Conditions" },
    { key: "provisioningRequest", text: "Provisioning" },
  ];

  const tokens: IStackTokens = {
    childrenGap: "s1",
  };

  const classNames = mergeStyleSets({
    container: { padding: 10 },
    item: itemStyle,
    button: { width: "100%", marginTop: 10 },
  });

  return (
    <div>
      <Separator />
      <Text variant="large">Search Logs</Text>
      <Stack className={classNames.container} tokens={tokens} horizontal wrap>
        <Stack.Item className={classNames.item}>
          <DatePicker
            label={"Start Date"}
            value={query.startTime}
            onSelectDate={opt => {
              if (opt) {
                updateQuery({ fieldName: "startTime", value: opt });
              } else {
                updateQuery({ fieldName: "startTime", value: undefined });
              }
            }}
            formatDate={(date?: Date) => {
              if (date) {
                return date.toLocaleDateString();
              } else {
                return "";
              }
            }}
            showCloseButton
            allowTextInput
            maxDate={new Date()}
          />
        </Stack.Item>
        <Stack.Item className={classNames.item}>
          <DatePicker
            label={"End Date"}
            value={query.endTime}
            onSelectDate={opt => {
              if (opt) {
                updateQuery({ fieldName: "endTime", value: opt });
              } else {
                updateQuery({ fieldName: "endTime", value: undefined });
              }
            }}
            formatDate={(date?: Date) => {
              if (date) {
                return date.toLocaleDateString();
              } else {
                return "";
              }
            }}
            showCloseButton
            allowTextInput
            maxDate={new Date()}
          />
        </Stack.Item>
        <Stack.Item className={classNames.item}>
          <ComboBox
            label="Action"
            allowFreeform
            style={{ marginBottom: 10 }}
            selectedKey={selectedAction?.key}
            onChange={(ev, option) => {
              const value = actionOptions
                .find(aOption => aOption.key === option?.key)
                ?.key.toString();
              updateQuery({
                fieldName: "action",
                value,
              });
              setSelectionAction(
                actionOptions.find(aOption => aOption.key === option?.key)
              );
            }}
            options={actionOptions}
          />
        </Stack.Item>

        <Stack.Item className={classNames.item}>
          <ComboBox
            label="Resource"
            allowFreeform
            style={{ marginBottom: 10 }}
            selectedKey={selectedResource?.key}
            onChange={(ev, option) => {
              const value = resourceOptions
                .find(rOption => rOption.key === option?.key)
                ?.key.toString();
              updateQuery({
                fieldName: "resource",
                value,
              });
              setSelectedResource(
                resourceOptions.find(rOption => rOption.key === option?.key)
              );
            }}
            options={resourceOptions}
          />
        </Stack.Item>
        <Stack.Item className={classNames.item}>
          <Toggle
            label="Show System Events"
            checked={!query.isUserAction}
            onChange={(ev, option) => {
              updateQuery({ fieldName: "isUserAction", value: !option });
            }}
          />
        </Stack.Item>
      </Stack>
      <Stack className={classNames.container} tokens={tokens} horizontal wrap>
        {" "}
        <Stack.Item className={classNames.item} grow align="end">
          <PrimaryButton
            label="Search"
            className={classNames.button}
            onClick={() => {
              performQuery();
            }}
            disabled={isRequestLoading}
          >
            {isRequestLoading ? <Spinner size={SpinnerSize.large} /> : "Search"}
          </PrimaryButton>
        </Stack.Item>
        <Stack.Item className={classNames.item} grow align="end">
          <DefaultButton
            className={classNames.button}
            onClick={() => clearForm()}
            label="Clear Search"
            disabled={isRequestLoading}
          >
            Clear
          </DefaultButton>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default AuditQuery;
