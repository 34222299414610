import React, {} from "react";
import {
  Stack,
  IStackTokens,
  IComboBoxOption,
  ComboBox
} from "office-ui-fabric-react";
import { Modal } from "../../Modal";
import {useHistory} from "react-router-dom";

export interface IDrivesModalViewProps {
  drives?: any,
  isOpen: boolean;
  onDismiss?: () => void;
  serviceAccount?:any;
  serviceType?:any;
}

export const DrivesModalView: React.FC<IDrivesModalViewProps> = ({
  drives,
  isOpen,
  onDismiss,
  serviceAccount,
  serviceType,
}) => {
  const history = useHistory();

  const outerTokens: IStackTokens = {
    childrenGap: "m"
  };

  const innerTokens: IStackTokens = {
    childrenGap: "l1",
    padding: "m"
  };

  const comboOptions = JSON.parse(drives || "[\"Shared Documents\"]")?.map((drive: any) => ({
    key: drive,
    text: drive
  })) ?? [];

  const driveOptions: IComboBoxOption[] = comboOptions;

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} header="Select drive">
      <Stack tokens={outerTokens}>
        <Stack horizontal wrap tokens={innerTokens}>
          <ComboBox
              autoComplete="on"
              options={driveOptions}
              label="Drive"
              onChange={(event, option) => history.push(
                  `/customers/${serviceAccount?.organisationId}/services/${serviceType}/${serviceAccount.id}/browse/${option?.key}`
              ) }
              selectedKey={"1"}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
