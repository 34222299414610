import React, { useEffect, useState, useReducer, useCallback } from "react";
import {
  IFileItem,
  IVersion,
  IItemVersionsResponse
} from "../../../providers/ApiProvider/ApiClient/models/browse";
import { useApiClient } from "../../../providers/ApiProvider";
import { FileModalView } from "./FileModalView";
import { IServiceAccount } from "../../../providers/ApiProvider/ApiClient/models/accounts";
import { TPerformRestoreFn } from "..";

export interface IFileModalProps {
  file?: IFileItem;
  organisationId: string;
  serviceAccounts: IServiceAccount[];
  isServiceAccountsLoading?: boolean;
  onDismiss: (result?: boolean) => void;
  isOpen: boolean;
  performRestore: TPerformRestoreFn;
}

type TVersionAction =
  | { type: "MERGE_VERSIONS"; payload: IItemVersionsResponse }
  | { type: "ADD_VERSION"; payload: { versionId: string; file: IFileItem } }
  | { type: "CLEAR_STATE" };

interface IVersionState {
  versionList: IVersion[];
  versionMap: Record<string, IFileItem>;
  cursor?: string;
  hasNextPage: boolean;
}

const initialVersionState: IVersionState = {
  versionList: [],
  versionMap: {},
  hasNextPage: true
};

const versionReducer: React.Reducer<IVersionState, TVersionAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case "MERGE_VERSIONS":
      // Filter out deleted versions
      const newVersions = action.payload.versions.filter(
        version => version.itemId !== undefined
      );

      return {
        ...prevState,
        versionList: [...prevState.versionList, ...newVersions],
        cursor: action.payload.pageInfo.cursor,
        hasNextPage: action.payload.pageInfo.hasNextPage
      };

    case "ADD_VERSION":
      return {
        ...prevState,
        versionMap: {
          ...prevState.versionMap,
          [action.payload.versionId]: action.payload.file
        }
      };

    case "CLEAR_STATE":
      return initialVersionState;

    default:
      return prevState;
  }
};

export const FileModal: React.FC<IFileModalProps> = ({
  organisationId,
  serviceAccounts,
  isServiceAccountsLoading,
  file,
  onDismiss,
  isOpen,
  performRestore
}) => {
  const api = useApiClient();

  const [versionState, dispatchVersion] = useReducer(
    versionReducer,
    initialVersionState
  );

  const [selectedFile, selectFile] = useState<IFileItem | undefined>(file);
  const [selectedVersionId, selectVersionId] = useState<string>();
  const [selectedServiceAccountId, selectServiceAccountId] = useState<string>();
  const [isLoadingRestore, setLoadingRestore] = useState(false);

  // Clear the state anytime the file changes
  useEffect(() => {
    if (file !== undefined) {
      dispatchVersion({ type: "CLEAR_STATE" });
      selectFile(file);
      selectServiceAccountId(file.serviceAccountId);
    }
  }, [file, organisationId]);

  // Get all versions for the file
  useEffect(() => {
    if (file !== undefined && versionState.hasNextPage) {
      api.browse
        .itemVersions({
          organisationId,
          serviceId: "123df61b-7b40-41bd-b962-dc8bf6a1f214",
          serviceAccountId: file.serviceAccountId,
          key: file.key,
          cursor: versionState.cursor
        })
        .then(res => {
          if (res !== undefined) {
            dispatchVersion({ type: "MERGE_VERSIONS", payload: res });
          }
        });
    }
  }, [
    organisationId,
    file,
    api,
    versionState.cursor,
    versionState.hasNextPage
  ]);

  // Set the selectedVersionId
  useEffect(() => {
    if (file !== undefined) {
      const selectedVersion = versionState.versionList.find(
        version => version.timestamp === file?.timestamp
      );

      selectVersionId(selectedVersion?.versionId);

      if (selectedVersion?.versionId) {
        dispatchVersion({
          type: "ADD_VERSION",
          payload: { file, versionId: selectedVersion.versionId }
        });
      }
    }
  }, [versionState.versionList, file]);

  // Set the selectedFile to match the selectedVersionId
  useEffect(() => {
    if (file && selectedVersionId) {
      const existingVersion = versionState.versionMap[selectedVersionId];
      if (existingVersion === undefined) {
        // We need to fetch this version
        selectFile(undefined);

        api.browse
          .itemVersion({
            serviceId: file.serviceId,
            serviceAccountId: file.serviceAccountId,
            organisationId,
            key: file.key,
            versionId: selectedVersionId
          })
          .then(res => {
            if (res) {
              dispatchVersion({
                type: "ADD_VERSION",
                payload: { versionId: selectedVersionId, file: res }
              });
            }
          });
      } else {
        selectFile(existingVersion);
      }
    }
  }, [
    api.browse,
    file,
    organisationId,
    versionState.versionMap,
    selectedVersionId
  ]);

  const restore = useCallback(async () => {
    if (
      selectedFile !== undefined &&
      selectedFile.itemId !== undefined &&
      selectedServiceAccountId !== undefined
    ) {
      setLoadingRestore(true);

      const itemId = selectedFile.itemId;

      const result = await performRestore({
        sourceServiceAccountId: selectedFile.serviceAccountId,
        destinationServiceAccountId: selectedServiceAccountId,
        fileIds: [itemId],
        folderIds: [],
      });

      setLoadingRestore(false);
      onDismiss(result);
    }
  }, [selectedFile, selectedServiceAccountId, performRestore, onDismiss]);

  return (
    <FileModalView
      isOpen={isOpen}
      onDismiss={onDismiss}
      file={selectedFile}
      versions={versionState.versionList}
      isVersionsLoading={versionState.hasNextPage}
      selectedVersionId={selectedVersionId}
      selectVersion={selectVersionId}
      serviceAccounts={serviceAccounts}
      isServiceAccountsLoading={isServiceAccountsLoading}
      setSelectedServiceAccountId={selectServiceAccountId}
      selectedServiceAccountId={selectedServiceAccountId}
      restore={restore}
      isLoadingRestore={isLoadingRestore}
    />
  );
};
