import { createStore } from "redux";
import rootReducer from "./rootReducer";
import { IAuthState } from "./auth/types";
import { allStoreActions } from "./actions";
import { IAccountsState } from "./accounts/types";

export { allStoreActions };

export interface IRootState {
  auth: IAuthState;
  accounts: IAccountsState;
}

const store = createStore(rootReducer);

export default store;
