import React, { useCallback, useEffect, useState } from "react";

import { AcceptedTermsAndConditionsView } from "./acceptedTcsView";
import { Shimmer } from "office-ui-fabric-react";
import { TermsAndConditionsView } from "./tcsView";
import { useApiClient } from "../../providers/ApiProvider";

export interface ITermsAndConditionsProps {
  organisationId: string;
}

export const TermsAndConditions: React.FC<ITermsAndConditionsProps> = ({
  organisationId,
}) => {
  const api = useApiClient();
  const [hasAcceptedTcs, setAcceptedTcs] = useState<boolean>();
  const [isAcceptLoading, setAcceptLoading] = useState(false);

  useEffect(() => {
    api.legal.checkTcs({ organisationId }).then(result => {
      if (result.isOk()) {
        setAcceptedTcs(result.value);
      }
    });
  }, [api, organisationId]);

  const acceptTcs = useCallback(
    ({ name, email }: { name: string; email: string }) => {
      setAcceptLoading(true);
      api.legal
        .acceptTcs({
          organisationId,
          name,
          email,
        })
        .then(result => {
          if (result.isOk()) {
            setAcceptedTcs(true);
          }

          setAcceptLoading(false);
        });
    },
    [api, organisationId]
  );

  return (
    <Shimmer isDataLoaded={hasAcceptedTcs !== undefined}>
      {hasAcceptedTcs ? (
        <AcceptedTermsAndConditionsView />
      ) : (
        <TermsAndConditionsView
          onAccept={acceptTcs}
          isAcceptLoading={isAcceptLoading}
        />
      )}
    </Shimmer>
  );
};
