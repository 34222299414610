import React, {
  FormEvent,
  useReducer,
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import {
  Selection,
  TextField,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  IDetailsRowProps,
  SelectionMode,
  PrimaryButton,
  IColumn
} from "office-ui-fabric-react";
import { IRestoreRequest } from "../../providers/ApiProvider/ApiClient/models/restore";
import { useApiClient } from "../../providers/ApiProvider";
import { prepareItems, prepareColumns } from "./helpers";
import { RestoreHistoryModal } from "./RestoreHistoryModal";
import { useHistory } from "react-router-dom";

export interface IRestoreHistoryProps {
  organisationId: string;
  serviceAccountId: string;
  serviceId: string;
}

interface IRestoreHistoryState {
  restoreHistories: IRestoreRequest[];
  cursor?: string;
}

type TSyncStatsActions = {
  type: "SET_HISTORIES";
  payload: IRestoreHistoryState;
};

export interface IHistoryItem extends IRestoreRequest {
  [key: string]: any;
  key: string;
  startDate: string;
  endDate: string;
  restoreType: string;
  friendlyStatus: string;
}

export interface IRestoreHistoryColumn extends IColumn {
  isSortable?: boolean;
}

const restoreHistoryReducer: React.Reducer<
  IRestoreHistoryState,
  TSyncStatsActions
> = (prevState: IRestoreHistoryState, action: TSyncStatsActions) => {
  switch (action.type) {
    case "SET_HISTORIES":
      return { ...prevState, ...action.payload };
    default:
      return prevState;
  }
};

export const RestoreHistory: React.FC<IRestoreHistoryProps> = ({
  organisationId,
  serviceAccountId,
  serviceId
}) => {
  const [filterQuery, setFilterQuery] = useState<string>();
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const selection = useMemo(
    () =>
      new Selection<any>({
        onSelectionChanged: () => {
          if (selection.getSelection()[0]) {
            setShouldShowModal(true);
          }
        }
      }),
    []
  );

  const [isLoading, setLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [columns, setColumns] = useState(prepareColumns());

  const [{ restoreHistories, cursor }, dispatch] = useReducer(
    restoreHistoryReducer,
    {
      restoreHistories: []
    }
  );

  const onFilterChange = (
    ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string | undefined
  ) => {
    setFilterQuery(value);
  };

  const closeModal = (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    setShouldShowModal(false);
  };

  const api = useApiClient();
  const fetchHistory = useCallback(
    async ({
      cursor
    }): Promise<{
      restoreHistories: IRestoreRequest[];
      nextCursor?: string;
    }> => {
      const response = await api.restore.listRestores({
        organisationId,
        serviceAccountId,
        serviceId,
        cursor
      });

      if (!response) {
        return { restoreHistories: [] };
      }

      return {
        nextCursor: response.pageInfo.cursor,
        restoreHistories: response.nodes
      };
    },
    [api.restore, organisationId, serviceAccountId, serviceId]
  );

  useEffect(() => {
    fetchHistory({ cursor: undefined }).then(
      ({ restoreHistories, nextCursor }) => {
        dispatch({
          type: "SET_HISTORIES",
          payload: { restoreHistories, cursor: nextCursor }
        });
        setIsDataLoading(false);
        setLoading(false);
      }
    );

    setLoading(true);
    setIsDataLoading(true);
  }, [fetchHistory, api.restore, organisationId, serviceId, serviceAccountId]);

  const loadNextPage = async () => {
    if (!isLoading) {
      setLoading(true);

      const {
        restoreHistories: nextRestoreHistories,
        nextCursor
      } = await fetchHistory({
        cursor
      });
      dispatch({
        type: "SET_HISTORIES",
        payload: {
          restoreHistories: [...restoreHistories, ...nextRestoreHistories],
          cursor: nextCursor
        }
      });
      setLoading(false);
    }
  };

  const items: Array<IHistoryItem | null> = prepareItems({
    filterQuery,
    restoreHistories,
    columns
  });

  if (cursor) {
    items.push(null);
  }

  const selectedRestorePoint: IHistoryItem = selection.getSelection()[0];

  const history = useHistory();

  const onSelectColumn = (ev?: any, column?: IRestoreHistoryColumn) => {
    if (columns && column && column.isSortable) {
      const newCols = columns.map(col => {
        if (col.key === column.key) {
          const isSortedDescending = column.isSorted
            ? !column.isSortedDescending
            : false;

          return {
            ...col,
            isSorted: true,
            isSortedDescending
          };
        }

        return {
          ...col,
          isSorted: false,
          isSortedDescending: false
        };
      });

      setColumns(newCols);
    }
  };

  return (
    <>
      <PrimaryButton onClick={history.goBack}>Go Back</PrimaryButton>
      <RestoreHistoryModal
        organisationId={organisationId}
        serviceId={serviceId}
        serviceAccountId={serviceAccountId}
        selectedRestorePoint={selectedRestorePoint}
        shouldShowModal={shouldShowModal}
        toggleModal={closeModal}
      />
      <div className="ms-Grid-col ms-sm12">
        <TextField
          styles={{ root: { marginTop: 15 } }}
          label="Filter Restore History"
          onChange={onFilterChange}
        />
      </div>
      <div className="ms-Grid-col ms-sm12">
        <ShimmeredDetailsList
          items={items}
          enableShimmer={isDataLoading ?? false}
          columns={columns}
          onColumnHeaderClick={onSelectColumn}
          selectionMode={SelectionMode.single}
          selection={selection}
          layoutMode={DetailsListLayoutMode.justified}
          onRenderCustomPlaceholder={(
            rowProps: IDetailsRowProps,
            index?: number,
            defaultRender?: (props: IDetailsRowProps) => React.ReactNode
          ) => {
            loadNextPage?.();
            return defaultRender?.(rowProps);
          }}
        />
      </div>
    </>
  );
};
