import {
  DetailsListLayoutMode,
  IColumn,
  Selection,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
  mergeStyles,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { IProvisioningRequest } from "../../providers/ApiProvider/ApiClient/models/provisioning";
import { makeTitleCase } from "../RestoreHistory/helpers";
import { useApiClient } from "../../providers/ApiProvider";
import { useHistory } from "react-router-dom";

interface IProvisioningProps {}

interface IProvisioningRequestWithName extends IProvisioningRequest {
  name: string;
}

export interface IProvisioningViewColumn extends IColumn {
  key: string;
  name: string;
  isSortable?: true;
}

interface IProvisioningItem {
  name: string;
  requestTime: string;
  service: string;
  status: string;
  organisationId: string;
  [key: string]: string;
}

export const Provisioning: React.FC<IProvisioningProps> = () => {
  const api = useApiClient();

  const [isLoading, setIsLoading] = useState(true);
  const [provisioningRequests, setProvisioning] = useState<
    IProvisioningRequestWithName[]
  >([]);
  const [columns, setColumns] = useState(defaultColumns());
  const [filterQuery, setFilterQuery] = useState<string | undefined>();

  const onFilterChange = (ev: any, value: string | undefined) => {
    setFilterQuery(value);
  };

  const history = useHistory();

  const container = mergeStyles({
    maxHeight: "55vh",
    overflowY: "scroll",
  });

  const selection = useMemo(
    () =>
      new Selection<any>({
        onSelectionChanged: () => {
          const sel = selection.getSelection()[0];
          if (!sel) {
            return;
          }
          const pathname = `/customers/${
            sel.organisationId
          }/services/${sel.service.toLowerCase()}`;
          history.push({ pathname });
        },
      }),
    [history]
  );

  useEffect((): void => {
    const loadData = async () => {
      const serviceIds = [EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID];

      const allProvisioning: IProvisioningRequestWithName[][] = await Promise.all(
        serviceIds.map(async serviceId => {
          const provisioningRequestsResp = await api.provisioning.listProvisioningRequests(
            {
              serviceId,
              status: ["FAILED", "IN_PROGRESS", "PENDING"],
            }
          );

          if (provisioningRequestsResp.isOk()) {
            const provWithNames = await Promise.all(
              provisioningRequestsResp.value.nodes.map(async request => {
                const org = await api.accounts.getOrganisation({
                  organisationId: request.organisationId,
                });
                const name = org?.name ?? "";
                return {
                  ...request,
                  name,
                };
              })
            );
            return provWithNames;
          } else {
            return [];
          }
        })
      );

      const provisioning = allProvisioning.reduce((prev, curr) => {
        return prev.concat(curr);
      }, []);
      setProvisioning(provisioning);
      setIsLoading(false);
    };

    loadData();
  }, [api.accounts, api.provisioning]);

  const onSelectColumn = (ev?: any, column?: IProvisioningViewColumn) => {
    if (columns && column && column.isSortable) {
      const newCols = columns.map(col => {
        if (col.key === column.key) {
          const isSortedDescending = column.isSorted
            ? !column.isSortedDescending
            : false;

          return {
            ...col,
            isSorted: true,
            isSortedDescending,
          };
        }

        return {
          ...col,
          isSorted: false,
          isSortedDescending: false,
        };
      });

      setColumns(newCols);
    }
  };

  const provisionings = prepareItems({
    provisioningRequests,
    filterQuery,
    columns,
  });

  return (
    <Stack>
      <Text variant="xxLarge">Provisioning Requests</Text>

      <TextField label="Filter" onChange={onFilterChange} />
      <ShimmeredDetailsList
        className={container}
        items={provisionings}
        enableShimmer={isLoading}
        columns={columns}
        onColumnHeaderClick={onSelectColumn}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
      />
    </Stack>
  );
};

const defaultColumns = (): IProvisioningViewColumn[] => {
  return [
    {
      key: "name",
      name: "Customer",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isSortable: true,
      isSorted: true,
      isSortedDescending: true,
    },
    {
      key: "service",
      name: "Service",
      fieldName: "service",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isSortable: true,
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isSortable: true,
      isCollapsable: true,
    },
    {
      key: "requestTime",
      name: "Created Time",
      fieldName: "requestTime",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isSortable: true,
      isCollapsable: true,
    },
  ];
};

export const prepareItems = ({
  provisioningRequests,
  filterQuery,
  columns,
}: {
  provisioningRequests: IProvisioningRequestWithName[];
  filterQuery?: string;
  columns?: IProvisioningViewColumn[];
}) => {
  let items: IProvisioningItem[] = [];

  items = provisioningRequests.map(item => {
    let service: string = "";
    if (item.serviceId === SHAREPOINT_SERVICE_ID) {
      service = "SharePoint";
    } else if (item.serviceId === EWS_SERVICE_ID) {
      service = "Mailbox";
    }

    const status = makeTitleCase(item.status);

    const requestTime = item.createdTime
      ? DateTime.fromISO(item.createdTime).toLocaleString({
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        })
      : " ";

    return {
      requestTime,
      service,
      name: item.name,
      status,
      organisationId: item.organisationId,
    };
  });

  let finalItems = items;

  if (filterQuery) {
    finalItems = finalItems.filter(item =>
      Object.values(item)
        .map(i => {
          return i.toLowerCase().includes(filterQuery.toLowerCase());
        })
        .includes(true)
    );
  }

  if (columns) {
    for (const column of columns) {
      if (column.isSorted) {
        finalItems = finalItems.sort((a, b) => {
          let res = 0;

          res = a[column.key] > b[column.key] ? -1 : 1;

          if (column.isSortedDescending) {
            res *= -1;
          }

          return res;
        });
      }
    }
  }

  return finalItems;
};
