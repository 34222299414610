export type Result<T> = Ok<T> | PortalError<T>;

export const ok = <T>(value: T): Ok<T> => new Ok(value);

interface IResultClass<T> {
  isOk: () => this is Ok<T>;
  isErr: () => this is PortalError<T>;
}

export class Ok<T> implements IResultClass<T> {
  constructor(readonly value: T) {}

  isOk(): this is Ok<T> {
    return true;
  }

  isErr(): this is PortalError<T> {
    return !this.isOk();
  }
}

export type TErrorCode = "UNAUTHORIZED" | "NOT_FOUND" | "UNKNOWN";

export class PortalError<T> extends Error implements IResultClass<T> {
  readonly code: TErrorCode;

  constructor(code: TErrorCode, msg?: string) {
    const msgStr = msg ? `: ${msg}` : "";
    super(`${code}${msgStr}`);
    Object.setPrototypeOf(this, new.target.prototype);

    this.code = code;
  }

  isOk(): this is Ok<T> {
    return false;
  }

  isErr(): this is PortalError<T> {
    return !this.isOk();
  }
}
