import {
  DocumentCard,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardTitle,
  DocumentCardType,
  IStyle,
  Stack,
  classNamesFunction,
  styled,
} from "office-ui-fabric-react";

import React from "react";

interface ILoginProvidersStyles {
  loginProviders: IStyle;
  loginTiles: IStyle;
  providerLogo: IStyle;
  preview: IStyle;
}

interface ILoginProvidersProps {
  styles?: ILoginProvidersStyles;
}

const loginProvidersStyles = (): ILoginProvidersStyles => {
  return {
    loginProviders: {
      width: "100%",
      height: 72,
      marginBottom: 20,
      maxWidth: "99.5%",
    },
    loginTiles: {
      padding: 0,
      marginTop: 26,
      marginLeft: 10,
      fontSize: "18px !important",
    },
    providerLogo: {
      marginTop: "-8px",
    },
    preview: {
      borderRight: "1px solid rgb(55, 55, 55)",
    },
  };
};

const getClassNames = classNamesFunction<{}, ILoginProvidersStyles>();

export const BaseLoginProviders: React.FC<ILoginProvidersProps> = ({
  styles,
}) => {
  const classNames = getClassNames(styles);

  const previewImages = [
    {
      name: "rhipe Logo",
      previewImageSrc: "/images/rhipeLogo.png",
      width: 73,
    },
    {
      name: "Microsoft Logo",
      previewImageSrc: "/images/microsoftLogo.svg",
      width: 73,
    },
  ];

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const cognitoAuthDomain = process.env.REACT_APP_COGNITO_AUTH_DOMAIN;
  const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;

  if (
    baseUrl === undefined ||
    cognitoAuthDomain === undefined ||
    cognitoClientId === undefined
  ) {
    throw new Error(
      "Missing baseUrl or cognitoAuthDomain or cognitoClientId env var"
    );
  }

  const formattedUrl = new URL(window.location.href)
  const path = formattedUrl.hostname
  const subdomain = path.split('.')[0]
  let state: string = ''
  if (!['portal', 'localhost'].includes(subdomain)){
    state = `&state=${subdomain}`
  }

  const msDevUrl = `${cognitoAuthDomain}/oauth2/authorize?client_id=${cognitoClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${baseUrl}/sso/redirect&identity_provider=Microsoft${state}`;

  return (
    <Stack>
      {/* <DocumentCard
        onClickHref="https://identity.prismportal.online/core/login"
        className={classNames.loginProviders}
        type={DocumentCardType.compact}
      >
        <DocumentCardPreview
          className={classNames.preview}
          previewImages={[previewImages[0]]}
        />
        <DocumentCardDetails>
          <DocumentCardTitle
            className={classNames.loginTiles}
            title="Sign in with rhipe"
          />
        </DocumentCardDetails>
      </DocumentCard> */}
      <DocumentCard
        onClickHref={msDevUrl}
        className={classNames.loginProviders}
        type={DocumentCardType.compact}
      >
        <DocumentCardPreview
          className={classNames.preview}
          previewImages={[previewImages[1]]}
        />
        <DocumentCardDetails>
          <DocumentCardTitle
            className={classNames.loginTiles}
            title="Sign in with Microsoft"
          />
        </DocumentCardDetails>
      </DocumentCard>
    </Stack>
  );
};

export const LoginProviders = styled(BaseLoginProviders, loginProvidersStyles);
