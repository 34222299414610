const round = (val: number, dp: number = 2): number => {
  const roundTo = Math.pow(10, dp);
  const rounded = Math.round((val + Number.EPSILON) * roundTo) / roundTo;

  return rounded;
};

export const humanReadableBytes = (bytes: number): string => {
  const prefixes = ["B", "kB", "MB", "GB", "TB", "PB"];

  let index = 0;
  let val = bytes;
  while (val >= 1000 && index < prefixes.length - 1) {
    index += 1;
    val /= 1000;
  }

  const rounded = round(val);

  const res = `${rounded} ${prefixes[index]}`;

  return res;
};

export const humanReadableNumber = (n: number): string => {
  if (n > 1e6) {
    // 1 million
    const val = n / 1e6;
    const rounded = round(val);

    return `${rounded} M`;
  } else if (n > 1e4) {
    // 10 thousand
    const val = n / 1e3;
    const rounded = round(val);

    return `${rounded} k`;
  } else {
    return n.toString();
  }
};

export const pluralise = (str: string, count: number): string => {
  if (count === 1) {
    return str;
  } else {
    return `${str}s`;
  }
};
