import {
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  Icon,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  mergeStyles,
} from "office-ui-fabric-react";
import React, { useRef, useState } from "react";

import { IAuditItem } from ".";
import { TOwnerType } from "../../providers/ApiProvider/ApiClient/models/audit";
import { toCamelCase } from "../../utils/strings";

export interface IAuditTableProps {
  items: Array<IAuditItem | null>;
  ownerType: TOwnerType;
  isRequestLoading: boolean;
  setQueryReverse: (reverse: boolean) => void;
  loadNextPage: (reverse: boolean) => void;
}

interface IAuditColumn extends IColumn {
  isSortable?: boolean;
}

export const AuditTable: React.FC<IAuditTableProps> = ({
  items,
  ownerType,
  isRequestLoading,
  setQueryReverse,
  loadNextPage,
}) => {
  const [auditColumns, setAuditColumns] = useState(
    defaultColumns({ ownerType })
  );

  const onSelectAuditColumn = (ev?: any, column?: IAuditColumn) => {
    if (column && column.key !== "cancel" && column.isSortable) {
      const newColumns = auditColumns.map(sortColumns(column));

      setQueryReverse(newColumns[0].isSortedDescending!);

      setAuditColumns(newColumns);
    }
  };

  const scrollToRef = (ref: React.MutableRefObject<HTMLDivElement | null>) =>
    ref.current?.scrollIntoView();
  const scrollRef = useRef(null as HTMLDivElement | null);
  const executeScroll = () => scrollToRef(scrollRef);

  const tableRef = useRef(null as HTMLDivElement | null);

  const sortColumns = (column: IAuditColumn) => (col: IAuditColumn) => {
    if (col.key === column.key) {
      const isSortedDescending = column.isSorted
        ? !column.isSortedDescending
        : false;

      return {
        ...col,
        isSorted: true,
        isSortedDescending,
      };
    }

    return {
      ...col,
      isSorted: false,
      isSortedDescending: false,
    };
  };

  const sortItems = (column: IAuditColumn) => (a: any, b: any) => {
    if (column.fieldName !== "formattedDate") {
      return 1;
    }
    if (a === null || b === null) {
      return 1;
    }
    const aStr: string | undefined = column.fieldName
      ? a[column.fieldName]
      : undefined;
    const bStr: string | undefined = column.fieldName
      ? b[column.fieldName]
      : undefined;

    let res = 0;
    if (aStr !== undefined && bStr !== undefined) {
      if (column.fieldName === "formattedDate") {
        res = new Date(aStr).getTime() - new Date(bStr).getTime();
      } else {
        res = aStr.localeCompare(bStr);
      }
    } else if (aStr !== undefined) {
      res = -1;
    } else if (bStr !== undefined) {
      res = 1;
    }

    if (column.isSortedDescending) {
      res *= -1;
    }

    return res;
  };

  items.map((item: any) => {
    if (!item) {
      return undefined;
    }
    Object.keys(item).map(itemKey => {
      const keyValue = item[itemKey];
      if (itemKey === "action" || itemKey === "resource") {
        item[itemKey] = toCamelCase(keyValue);
      }

      return undefined;
    });

    return undefined;
  });

  let sortedItems = items;
  for (const column of auditColumns) {
    if (column.isSorted) {
      sortedItems = sortedItems.sort(sortItems(column));
    }
  }

  const goToTop = mergeStyles({
    position: "fixed",
    right: "5%",
    bottom: "5%",
  });

  let topButton = <></>;
  if (tableRef && tableRef.current && tableRef.current?.clientHeight > 450) {
    topButton = (
      <PrimaryButton onClick={executeScroll} className={goToTop}>
        <Icon iconName="Up" />
      </PrimaryButton>
    );
  }

  return (
    <div ref={tableRef}>
      <div ref={scrollRef}></div>
      <ShimmeredDetailsList
        items={sortedItems}
        enableShimmer={isRequestLoading}
        onColumnHeaderClick={onSelectAuditColumn}
        columns={auditColumns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        onRenderCustomPlaceholder={(
          rowProps: IDetailsRowProps,
          index?: number,
          defaultRender?: (props: IDetailsRowProps) => React.ReactNode
        ) => {
          loadNextPage?.(auditColumns[0].isSortedDescending!);
          return defaultRender?.(rowProps);
        }}
      />
      {topButton}
    </div>
  );
};

const defaultColumns = ({
  ownerType,
}: {
  ownerType: TOwnerType;
}): IAuditColumn[] => {
  const ownerName =
    ownerType === "ORGANISATION" ? "Customer Name" : "User Name";
  return [
    {
      key: "formattedDate",
      name: "Date",
      fieldName: "formattedDate",
      minWidth: 150,
      maxWidth: 150,
      isSorted: true,
      isSortedDescending: true,
      isResizable: true,
      isSortable: true,
    },
    {
      key: "ownerName",
      name: ownerName,
      fieldName: "ownerName",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isSorted: false,
      isSortable: false,
    },
    {
      key: "userName",
      name: "Acting User",
      fieldName: "userName",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      isSortable: false,
    },
    {
      key: "userEmail",
      name: "Acting User Email",
      fieldName: "userEmail",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      isSortable: false,
    },
    {
      key: "resource",
      name: "Resource Type",
      fieldName: "resource",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      isSortable: false,
    },
    {
      key: "action",
      name: "Action",
      fieldName: "action",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      isSortable: false,
    },
    {
      key: "subsystem",
      name: "Subsystem",
      fieldName: "subsystem",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      isSortable: false,
    },
    {
      key: "ipAddress",
      name: "IP Address",
      fieldName: "ipAddress",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      isSortable: false,
    },
  ];
};
