import { Actions, IAction } from "./Actions";
import { IStat, Stats } from "./Stats";
import React, { useCallback } from "react";
import { getLicensing, statusCodeToString } from "../ServiceAccounts/utils";
import { humanReadableBytes, humanReadableNumber } from "./utils";

import { Details } from "./Details";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { TimeStats } from "./TimeStats";
import { useHistory } from "react-router-dom";

export interface IEwsStatsProps {
  serviceAccount?: IServiceAccount;
  isServiceAccountLoading?: boolean;
  stats?: Record<string, number>;
  isStatsLoading?: boolean;
  firstBackup?: Date;
  lastBackup?: Date;
  isBackupTimeLoading?: boolean;
  toggleEnabled?: (checked: boolean) => void;
  isEnabledLoading?: boolean;
  organisationId: string;
  serviceId: string;
}

export const EwsStats: React.FC<IEwsStatsProps> = ({
  serviceAccount,
  isServiceAccountLoading,
  stats,
  isStatsLoading,
  firstBackup,
  lastBackup,
  isBackupTimeLoading,
  toggleEnabled,
  isEnabledLoading,
  organisationId,
  serviceId,
}) => {
  const history = useHistory();

  const { status, enabled } = serviceAccount
    ? statusCodeToString(serviceAccount?.status)
    : { status: undefined, enabled: undefined };

  const licensing = serviceAccount ? getLicensing(serviceAccount) : undefined;

  const details: Record<string, string | undefined> = {
    Name: serviceAccount?.displayName,
    Email: serviceAccount?.identifier,
    Licensing: licensing,
    Status: status,
  };

  const hrItemCount = stats?.itemCount
    ? humanReadableNumber(stats.itemCount)
    : undefined;
  const itemCountHover =
    hrItemCount && hrItemCount !== stats?.itemCount.toString()
      ? stats?.itemCount.toString()
      : undefined;

  const hrBytes = stats?.itemSize
    ? humanReadableBytes(stats?.itemSize)
    : undefined;

  const displayStats: IStat[] = [
    {
      title: "Item Count",
      metric: hrItemCount,
      hover: itemCountHover,
      iconName: "Mail",
    },
    {
      title: "Total Size",
      metric: hrBytes,
      iconName: "PageData",
    },
  ];

  const performAction = useCallback(
    (action: string) => {
      if (serviceAccount) {
        history.push(
          `/customers/${serviceAccount.organisationId}/services/mailbox/${serviceAccount.id}/${action}`
        );
      }
    },
    [serviceAccount, history]
  );

  const actions: IAction[] = [
    {
      label: "Search Mailbox",
      onClick: () => {
        performAction("search");
      },
    },
    {
      label: "Restore",
      onClick: () => {
        performAction("restore");
      },
    },
  ];

  return (
    <>
      <Details
        serviceAccountType="Mailbox"
        details={details}
        enabled={enabled}
        isLoadingDetails={isServiceAccountLoading}
        toggleEnabled={toggleEnabled}
        isEnabledLoading={isEnabledLoading}
        organisationId={organisationId}
        serviceId={serviceId}
      />
      <Actions actions={actions} />
      <TimeStats
        initialBackup={firstBackup}
        recentBackup={lastBackup}
        isLoading={isBackupTimeLoading}
      />
      <Stats stats={displayStats} isLoading={isStatsLoading} />
    </>
  );
};
