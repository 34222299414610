import Dashboard from "../components/Dashboard";
import React from "react";

export const HomePage: React.FC = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};
