import { Icon, Text } from "office-ui-fabric-react";
import React, { ReactElement } from "react";

import { MenuItem } from "./MenuItem";
import { useHistory } from "react-router-dom";

export interface IUnauthedMenuAcionsProps {
  helpUrl?: string;
}

// An alternate set of menu actions to be used when a user is not logged in
export const UnauthedMenuAcions: React.FC<IUnauthedMenuAcionsProps> = ({
  helpUrl,
}) => {
  const history = useHistory();

  let menuItem: ReactElement | undefined = (
    <MenuItem onClick={() => window.open(helpUrl)}>
      <Icon iconName="Help" />
    </MenuItem>
  );

  if (!helpUrl) {
    menuItem = undefined;
  }

  return (
    <>
      {menuItem}
      <MenuItem
        styles={{ root: { width: 60, paddingLeft: 10 } }}
        onClick={() => history.push("/login")}
      >
        <Text variant="mediumPlus" nowrap>
          Sign in
        </Text>
      </MenuItem>
    </>
  );
};
