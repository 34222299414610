import {
  DetailsListLayoutMode,
  IColumn,
  IStyle,
  IconButton,
  MessageBarType,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
  Text,
  TextField,
  getTheme,
  mergeStyles,
} from "office-ui-fabric-react";
import React, { useCallback, useEffect, useState } from "react";

import { IRole } from "../../providers/ApiProvider/ApiClient/models/auth";
import { SubmitDialog } from "./submitDialog";
import { useApiClient } from "../../providers/ApiProvider";
import { useMessageBar } from "../../providers/messageBarProvider";

export const itemStyle: IStyle = { width: "30%", minWidth: 300 };

export interface ICustomerRoleConfig {
  userId: string;
  didAccept: boolean;
  setDidAccept: (didAccept: boolean) => void;
}

export interface IRoleColumns extends IColumn {
  key: string;
  name: string;
  isSortable?: true;
}

interface IRolesWithOrg extends IRole {
  organisationName?: string;
}

const CustomerRolesView: React.FC<ICustomerRoleConfig> = ({
  userId,
  didAccept,
  setDidAccept,
}) => {
  const api = useApiClient();
  const [roles, setRoles] = useState<IRolesWithOrg[]>([]);
  const [columns, setColumns] = useState(defaultColumns());
  const [filterQuery, setFilterQuery] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [isInnerLoading, setInnerLoading] = useState(false);
  const [dialogHidden, setDialogHidden] = useState(true);
  const [selectedRole, setSelectedRole] = useState<IRole>();

  const container = mergeStyles({
    maxHeight: "55vh",
    overflowY: "scroll",
  });

  const fetchData = useCallback(async () => {
    const rolesResp = await api.auth.listRolesByUser({
      userId,
    });
    if (rolesResp.isOk()) {
      const rolesWithOrgName = await Promise.all(
        rolesResp.value.map(async role => {
          const org = await api.accounts.getOrganisation({
            organisationId: role.organisationId,
          });
          const organisationName = org?.name;
          return {
            ...role,
            organisationName,
          };
        })
      );
      setRoles(rolesWithOrgName);
    }
  }, [api, setRoles, userId]);
  const { sendMessage } = useMessageBar();

  const deleteRole = useCallback(
    async ({ roleId }: { roleId: string }) => {
      setDialogHidden(true);
      setInnerLoading(true);
      const request = await api.auth.removeRoles({ userId, roleIds: [roleId] });
      await fetchData();
      setInnerLoading(false);
      if (request.isOk()) {
        sendMessage({
          messageType: MessageBarType.success,
          text: "Role removed successfully.",
        });
      } else {
        sendMessage({
          messageType: MessageBarType.error,
          text: request.message,
        });
      }
    },
    [setInnerLoading, fetchData, api, userId, sendMessage]
  );

  const onFilterChange = (ev: any, value: string | undefined) => {
    setFilterQuery(value);
  };

  const onSelectColumn = (ev?: any, column?: any) => {
    if (columns && column && column.isSortable) {
      const newCols = columns.map(col => {
        if (col.key === column.key) {
          const isSortedDescending = column.isSorted
            ? !column.isSortedDescending
            : false;

          return {
            ...col,
            isSorted: true,
            isSortedDescending,
          };
        }

        return {
          ...col,
          isSorted: false,
          isSortedDescending: false,
        };
      });

      setColumns(newCols);
    }
  };

  useEffect(() => {
    if (didAccept) {
      setIsLoading(true);
    }
    fetchData().then(() => {
      setIsLoading(false);
      if (didAccept) {
        setDidAccept(false);
      }
    });
  }, [api, didAccept, setDidAccept, fetchData]);

  const items = prepareItems({
    roles,
    filterQuery,
    selectedRole,
    setSelectedRole,
    setDialogHidden,
    dialogHidden,
    columns,
    isLoading: isInnerLoading,
  });

  let submitFunction = () => {};
  if (selectedRole) {
    submitFunction = () =>
      deleteRole({
        roleId: selectedRole.roleId,
      });
  }

  return (
    <>
      <SubmitDialog
        hidden={dialogHidden}
        submit={submitFunction}
        displayTitle={"Are you sure you want to remove this role?"}
        displayText={
          "You may lose access to this customer and this action cannot be undone."
        }
        setIsOpen={() => {
          setDialogHidden(!dialogHidden);
        }}
      ></SubmitDialog>
      <Text variant="xLarge">Existing Roles</Text>
      <TextField label="Filter" onChange={onFilterChange} />
      <ShimmeredDetailsList
        className={container}
        items={items}
        enableShimmer={isLoading}
        columns={columns}
        onColumnHeaderClick={onSelectColumn}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
      />
    </>
  );
};

const defaultColumns = (): IRoleColumns[] => {
  return [
    {
      key: "organisationName",
      name: "Customer",
      fieldName: "organisationName",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSortable: true,
      isSorted: true,
      isSortedDescending: true,
    },
    {
      key: "name",
      name: "Role",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSortable: true,
      isCollapsable: true,
    },
    {
      key: "description",
      name: "Description",
      fieldName: "description",
      minWidth: 400,
      isResizable: true,
      isSortable: true,
      isCollapsable: true,
    },
    {
      key: "remove",
      name: "Remove",
      fieldName: "remove",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];
};

export const prepareItems = ({
  roles,
  filterQuery,
  columns,
  isLoading,
  selectedRole,
  setSelectedRole,
  setDialogHidden,
  dialogHidden,
}: {
  roles: IRolesWithOrg[];
  filterQuery?: string;
  columns?: IRoleColumns[];
  isLoading: boolean;
  dialogHidden: boolean;
  selectedRole: IRole | undefined;
  setSelectedRole: React.Dispatch<IRole | undefined>;
  setDialogHidden: React.Dispatch<boolean>;
}) => {
  const theme = getTheme();

  const handleSelectedRole = ({ role }: { role: IRole }) => {
    setDialogHidden(!dialogHidden);
    setSelectedRole(role);
  };

  let finalItems = roles;

  if (filterQuery) {
    finalItems = finalItems.filter(item =>
      Object.values(item)
        .map(i => {
          if (typeof i === "string") {
            return i.toLowerCase().includes(filterQuery.toLowerCase());
          }

          return undefined;
        })
        .includes(true)
    );
  }

  if (columns) {
    for (const column of columns) {
      if (column.isSorted) {
        finalItems = finalItems.sort((a: any, b: any) => {
          let res = 0;

          if (column.fieldName === undefined) {
            return res;
          }

          res = a[column.fieldName] > b[column.fieldName] ? -1 : 1;

          if (column.isSortedDescending) {
            res *= -1;
          }

          return res;
        });
      }
    }
  }

  finalItems = finalItems.map(role => {
    let remove = (
      <IconButton
        iconProps={{ iconName: "Delete" }}
        onClick={() => handleSelectedRole({ role })}
        id={role.roleId}
        styles={{
          root: {
            padding: 0,
            marginTop: -5,
          },
          icon: {
            fontSize: theme.fonts.xLarge.fontSize,
            color: theme.palette.red,
          },
        }}
      />
    );
    if (isLoading && selectedRole?.roleId === role.roleId) {
      remove = <Spinner size={SpinnerSize.small} />;
    }
    return {
      ...role,
      remove,
    };
  });

  return finalItems;
};

export default CustomerRolesView;
