import React, { useCallback } from "react";
import { IServiceAccount } from "../../../providers/ApiProvider/ApiClient/models/accounts";
import {
  IStackTokens,
  Text,
  Stack,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Checkbox
} from "office-ui-fabric-react";
import { pluralise } from "../../ServiceAccountStats/utils";
import { Modal } from "../../Modal";
// import { DestinationComboBox } from "../DestinationComboBox";

export interface IRestoreItemsModalViewProps {
  isOpen: boolean;
  onDismiss?: () => void;
  selectedFileCount: number;
  serviceAccounts: IServiceAccount[];
  isServiceAccountsLoading?: boolean;
  selectedServiceAccountId?: string;
  setDownload: (val: any)=>void;
  setSelectedServiceAccount?: (serviceAccountId: string | undefined) => void;
  isRestoreLoading?: boolean;
  restore?: () => void;
}

export const RestoreItemsModalView: React.FC<IRestoreItemsModalViewProps> = ({
  isOpen,
  onDismiss,
  selectedFileCount: count,
  serviceAccounts,
  isServiceAccountsLoading,
  selectedServiceAccountId,
  setSelectedServiceAccount,
  isRestoreLoading,
  setDownload,
  restore
}) => {
  const tokens: IStackTokens = {
    childrenGap: "s1"
  };

  const _onChangeDownload = useCallback((e, val)=>{
    setDownload(val)
  }, [setDownload])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} header="Restore Items">
      <Stack tokens={tokens}>
        <Stack.Item align="center">
          <Text variant="xLarge">
            Restore {count} {pluralise("item", count)}?
          </Text>
        </Stack.Item>
        <Checkbox label="Restore to a downloadable file." onChange={_onChangeDownload} defaultChecked={true} disabled={true}/>
        <Text variant="medium">A link will be emailed to you when the download link is ready.</Text>
        {/*<DestinationComboBox*/}
        {/*  serviceAccounts={serviceAccounts}*/}
        {/*  isServiceAccountsLoading={isServiceAccountsLoading}*/}
        {/*  selectedServiceAccountId={selectedServiceAccountId}*/}
        {/*  setSelectedServiceAccountId={setSelectedServiceAccount}*/}
        {/*/>*/}
        {isRestoreLoading ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <PrimaryButton
            disabled={isServiceAccountsLoading}
            onClick={() => restore?.()}
          >
            Restore
          </PrimaryButton>
        )}
      </Stack>
    </Modal>
  );
};
