import React from "react";
import { Text } from "office-ui-fabric-react";

export interface ITermsAndConditionsContentProps {}

export const TermsAndConditionsContent: React.FC<ITermsAndConditionsContentProps> = () => {
  return (
    <>
      <Text block variant="xLarge">
        Terms and Conditions
      </Text>
      <Text block variant="xLarge">
        BACKUP365 STANDARD TERMS & CONDITIONS
      </Text>
      <p>
        <Text variant="medium">
          Backup365, and Customer (as identified on the Services Application
          Form) agree to the following Backup365, Standard Terms and Conditions,
          as may be supplemented by any additional terms attached hereto (the
          “Terms and Conditions”). The Services Application Form (“Order Form”)
          executed by Customer and these Terms and Conditions are collectively
          referred to as the “Agreement”.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          1. DEFINITIONS. For purposes of the Agreement, the following
          definitions shall apply: (a)
        </Text>
      </p>
      <p>
        <Text variant="medium">
          2. DESCRIPTION OF SERVICES. Backup365 will provide Customer the
          services designated in the Order Form (the “Services”). Backup365 may
          change, update or enhance the Services from time to time as it deems
          necessary. For Services designated as in Beta stage, Backup365
          reserves the right to materially change the functionality of the
          Services without notice to Customer.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          3. CUSTOMER OBLIGATIONS. Customer will provide Backup365 with all
          technical data and all other relevant information and assistance
          Backup365 reasonably requires to supply the Services. To the best of
          Customer’s knowledge, all information supplied to Backup365 will be
          complete, accurate and provided in good faith.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          Customer agrees not to: (i) use the Services for any purpose that is
          in violation of any applicable law or is otherwise an infringement on
          the rights of any person or entity, or use the Services in the event
          Customer provides similar services; (ii) modify, decompile, reverse
          engineer, disassemble or reproduce any components of the Services
          (iii) interfere with or disrupt the Services or any networks connected
          to Services; (iv) conduct fraudulent activities, including the
          initiation or propagation of any Malware; (v) take an unreasonable or
          disproportionately large load (traffic bandwidth or data storage per
          user exceeding 150% of the average for all Backup365 customers using
          similar services) on Backup365 infrastructure providing the Services,
          unless the parties agree an overage charge rate as set forth in a
          Service Application Form; (vi) impersonate any person or entity or
          falsely state or otherwise misrepresent an affiliation with a person
          or entity; (vii) allow the resell the Services, or otherwise permit
          the use of the Services by any third party users that are not bound by
          these Terms and Conditions (each of (i) to (vii), a "Prohibited Use").
        </Text>
      </p>
      <p>
        <Text variant="medium">
          At Backup365’s reasonable request, Customer shall provide to Backup365
          comments, criticisms, suggested improvements and other feedback, about
          the use, operation, functionality and features of the Services
          (collectively, the “Feedback”). The Feedback shall include, without
          limitation, any information about operating results, known or
          suspected bugs, errors or compatibility problems, user-desired
          features and the results of any and all benchmark or similar testing
          conducted within the term of this Agreement. In addition, Customer
          shall report to Backup365 any unusual, unplanned or
          out-of-the-ordinary performance of the Services observed by any of
          Customer’s personnel. Customer agrees that Backup365 has the
          unrestricted right to use the Feedback at its sole discretion, without
          notice to, payment to or consent from Customer, provided that
          Backup365’s use of the Feedback complies with the nondisclosure
          obligations set forth in Section 11 below. Customer agrees not to
          disclose Feedback to any third party without the express written
          consent of Backup365.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          4. SYSTEM ADMINISTRATOR. The customer will provide Backup365 with
          technical contact information regarding Customer’s system
          administrator (“System Administrator”) who Customer hereby authorises
          and directs to act on its behalf and provide the information required
          by Backup365 to configure and manage the Services.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          5. TERM. The initial term of this Agreement is specified in the Order
          Form (the “Initial Term”). This Agreement shall renew for successive
          terms of equal length as the Initial Term (each a “Renewal Term”)
          unless either party provides written notice of its intent not to renew
          this Agreement at least five (5) days prior to the end of the then
          current term. During any Renewal Term, the parties shall be bound by
          the provisions of these Terms and Conditions in the form published on
          Backup365’s website https://backup365.io/terms-and-conditions/ as of
          the effective date of the Renewal Term. Customer acknowledges and
          agrees that billing for the Services shall continue through the
          effective date of the termination, subject to the notice requirement
          and other applicable terms herein.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          6. FEES; INVOICE; AUDIT. Customer will pay the Fees set forth on the
          Order Form, which are subject to change based on Customer’s request
          for an increased number of users served or other options ordered by
          Customer, or at the discretion of Backup365 at the beginning of any
          Renewal Term. Customer is responsible for the payment of any and all
          taxes related to use of the Services (excluding taxes based on
          Backup365’s income). If the Customer cancels the Services during the
          Initial Term or any Renewal Term they will not be entitled to a
          refund.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          7. SUSPENSION OF SERVICE. Backup365 may suspend the Services as
          follows: a) immediately if Backup365 deems it necessary to terminate
          any Prohibited Use either directed at or originating from Customer’s
          domains or servers; or b) upon written notice to Customer if Customer
          breaches this Agreement and fails to cure such breach to Backup365’s
          satisfaction within five (5) days after Backup365’s written notice
          specifying the breach. Suspension of Services shall be without
          prejudice to any rights or liabilities accruing prior to or during the
          suspension, including but not limited to Customer’s obligation to pay
          Fees.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          8. TERMINATION. Backup365 may terminate this Agreement for cause
          immediately upon email notice to Customer: (a) in the event that
          Backup365 does not receive Customer’s payment of any non-disputed Fees
          within thirty (30) days of the invoice date, (b) Customer fails to
          remedy any situation giving rise to a Suspension of Service; (c)
          Customer commits a material breach of this Agreement; or (d) Backup365
          makes a reasonable determination that Customer’s traffic volume is
          disproportionately large. In the event of (d) above, Backup365 may
          request that Customer enter negotiations regarding a Fee adjustment as
          a prerequisite to continuing Services. Within thirty (30) calendar
          days after termination or expiration of this Agreement, the Customer
          will: (a) return to Backup365 all materials provided to the Customer
          by Backup365 under this Agreement; and (b) certify in writing through
          an authorised representative of Customer that such return has been
          effected.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          9. CUSTOMER SERVICE. The Order Form specifies the party that will
          provide Customer technical support, which may be Backup365 or a
          Backup365 partner (“Channel Partner”), in which case Customer agrees
          that it will seek technical support exclusively from Channel Partner.
          Terms of customer support provided by Backup365 are available on
          Backup365’s web site (http://www.backup365.io/support/). Backup365 may
          from time to time perform maintenance on or otherwise temporarily
          suspend the Services. Backup365 will use commercially reasonable
          efforts to minimise any disruption of Services. 10. SECURITY. Although
          information transmitted to Backup365 is stored in secure operating
          environments, Customer understands that no data transmission over the
          Internet can be guaranteed to be 100% secure. Backup365 is not
          responsible for any interception or interruption of any communications
          through the Internet. Customer is responsible for maintaining the
          security of its networks, servers, applications and access codes.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          10. SECURITY. Although information transmitted to Backup365 is stored
          in secure operating environments, Customer understands that no data
          transmission over the Internet can be guaranteed to be 100% secure.
          Backup365 is not responsible for any interception or interruption of
          any communications through the Internet. Customer is responsible for
          maintaining the security of its networks, servers, applications and
          access codes.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          11. CONFIDENTIALITY; PRIVACY. Each party agrees and undertakes that
          during the term of this Agreement and for three (3) years thereafter,
          it will keep confidential and will not use for its own purposes
          without the prior written consent of the disclosing party any
          information of a confidential nature which may become known to the
          receiving party from the disclosing party (“Confidential Information”)
          unless: (i) such disclosure is otherwise permitted or contemplated by
          this Agreement; (ii) the information is publicly known or already
          known to the receiving party at the time of disclosure; (iii) the
          information subsequently comes lawfully into the possession of the
          receiving party from a third party; or (iv) disclosure is required by
          court order or otherwise by law. Customer acknowledges that
          Backup365’s Intellectual Property (defined below) constitutes
          Confidential Information of Backup365.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          In Backup365’s normal provision of the Services, Customer’s traffic is
          processed electronically, and is not reviewed by Backup365’s
          personnel. Backup365 recognises that user specific information and the
          content of the traffic sent to or from Customer constitutes
          Confidential Information of the Customer. Backup365 will not sell,
          rent, license or exchange personally identifiable data with a third
          party without the Customer’s consent unless required to do so by law
          or to enforce this Agreement. Notwithstanding the foregoing, Backup365
          reserves the right to utilise any content of the traffic to maintain
          or improve the performance of the Services, or to observe, study and
          test the functioning of the Services. In addition, some information
          may be shared on an aggregate basis only as a part of a larger set of
          statistics (for example, statistics that indicate amount of traffic,
          success rates, and size of Backup365’s customers). Backup365 may use
          cookies to store user session information, access codes, and
          application settings to ease site navigation processes. Cookies do not
          hold any personal information.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          12. INTELLECTUAL PROPERTY. The Services (including any associated
          software, hardware, websites, passwords, components and tools) are and
          will remain the sole property of Backup365 or its licensors,
          respectively. Backup365 or its licensors, respectively, will retain
          sole ownership of all right, title and interest in and to the
          Services, as well as any derivative works thereof, including but not
          limited to copyrights, patent rights, trademark and service mark
          rights, trade secret rights, moral rights, and all other intellectual
          property and proprietary rights (“Intellectual Property”). Customer
          agrees, on behalf of itself, its employees and agents, that it will
          not remove or alter any trademarks, or other proprietary notices,
          legends, symbols, or labels appearing in the Service or other
          materials delivered by Backup365.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          13. THIRD PARTIES. The Services may contain products of independent
          third parties, such as Malware definitions or algorithms.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          14. DISCLAIMER OF WARRANTIES AND LIMITATIONS OF LIABILITY. ANY USE BY
          YOU OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED “AS
          IS,” AND BACKUP365 AND ITS LICENSORS MAKE NO WARRANTIES OF ANY KIND TO
          THE MAXIMUM EXTENT PERMITTED BY LAW, WITH RESPECT TO THE SERVICES,
          INCLUDING BUT NOT LIMITED TO WARRANTIES OF QUALITY, PERFORMANCE,
          MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, CONFORMITY TO ANY
          REPRESENTATION OR DESCRIPTION, OR NON-INFRINGEMENT. TO THE EXTENT NOT
          PROHIBITED BY APPLICABLE LAW, BACKUP365’S OR ITS LICENSORS’ AGGREGATE
          LIABILITY UNDER THIS AGREEMENT, WHETHER FOR BREACH OR IN TORT, IS
          LIMITED TO THE FEES PAID BY CUSTOMER FOR THE TWO MONTHS IMMEDIATELY
          PRECEDING THE EVENT GIVING RISE TO SUCH LIABILITY. IN NO EVENT WILL
          BACKUP365 OR ITS LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE,
          SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR
          ARISING OUT OF THIS AGREEMENT (INCLUDING BUT NOT LIMITED TO ANY LOST
          PROFITS, LOST SAVINGS, LOSS OF CUSTOMERS, LOSS OF, OR LOSS OF USE OF,
          ANY SOFTWARE, DATA, WEB TRAFFIC, OR EMAILS, BUSINESS INTERRUPTION,
          DELAYS OR FAILURE TO DELIVER WEB TRAFFIC OR EMAIL, DELAYS OR FAILURE
          TO DETECT UNDESIRABLE WEB TRAFFIC OR MALWARE, OR WRONGFULLY
          IDENTIFYING WEB TRAFFIC OR EMAIL FOR FILTERING OR AS CONTAINING
          MALWARE) HOWEVER CAUSED AND REGARDLESS OF THE LEGAL THEORY OF
          LIABILITY, EVEN IF BACKUP365 HAS BEEN PREVIOUSLY ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, AND EVEN IF ANY EXCLUSIVE REMEDY PROVIDED
          FOR HEREIN FAILS OF ITS ESSENTIAL PURPOSE. NEITHER BACKUP365 NOR ITS
          LICENSORS WARRANT THAT THE SERVICES ARE ERROR-FREE OR THAT OPERATION
          OF THE SERVICES WILL BE UNINTERRUPTED.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          15. INDEMNIFICATION. Customer agrees to indemnify, defend, and hold
          Backup365 harmless from and against any and all claims, liabilities,
          damages, fines, penalties, losses, costs and expenses (including
          reasonable attorneys’ fees) arising out of or relating to (i) any
          breach by Customer of this Agreement; (ii) any information or content
          passing through the Services and/or Backup365’s network to or from
          Customer; (iii) any taxes arising from the Services whether now in
          effect or imposed in the future (excluding taxes based on Backup365’s
          income); and (iv) claims by third-parties arising from Customer’s use
          of the Services (excluding claims that the Services, as provided by
          Backup365, infringe third party intellectual property rights).
        </Text>
      </p>
      <p>
        <Text variant="medium">
          Backup365 agrees to indemnify, defend, and hold Customer harmless from
          and against any and all claims, liabilities, damages, fines,
          penalties, losses, costs and expenses (including reasonable attorneys’
          fees) arising out of third party claims that Customer’s authorised use
          of the Services, as provided by Backup365, infringes the intellectual
          property rights of such third party. In the event that a claim of
          infringement is made or threatened, Backup365 may: (i) modify the
          Services to render them non-infringing; (ii) secure for Customer the
          right to use the Services; or (iii) terminate this Agreement. The
          foregoing states the entire liability of Backup365 with respect to
          infringement.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          16. WAIVER. The failure of a party to exercise or enforce any right
          under this Agreement shall not be deemed to be a waiver of that right
          nor operate to bar the exercise or enforcement of it at any time or
          times thereafter.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          17. JURISDICTION/GOVERNING LAW; COSTS; LIMITATION PERIOD. The laws of
          the State of Victoria, Australia shall govern this Agreement and any
          interpretations or constructions thereof. Further, the place of
          performance and transaction of business shall be deemed to be in the
          state of Victoria, Australia in the event of litigation, the exclusive
          venue and place of jurisdiction shall be the state courts of Victoria,
          Australia or federal courts of Australia, as applicable. The parties
          hereby submit themselves to the personal jurisdiction and venue of
          such courts.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          In any dispute regarding the enforcement of this Agreement, the
          prevailing party shall be entitled to recover all costs and fees,
          including attorneys’ fees, incurred by such prevailing party in
          enforcing the terms of this Agreement.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          Customer agrees that regardless of any statute or law to the contrary,
          any claim or cause of action arising out of this Agreement must be
          filed within one (1) year after such claim or cause of action arises.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          18. NOTICE. Any notice required in this Agreement shall be delivered
          by facsimile or registered mail sent to the addresses set forth on the
          service application Form. Notices shall be deemed effective upon
          receipt.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          19. BINDING EFFECT AND ASSIGNMENT. This Agreement shall be binding
          upon and inure to the benefit of the parties and their respective
          successors and assigns. Customer may not assign this Agreement without
          the prior written permission of Backup365.
        </Text>
      </p>
      <p>
        <Text variant="medium">
          20. SURVIVAL. Rights and obligations which by their nature should
          survive will survive the termination or expiration of this Agreement,
          including but not limited to Sections 1, 6, 8, 11-19 and 20.
        </Text>
      </p>
    </>
  );
};
