import { IRootState, allStoreActions } from "../../store";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserId } from "../../utils/parseJWT";
import { useApiClient } from "../ApiProvider";

const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: IRootState) => state.auth);
  const client = useApiClient();

  // Using window.location because we can't access the useHistory hook here
  const parsedTokens = new URLSearchParams(window.location.search).entries();
  const tokens = Object.fromEntries(parsedTokens);
  if (tokens.refreshToken) {
    dispatch(allStoreActions.auth.setTokens(tokens));
  }

  useEffect(() => {
    const refreshToken = authState.tokens.refreshToken;
    let expiresAt = authState.tokens.idTokenExpiresAt;
    const now = new Date();
    const idToken = authState.tokens.idToken;
    if (idToken && authState.userPermissions === undefined) {
      const userId = getUserId(idToken);
      client.auth.getUserPermissions({ userId }).then(userPermissionsResp => {
        if (userPermissionsResp.isOk()) {
          dispatch(
            allStoreActions.auth.setUserPermissions(userPermissionsResp.value)
          );
        }
      });
    }

    const refreshTokens = (refreshToken: string) => {
      client.public
        .refreshLoginTokens({
          refreshToken,
        })
        .then(tokens => {
          if (tokens.idToken) {
            dispatch(allStoreActions.auth.setTokens(tokens));
            setTimeout(
              refreshTokens,
              tokens.expiresIn * 1000 - 5 * 60 * 1000,
              refreshToken
            );
          } else {
            dispatch(allStoreActions.auth.setIsLoggedOut());
          }
        })
        .catch(err => {
          dispatch(allStoreActions.auth.setIsLoggedOut());
        });
    };
    if (
      (refreshToken && !authState.loggedIn) ||
      (refreshToken && expiresAt && expiresAt < now)
    ) {
      refreshTokens(refreshToken);
    }
  });

  return <>{children}</>;
};

export default AuthProvider;
