import {
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";

export interface IListViewColumn extends IColumn {
  isSortable?: boolean;
}

export interface IBaseListProps {
  items?: any[];
  columns?: IListViewColumn[];
  isDataLoading?: boolean;
  hasNextPage?: boolean;
  loadNextPage?: () => void;
}

const isString = (val: any): val is string => typeof val === "string";

export const BaseListView: React.FC<IBaseListProps> = props => {
  const [columns, setColumns] = useState(props.columns);
  const [filter, setFilter] = useState<string>();

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  let filteredItems: any[] = props.items ?? [];
  if (filter && filter !== "") {
    filteredItems = filteredItems.filter(item => {
      const fields = Object.values(item).filter(isString);

      for (const field of fields) {
        if (field.toLowerCase().includes(filter.toLowerCase())) {
          return true;
        }
      }

      return false;
    });
  }

  let sortedItems = filteredItems;
  if (columns) {
    for (const column of columns) {
      if (column.isSorted) {
        sortedItems = sortedItems.sort((a, b) => {
          const aStr: string | undefined = column.fieldName
            ? a[column.fieldName]
            : undefined;
          const bStr: string | undefined = column.fieldName
            ? b[column.fieldName]
            : undefined;

          let res = 0;
          if (aStr !== undefined && bStr !== undefined) {
            res = aStr.localeCompare(bStr);
          } else if (aStr !== undefined) {
            res = -1;
          } else if (bStr !== undefined) {
            res = 1;
          }

          if (column.isSortedDescending) {
            res *= -1;
          }

          return res;
        });
      }
    }
  }

  let items: any[];
  if (props.hasNextPage) {
    items = [...filteredItems, null];
  } else {
    items = [...filteredItems];
  }

  const onSelectColumn = (ev?: any, column?: IListViewColumn) => {
    if (columns && column && column.isSortable) {
      const newCols = columns.map(col => {
        if (col.key === column.key) {
          const isSortedDescending = column.isSorted
            ? !column.isSortedDescending
            : false;

          return {
            ...col,
            isSorted: true,
            isSortedDescending,
          };
        }

        return {
          ...col,
          isSorted: false,
          isSortedDescending: false,
        };
      });

      setColumns(newCols);
    }
  };

  return (
    <>
      <TextField
        label="Filter"
        value={filter}
        onChange={(ev: any, newValue?: string) => setFilter(newValue)}
      />
      <ShimmeredDetailsList
        items={items}
        enableShimmer={props.isDataLoading ?? false}
        columns={columns}
        onColumnHeaderClick={onSelectColumn}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        onRenderCustomPlaceholder={(
          rowProps: IDetailsRowProps,
          index?: number,
          defaultRender?: (props: IDetailsRowProps) => React.ReactNode
        ) => {
          props.loadNextPage?.();

          return defaultRender?.(rowProps);
        }}
      />
    </>
  );
};
