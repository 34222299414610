import {
  Checkbox,
  Label,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TagPicker,
  Text,
  TextField,
  getTheme,
  mergeStyleSets,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, { useCallback, useState } from "react";

import { useApiClient } from "../../providers/ApiProvider";
import { useForm } from "../../hooks/useForm";
import { useMessageBar } from "../../providers/messageBarProvider";
import { validateEmail } from "../../utils/validateEmail";

export interface IProvisionPanelProps {
  isOpen: boolean;
  onDismiss: (shouldReload?: boolean) => void;
  serviceId: string;
  organisationId: string;
}

interface IFormState {
  reportingRecipients: string[];
  reportingDaily: boolean;
  reportingWeekly: boolean;
  reportingEvents: boolean;
  credentialsEmail?: string;
  credentialsPassword?: string;
  tenantUuid?: string;
}

const isRequired = (form: IFormState): form is Required<IFormState> => {
  return form.tenantUuid !== undefined;
};

export const ProvisionPanel: React.FC<IProvisionPanelProps> = ({
  isOpen,
  onDismiss,
  serviceId,
  organisationId,
}) => {
  const theme = getTheme();
  const api = useApiClient();
  const { sendMessage } = useMessageBar();

  const [form, updateForm, clearForm] = useForm<IFormState>({
    initialState: {
      reportingRecipients: [],
      reportingDaily: false,
      reportingWeekly: true,
      reportingEvents: true,
    },
    validation: {
      reportingRecipients: vals => {
        if (vals.length === 0) {
          return "At least one recipient address is required";
        }

        const validEmails = vals.map(validateEmail);
        if (validEmails.includes(false)) {
          return "Must be valid email addresses";
        }
      },
      credentialsEmail: (val, form) => {
        if (val !== undefined && !validateEmail(val)) {
          return "Must be a valid email address";
        }

        if (val === undefined && form.credentialsPassword !== undefined) {
          return "Must include email address if password is included";
        }
      },
      credentialsPassword: (val, form) => {
        if (val === undefined && form.credentialsEmail !== undefined) {
          return "Must include password if email address is included";
        }
      },
      tenantUuid: val => {
        if (val === undefined || val.trim() === "") {
          return "Tenant domain is required";
        }
      },
    },
  });

  const [isLoading, setLoading] = useState(false);

  const serviceName =
    serviceId === EWS_SERVICE_ID
      ? "Mailbox"
      : serviceId === SHAREPOINT_SERVICE_ID
      ? "SharePoint/OneDrive"
      : "";

  const createRequest = useCallback(
    async (formData: Required<IFormState>) => {
      const credentials =
        formData.credentialsEmail !== undefined &&
        formData.credentialsPassword !== undefined
          ? {
              email: formData.credentialsEmail,
              password: formData.credentialsPassword,
            }
          : undefined;

      const response = await api.provisioning.requestProvisioning({
        serviceId,
        organisationId,
        credentials,
        reportingConfig: {
          recipients: formData.reportingRecipients,
          daily: formData.reportingDaily,
          weekly: formData.reportingWeekly,
          events: formData.reportingEvents,
        },
        tenantUuid: formData.tenantUuid.trim(),
      });

      if (response.isOk()) {
        sendMessage({
          messageType: MessageBarType.success,
          text: `Provisioning request sent for ${serviceName} service`,
        });
      } else {
        sendMessage({
          messageType: MessageBarType.error,
          text: `Failed to provision ${serviceName} service with error - ${response.message}`,
        });
      }
    },
    [serviceId, organisationId, serviceName, api.provisioning, sendMessage]
  );

  const { data, validation } = form;
  const isErrors = Object.values(validation).some(val => val !== undefined);

  const classNames = mergeStyleSets({
    button: {
      paddingTop: 12,
    },
    errorText: {
      color: theme.semanticColors.errorText,
    },
  });

  return (
    <Panel
      headerText={`Provision ${serviceName} Service`}
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={() => {
        clearForm();
        onDismiss();
      }}
    >
      <Stack tokens={{ childrenGap: "m", padding: "s1" }}>
        <TextField
          required
          label="Office365 Tenant ID"
          placeholder="ebea8edd-f288-43d7-a287-12c25197f8fc"
          value={data.tenantUuid ?? ""}
          onChange={(ev, newValue) => {
            let value = newValue;
            if (value === "") {
              value = undefined;
            }

            updateForm({ field: "tenantUuid", value });
          }}
          errorMessage={validation.tenantUuid}
          disabled={isLoading}
        />
        <Stack>
          <Text>
            <b>Global Administrator Credentials</b>
          </Text>
          <TextField
            label="Email"
            value={data.credentialsEmail ?? ""}
            onChange={(ev, newValue) => {
              let value = newValue;
              if (value === "") {
                value = undefined;
              }

              updateForm({ field: "credentialsEmail", value });
            }}
            errorMessage={validation.credentialsEmail}
            disabled={isLoading}
          />
          <TextField
            type="password"
            label="Password"
            value={data.credentialsPassword ?? ""}
            onChange={(ev, newValue) => {
              let value = newValue;
              if (value === "") {
                value = undefined;
              }

              updateForm({ field: "credentialsPassword", value });
            }}
            errorMessage={validation.credentialsPassword}
            disabled={isLoading}
          />
        </Stack>
        <Stack tokens={{ childrenGap: "s1" }}>
          <Text>
            <b>Reporting Configuration</b>
          </Text>
          <Checkbox
            label="Daily"
            checked={data.reportingDaily}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                updateForm({ field: "reportingDaily", value: checked });
              }
            }}
            disabled={isLoading}
          />
          <Checkbox
            label="Weekly"
            checked={data.reportingWeekly}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                updateForm({ field: "reportingWeekly", value: checked });
              }
            }}
            disabled={isLoading}
          />
          <Checkbox
            label="Events"
            checked={data.reportingEvents}
            onChange={(ev, checked) => {
              if (checked !== undefined) {
                updateForm({ field: "reportingEvents", value: checked });
              }
            }}
            disabled={isLoading}
          />
          <Stack tokens={{ childrenGap: "s2" }}>
            <Label required>Recipients</Label>
            <TagPicker
              selectedItems={data.reportingRecipients.map(recipient => ({
                key: recipient,
                name: recipient,
              }))}
              onChange={items => {
                if (items) {
                  updateForm({
                    field: "reportingRecipients",
                    value: items.map(item => item.key + ''),
                  });
                }
              }}
              onResolveSuggestions={filter => [{ key: filter, name: filter }]}
              disabled={isLoading}
            />
            {validation.reportingRecipients !== undefined ? (
              <Text className={classNames.errorText} variant="small">
                {validation.reportingRecipients}
              </Text>
            ) : (
              undefined
            )}
          </Stack>
        </Stack>
        <Stack.Item className={classNames.button} align="end">
          <PrimaryButton
            disabled={isErrors || isLoading}
            onClick={() => {
              if (isRequired(data)) {
                setLoading(true);

                createRequest(data).then(() => {
                  setLoading(false);
                  onDismiss(true);
                  clearForm();
                });
              }
            }}
          >
            {isLoading ? <Spinner size={SpinnerSize.medium} /> : "Provision"}
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </Panel>
  );
};
