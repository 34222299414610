import {
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  IStackTokens,
  Icon,
  Link,
  PrimaryButton,
  Selection,
  ShimmeredDetailsList,
  Stack,
  TextField,
  mergeStyles,
} from "office-ui-fabric-react";
import {
  FileIconType,
  IFileTypeIconOptions,
  getFileTypeIconProps,
} from "@uifabric/file-type-icons";
import React, { useMemo, useState } from "react";

export interface IItem {
  itemId: string;
  itemType: "file" | "folder";
  name: string;
  created?: string;
  modified?: string;
  fileSize?: string;
  onClick?: () => void;
}

export interface IBrowseViewProps {
  folders?: IItem[];
  files?: IItem[];
  isLoading?: boolean;
  restoreItems?: (items: IItem[]) => void;
  hasNextPage?: boolean;
  loadNextPage?: () => Promise<void>;
}

export const BrowseView: React.FC<IBrowseViewProps> = ({
  folders,
  files,
  isLoading,
  restoreItems,
  loadNextPage,
  hasNextPage,
}) => {
  const [filter, setFilter] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);

  const items: IItem[] = [];
  if (!filter) {
    items.push(...(folders ?? []));
    items.push(...(files ?? []));
  } else {
    const filteredFiles =
      files?.filter(file => {
        return file.name.toLowerCase().includes(filter.toLowerCase());
      }) ?? [];

    items.push(...filteredFiles);
  }

  const columns: IColumn[] = [
    {
      key: "icon",
      name: "File Type",
      iconName: "Page",
      isIconOnly: true,
      minWidth: 16,
      maxWidth: 16,
      onRender: (item: IItem) => {
        let options: IFileTypeIconOptions;
        if (item.itemType === "file") {
          const nameParts = item.name.split(".");
          const ext = nameParts[nameParts.length - 1];

          options = { extension: ext };
        } else {
          options = { type: FileIconType.folder };
        }

        return <Icon {...getFileTypeIconProps(options)} />;
      },
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      isResizable: true,
      minWidth: 100,
      maxWidth: 300,
      onRender: item => (
        <Link onClick={() => item.onClick?.()}>{item.name}</Link>
      ),
    },
    {
      key: "modified",
      name: "Modified",
      fieldName: "modified",
      isCollapsable: true,
      isResizable: true,
      minWidth: 70,
    },
    {
      key: "created",
      name: "Created",
      fieldName: "created",
      isCollapsable: true,
      isResizable: true,
      minWidth: 70,
    },
    {
      key: "fileSize",
      name: "File Size",
      fieldName: "fileSize",
      isCollapsable: true,
      isResizable: true,
      minWidth: 60,
    },
  ];

  const selection = useMemo(
    () =>
      new Selection<any>({
        canSelectItem: (item: IItem) => {
          return item.itemType === "file" || item.itemType === "folder";
        },
        getKey: (item: IItem) => item.itemId,
        onSelectionChanged: () => {
                  console.log("selection " + selection);
                  console.log("selection.getSelection() " + selection.getSelection());
          setSelectedItems(selection.getSelection());
        },
      }),
    []
  );

  const button = mergeStyles({
    minWidth: 90,
  });

  const spacing = mergeStyles({
    paddingTop: 10,
  });

  const tokens: IStackTokens = {
    childrenGap: "s1",
  };

  const finalItems: any = items;
  if (hasNextPage && !isLoading) {
    finalItems.push(null);
  }
  return (
    <div className={spacing}>
      <Stack horizontal wrap tokens={tokens}>
        <Stack.Item grow>
          <TextField
            placeholder="Filter files"
            value={filter}
            onChange={(_, newValue?: string) => setFilter(newValue)}
          />
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton
            className={button}
            disabled={selectedItems.length === 0}
            onClick={() => restoreItems?.(selectedItems)}
          >
            Restore
          </PrimaryButton>
        </Stack.Item>
      </Stack>
      <ShimmeredDetailsList
        selection={selection}
        enableShimmer={isLoading}
        items={finalItems}
        setKey="set"
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick
        onRenderCustomPlaceholder={(
          rowProps: IDetailsRowProps,
          index?: number,
          defaultRender?: (props: IDetailsRowProps) => React.ReactNode
        ) => {
          loadNextPage?.();
          return defaultRender?.(rowProps);
        }}
      />
    </div>
  );
};
