export interface ITimezone {
  key: string;
  displayName: string;
}

export const timezones: ITimezone[] = [
  {
    key: "Etc/GMT+12",
    displayName: "(UTC-12:00) International Date Line West",
  },
  {
    key: "Etc/GMT+11",
    displayName: "(UTC-11:00) Coordinated Universal Time-11",
  },
  { key: "Pacific/Honolulu", displayName: "(UTC-10:00) Hawaii" },
  { key: "America/Anchorage", displayName: "(UTC-09:00) Alaska" },
  { key: "America/Tijuana", displayName: "(UTC-08:00) Baja California" },
  {
    key: "America/Los_Angeles",
    displayName: "(UTC-08:00) Pacific Time (US and Canada)",
  },
  { key: "America/Phoenix", displayName: "(UTC-07:00) Arizona" },
  {
    key: "America/Chihuahua",
    displayName: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
  },
  {
    key: "America/Denver",
    displayName: "(UTC-07:00) Mountain Time (US and Canada)",
  },
  { key: "America/Mexico_City", displayName: "(UTC-06:00) Central America" },
  {
    key: "	America/Chicago",
    displayName: "(UTC-06:00) Central Time (US and Canada)",
  },
  {
    key: "America/Monterrey",
    displayName: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
  },
  { key: "America/Regina", displayName: "(UTC-06:00) Saskatchewan" },
  { key: "America/Lima", displayName: "(UTC-05:00) Bogota, Lima, Quito" },
  {
    key: "America/New_York",
    displayName: "(UTC-05:00) Eastern Time (US and Canada)",
  },
  {
    key: "America/Indiana/Indianapolis",
    displayName: "(UTC-05:00) Indiana (East)",
  },
  { key: "America/Caracas", displayName: "(UTC-04:30) Caracas" },
  { key: "America/Asuncion", displayName: "(UTC-04:00) Asuncion" },
  {
    key: "America/Goose_Bay",
    displayName: "(UTC-04:00) Atlantic Time (Canada)",
  },
  { key: "America/Cuiaba", displayName: "(UTC-04:00) Cuiaba" },
  {
    key: "America/Manaus",
    displayName: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
  },
  { key: "America/Santiago", displayName: "(UTC-04:00) Santiago" },
  { key: "America/St_Johns", displayName: "(UTC-03:30) Newfoundland" },
  { key: "America/Sao_Paulo", displayName: "(UTC-03:00) Brasilia" },
  {
    key: "America/Argentina/Buenos_Aires",
    displayName: "(UTC-03:00) Buenos Aires",
  },
  { key: "America/Cayenne", displayName: "(UTC-03:00) Cayenne, Fortaleza" },
  { key: "America/Godthab", displayName: "(UTC-03:00) Greenland" },
  { key: "America/Montevideo", displayName: "(UTC-03:00) Montevideo" },
  { key: "America/Bahia", displayName: "(UTC-03:00) Salvador" },
  {
    key: "Etc/GMT+2",
    displayName: "(UTC-02:00) Coordinated Universal Time-02",
  },
  { key: "America/Noronha", displayName: "(UTC-02:00) Mid-Atlantic" },
  { key: "Atlantic/Azores", displayName: "(UTC-01:00) Azores" },
  { key: "Atlantic/Cape_Verde", displayName: "(UTC-01:00) Cabo Verde" },
  { key: "Etc/UTC", displayName: "(UTC) Coordinated Universal Time" },
  {
    key: "Europe/London",
    displayName: "(UTC) Dublin, Edinburgh, Lisbon, London",
  },
  { key: "Atlantic/Reykjavik", displayName: "(UTC) Monrovia, Reykjavik" },
  {
    key: "Europe/Amsterdam",
    displayName: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    key: "Europe/Belgrade",
    displayName:
      "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    key: "Europe/Brussels",
    displayName: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    key: "Europe/Sarajevo",
    displayName: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  },
  { key: "Africa/Tunis", displayName: "(UTC+01:00) West Central Africa" },
  { key: "Asia/Amman", displayName: "(UTC+02:00) Amman" },
  { key: "Europe/Athens", displayName: "(UTC+02:00) Athens, Bucharest" },
  { key: "Asia/Beirut", displayName: "(UTC+02:00) Beirut" },
  { key: "Africa/Cairo", displayName: "(UTC+02:00) Cairo" },
  { key: "Asia/Damascus", displayName: "(UTC+02:00) Damascus" },
  { key: "Africa/Maputo", displayName: "(UTC+02:00) Harare, Pretoria" },
  {
    key: "Europe/Helsinki",
    displayName: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  { key: "Asia/Jerusalem", displayName: "(UTC+02:00) Jerusalem" },
  { key: "Europe/Sofia", displayName: "(UTC+02:00) E. Europe" },
  { key: "Europe/Kaliningrad", displayName: "(UTC+02:00) Kaliningrad" },
  { key: "Asia/Baghdad", displayName: "(UTC+03:00) Baghdad" },
  { key: "Europe/Istanbul", displayName: "(UTC+03:00) Istanbul" },
  { key: "Asia/Riyadh", displayName: "(UTC+03:00) Kuwait, Riyadh" },
  { key: "Europe/Minsk", displayName: "(UTC+03:00) Minsk" },
  {
    key: "Europe/Moscow",
    displayName: "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
  },
  { key: "Africa/Nairobi", displayName: "(UTC+03:00) Nairobi" },
  { key: "Asia/Tehran", displayName: "(UTC+03:30) Tehran" },
  { key: "Asia/Dubai", displayName: "(UTC+04:00) Abu Dhabi, Muscat" },
  { key: "Europe/Astrakhan", displayName: "(UTC+04:00) Astrakhan, Ulyanovsk" },
  { key: "Asia/Baku", displayName: "(UTC+04:00) Baku" },
  { key: "Europe/Samara", displayName: "(UTC+04:00) Izhevsk, Samara" },
  { key: "Indian/Mauritius", displayName: "(UTC+04:00) Port Louis" },
  { key: "Asia/Tbilisi", displayName: "(UTC+04:00) Tbilisi" },
  { key: "Asia/Yerevan", displayName: "(UTC+04:00) Yerevan" },
  { key: "Asia/Kabul", displayName: "(UTC+04:30) Kabul" },
  { key: "Asia/Yekaterinburg", displayName: "(UTC+05:00) Ekaterinburg" },
  { key: "Asia/Karachi", displayName: "(UTC+05:00) Islamabad, Karachi" },
  { key: "Asia/Tashkent", displayName: "(UTC+05:00) Tashkent" },
  {
    key: "Asia/Kolkata",
    displayName: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  { key: "Asia/Kathmandu", displayName: "(UTC+05:45) Kathmandu" },
  { key: "Asia/Almaty", displayName: "(UTC+06:00) Astana" },
  { key: "Asia/Dhaka", displayName: "(UTC+06:00) Dhaka" },
  { key: "Asia/Omsk", displayName: "(UTC+06:00) Omsk" },
  { key: "Asia/Yangon", displayName: "(UTC+06:30) Yangon (Rangoon)" },
  { key: "Asia/Bangkok", displayName: "(UTC+07:00) Bangkok, Hanoi, Jakarta" },
  { key: "Asia/Barnaul", displayName: "(UTC+07:00) Barnaul, Gorno-Altaysk" },
  { key: "Asia/Krasnoyarsk", displayName: "(UTC+07:00) Krasnoyarsk" },
  { key: "Asia/Novosibirsk", displayName: "(UTC+07:00) Novosibirsk" },
  { key: "Asia/Tomsk", displayName: "(UTC+07:00) Tomsk" },
  {
    key: "Asia/Shanghai",
    displayName: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  },
  { key: "Asia/Irkutsk", displayName: "(UTC+08:00) Irkutsk" },
  { key: "Asia/Singapore", displayName: "(UTC+08:00) Kuala Lumpur, Singapore" },
  { key: "Australia/Perth", displayName: "(UTC+08:00) Perth" },
  { key: "Asia/Taipei", displayName: "(UTC+08:00) Taipei" },
  { key: "Asia/Ulaanbaatar", displayName: "(UTC+08:00) Ulaanbaatar" },
  { key: "Asia/Tokyo", displayName: "(UTC+09:00) Osaka, Sapporo, Tokyo" },
  { key: "Asia/Seoul", displayName: "(UTC+09:00) Seoul" },
  { key: "Asia/Yakutsk", displayName: "(UTC+09:00) Yakutsk" },
  { key: "Australia/Adelaide", displayName: "(UTC+09:30) Adelaide" },
  { key: "Australia/Darwin", displayName: "(UTC+09:30) Darwin" },
  { key: "Australia/Brisbane", displayName: "(UTC+10:00) Brisbane" },
  {
    key: "Australia/Sydney",
    displayName: "(UTC+10:00) Canberra, Melbourne, Sydney",
  },
  { key: "Pacific/Guam", displayName: "(UTC+10:00) Guam, Port Moresby" },
  { key: "Australia/Hobart", displayName: "(UTC+10:00) Hobart" },
  { key: "Asia/Vladivostok", displayName: "(UTC+10:00) Vladivostok" },
  { key: "Asia/Sakhalin", displayName: "(UTC+11:00) Sakhalin" },
  {
    key: "Pacific/Noumea",
    displayName: "(UTC+11:00) Solomon Is., New Caledonia",
  },
  {
    key: "Asia/Anadyr",
    displayName: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
  },
  { key: "Pacific/Auckland", displayName: "(UTC+12:00) Auckland, Wellington" },
  {
    key: "Etc/GMT-12",
    displayName: "(UTC+12:00) Coordinated Universal Time+12",
  },
  { key: "Pacific/Fiji", displayName: "(UTC+12:00) Fiji" },
  { key: "Pacific/Tongatapu", displayName: "(UTC+13:00) Nuku'alofa" },
  { key: "Pacific/Pago_Pago", displayName: "(UTC+13:00) Samoa" },
];

export const setTimezoneOffset = (date: Date) => {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date;
};
