import React from "react";
import {
  mergeStyles,
  IStackTokens,
  Stack,
  Text,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType
} from "office-ui-fabric-react";
import { humanReadableBytes } from "../../ServiceAccountStats/utils";
import { DateTime } from "luxon";

export interface IFileDetails {
  name?: string;
  fileSize?: string;
  created?: string;
  modified?: string;
}

export interface IFileModalDetailsProps {
  file?: IFileDetails;
  isFileLoading?: boolean;
}

export const humanReadableDate = (date?: string): string | undefined => {
  if (date === undefined) {
    return undefined;
  }

  const dt = DateTime.fromISO(date);
  const hrDate = dt.toLocaleString({
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false
  });

  return hrDate;
};

export const FileModalDetails: React.FC<IFileModalDetailsProps> = ({
  file: rawFile,
  isFileLoading
}) => {
  const file: IFileDetails = {
    name: rawFile?.name,
    fileSize:
      rawFile?.fileSize !== undefined
        ? humanReadableBytes(parseInt(rawFile.fileSize))
        : undefined,
    created: humanReadableDate(rawFile?.created),
    modified: humanReadableDate(rawFile?.modified)
  };

  const details = [
    {
      name: "Name",
      key: "name"
    },
    {
      name: "File Size",
      key: "fileSize"
    },
    {
      name: "Created Date",
      key: "created"
    },
    {
      name: "Last Modified",
      key: "modified"
    }
  ] as const;

  const stackTokens: IStackTokens = {
    childrenGap: "m"
  };

  const detailHeader = mergeStyles({
    minWidth: 100,
    textAlign: "right",
    marginRight: 12
  });

  const detailContent = mergeStyles({
    overflowX: "scroll",
    width: "100%"
  });

  const detailTokens: IStackTokens = {
    childrenGap: "s2"
  };

  const shimmerWidths = {
    name: 0.5,
    fileSize: 0.3,
    created: 0.7,
    modified: 1
  };

  const detailShimmerElement = (width: number = 1) => (
    <ShimmerElementsGroup
      shimmerElements={[
        { type: ShimmerElementType.line, width: 160 * width },
        { type: ShimmerElementType.gap, width: "100%" }
      ]}
    />
  );

  return (
    <Stack tokens={stackTokens}>
      {details.map(detail => (
        <div key={detail.key}>
          <Stack horizontal tokens={detailTokens}>
            <Stack.Item className={detailHeader}>
              <Text variant="mediumPlus">{detail.name}</Text>
            </Stack.Item>
            <Stack.Item className={detailContent}>
              <Shimmer
                isDataLoaded={!isFileLoading}
                customElementsGroup={detailShimmerElement(
                  shimmerWidths[detail.key]
                )}
              >
                <Text variant="mediumPlus">{file?.[detail.key]}</Text>
              </Shimmer>
            </Stack.Item>
          </Stack>
        </div>
      ))}
    </Stack>
  );
};
