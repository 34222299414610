import {
  IStackTokens,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "office-ui-fabric-react";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import { DestinationComboBox } from "../Browse/DestinationComboBox";
import { IPaginate } from "../../providers/ApiProvider/ApiClient/models/utils";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { Modal } from "../Modal";
import { pluralise } from "../ServiceAccountStats/utils";
import { useApiClient } from "../../providers/ApiProvider";
import { useMessageBar } from "../../providers/messageBarProvider";

export interface IRestoreItemsModalProps {
  isOpen: boolean;
  onDismiss?: () => void;
  serviceId: string;
  organisationId: string;
  serviceAccountId: string;
  selectedItemIds: string[];
}

interface IServiceAccountState {
  serviceAccounts: IServiceAccount[];
  cursor?: string;
  hasNextPage: boolean;
}

type TServiceAccountAction =
  | {
      type: "MERGE_SERVICE_ACCOUNTS";
      payload: IPaginate<IServiceAccount>;
    }
  | { type: "CLEAR_STATE" };

const initialServiceAccountState: IServiceAccountState = {
  serviceAccounts: [],
  hasNextPage: true,
};

const serviceAccountReducer: React.Reducer<
  IServiceAccountState,
  TServiceAccountAction
> = (prevState, action) => {
  switch (action.type) {
    case "MERGE_SERVICE_ACCOUNTS":
      return {
        ...prevState,
        serviceAccounts: [
          ...prevState.serviceAccounts,
          ...action.payload.nodes,
        ],
        cursor: action.payload.pageInfo.cursor,
        hasNextPage: action.payload.pageInfo.hasNextPage,
      };
    case "CLEAR_STATE":
      return initialServiceAccountState;
    default:
      return prevState;
  }
};

export const RestoreItemsModal: React.FC<IRestoreItemsModalProps> = ({
  isOpen,
  onDismiss,
  serviceId,
  organisationId,
  serviceAccountId,
  selectedItemIds,
}) => {
  const api = useApiClient();
  const { sendMessage } = useMessageBar();

  const [serviceAccountState, dispatchServiceAccount] = useReducer(
    serviceAccountReducer,
    initialServiceAccountState
  );

  const [selectedServiceAccountId, setSelectedServiceAccountId] = useState(
    serviceAccountId
  );

  const [isRestoreLoading, setRestoreLoading] = useState(false);

  // Clear the serviceAccount if the organisationId or serviceId changes
  useEffect(() => {
    dispatchServiceAccount({ type: "CLEAR_STATE" });
  }, [organisationId, serviceAccountId]);

  // Load the serviceAccounts for the organisaion
  useEffect(() => {
    if (serviceAccountState.hasNextPage) {
      api.accounts
        .listServiceAccounts({
          organisationId,
          serviceId,
          cursor: serviceAccountState.cursor,
        })
        .then(res => {
          if (res !== undefined) {
            dispatchServiceAccount({
              type: "MERGE_SERVICE_ACCOUNTS",
              payload: res,
            });
          }
        });
    }
  }, [
    api.accounts,
    organisationId,
    serviceId,
    serviceAccountState.cursor,
    serviceAccountState.hasNextPage,
  ]);

  const itemCount = selectedItemIds.length;
  const isServiceAccountsLoading =
    serviceAccountState.serviceAccounts.length === 0 &&
    !serviceAccountState.hasNextPage;

  const restore = useCallback(async () => {
    setRestoreLoading(true);

    const res = await api.restore.ewsPartialRestore({
      serviceId,
      organisationId,
      serviceAccountId,
      dstServiceAccountId: selectedServiceAccountId,
      itemIds: selectedItemIds,
    });

    if (res) {
      sendMessage({
        messageType: MessageBarType.success,
        text: `Restore scheduled for ${selectedItemIds.length} ${pluralise(
          "item",
          selectedItemIds.length
        )}`,
      });
    } else {
      sendMessage({
        messageType: MessageBarType.error,
        text: "Failed to schedule restore",
      });
    }

    setRestoreLoading(false);
    onDismiss?.();
  }, [
    api.restore,
    serviceId,
    organisationId,
    serviceAccountId,
    selectedServiceAccountId,
    selectedItemIds,
    sendMessage,
    onDismiss,
  ]);

  const tokens: IStackTokens = {
    childrenGap: "s1",
  };

  return (
    <Modal header="Restore" isOpen={isOpen} onDismiss={onDismiss}>
      <Stack tokens={tokens}>
        <Stack.Item align="center">
          <Text variant="xLarge">
            {`Restore ${itemCount} ${pluralise("item", itemCount)}?`}
          </Text>
        </Stack.Item>
        <DestinationComboBox
          serviceAccounts={serviceAccountState.serviceAccounts}
          isServiceAccountsLoading={isServiceAccountsLoading}
          selectedServiceAccountId={selectedServiceAccountId}
          setSelectedServiceAccountId={setSelectedServiceAccountId}
        />
        {isRestoreLoading ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <PrimaryButton
            disabled={isServiceAccountsLoading}
            onClick={() => restore()}
          >
            Restore
          </PrimaryButton>
        )}
      </Stack>
    </Modal>
  );
};
