import { useCallback, useReducer } from "react";

type TForm<F> = {
  data: F;
  validation: {
    [K in keyof F]?: string | undefined;
  };
};

type TFormValidation<F> = {
  [K in keyof F]?: (value: F[K], form: F) => string | undefined;
};

type TAction<TFormState> =
  | {
      type: "UPDATE_FORM";
      payload: { field: keyof TFormState; value: TFormState[keyof TFormState] };
    }
  | { type: "CLEAR_FORM" };

type TUpdateForm<TFormState> = <
  T extends keyof TFormState,
  K extends TFormState[T]
>(payload: {
  field: T;
  value: K;
}) => void;

type TClearForm = () => void;

const evaluateValidation = <T extends any>(
  state: T,
  validation?: TFormValidation<T>
): { [K in keyof T]?: string | undefined } => {
  if (validation === undefined) {
    return {};
  }

  return Object.keys(validation).reduce<{ [K in keyof T]: string | undefined }>(
    (prev, key) => {
      return {
        ...prev,
        [key]: validation?.[key]?.(state[key], state),
      };
    },
    {} as any
  );
};

const initializeValidation = <T extends any>(
  initialState: T,
  validation?: TFormValidation<T>
): { [K in keyof T]?: string | undefined } => {
  if (validation === undefined) {
    return {};
  }

  return evaluateValidation(initialState, validation);
};

const formReducer = <T>(
  initialState: T,
  validation?: TFormValidation<T>
): React.Reducer<TForm<T>, TAction<T>> => (
  prevState: TForm<T>,
  action: TAction<T>
): TForm<T> => {
  switch (action.type) {
    case "UPDATE_FORM":
      const newData = {
        ...prevState.data,
        [action.payload.field]: action.payload.value,
      };

      const newValidation = evaluateValidation(newData, validation);

      return {
        ...prevState,
        data: newData,
        validation: newValidation,
      };
    case "CLEAR_FORM":
      const initialValidation = initializeValidation(initialState, validation);

      return {
        data: initialState,
        validation: initialValidation,
      };
    default:
      return prevState;
  }
};

export const useForm = <TFormState>(opts: {
  initialState: TFormState;
  validation?: TFormValidation<TFormState>;
}): [TForm<TFormState>, TUpdateForm<TFormState>, TClearForm] => {
  const reducer = formReducer(opts.initialState, opts.validation);
  const initialValidation = initializeValidation(
    opts.initialState,
    opts.validation
  );

  const [form, dispatch] = useReducer(reducer, {
    data: opts.initialState,
    validation: initialValidation,
  });

  const updateForm = useCallback<TUpdateForm<TFormState>>(payload => {
    dispatch({ type: "UPDATE_FORM", payload });
  }, []);

  const clearForm = useCallback(() => {
    dispatch({ type: "CLEAR_FORM" });
  }, []);

  return [form, updateForm, clearForm];
};
