import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";
import { IClientOpts } from "..";

export interface IBranding {
  subdomain: string;
  partnerName: string;
  themeColours: {
    primaryColour: string;
    textColour: string;
    backgroundColour: string;
  };
  websiteUrl: string;
  productName?: string;
  helpUrl?: string;
  createdTime: Date;
  updatedTime: Date;
}

export class BrandingModel extends BaseModel {
  private hostname: string;

  constructor(opts: IClientOpts) {
    super(opts);
    this.hostname = opts.hostname;
  }

  public async getPartnerDetails(): Promise<Result<IBranding>> {
    const response = await this.request({
      url: `branding/${this.hostname}`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }
}
