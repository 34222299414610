import {
  IStyle,
  List,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  getTheme,
  mergeStyleSets,
} from "office-ui-fabric-react";
import React, { useMemo, useState } from "react";

import { CreateOrganisationPanel } from "../CreateOrganisationPanel";
import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import store from "../../store";
import { useValidatePermissions } from "../../providers/validatePermissionsProvider";

export interface ICustomersListViewProps {
  organisations?: IOrganisation[];
  parentOrganisations?: IOrganisation[];
  selectOrganisation?: (organisationId: string) => void;
  reloadOrgs?: () => void;
}

interface ICustomersListViewStyles {
  container?: IStyle;
  itemCell?: IStyle;
  itemContent?: IStyle;
  chevron?: IStyle;
}

export const CustomersListView: React.FC<ICustomersListViewProps> = ({
  organisations,
  parentOrganisations,
  selectOrganisation,
  reloadOrgs,
}) => {
  const [filterText, setFilterText] = useState<string>();
  const [isCreateCustomerOpen, setCreateCustomerOpen] = useState(false);

  // An array which combines the parentOrgs and orgs arrays
  const allOrganisations = useMemo(() => {
    const res = [...(organisations ?? []), ...(parentOrganisations ?? [])];

    return res.sort((a, b) => a.name.localeCompare(b.name));
  }, [organisations, parentOrganisations]);

  const onFilterChanged = (event: any, newValue?: string | undefined) => {
    const value = newValue !== "" ? newValue : undefined;
    setFilterText(value);
  };

  const items = organisations
    ?.filter(organisation => {
      if (filterText === undefined) {
        return true;
      }

      return organisation.name.toLowerCase().includes(filterText.toLowerCase());
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const theme = getTheme();

  const classNames = mergeStyleSets({
    container: {},
    itemCell: {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": {
          background: theme.palette.neutralLighter,
        },
      },
    },
    itemContent: {
      marginLeft: 10,
      alignSelf: "center",
      overflow: "hidden",
      flexGrow: 1,
      selectors: {
        ":hover": {
          cursor: "pointer",
        },
      },
    },
  });

  const onRenderCell = (
    item?: IOrganisation,
    index?: number,
    isScrolling?: boolean
  ): React.ReactNode => {
    return (
      <div className={classNames.itemCell}>
        <div
          className={classNames.itemContent}
          onClick={() => item && selectOrganisation?.(item.id)}
        >
          <Text variant="mediumPlus">{item?.name}</Text>
        </div>
      </div>
    );
  };

  const state = store.getState();
  const userId = state.auth.userId;
  const { validate } = useValidatePermissions();
  let createCustomer = <></>;
  const parentOrganisationIds = allOrganisations.map(organisation => {
    return organisation.id;
  });
  const results = allOrganisations.map(org => {
    return validate({
      userId,
      action: "Create",
      resource: "accounts:organisations",
      scope: { organisationId: org.id },
      parentOrganisationIds,
    });
  });
  if (results.includes(true)) {
    createCustomer = (
      <PrimaryButton
        onClick={() => {
          setCreateCustomerOpen(true);
        }}
      >
        Create Customer
      </PrimaryButton>
    );
  }

  return (
    <>
      <div className={classNames.container}>
        <Stack horizontal verticalAlign="end" tokens={{ childrenGap: "s1" }}>
          <Stack.Item grow>
            <TextField label="Filter Customers" onChange={onFilterChanged} />
          </Stack.Item>
          {createCustomer}
        </Stack>
        <List items={items} onRenderCell={onRenderCell} />
      </div>
      <CreateOrganisationPanel
        isOpen={isCreateCustomerOpen}
        onDismiss={shouldReload => {
          setCreateCustomerOpen(false);
          if (shouldReload) {
            reloadOrgs?.();
          }
        }}
        parentOrganisations={allOrganisations}
        // The initial parent is the last element in the parents array
        initialParentOrganisationId={
          parentOrganisations?.[parentOrganisations?.length - 1]?.id
        }
      />
    </>
  );
};
