import { Customer, CustomerHOC } from "./components/Customer";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { AuditPage } from "./pages/audit";
import { BrowsePage } from "./pages/browse";
import { CustomerRolesPage } from "./pages/customerRoles";
import { HomePage } from "./pages/home";
import { IRootState } from "./store";
import { LoginPage } from "./pages/login";
import { NotFound } from "./pages/notFound";
import { PageLayout } from "./components/PageLayout";
import React from "react";
import { ReportingConfig } from "./components/ReportingConfig";
import { SearchPage } from "./pages/search";
import { ServiceAccountPage } from "./pages/serviceAccount";
import { ServiceAccountRestoreHistoryPage } from "./pages/serviceAccountRestoreHistory";
import { ServiceAccountRestorePage } from "./pages/serviceAccountRestore";
import { SignoutPage } from "./pages/signout";
import { TermsAndConditionsPage } from "./pages/termsAndConditions";
import { WelcomePage } from "./pages/welcome";
import { hasRoles } from "./utils/parseJWT";
import { useSelector } from "react-redux";

const PrivateRoute: React.FC<RouteProps> = props => {
  const isAuthed = useSelector((state: IRootState) => {
    return state.auth.loggedIn;
  });

  const { component, ...rest } = props;
  const Component = component as React.FC<RouteComponentProps>;

  if (!isAuthed && props.location) {
    localStorage.setItem("backup365_next_url", props.location.pathname);
  }

  const idToken = localStorage.getItem("backup365_id_token");
  if (isAuthed && idToken && hasRoles(idToken) !== true) {
    return <Route path="/welcome" component={WelcomePage} />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthed ? (
          <PageLayout>
            <Component {...(props as RouteComponentProps)} />
          </PageLayout>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Routes: React.FC = props => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/" exact component={HomePage} />
        <PrivateRoute path="/customers" exact component={Customer} />
        <PrivateRoute
          path="/customers/:organisationId"
          exact
          component={Customer}
        />
        <PrivateRoute
          // TODO might want to redirect this to /customers/:organisationId
          path="/customers/:organisationId/services"
          exact
          component={Customer}
        />
        <PrivateRoute
          path="/customers/:organisationId/services/:serviceName"
          exact
          component={Customer}
        />
        <PrivateRoute
          path="/customers/:organisationId/services/:serviceName/:serviceAccountId"
          exact
          component={ServiceAccountPage}
        />
        <PrivateRoute
          path="/customers/:organisationId/services/:serviceName/:serviceAccountId/browse/:drive"
          exact
          component={BrowsePage}
        />
        <PrivateRoute
          path="/customers/:organisationId/services/:serviceName/:serviceAccountId/search"
          exact
          component={SearchPage}
        />
        <PrivateRoute
          path="/customers/:organisationId/services/:serviceName/:serviceAccountId/restore"
          exact
          component={ServiceAccountRestorePage}
        />
        <PrivateRoute
          path="/customers/:organisationId/services/:serviceName/:serviceAccountId/restore/history"
          exact
          component={ServiceAccountRestoreHistoryPage}
        />
        <PrivateRoute
          path="/customers/:organisationId/reporting"
          exact
          component={CustomerHOC("reporting")}
        />
        <PrivateRoute
          path="/customers/:organisationId/people"
          exact
          component={CustomerHOC("people")}
        />
        <PrivateRoute
          path="/customers/:organisationId/reporting/:reportType/:reportConfigId"
          exact
          component={ReportingConfig}
        />
        <PrivateRoute path="/invite/:inviteId" exact>
          <Redirect to={{ pathname: "/settings/roles" }} />
        </PrivateRoute>
        <PrivateRoute
          path="/settings/roles"
          exact
          component={CustomerRolesPage}
        />
        {/* <PrivateRoute path="/reporting" exact component={ReportingPage} /> */}
        <PrivateRoute path="/audit" exact component={AuditPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/signout" component={SignoutPage} />
        <Route
          path="/termsAndConditions/:organisationId"
          component={TermsAndConditionsPage}
        />
        <Route path="/welcome" component={WelcomePage} />;
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
