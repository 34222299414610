import {
  IRectangle,
  IStyle,
  List,
  Spinner,
  SpinnerSize,
  classNamesFunction,
  getTheme,
  styled,
} from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";

import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { useApiClient } from "../../providers/ApiProvider";
import { useWindowDimensions } from "../../providers/windowDimensionsProvider";

export type TTileHeading =
  | "Billed Units"
  | "Enabled Mailboxes"
  | "Manually Disabled Mailboxes"
  | "Enabled Sites"
  | "Manually Disabled Sites"
  | "Enabled Drives"
  | "Manually Disabled Drives"
  | "% Sync Success 48h";

interface IStatsTilesStyles {
  listGrid: IStyle;
  listTile: IStyle;
  tileSizer: IStyle;
  tilePadder: IStyle;
  tileLabel: IStyle;
  innerText: IStyle;
  innerTextSmall: IStyle;
  listTileSmall: IStyle;
}

interface IStatsTilesProps {
  organisations?: IOrganisation[];
  excludeTiles?: TTileHeading[];
  styles?: IStatsTilesStyles;
}

interface ITileStats {
  billedUnits: number;
  enabledEWSMailboxes: number;
  enabledSharePointSites: number;
  enabledOneDriveSites: number;
  syncSuccess48h: number;
  manuallyDisabledEWSMailboxes: number;
  manuallyDisabledSharePointSites: number;
  manuallyDisabledOneDriveSites: number;
}

interface ITile {
  label: TTileHeading;
  value: number | string | undefined;
}

const statsTilesStyles = (): IStatsTilesStyles => {
  const { palette, fonts } = getTheme();
  return {
    listGrid: {
      overflow: "hidden",
      fontSize: 0,
      position: "relative",
    },
    listTile: {
      textAlign: "center",
      outline: "none",
      position: "relative",
      float: "left",
      background: palette.neutralLighter,
      boxSizing: "border-box",
      border: `8px solid ${palette.white}`,
    },
    listTileSmall: {
      textAlign: "center",
      outline: "none",
      position: "relative",
      float: "left",
      background: palette.neutralLighter,
      boxSizing: "border-box",
      border: `8px solid ${palette.white}`,
    },
    tileSizer: {
      paddingBottom: "100%",
      border: "5px solid #FFF",
    },
    tilePadder: {
      position: "absolute",
      left: 2,
      top: 2,
      right: 2,
      bottom: 2,
      padding: 20,
    },
    tileLabel: {
      background: "rgba(0, 0, 0, 0.3)",
      fontWeight: 800,
      color: "#FFFFFF",
      position: "absolute",
      padding: 10,
      bottom: 0,
      left: 0,
      width: "100%",
      fontSize: fonts.small.fontSize,
      boxSizing: "border-box",
      border: "3px solid #FFF",
    },
    innerText: {
      fontSize: 36,
      position: "relative",
      top: "30%",
    },
    innerTextSmall: {
      fontSize: 36,
      position: "relative",
      top: "5%",
    },
  };
};

const getClassNames = classNamesFunction<{}, IStatsTilesStyles>();

const BaseStatsTiles: React.FC<IStatsTilesProps> = ({
  styles,
  organisations,
  excludeTiles,
}) => {
  const api = useApiClient();

  const [tileStats, setTileStats] = useState<ITileStats>();

  // Make sure that the page is scrolled to the top on load
  // This is here to fix an issue on mobile where on login the home page would be scrolled down
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (organisations === undefined) {
        return;
      }

      // Get the stats for each org
      const allStats = await Promise.all(
        organisations.map(
          async (org): Promise<ITileStats> => {
            const statsResponse = await api.accounts.getOrganisation({
              organisationId: org.id,
              stats: true,
            });

            let stats: ITileStats = {
              billedUnits: 0,
              enabledEWSMailboxes: 0,
              enabledSharePointSites: 0,
              enabledOneDriveSites: 0,
              manuallyDisabledEWSMailboxes: 0,
              manuallyDisabledOneDriveSites: 0,
              manuallyDisabledSharePointSites: 0,
              syncSuccess48h: 0,
            };

            if (
              statsResponse !== undefined &&
              statsResponse.stats !== undefined
            ) {
              stats = statsResponse.stats;
            }

            return stats;
          }
        )
      );

      // Reduce the stats into a single object
      const stats = allStats.reduce<ITileStats>(
        (prev, curr) => {
          return {
            billedUnits: prev.billedUnits + curr.billedUnits,
            enabledEWSMailboxes:
              prev.enabledEWSMailboxes + curr.enabledEWSMailboxes,
            enabledSharePointSites:
              prev.enabledSharePointSites + curr.enabledSharePointSites,
            enabledOneDriveSites:
              prev.enabledOneDriveSites + curr.enabledOneDriveSites,
            manuallyDisabledEWSMailboxes:
              prev.manuallyDisabledEWSMailboxes +
              curr.manuallyDisabledEWSMailboxes,
            manuallyDisabledOneDriveSites:
              prev.manuallyDisabledOneDriveSites +
              curr.manuallyDisabledOneDriveSites,
            manuallyDisabledSharePointSites:
              prev.manuallyDisabledSharePointSites +
              curr.manuallyDisabledSharePointSites,
            syncSuccess48h: prev.syncSuccess48h + curr.syncSuccess48h,
          };
        },
        {
          billedUnits: 0,
          enabledEWSMailboxes: 0,
          enabledSharePointSites: 0,
          enabledOneDriveSites: 0,
          manuallyDisabledEWSMailboxes: 0,
          manuallyDisabledOneDriveSites: 0,
          manuallyDisabledSharePointSites: 0,
          syncSuccess48h: 0,
        }
      );

      setTileStats(stats);
    };

    loadData();
  }, [organisations, api.accounts]);

  const classNames = getClassNames(styles);

  let columnCount = 0;
  let columnWidth = 0;
  let rowHeight = 0;

  const ROWS_PER_PAGE = 3;
  const MAX_ROW_HEIGHT = 250;

  const getPageHeight = (): number => {
    return rowHeight * ROWS_PER_PAGE;
  };

  const getItemCountForPage = (
    itemIndex?: number,
    surfaceRect?: IRectangle
  ): number => {
    if (itemIndex === 0 && surfaceRect) {
      columnCount = Math.ceil(surfaceRect.width / MAX_ROW_HEIGHT);
      columnWidth = Math.floor(surfaceRect.width / columnCount);
      rowHeight = columnWidth;
    }

    return columnCount * ROWS_PER_PAGE;
  };

  const dimensions = useWindowDimensions();
  const width = dimensions?.width;

  const onRenderCell = (
    item: ITile | undefined,
    index: number | undefined
  ): JSX.Element => {
    const label = item?.label;
    const value =
      item?.value !== undefined ? (
        item.value
      ) : (
        <Spinner size={SpinnerSize.large} />
      );

    const innerClassName =
      width && width < 440 ? classNames.innerTextSmall : classNames.innerText;

    const listTile =
      width && width < 440 ? classNames.listTileSmall : classNames.listTile;

    return (
      <div
        className={listTile}
        data-is-focusable={true}
        style={{
          width: 100 / columnCount + "%",
        }}
      >
        <div className={classNames.tileSizer}>
          <div className={classNames.tilePadder}>
            <div className={innerClassName}>{value}</div>

            <span className={classNames.tileLabel}>{label}</span>
          </div>
        </div>
      </div>
    );
  };

  const syncPercent = tileStats
    ? Math.floor(
        (tileStats.syncSuccess48h /
          (tileStats.enabledEWSMailboxes +
            tileStats.enabledSharePointSites +
            tileStats.enabledOneDriveSites)) *
          100
      )
    : undefined;

  const items: ITile[] = [
    { label: "Billed Units", value: tileStats?.billedUnits },
    {
      label: "% Sync Success 48h",
      value: syncPercent ? `${syncPercent?.toString()}%` : undefined,
    },
    { label: "Enabled Mailboxes", value: tileStats?.enabledEWSMailboxes },
    {
      label: "Manually Disabled Mailboxes",
      value: tileStats?.manuallyDisabledEWSMailboxes,
    },
    { label: "Enabled Sites", value: tileStats?.enabledSharePointSites },
    {
      label: "Manually Disabled Sites",
      value: tileStats?.manuallyDisabledSharePointSites,
    },
    { label: "Enabled Drives", value: tileStats?.enabledOneDriveSites },
    {
      label: "Manually Disabled Drives",
      value: tileStats?.manuallyDisabledOneDriveSites,
    },
  ];

  const finalItems = items.filter(tile =>
    excludeTiles ? !excludeTiles.includes(tile.label) : true
  );

  return (
    <List
      className={classNames.listGrid}
      items={finalItems}
      getItemCountForPage={getItemCountForPage}
      getPageHeight={getPageHeight}
      renderedWindowsAhead={4}
      onRenderCell={onRenderCell}
    />
  );
};

export const StatsTiles = styled(BaseStatsTiles, statsTilesStyles);
