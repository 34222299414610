import {
  AnimationStyles,
  Checkbox,
  ComboBox,
  IComboBoxOption,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
  getTheme,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, { useCallback, useEffect, useState } from "react";

import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { mergeStyles } from "office-ui-fabric-react";
import { useApiClient } from "../../providers/ApiProvider";
import { useForm } from "../../hooks/useForm";
import { useMessageBar } from "../../providers/messageBarProvider";
import { validateEmail } from "../../utils/validateEmail";

export interface ICreateOrganisationPanelProps {
  isOpen: boolean;
  onDismiss: (shouldReload?: boolean) => void;
  parentOrganisations?: IOrganisation[];
  initialParentOrganisationId?: string;
}

interface IFormState {
  organisationName?: string;
  parentOrganisationId?: string;
  publicNotes?: string;
  shouldProvision: boolean;
  iul: boolean;
  nfp: boolean;
  provisionMailbox: boolean;
  provisionSharepoint: boolean;
  tenant?: string;
  credentialEmail?: string;
  credentialPassword?: string;
  reportingEmail?: string;
}

interface IRequiredFormState extends IFormState {
  tenant: string;
  reportingEmail: string;
}

const isProvisioningConfigValid = (
  form: IFormState
): form is IRequiredFormState => {
  return (
    form.shouldProvision &&
    !(!form.provisionMailbox && !form.provisionSharepoint) &&
    form.tenant !== undefined &&
    form.reportingEmail !== undefined
  );
};

export const CreateOrganisationPanel: React.FC<ICreateOrganisationPanelProps> = ({
  isOpen,
  onDismiss,
  parentOrganisations,
  initialParentOrganisationId,
}) => {
  const theme = getTheme();
  const api = useApiClient();
  const { sendMessage } = useMessageBar();

  const [
    { data: form, validation: errorMessages },
    updateForm,
    clearForm,
  ] = useForm<IFormState>({
    initialState: {
      parentOrganisationId: initialParentOrganisationId,
      shouldProvision: false,
      provisionMailbox: true,
      provisionSharepoint: true,
      iul: false,
      nfp: false,
    },
    validation: {
      organisationName: val => {
        if (val === undefined || val.trim() === "") {
          return "Customer Name is required";
        }
      },
      parentOrganisationId: val => {
        if (val === undefined) {
          return "Parent Customer is required";
        }
      },
      credentialEmail: (val, form) => {
        if (val !== undefined && !validateEmail(val)) {
          return "Must be a valid email address";
        } else if (val === undefined && form.credentialPassword !== undefined) {
          return "Must include email address if password is included";
        }
      },
      credentialPassword: (val, form) => {
        if (val === undefined && form.credentialEmail !== undefined) {
          return "Must include password if email address is included";
        }
      },
      reportingEmail: val => {
        if (val !== undefined && !validateEmail(val)) {
          return "Must be a valid email address";
        }
      },
    },
  });

  const [isCreateLoading, setCreateLoading] = useState(false);
  const [progressText, setProgressText] = useState<string>();

  // Update the form parentOrganisationId whenever the prop changes
  useEffect(() => {
    updateForm({
      field: "parentOrganisationId",
      value: initialParentOrganisationId,
    });
  }, [initialParentOrganisationId, updateForm]);

  const createOrganisation = useCallback(
    async (opts: IFormState) => {
      if (
        opts.organisationName === undefined ||
        opts.parentOrganisationId === undefined
      ) {
        // Do nothing. This function should not be called if required fields are missing
        return;
      }

      setProgressText("Creating customer...");

      const orgResponse = await api.accounts.createOrganisation({
        name: opts.organisationName.trim(),
        parentOrganisationId: opts.parentOrganisationId,
        publicNotes: opts.publicNotes?.trim(),
        iul: opts.iul,
        nfp: opts.nfp,
      });

      if (orgResponse.isErr()) {
        sendMessage({
          messageType: MessageBarType.error,
          text: orgResponse.message,
        });

        onDismiss();
        return;
      }

      // Make sure fields are valid before provisioning
      if (isProvisioningConfigValid(opts)) {
        const newOrg = orgResponse.value;

        const credentials =
          opts.credentialEmail !== undefined &&
          opts.credentialPassword !== undefined
            ? {
                email: opts.credentialEmail.trim(),
                password: opts.credentialPassword,
              }
            : undefined;

        const provisioningConfig = {
          tenantUuid: opts.tenant.trim(),
          reportingConfig: {
            recipients: [opts.reportingEmail.trim()],
            weekly: true,
            daily: false,
            events: true,
          },
          credentials,
        };

        if (opts.provisionMailbox) {
          setProgressText("Provisioning Mailbox service...");

          const mailboxResponse = await api.provisioning.requestProvisioning({
            serviceId: EWS_SERVICE_ID,
            organisationId: newOrg.id,
            ...provisioningConfig,
          });

          if (mailboxResponse.isErr()) {
            sendMessage({
              messageType: MessageBarType.error,
              text: `Failed to provision Mailbox service with error - ${mailboxResponse.message}`,
            });
          }
        }

        if (opts.provisionSharepoint) {
          setProgressText("Provisioning SharePoint/OneDrive service...");

          const sharepointRepsonse = await api.provisioning.requestProvisioning(
            {
              serviceId: SHAREPOINT_SERVICE_ID,
              organisationId: newOrg.id,
              ...provisioningConfig,
            }
          );

          if (sharepointRepsonse.isErr()) {
            sendMessage({
              messageType: MessageBarType.error,
              text: `Failed to provision SharePoint/OneDrive service with error - ${sharepointRepsonse.message}`,
            });
          }
        }
      }

      sendMessage({
        messageType: MessageBarType.success,
        text: `Successfully created new customer: ${opts.organisationName}`,
      });

      onDismiss(true);
      setProgressText(undefined);
      clearForm();
    },
    [api, sendMessage, onDismiss, clearForm]
  );

  const buttonStyles = mergeStyles({
    paddingTop: 12,
  });

  const fontColor = mergeStyles({
    color: theme.palette.themePrimary,
  });

  const parentOptions: IComboBoxOption[] =
    parentOrganisations?.map(org => ({
      key: org.id,
      text: org.name,
    })) ?? [];

  const isErrors =
    errorMessages.organisationName !== undefined ||
    errorMessages.parentOrganisationId !== undefined ||
    errorMessages.tenant !== undefined ||
    errorMessages.credentialEmail !== undefined ||
    errorMessages.credentialPassword !== undefined ||
    errorMessages.reportingEmail !== undefined ||
    (form.shouldProvision && !isProvisioningConfigValid(form));

  const animate = mergeStyles({
    ...AnimationStyles.slideDownIn20,
  });

  return (
    <Panel
      headerText="Create Customer"
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={() => {
        clearForm();
        onDismiss();
      }}
    >
      <Stack tokens={{ childrenGap: "s2", padding: "s1" }}>
        <TextField
          label="Customer Name"
          required
          value={form.organisationName ?? ""}
          onChange={(ev, newValue) => {
            let value = newValue;
            if (newValue === "") {
              value = undefined;
            }
            updateForm({ field: "organisationName", value });
          }}
          errorMessage={errorMessages.organisationName}
          disabled={isCreateLoading}
        />
        <ComboBox
          allowFreeform
          required
          label="Parent Customer"
          options={parentOptions}
          selectedKey={form.parentOrganisationId}
          onChange={(ev, option) => {
            if (option !== undefined) {
              updateForm({
                field: "parentOrganisationId",
                // We cast to string here because the IComboBox key can be a string or a number,
                // but it is safe because we set the keys to organistaion.id which is always a string
                value: option.key as string,
              });
            }
          }}
          errorMessage={errorMessages.parentOrganisationId}
          disabled={isCreateLoading}
        />
        <TextField
          multiline
          label="Public Notes"
          value={form.publicNotes ?? ""}
          onChange={(ev, newValue) => {
            let value = newValue;
            if (value === "") {
              value = undefined;
            }

            updateForm({ field: "publicNotes", value });
          }}
          disabled={isCreateLoading}
        />
        <Checkbox
          styles={{ root: { paddingTop: 8 } }}
          label="IUL"
          checked={form.iul}
          onChange={(ev, checked) => {
            if (checked !== undefined) {
              updateForm({ field: "iul", value: checked });
            }
          }}
          disabled={isCreateLoading}
        />
        <Checkbox
          styles={{ root: { paddingTop: 8 } }}
          label="NFP"
          checked={form.nfp}
          onChange={(ev, checked) => {
            if (checked !== undefined) {
              updateForm({ field: "nfp", value: checked });
            }
          }}
          disabled={isCreateLoading}
        />
        <Checkbox
          styles={{ root: { paddingTop: 8 } }}
          label="Provision Services"
          onChange={(ev, checked) => {
            if (checked !== undefined) {
              updateForm({ field: "shouldProvision", value: checked });
            }
          }}
          disabled={isCreateLoading}
        />
        {form.shouldProvision ? (
          <div className={animate}>
            <Separator />
            <Stack tokens={{ childrenGap: "m" }}>
              <Stack tokens={{ childrenGap: "s1" }}>
                <Text variant="medium">
                  <b>Services</b>
                </Text>
                <Checkbox
                  label="Mailbox"
                  checked={form.provisionMailbox}
                  onChange={(ev, checked) => {
                    if (checked !== undefined) {
                      updateForm({ field: "provisionMailbox", value: checked });
                    }
                  }}
                  disabled={isCreateLoading}
                />
                <Checkbox
                  label="SharePoint/OneDrive"
                  checked={form.provisionSharepoint}
                  onChange={(ev, checked) => {
                    if (checked !== undefined) {
                      updateForm({
                        field: "provisionSharepoint",
                        value: checked,
                      });
                    }
                  }}
                  disabled={isCreateLoading}
                />
              </Stack>
              <TextField
                required
                label="Office365 Tenant ID"
                placeholder="ebea8edd-f288-43d7-a287-12c25197f8fc"
                value={form.tenant ?? ""}
                onChange={(ev, newValue) => {
                  let value = newValue;
                  if (value === "") {
                    value = undefined;
                  }

                  updateForm({ field: "tenant", value });
                }}
                disabled={isCreateLoading}
                errorMessage={errorMessages.tenant}
              />
              <Stack>
                <Text>
                  <b>Global Administrator Credentials</b>
                </Text>
                <TextField
                  label="Email"
                  value={form.credentialEmail ?? ""}
                  onChange={(ev, newValue) => {
                    let value = newValue;
                    if (value === "") {
                      value = undefined;
                    }

                    updateForm({ field: "credentialEmail", value });
                  }}
                  disabled={isCreateLoading}
                  errorMessage={errorMessages.credentialEmail}
                />
                <TextField
                  type="password"
                  label="Password"
                  value={form.credentialPassword ?? ""}
                  onChange={(ev, newValue) => {
                    let value = newValue;
                    if (value === "") {
                      value = undefined;
                    }

                    updateForm({ field: "credentialPassword", value });
                  }}
                  disabled={isCreateLoading}
                  errorMessage={errorMessages.credentialPassword}
                />
              </Stack>
              <TextField
                required
                label="Default Reporting Email"
                value={form.reportingEmail ?? ""}
                onChange={(ev, newValue) => {
                  let value = newValue;
                  if (value === "") {
                    value = undefined;
                  }

                  updateForm({ field: "reportingEmail", value });
                }}
                disabled={isCreateLoading}
                errorMessage={errorMessages.reportingEmail}
              />
            </Stack>
            <Separator />
          </div>
        ) : (
          undefined
        )}

        <Stack
          className={buttonStyles}
          tokens={{ childrenGap: "m" }}
          horizontal
          horizontalAlign="end"
          verticalAlign="end"
        >
          {progressText ? (
            <Text variant="smallPlus" className={fontColor}>
              {progressText}
            </Text>
          ) : (
            undefined
          )}
          <PrimaryButton
            disabled={isErrors || isCreateLoading}
            onClick={() => {
              // If this condition evaluates to false, we will just do nothing
              // However, this should never occur as the button will be disabled
              if (
                form.organisationName !== undefined &&
                form.parentOrganisationId !== undefined
              ) {
                setCreateLoading(true);

                createOrganisation(form).then(() => {
                  setCreateLoading(false);
                });
              }
            }}
          >
            {isCreateLoading ? <Spinner size={SpinnerSize.medium} /> : "Create"}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Panel>
  );
};
