import React, { useEffect } from 'react';
import { useWebSocket } from './WebSocketContext';

interface WebSocketConfigProps {
    onMessage: (message: any) => void;
}

const WebSocketConfig: React.FC<WebSocketConfigProps> = ({ onMessage }) => {
    const { socket } = useWebSocket();

    useEffect(() => {
        if (!socket) return;

        const handleSocketMessage = (event: MessageEvent) => {
            const receivedMessage = JSON.parse(event.data);
            onMessage(receivedMessage);
        };

        socket.addEventListener('message', handleSocketMessage);

        return () => {
            socket.removeEventListener('message', handleSocketMessage);
        };
    }, [socket, onMessage]);

    return null; // This component doesn't render anything
};

export default WebSocketConfig;
