import React from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allStoreActions } from "../store";

export const SignoutPage = () => {
  const dispatch = useDispatch();
  dispatch(allStoreActions.auth.setIsLoggedOut());

  return <Redirect to={"/login"} />;
};
