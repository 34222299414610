import { BaseModel } from "./base";
import { isAxiosError } from "./utils";

export interface ILoginResponse {
  idToken?: string;
  refreshToken?: string;
  expiresIn: number;
  code?: string;
  message: string;
}

export class PublicModel extends BaseModel {
  public async login({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<ILoginResponse> {
    try {
      const response = await this.request({
        url: "/login",
        method: "post",
        data: {
          username,
          password,
        },
      });
      return response.data;
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        return err.response.data.message;
      } else {
        return err.message;
      }
    }
  }
}
