import React, { useState, useEffect } from "react";
import { useRequesterClient } from "../../providers/RequesterProvider";
import {
  IOrganisation,
  IServiceAccount
} from "../../providers/ApiProvider/ApiClient/models/accounts";
import { IBreadcrumbItem, Breadcrumb } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import { useApiClient } from "../../providers/ApiProvider";

export interface IServiceAccountBreadCrumbsProps {
  organisationId: string;
  serviceName: string;
  serviceAccountId: string;
  serviceId: string;
  linkServiceAccount?: boolean;
}

const serviceNameMap: { [k: string]: string } = {
  mailbox: "Mailbox",
  sharepoint: "SharePoint",
  onedrive: "OneDrive"
};

export const ServiceAccountBreadCrumbs: React.FC<IServiceAccountBreadCrumbsProps> = ({
  organisationId,
  serviceName,
  serviceId,
  serviceAccountId,
  linkServiceAccount
}) => {
  const history = useHistory();
  const requester = useRequesterClient();
  const api = useApiClient();

  const [organisation, setOrganistaion] = useState<IOrganisation>();
  const [serviceAccount, setServiceAccount] = useState<IServiceAccount>();

  useEffect(() => {
    requester.accounts.getOrganisation({ organisationId }).then(res => {
      setOrganistaion(res);
    });
  }, [organisationId, requester.accounts]);

  useEffect(() => {
    api.accounts
      .getServiceAccount({
        organisationId,
        serviceId,
        serviceAccountId
      })
      .then(res => {
        setServiceAccount(res);
      });
  }, [api, organisationId, serviceId, serviceAccountId]);

  const items: IBreadcrumbItem[] = [
    {
      text: organisation?.name ?? "",
      key: "organisation",
      onClick: () => {
        history.push(`/customers/${organisationId}`);
      }
    },
    {
      text: serviceNameMap[serviceName] ?? "",
      key: "service",
      onClick: () => {
        history.push(`/customers/${organisationId}/services/${serviceName}`);
      }
    },
    {
      text: serviceAccount?.displayName ?? "",
      key: "serviceAccount",
      onClick: linkServiceAccount
        ? () => {
            history.push(
              `/customers/${organisationId}/services/${serviceName}/${serviceAccountId}`
            );
          }
        : undefined
    }
  ];

  return <Breadcrumb items={items} />;
};
