import React from "react";
import {
  Stack,
  IStackTokens,
  VirtualizedComboBox,
  IComboBoxOption,
  PrimaryButton,
  Shimmer,
  Spinner,
  SpinnerSize
} from "office-ui-fabric-react";
import {
  IFileItem,
  IVersion
} from "../../../providers/ApiProvider/ApiClient/models/browse";
import { FileModalDetails } from "./FileModalDetails";
import { DateTime } from "luxon";
import { IServiceAccount } from "../../../providers/ApiProvider/ApiClient/models/accounts";
import { Modal } from "../../Modal";
import { comboBoxElements } from "../ComboBoxShimmer";
import { DestinationComboBox } from "../DestinationComboBox";

export interface IFileModalViewProps {
  isOpen: boolean;
  onDismiss?: () => void;
  file?: IFileItem;
  versions?: IVersion[];
  selectedVersionId?: string;
  isVersionsLoading?: boolean;
  selectVersion?: (versionId: string | undefined) => void;
  serviceAccounts: IServiceAccount[];
  isServiceAccountsLoading?: boolean;
  selectedServiceAccountId?: string;
  setSelectedServiceAccountId?: (serviceAccountId: string) => void;
  restore?: () => void;
  isLoadingRestore?: boolean;
}

export const humanReadableDate = (date: string): string => {
  const dt = DateTime.fromISO(date);
  const hrDate = dt.toLocaleString({
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false
  });

  return hrDate;
};

export const FileModalView: React.FC<IFileModalViewProps> = ({
  isOpen,
  onDismiss,
  file,
  versions,
  selectedVersionId,
  isVersionsLoading,
  selectVersion,
  serviceAccounts,
  isServiceAccountsLoading,
  setSelectedServiceAccountId,
  selectedServiceAccountId,
  restore,
  isLoadingRestore
}) => {
  const outerTokens: IStackTokens = {
    childrenGap: "m"
  };

  const innerTokens: IStackTokens = {
    childrenGap: "l1",
    padding: "m"
  };

  const stackTokens: IStackTokens = {
    childrenGap: "s1"
  };

  const versionOptions: IComboBoxOption[] =
    versions?.map(version => ({
      key: version.versionId,
      text: humanReadableDate(version.timestamp)
    })) ?? [];

  const fileDetails = {
    name: file?.item?.fileName,
    fileSize: file?.item?.fileSize,
    created: file?.item?.timeCreated,
    modified: file?.item?.timeLastModified
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} header="Restore Item">
      <Stack tokens={outerTokens}>
        <Stack horizontal wrap tokens={innerTokens}>
          <Stack.Item grow>
            <FileModalDetails
              file={fileDetails}
              isFileLoading={file?.item === undefined || isVersionsLoading}
            />
          </Stack.Item>
          <Stack.Item grow>
            <Stack tokens={stackTokens}>
              <Shimmer
                isDataLoaded={!isVersionsLoading}
                customElementsGroup={comboBoxElements}
              >
                <VirtualizedComboBox
                  label="Select a version"
                  options={versionOptions}
                  selectedKey={selectedVersionId}
                  onChange={(_, option?: IComboBoxOption) => {
                    if (option) {
                      // Cast to string because our keys are always strings
                      selectVersion?.(option.key as string);
                    }
                  }}
                  useComboBoxAsMenuWidth
                />
              </Shimmer>
              <DestinationComboBox
                serviceAccounts={serviceAccounts}
                isServiceAccountsLoading={isServiceAccountsLoading}
                selectedServiceAccountId={selectedServiceAccountId}
                setSelectedServiceAccountId={setSelectedServiceAccountId}
              />
            </Stack>
          </Stack.Item>
        </Stack>
        {isLoadingRestore ? (
          <Spinner size={SpinnerSize.large} />
        ) : (
          <PrimaryButton
            disabled={isVersionsLoading || isServiceAccountsLoading}
            onClick={() => restore?.()}
          >
            Restore
          </PrimaryButton>
        )}
      </Stack>
    </Modal>
  );
};
