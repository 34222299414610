import React from "react";
import { IFormState, TFormDates } from "../types";
import { Stack, mergeStyles, DatePicker } from "office-ui-fabric-react";
import { itemStyle } from ".";

export interface IDateItemProps {
  formState: IFormState;
  label: string;
  fieldName: TFormDates;
}

export const DateItemHOC = (
  updateDate: (fieldName: keyof IFormState) => any
): React.FC<IDateItemProps> => ({ formState, label, fieldName }) => {
  const item = mergeStyles(itemStyle);

  return (
    <Stack.Item className={item} grow>
      <DatePicker
        label={label}
        value={formState[fieldName]}
        onSelectDate={updateDate(fieldName)}
        formatDate={(date?: Date) => {
          if (date) {
            return date.toLocaleDateString();
          } else {
            return "";
          }
        }}
        showCloseButton
        allowTextInput
        maxDate={new Date()}
      />
    </Stack.Item>
  );
};
