import {
  DetailsListLayoutMode,
  IColumn,
  Selection,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
  mergeStyles,
} from "office-ui-fabric-react";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import React, { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { IServiceAccountError } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { useApiClient } from "../../providers/ApiProvider";
import { useHistory } from "react-router-dom";

interface IAccountErrorsProps {
  organisations?: IOrganisation[];
}

export interface IAccountErrorsViewColumn extends IColumn {
  key: string;
  name: string;
  isSortable?: true;
}

interface IServiceAccountErrorWithOrg extends IServiceAccountError {
  organisationName: string;
}

interface IErrorItem {
  organisationName: string;
  identifier: string;
  lastSyncTime: string;
  service: string;
  errorDescription: string;
  [key: string]: string;
}

export const AccountErrors: React.FC<IAccountErrorsProps> = ({
  organisations,
}) => {
  const api = useApiClient();

  const [isLoading, setIsLoading] = useState(true);
  const [accountErrors, setAccountErrors] = useState<
    IServiceAccountErrorWithOrg[]
  >([]);
  const [columns, setColumns] = useState(defaultColumns());
  const [filterQuery, setFilterQuery] = useState<string | undefined>();

  const onFilterChange = (ev: any, value: string | undefined) => {
    setFilterQuery(value);
  };

  const history = useHistory();

  const container = mergeStyles({
    maxHeight: "55vh",
    overflowY: "scroll",
  });

  const selection = useMemo(
    () =>
      new Selection<any>({
        onSelectionChanged: () => {
          const sel = selection.getSelection()[0];
          if (!sel) {
            return;
          }
          const pathname = `/customers/${
            sel.organisationId
          }/services/${sel.service.toLowerCase()}/${sel.id}`;
          history.push({ pathname });
        },
      }),
    [history],
  );

  useEffect((): void => {
    const loadData = async () => {
      if (!organisations) {
        return;
      }
      const allAccountErrors: IServiceAccountErrorWithOrg[][] = await Promise.all(
        organisations.map(async org => {
          const orgErrors = await api.accounts.getOrganisationErrors({
            organisationId: org.id,
          });
          if (orgErrors) {
            const errorsWithOrgName = await Promise.all(
              orgErrors.errors.map(async request => {
                const org = await api.accounts.getOrganisation({
                  organisationId: request.organisationId,
                });
                const name = org?.name ?? "";
                return {
                  ...request,
                  organisationName: name,
                };
              }),
            );
            return errorsWithOrgName;
          } else {
            return [];
          }
        }),
      );
      const accountErrors = allAccountErrors.reduce((prev, curr) => {
        return prev.concat(curr);
      }, []);
      setAccountErrors(accountErrors);
      setIsLoading(false);
    };

    loadData();
  }, [organisations, api.accounts]);

  const onSelectColumn = (ev?: any, column?: IAccountErrorsViewColumn) => {
    if (columns && column && column.isSortable) {
      const newCols = columns.map(col => {
        if (col.key === column.key) {
          const isSortedDescending = column.isSorted
            ? !column.isSortedDescending
            : false;

          return {
            ...col,
            isSorted: true,
            isSortedDescending,
          };
        }

        return {
          ...col,
          isSorted: false,
          isSortedDescending: false,
        };
      });

      setColumns(newCols);
    }
  };

  const errors = prepareItems({ accountErrors, filterQuery, columns });

  return (
    <Stack>
      <Text variant="xxLarge">Current Issues</Text>
      <TextField label="Filter" onChange={onFilterChange} />
      <ShimmeredDetailsList
        className={container}
        items={errors}
        enableShimmer={isLoading}
        columns={columns}
        onColumnHeaderClick={onSelectColumn}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
      />
    </Stack>
  );
};

const defaultColumns = (): IAccountErrorsViewColumn[] => {
  return [
    {
      key: "organisationName",
      name: "Customer",
      fieldName: "organisationName",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSortable: true,
      isSorted: true,
      isSortedDescending: true,
    },
    {
      key: "identifier",
      name: "Account",
      fieldName: "identifier",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isSortable: true,
    },
    {
      key: "service",
      name: "Service",
      fieldName: "service",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSortable: true,
    },
    {
      key: "lastSyncTime",
      name: "Last Backup",
      fieldName: "lastSyncTime",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSortable: true,
      isCollapsable: true,
    },
    {
      key: "errorDescription",
      name: "Description",
      fieldName: "errorDescription",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isSortable: true,
      isCollapsable: true,
    },
  ];
};

export const prepareItems = ({
  accountErrors,
  filterQuery,
  columns,
}: {
  accountErrors: IServiceAccountErrorWithOrg[];
  filterQuery?: string;
  columns?: IAccountErrorsViewColumn[];
}) => {
  let items: IErrorItem[] = [];

  items = accountErrors.map(item => {
    let service: string = "";
    if (item.serviceId === SHAREPOINT_SERVICE_ID) {
      service = "SharePoint";
      if (item.identifier.includes("my.sharepoint.com")) {
        service = "OneDrive";
      }
    } else if (item.serviceId === EWS_SERVICE_ID) {
      service = "Mailbox";
    }

    const errorDescription =
      item.errorType === "ACCOUNT_NOT_SYNCED"
        ? "Account not backed up in 24 hours"
        : "Unknown error";

    return {
      lastSyncTime: DateTime.fromISO(item.lastSyncTime).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      }),
      service,
      errorDescription,
      identifier: item.identifier,
      organisationId: item.organisationId,
      id: item.serviceAccountId,
      organisationName: item.organisationName,
    };
  });

  let finalItems = items;

  if (filterQuery) {
    finalItems = finalItems.filter(item =>
      Object.values(item)
        .map(i => {
          return i.toLowerCase().includes(filterQuery.toLowerCase());
        })
        .includes(true),
    );
  }

  if (columns) {
    for (const column of columns) {
      if (column.isSorted) {
        finalItems = finalItems.sort((a, b) => {
          let res = 0;

          if (column.fieldName === "lastSyncTime") {
            res =
              new Date(a.lastSyncTime).getTime() -
              new Date(b.lastSyncTime).getTime();
          } else {
            res = a[column.key] > b[column.key] ? -1 : 1;
          }

          if (column.isSortedDescending) {
            res *= -1;
          }

          return res;
        });
      }
    }
  }

  return finalItems;
};
