import {
  IStackTokens,
  Stack,
  Text,
  getTheme,
  mergeStyles,
} from "office-ui-fabric-react";

import React from "react";

export interface IFormFieldProps {
  title?: string;
  errorMessage?: string;
  horizontal?: boolean;
}

export const FormField: React.FC<IFormFieldProps> = ({
  title,
  errorMessage,
  horizontal = false,
  children,
}) => {
  const tokens: IStackTokens = {
    childrenGap: "s1",
  };

  const fieldStyle = mergeStyles({ width: "45%", minWidth: 300 });

  const theme = getTheme();
  const errorTextStyles = mergeStyles({
    color: theme.semanticColors.errorText,
  });

  return (
    <Stack className={fieldStyle} tokens={tokens} horizontal={horizontal} grow>
      {title ? <Text variant="xLarge">{title}</Text> : undefined}
      {children}
      {errorMessage ? (
        <Text className={errorTextStyles} variant="small">
          {errorMessage}
        </Text>
      ) : (
        undefined
      )}
    </Stack>
  );
};
