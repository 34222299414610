import {
  IStackTokens,
  Separator,
  Shimmer,
  ShimmerElementType,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  Toggle,
  TooltipHost,
  mergeStyles,
} from "office-ui-fabric-react";
import React, { useState } from "react";

import EnforcePermissions from "../Permissions";

export interface IDetailsProps {
  serviceAccountType: "Drive" | "Site" | "Mailbox";
  details: Record<string, string | undefined>;
  isLoadingDetails?: boolean;
  enabled?: boolean;
  toggleEnabled?: (checked: boolean) => void;
  isEnabledLoading?: boolean;
  organisationId: string;
  serviceId: string;
}

const randPercentage = (): string => {
  const max = 100;
  const min = 20;

  const val = Math.floor(Math.random() * (max - min) + min);

  return `${val}%`;
};

export const Details: React.FC<IDetailsProps> = ({
  serviceAccountType,
  details,
  enabled,
  toggleEnabled,
  isLoadingDetails,
  isEnabledLoading,
  organisationId,
  serviceId,
}) => {
  const detailTokens: IStackTokens = {
    childrenGap: "s2",
    padding: "s2",
  };

  const stackTokens: IStackTokens = {
    childrenGap: "s2",
    padding: "s2",
  };

  const stack = mergeStyles({
    paddingBottom: 16,
  });

  const detailHeader = mergeStyles({
    width: 70,
    minWidth: 70,
    textAlign: "right",
    marginRight: 24,
  });

  const detailContent = mergeStyles({
    overflowX: "scroll",
    width: "100%",
  });

  const spinner = mergeStyles({
    alignItems: "flex-start",
  });

  const enabledHeight = mergeStyles({
    minHeight: 36,
  });

  const [permissionDisabled, setPermissionDisabled] = useState<boolean>();

  return (
    <>
      <Text variant="xxLarge">{serviceAccountType} Details</Text>
      <Stack className={stack} tokens={stackTokens}>
        <Separator />
        {Object.keys(details).map(key => (
          <div key={key}>
            <Stack horizontal tokens={detailTokens}>
              <Stack.Item className={detailHeader}>
                <Text variant="large">{key}</Text>
              </Stack.Item>
              <Stack.Item className={detailContent}>
                <Shimmer
                  isDataLoaded={!isLoadingDetails}
                  shimmerElements={[
                    {
                      type: ShimmerElementType.line,
                      width: randPercentage(),
                    },
                    { type: ShimmerElementType.gap, width: "100%" },
                  ]}
                >
                  <TooltipHost content={details[key]}>
                    <Text variant="large">{details[key]}</Text>
                  </TooltipHost>
                </Shimmer>
              </Stack.Item>
            </Stack>
            <Separator />
          </div>
        ))}
        <Stack horizontal tokens={detailTokens} className={enabledHeight}>
          <Stack.Item className={detailHeader}>
            <Text variant="large">Enabled</Text>
          </Stack.Item>
          <Stack.Item className={detailContent}>
            <Shimmer
              isDataLoaded={!isLoadingDetails}
              shimmerElements={[
                {
                  type: ShimmerElementType.line,
                  width: "5%",
                },
                { type: ShimmerElementType.gap, width: "100%" },
              ]}
            >
              {isEnabledLoading ? (
                <Spinner className={spinner} size={SpinnerSize.medium} />
              ) : (
                <EnforcePermissions
                  organisationId={organisationId}
                  serviceId={serviceId}
                  action="Update"
                  resource="accounts:serviceAccounts"
                  enforceType="DISABLE"
                  setDisabled={setPermissionDisabled}
                >
                  <Toggle
                    checked={enabled}
                    disabled={
                      permissionDisabled === true || enabled === undefined
                    }
                    onChange={(ev, checked) => {
                      if (checked !== undefined) {
                        toggleEnabled?.(checked);
                      }
                    }}
                  />
                </EnforcePermissions>
              )}
            </Shimmer>
          </Stack.Item>
        </Stack>
        <Separator />
      </Stack>
    </>
  );
};
