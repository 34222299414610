import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";

export class LegalModel extends BaseModel {
  public async checkTcs(opts: {
    organisationId: string;
  }): Promise<Result<boolean>> {
    const response = await this.request({
      url: `/legal/termsAndConditions/organisations/${opts.organisationId}`,
      method: "get",
    });

    if (response.status === 200) {
      return ok(true);
    } else if (response.status === 404) {
      return ok(false);
    } else if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }
  }

  public async acceptTcs(opts: {
    organisationId: string;
    name: string;
    email: string;
  }): Promise<Result<undefined>> {
    const response = await this.request({
      url: `/legal/termsAndConditions/organisations/${opts.organisationId}`,
      method: "post",
      data: {
        name: opts.name,
        email: opts.email,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      // Organisation doesn't exist
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(undefined);
  }
}
