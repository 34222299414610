import React, { useCallback, useReducer, useState, useMemo } from "react";
import { SearchForm } from "./SearchForm";
import { useApiClient } from "../../providers/ApiProvider";
import {
  ISearchResultValue,
  ISearchResults,
  ISearchQuery
} from "../../providers/ApiProvider/ApiClient/models/search";
import { SearchResults } from "./SearchResults";
import { IFormState, IReprSearchResultValue } from "./types";
import { RestoreItemsModal } from "./RestoreItemsModal";

export interface ISearchProps {
  serviceId: string;
  organisationId: string;
  serviceAccountId: string;
}

type TPartialSearchQuery = Omit<ISearchQuery, "offset" | "limit">;

interface ISearchState {
  query: IFormState;
  items: Record<string, ISearchResultValue>;
  totalHits?: number;
  offset: string;
  hasNextPage: boolean;
}

type TAction =
  | {
      type: "UPDATE_QUERY";
      payload: {
        fieldName: keyof IFormState;
        value: IFormState[keyof IFormState];
      };
    }
  | { type: "MERGE_ITEMS"; payload: ISearchResults }
  | { type: "CLEAR_ITEMS" }
  | { type: "ERROR_RESPONSE" };

const mapItems = (
  items: ISearchResultValue[]
): Record<string, ISearchResultValue> =>
  items.reduce((map, item) => {
    return {
      ...map,
      [item.id]: item
    };
  }, {});

const initialSearchState: ISearchState = {
  query: {},
  items: {},
  offset: "",
  hasNextPage: false
};

const searchReducer: React.Reducer<ISearchState, TAction> = (
  prevState,
  action
) => {
  let items: Record<string, ISearchResultValue>;
  let offset: string;
  let totalHits: number;
  switch (action.type) {
    case "UPDATE_QUERY":
      let value = action.payload.value;

      // An empty string is invalid
      if (value === "") {
        value = undefined;
      }

      return {
        ...prevState,
        query: {
          ...prevState.query,
          [action.payload.fieldName]: value
        }
      };

    case "MERGE_ITEMS":
      items = mapItems(action.payload.nodes);
      offset = action.payload.pageInfo.offset;
      totalHits = action.payload.pageInfo.totalHits;

      return {
        ...prevState,
        items: {
          ...prevState.items,
          ...items
        },
        offset,
        totalHits,
        hasNextPage: offset !== ""
      };

    case "CLEAR_ITEMS":
      return {
        ...initialSearchState,
        query: prevState.query
      };

    case "ERROR_RESPONSE":
      // This is to prevent continuously trying to load a page if there is an error
      return {
        ...prevState,
        hasNextPage: false
      };

    default:
      return prevState;
  }
};

export const Search: React.FC<ISearchProps> = ({
  serviceId,
  organisationId,
  serviceAccountId
}) => {
  const api = useApiClient();

  const [searchState, dispatch] = useReducer(searchReducer, initialSearchState);
  const [selectedItems, setSelectedItems] = useState<IReprSearchResultValue[]>(
    []
  );
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRestoreModalOpen, setRestoreModalOpen] = useState(false);

  const updateQuery = useCallback(
    (payload: {
      fieldName: keyof IFormState;
      value: IFormState[keyof IFormState];
    }) => {
      dispatch({ type: "UPDATE_QUERY", payload });
    },
    []
  );

  const loadPage = useCallback(
    async (offset: string) => {
      const response = await api.search.query({
        serviceId,
        organisationId,
        serviceAccountId,
        query: {
          ...searchState.query,
          offset,
          limit: 100
        }
      });

      return response;
    },
    [api.search, serviceId, organisationId, serviceAccountId, searchState.query]
  );

  const performSearch = useCallback(() => {
    setRequestLoading(true);
    dispatch({ type: "CLEAR_ITEMS" });

    loadPage("").then(res => {
      if (res) {
        dispatch({ type: "MERGE_ITEMS", payload: res });
      } else {
        dispatch({ type: "ERROR_RESPONSE" });
      }

      setRequestLoading(false);
    });
  }, [loadPage]);

  const loadNextPage = useCallback(async () => {
    if (!isLoading && !isRequestLoading && searchState.hasNextPage) {
      setLoading(true);

      const res = await loadPage(searchState.offset);

      if (res) {
        dispatch({ type: "MERGE_ITEMS", payload: res });
      } else {
        dispatch({ type: "ERROR_RESPONSE" });
      }

      setLoading(false);
    }
  }, [
    isLoading,
    isRequestLoading,
    searchState.hasNextPage,
    searchState.offset,
    loadPage
  ]);

  const items = useMemo(() => {
    const res: Array<ISearchResultValue | null> = Object.values(
      searchState.items
    );

    if (searchState.hasNextPage) {
      res.push(null);
    }

    return res;
  }, [searchState.items, searchState.hasNextPage]);

  return (
    <>
      <SearchForm
        formState={searchState.query}
        updateQuery={updateQuery}
        onSearch={performSearch}
        selectedItemCount={selectedItems.length}
        onRestore={() => setRestoreModalOpen(true)}
      />
      <SearchResults
        items={items}
        isItemsLoading={isRequestLoading}
        loadNextPage={loadNextPage}
        setSelectedItems={setSelectedItems}
      />
      <RestoreItemsModal
        serviceId={serviceId}
        organisationId={organisationId}
        serviceAccountId={serviceAccountId}
        selectedItemIds={selectedItems.map(item => item.id)}
        isOpen={isRestoreModalOpen}
        onDismiss={() => setRestoreModalOpen(false)}
      />
    </>
  );
};
