import {
  IAuthState,
  IUserDetails,
  SET_AUTH_TOKENS,
  SET_IS_LOGGED_OUT,
  SET_USER_PERMISSIONS,
  TAuthActions,
} from "./types";
import { getUserDetails, getUserId } from "../../utils/parseJWT";

const initialState = (): IAuthState => {
  const refreshToken = localStorage.getItem("backup365_refresh_token");
  const idToken = localStorage.getItem("backup365_id_token");
  let loggedIn = false;
  if (refreshToken) {
    loggedIn = true;
  }
  let userId = undefined;
  let userDetails = undefined;
  let userPermissions = undefined;
  if (idToken) {
    userId = getUserId(idToken);
    userDetails = getUserDetails(idToken);
  }
  return {
    loggedIn,
    tokens: {
      idToken,
      refreshToken,
    },
    userId,
    userDetails,
    userPermissions,
  };
};

export const authReducer = (state = initialState(), action: TAuthActions) => {
  switch (action.type) {
    case SET_AUTH_TOKENS: {
      const refreshToken = action.payload.refreshToken;
      const idToken = action.payload.idToken;
      const expiresIn = action.payload.expiresIn;
      let idTokenExpiresAt: Date | undefined;
      let loggedIn: boolean = state.loggedIn;

      if (refreshToken) {
        localStorage.setItem("backup365_refresh_token", refreshToken);
      }
      let userId = undefined;
      let userDetails: IUserDetails | undefined;
      if (idToken) {
        localStorage.setItem("backup365_id_token", idToken);
        userId = getUserId(idToken);
        userDetails = getUserDetails(idToken);
        if (expiresIn) {
          idTokenExpiresAt = new Date();
          // set expires at 10 mins before actual expiry
          idTokenExpiresAt.setSeconds(expiresIn - 600);
          loggedIn = true;
        }
      }
      return {
        ...state,
        loggedIn,
        tokens: { ...action.payload, idTokenExpiresAt },
        userId,
        userDetails,
      };
    }
    case SET_USER_PERMISSIONS: {
      return {
        ...state,
        userPermissions: action.payload,
      };
    }
    case SET_IS_LOGGED_OUT: {
      localStorage.removeItem("backup365_refresh_token");
      localStorage.removeItem("backup365_id_token");
      return initialState();
    }
    default:
      return state;
  }
};
