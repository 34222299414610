import * as uuid from "uuid";

import AuditQuery, { itemStyle } from "./queryForm";
import {
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  VirtualizedComboBox,
  mergeStyleSets,
} from "office-ui-fabric-react";
import {
  IAuditLog,
  IAuditQuery,
  TOwnerType,
} from "../../providers/ApiProvider/ApiClient/models/audit";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { AuditTable } from "./auditTable";
import { DateTime } from "luxon";
import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { IPaginate } from "../../providers/ApiProvider/ApiClient/models/utils";
import { IRootState } from "../../store";
import { useApiClient } from "../../providers/ApiProvider";
import { useSelector } from "react-redux";

export interface IAuditProps {}

interface IAuditState {
  query: IAuditQuery;
  items: Record<string, IAuditLog>;
  cursor?: string;
  hasNextPage: boolean;
}

export interface IAuditItem extends IAuditLog {
  formattedDate?: string;
  id?: string;
}

interface IDisplayOrgs extends IOrganisation {
  displayName: string;
}

const mapItems = (items: IAuditItem[]): Record<string, IAuditItem> => {
  const itemMap = items.reduce((map, item) => {
    if (item.id === undefined) {
      item.id = uuid.v4();
    }
    const dt = DateTime.fromISO(item.time);
    item.formattedDate = dt.toLocaleString({
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });
    return {
      ...map,
      [item.id]: {
        ...item,
      },
    };
  }, {});

  return itemMap;
};

const initialAuditState: IAuditState = {
  query: {
    endTime: new Date(),
    startTime: undefined,
    subsystem: undefined,
    ownerId: "",
    ownerType: "ORGANISATION",
    isUserAction: false,
  },
  items: {},
  hasNextPage: false,
};

type TAction =
  | {
      type: "UPDATE_QUERY";
      payload: {
        fieldName: keyof IAuditQuery;
        value: IAuditQuery[keyof IAuditQuery];
      };
    }
  | { type: "MERGE_ITEMS"; payload: IPaginate<IAuditLog> }
  | { type: "CLEAR_ITEMS" }
  | { type: "ERROR_RESPONSE" }
  | { type: "CLEAR_QUERY" };

const auditReducer: React.Reducer<IAuditState, TAction> = (
  prevState,
  action
) => {
  let items: Record<string, IAuditLog>;
  let cursor: string | undefined;
  let hasNextPage: boolean;
  switch (action.type) {
    case "UPDATE_QUERY":
      let value = action.payload.value;

      // An empty string is invalid
      if (value === "") {
        value = undefined;
      }

      return {
        ...prevState,
        query: {
          ...prevState.query,
          [action.payload.fieldName]: value,
        },
      };

    case "MERGE_ITEMS":
      items = mapItems(action.payload.nodes);
      cursor = action.payload.pageInfo.cursor;
      hasNextPage = action.payload.pageInfo.hasNextPage;

      return {
        ...prevState,
        items: {
          ...prevState.items,
          ...items,
        },
        cursor,
        hasNextPage,
      };

    case "CLEAR_ITEMS":
      return {
        ...initialAuditState,
        query: prevState.query,
      };

    case "CLEAR_QUERY":
      return {
        ...initialAuditState,
      };

    case "ERROR_RESPONSE":
      // This is to prevent continuously trying to load a page if there is an error
      return {
        ...prevState,
        hasNextPage: false,
      };

    default:
      return prevState;
  }
};

export const AuditLogs: React.FC<IAuditProps> = () => {
  const state = useSelector((state: IRootState) => {
    return state.auth;
  });
  const api = useApiClient();

  const [organisations, setOrganisations] = useState<IDisplayOrgs[]>([]);
  const userId = state.userId;

  const [auditState, dispatch] = useReducer(auditReducer, initialAuditState);
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestLoading, setRequestLoading] = useState(false);

  const auditByOptions: IDropdownOption[] = [
    { key: "organisation", text: "Customer" },
    { key: "user", text: "Me" },
  ];

  const [queryReverse, setQueryReverse] = useState(true);

  const organisationOptions = organisations
    .map(org => ({
      key: org.id,
      text: org.displayName,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const [selectedOrganisation, setSelectedOrganisation] = useState<
    IDropdownOption
  >();
  const [selectedAuditBy, setSelectedAuditBy] = useState<IDropdownOption>(
    auditByOptions[0]
  );
  const ownerType: TOwnerType =
    selectedAuditBy.key === "organisation" ? "ORGANISATION" : "USER";

  const clearQuery = useCallback(() => {
    dispatch({ type: "CLEAR_QUERY" });
  }, []);

  const updateQuery = useCallback(
    (payload: {
      fieldName: keyof IAuditQuery;
      value: IAuditQuery[keyof IAuditQuery];
    }) => {
      dispatch({ type: "UPDATE_QUERY", payload });
    },
    []
  );

  const loadPage = useCallback(
    async (reverse: boolean, cursor?: string) => {
      const query = auditState.query;
      if (!selectedOrganisation) {
        return;
      }

      let ownerId = selectedOrganisation.key.toString();
      if (ownerType === "USER" && userId) {
        ownerId = userId;
      }

      const finalQuery = {
        ...query,
        ownerType,
        ownerId,
      };

      if (query.isUserAction === false) {
        // We want to allow showing both user and system events together,
        // without this it will only display either.
        delete finalQuery.isUserAction;
      }

      const response = await api.audit.query(finalQuery, reverse, cursor);
      if (response.isOk()) {
        return response.value;
      } else {
        return undefined;
      }
    },
    [api, ownerType, selectedOrganisation, auditState.query, userId]
  );

  const performQuery = useCallback(
    (reverse?: boolean) => {
      setRequestLoading(true);
      dispatch({ type: "CLEAR_ITEMS" });

      reverse = reverse !== undefined ? reverse : queryReverse;

      loadPage(reverse, undefined).then(res => {
        if (res) {
          dispatch({ type: "MERGE_ITEMS", payload: res });
        } else {
          dispatch({ type: "ERROR_RESPONSE" });
        }

        setRequestLoading(false);
        setIsLoading(false);
      });
    },
    [loadPage, queryReverse]
  );

  const loadNextPage = useCallback(
    async (reverse: boolean) => {
      if (!isLoading && !isRequestLoading && auditState.hasNextPage) {
        setIsLoading(true);

        const res = await loadPage(reverse, auditState.cursor);

        if (res) {
          dispatch({ type: "MERGE_ITEMS", payload: res });
        } else {
          dispatch({ type: "ERROR_RESPONSE" });
        }

        setIsLoading(false);
      }
    },
    [
      isRequestLoading,
      isLoading,
      auditState.hasNextPage,
      auditState.cursor,
      loadPage,
    ]
  );

  const sortQuery = useCallback(
    (reverse: boolean) => {
      setQueryReverse(reverse);
      performQuery(reverse);
    },
    [setQueryReverse, performQuery]
  );

  const items = useMemo(() => {
    const res: Array<IAuditLog | null> = Object.values(auditState.items);

    if (auditState.hasNextPage) {
      res.push(null);
    }

    return res;
  }, [auditState.items, auditState.hasNextPage]);

  const fetchOrgs = useCallback(async () => {
    const topOrgs = await api.accounts.listOrganisations({});
    if (topOrgs === undefined) {
      return;
    }
    const childOrgs = await Promise.all(
      topOrgs.nodes.map(async org => {
        const childOrgNodes = await api.accounts.getOrganisationHierarchy({
          organisationId: org.id,
        });
        return childOrgNodes?.childOrganisations;
      })
    );

    let flatChildOrgs: any = childOrgs.reduce((prev, curr) => {
      if (prev && curr) {
        return prev.concat(curr);
      } else {
        return [];
      }
    }, []);

    flatChildOrgs = flatChildOrgs.map((org: any) => {
      if (org.organisationId) {
        org.id = org.organisationId;
      }
      return org;
    });

    const flatOrgs = topOrgs.nodes.concat(flatChildOrgs);

    const notUndefined = (x: IOrganisation | undefined): x is IDisplayOrgs =>
      x !== undefined;

    const sortedOrgs = flatOrgs
      .filter(notUndefined)
      .sort((a, b) => a.name.localeCompare(b.name));

    const seenNames = new Set();
    const displayOrgPromises = sortedOrgs.map(async (org, index) => {
      let displayName = org.name;
      if (
        displayName === sortedOrgs[index + 1]?.name ||
        seenNames.has(displayName)
      ) {
        seenNames.add(displayName);

        const fullOrganisation = await api.accounts.getOrganisation({
          organisationId: org.id,
        });

        if (
          fullOrganisation !== undefined &&
          fullOrganisation.parentOrganisationId !== undefined
        ) {
          const parentOrganisation = await api.accounts.getOrganisation({
            organisationId: fullOrganisation.parentOrganisationId,
          });

          if (parentOrganisation !== undefined) {
            displayName = `${displayName} (${parentOrganisation.name})`;
          }
        }
      }

      return {
        ...org,
        displayName,
      };
    });

    // Using for loop rather than Promise.all to make sure
    // promises are executed sequentially
    let displayOrgs = [];
    for (const promise of displayOrgPromises) {
      const val = await promise;
      displayOrgs.push(val);
    }

    return displayOrgs;
  }, [api]);

  useEffect(() => {
    fetchOrgs().then(orgs => {
      if (orgs) {
        setOrganisations(orgs);
        setIsLoading(false);
      }
    });
  }, [fetchOrgs, setIsLoading]);

  useEffect(() => {
    if (selectedOrganisation === undefined) {
      setSelectedOrganisation(organisationOptions[0]);
    }
  }, [selectedOrganisation, organisationOptions, setSelectedOrganisation]);

  const tokens: IStackTokens = {
    childrenGap: "s1",
  };

  const classNames = mergeStyleSets({
    container: { padding: 10 },
    item: itemStyle,
    button: { width: "100%", marginTop: 10 },
  });

  return (
    <div>
      <Text variant="xxLarge">Audit</Text>
      <Separator />
      <Text variant="large">View actions performed on</Text>
      <Stack className={classNames.container} tokens={tokens} horizontal wrap>
        <Stack.Item className={classNames.item}>
          <Dropdown
            style={{ marginBottom: 10 }}
            selectedKey={selectedAuditBy.key}
            onChange={(ev, option) => {
              setSelectedAuditBy(
                // We don't allow no selection so there will always be an option.
                auditByOptions.find(aOption => aOption.key === option?.key)!
              );
            }}
            options={auditByOptions}
          />
        </Stack.Item>
        <Stack.Item className={classNames.item}>
          <VirtualizedComboBox
            hidden={selectedAuditBy.key !== "organisation"}
            selectedKey={selectedOrganisation?.key}
            onChange={(ev, option) => {
              setSelectedOrganisation(
                // We don't allow no selection so there will always be an option.
                organisationOptions.find(aOption => aOption.key === option?.key)
              );
            }}
            allowFreeform
            options={organisationOptions}
          />
        </Stack.Item>
        <Stack.Item>
          {isLoading === true ? <Spinner size={SpinnerSize.large} /> : ""}
        </Stack.Item>
      </Stack>
      <AuditQuery
        updateQuery={updateQuery}
        query={auditState.query}
        performQuery={performQuery}
        clearQuery={clearQuery}
        isRequestLoading={isRequestLoading}
      />
      <AuditTable
        loadNextPage={loadNextPage}
        items={items}
        isRequestLoading={isRequestLoading}
        ownerType={ownerType}
        setQueryReverse={sortQuery}
      />
    </div>
  );
};

export default AuditLogs;
