import { useCallback, useEffect, useState } from "react";

import { useApiClient } from "../../providers/ApiProvider";

export const useOrganisations = () => {
  const api = useApiClient();
  const [organisations, setOrganisations] = useState<
    {
      organisationId: string;
      name: string;
      displayName: string;
    }[]
  >();

  const fetchOrgs = useCallback(async () => {
    const topLevelOrgs = await api.accounts.listOrganisations({});
    if (topLevelOrgs === undefined) {
      // Failed to fetch orgs
      return;
    }

    const allOrgs = (
      await Promise.all(
        topLevelOrgs.nodes.map(async topOrg => {
          const childOrgsResponse = await api.accounts.getOrganisationHierarchy(
            {
              organisationId: topOrg.id,
            }
          );

          const parent: {
            organisationId: string;
            name: string;
          } = {
            organisationId: topOrg.id,
            name: topOrg.name,
          };

          if (childOrgsResponse === undefined) {
            return [parent];
          }

          return [
            parent,
            ...childOrgsResponse.childOrganisations.map(org => ({
              organisationId: org.organisationId,
              name: org.name,
            })),
          ];
        })
      )
    ).flat();

    const sortedOrgs = allOrgs.sort((a, b) => a.name.localeCompare(b.name));

    const seenNames = new Set();
    const displayOrgPromises = sortedOrgs.map(async (org, index) => {
      let displayName = org.name;
      if (
        displayName === sortedOrgs[index + 1]?.name ||
        seenNames.has(displayName)
      ) {
        seenNames.add(displayName);

        const fullOrganisation = await api.accounts.getOrganisation({
          organisationId: org.organisationId,
        });

        if (
          fullOrganisation !== undefined &&
          fullOrganisation.parentOrganisationId !== undefined
        ) {
          const parentOrganisation = await api.accounts.getOrganisation({
            organisationId: fullOrganisation.parentOrganisationId,
          });

          if (parentOrganisation !== undefined) {
            displayName = `${displayName} (${parentOrganisation.name})`;
          }
        }
      }

      return {
        ...org,
        displayName,
      };
    });

    // Using for loop rather than Promise.all to make sure
    // promises are executed sequentially
    let displayOrgs = [];
    for (const promise of displayOrgPromises) {
      const val = await promise;
      displayOrgs.push(val);
    }

    setOrganisations(displayOrgs);
  }, [api]);

  useEffect(() => {
    fetchOrgs();
  }, [fetchOrgs]);

  return { organisations };
};
