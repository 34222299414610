import React, { useEffect, useState } from "react";
import {
  TPermissionAction,
  useValidatePermissions,
} from "../../providers/validatePermissionsProvider";

import store from "../../store";
import { useApiClient } from "../../providers/ApiProvider";

interface IPermissionProps {
  organisationId: string;
  resource: string;
  action: TPermissionAction | "*";
  serviceAccountId?: string;
  serviceId?: string;
  enforceType: TEnforceAction;
  setDisabled?: (disabled: boolean) => void;
}

export type TEnforceAction = "HIDE" | "DISABLE";

const EnforcePermissions: React.FC<IPermissionProps> = ({
  organisationId,
  resource,
  action,
  serviceId,
  serviceAccountId,
  children,
  enforceType,
  setDisabled,
}) => {
  const client = useApiClient();
  const checkPermissions = useValidatePermissions();
  const state = store.getState();
  const userId = state.auth.userId;
  const [render, setRender] = useState<any>();

  useEffect(() => {
    client.accounts.listParents({ organisationId }).then(parents => {
      if (!parents) {
        return;
      }
      const parentOrgIds = parents.map(organisation => {
        return organisation.id;
      });

      parentOrgIds.push(organisationId);
      const valid = checkPermissions.validate({
        userId,
        action,
        resource,
        scope: { organisationId, serviceAccountId, serviceId },
        parentOrganisationIds: parentOrgIds,
      });
      if (valid) {
        if (enforceType === "DISABLE" && setDisabled !== undefined) {
          setDisabled(false);
        }
        setRender(children);
      }

      if (
        enforceType === "DISABLE" &&
        render === undefined &&
        setDisabled !== undefined &&
        valid !== true
      ) {
        setDisabled(true);
        setRender(children);
      }
    });
  }, [
    client,
    organisationId,
    action,
    checkPermissions,
    children,
    resource,
    userId,
    serviceAccountId,
    serviceId,
    enforceType,
    render,
    setDisabled,
  ]);

  return <>{render}</>;
};

export default EnforcePermissions;
