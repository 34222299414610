import { useRef, useEffect, MutableRefObject } from "react";

export const useLatest = <T>(val: T): MutableRefObject<T> => {
  const ref = useRef(val);

  useEffect(() => {
    ref.current = val;
  }, [val]);

  return ref;
};
