import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import {
  ParentOrganisationBreadcrumbs,
  rootItemKey,
} from "./ParentOrganisationBreadcrumbs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { ServicesPivot, TItemKey, isItemKey } from "../ServicesPivot";
import {
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Text,
} from "office-ui-fabric-react";
import { useHistory, useParams } from "react-router-dom";

import { CustomerView } from "./CustomerView";
import { CustomersListView } from "./CustomersListView";
import { CustomersPeople } from "../CustomersPeople";
import EnforcePermissions from "../Permissions";
import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { IPaginate } from "../../providers/ApiProvider/ApiClient/models/utils";
import { ReportingSummary } from "../ReportingSummary";
import { useApiClient } from "../../providers/ApiProvider";

export interface ICustomerProps {
  selectedPage?: TItemKey;
}

interface IState {
  organisations: {
    nodes: Record<string, IOrganisation>;
    cursor?: string;
    hasNextPage: boolean;
  };
}

type TActions =
  | {
      type: "MERGE_ORGANISATIONS";
      payload: IPaginate<IOrganisation>;
    }
  | { type: "CLEAR_ORGANISATIONS" };

const initialState: IState = {
  organisations: {
    nodes: {},
    hasNextPage: true,
  },
};

const stateReducer: React.Reducer<IState, TActions> = (prevState, action) => {
  switch (action.type) {
    case "MERGE_ORGANISATIONS":
      const orgNodes = action.payload.nodes.reduce((prev, curr) => {
        const id = curr.id;

        return {
          ...prev,
          [id]: curr,
        };
      }, {});

      return {
        ...prevState,
        organisations: {
          nodes: {
            ...prevState.organisations.nodes,
            ...orgNodes,
          },
          cursor: action.payload.pageInfo.cursor,
          hasNextPage: action.payload.pageInfo.hasNextPage,
        },
      };
    case "CLEAR_ORGANISATIONS":
      return {
        ...prevState,
        organisations: initialState.organisations,
      };
    default:
      return prevState;
  }
};

export const Customer: React.FC<ICustomerProps> = ({ selectedPage }) => {
  const api = useApiClient();
  const history = useHistory();
  const { organisationId, serviceName } = useParams();

  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [organisation, setOrganisation] = useState<IOrganisation>();
  const [services, setServices] = useState<string[]>();
  const [parents, setParents] = useState<IOrganisation[]>();
  const [disableViewReporting, setViewReporting] = useState<boolean>(true);
  const [disableViewPeople, setCanViewPeople] = useState<boolean>(true);

  // Clear state when the organisationId changes
  useEffect(() => {
    dispatch({ type: "CLEAR_ORGANISATIONS" });
    setOrganisation(undefined);
    setServices(undefined);
    setParents(undefined);
  }, [organisationId]);

  // List organistaions
  useEffect(() => {
    if (state.organisations.hasNextPage) {
      api.accounts
        .listOrganisations({
          parentOrganisationId: organisationId,
          cursor: state.organisations.cursor,
        })
        .then(res => {
          if (res !== undefined) {
            dispatch({ type: "MERGE_ORGANISATIONS", payload: res });
          } else {
            // Dispatch an empty message to prevent continuously fetching an error
            dispatch({
              type: "MERGE_ORGANISATIONS",
              payload: { nodes: [], pageInfo: { hasNextPage: false } },
            });
          }
        });
    }
  }, [
    organisationId,
    state.organisations.hasNextPage,
    state.organisations.cursor,
    api.accounts,
  ]);

  const fetchOrganisation = useCallback(
    async (organisationId: string) => {
      const res = await api.accounts.getOrganisation({ organisationId });
      setOrganisation(res);
    },
    [api]
  );

  // Get organisation (determine services)
  useEffect(() => {
    if (organisationId !== undefined) {
      fetchOrganisation(organisationId);

      Promise.all([
        api.accounts.getServiceConfigurations({
          organisationId,
          serviceId: EWS_SERVICE_ID,
        }),
        api.accounts.getServiceConfigurations({
          organisationId,
          serviceId: SHAREPOINT_SERVICE_ID,
        }),
      ]).then(([ewsResponse, spResponse]) => {
        const serviceIds: string[] = [];

        if (ewsResponse.isOk() && ewsResponse.value.nodes.length > 0) {
          serviceIds.push(EWS_SERVICE_ID);
        }

        if (spResponse.isOk() && spResponse.value.nodes.length > 0) {
          serviceIds.push(SHAREPOINT_SERVICE_ID);
        }

        setServices(serviceIds);
      });
    }
  }, [organisationId, api.accounts, fetchOrganisation]);

  const fetchParents = useCallback(
    async (organisationId: string) => {
      const res = await api.accounts.listParents({
        organisationId,
      });

      setParents(res ?? []);
    },
    [api]
  );

  // Get organisation parents
  useEffect(() => {
    if (organisationId !== undefined) {
      fetchParents(organisationId);
    } else {
      setParents([]);
    }
  }, [organisationId, fetchParents]);

  const isChildrenLoading = state.organisations.hasNextPage;
  const isServicesLoading =
    organisationId !== undefined &&
    (organisation === undefined || services === undefined);
  const isParentsLoading = parents === undefined;

  const childOrganisations = useMemo(
    () => Object.values(state.organisations.nodes),
    [state.organisations.nodes]
  );

  const parentOrganisations = useMemo(() => {
    const result = [];
    if (parents !== undefined) {
      result.push(...parents);
    }

    if (organisation !== undefined) {
      result.push(organisation);
    }

    return result;
  }, [organisation, parents]);

  let component: JSX.Element | undefined;
  let pivotItems: TItemKey[] = [];
  if (!isChildrenLoading && !isServicesLoading) {
    // All loading has finished
    if (
      childOrganisations.length > 0 &&
      (services === undefined || services.length === 0)
    ) {
      // If there are children and no services, show the children
      if (selectedPage === "people") {
        component = <CustomersPeople />;
      } else {
        component = (
          <CustomersListView
            organisations={childOrganisations}
            parentOrganisations={parentOrganisations}
            selectOrganisation={organisationId =>
              history.push(`/customers/${organisationId}`)
            }
          />
        );
      }

      if (organisationId !== undefined) {
        pivotItems = ["customers", "people"];
      }
    } else if (organisation !== undefined) {
      // Otherwise show the services
      if (selectedPage === "reporting") {
        component = <ReportingSummary />;
      } else if (selectedPage === "people") {
        component = <CustomersPeople />;
      } else {
        component = (
          <CustomerView
            organisation={organisation}
            serviceName={serviceName}
            updateOrganisation={() => {
              if (organisationId !== undefined) {
                fetchOrganisation(organisationId);
                fetchParents(organisationId);
              }
            }}
          />
        );
      }

      pivotItems = ["overview", "mailbox", "onedrive", "sharepoint"];
      if (disableViewReporting !== true) {
        pivotItems.push("reporting");
      }
      if (disableViewPeople !== true) {
        pivotItems.push("people");
      }
    }
  }

  let parentComponent: JSX.Element | undefined;
  if (parentOrganisations.length > 0) {
    parentComponent = (
      <ParentOrganisationBreadcrumbs
        parentOrganisations={parentOrganisations}
        selectOrganisation={organisationId => {
          if (organisationId === rootItemKey) {
            history.push(`/customers`);
          } else {
            history.push(`/customers/${organisationId}`);
          }
        }}
      />
    );
  } else if (parentOrganisations.length === 0) {
    parentComponent = <Text variant="xLarge">Customers</Text>;
  }

  const selectedKey: TItemKey =
    selectedPage ?? (isItemKey(serviceName) ? serviceName : "overview");

  const customerShimmerElements = (
    <ShimmerElementsGroup
      flexWrap
      shimmerElements={[
        { type: ShimmerElementType.gap, width: "100%", height: 32 },
      ].concat(
        ...Array(5)
          .fill(null)
          .map(() => [
            { type: ShimmerElementType.line, width: "4%", height: 22 },
            { type: ShimmerElementType.gap, width: "1%", height: 22 },
            { type: ShimmerElementType.line, width: "60%", height: 22 },
            { type: ShimmerElementType.gap, width: "35%", height: 22 },
          ])
      )}
    />
  );

  let permissionsCheck: any;
  if (organisationId) {
    permissionsCheck = (
      <>
        <EnforcePermissions
          organisationId={organisationId}
          action="Cancel"
          resource="reporting:scheduledReport"
          enforceType="DISABLE"
          setDisabled={setViewReporting}
        ></EnforcePermissions>
        <EnforcePermissions
          organisationId={organisationId}
          action="Cancel"
          resource="auth:invites"
          enforceType="DISABLE"
          setDisabled={setCanViewPeople}
        ></EnforcePermissions>
      </>
    );
  }

  return (
    <>
      {permissionsCheck}
      <Shimmer
        shimmerElements={[
          { type: ShimmerElementType.line, width: 150, height: 38 },
          { type: ShimmerElementType.gap, width: "100%", height: 38 },
        ]}
        isDataLoaded={!isParentsLoading}
      >
        {parentComponent}
      </Shimmer>
      <Shimmer
        customElementsGroup={customerShimmerElements}
        isDataLoaded={!isChildrenLoading && !isServicesLoading}
      >
        <ServicesPivot selectedKey={selectedKey} includedItems={pivotItems} />
        {component}
      </Shimmer>
    </>
  );
};

export const CustomerHOC = (selectedPage?: TItemKey): React.FC => props => (
  <Customer selectedPage={selectedPage} {...props} />
);
