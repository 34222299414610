import React, { useEffect, useState } from "react";
import { initializeIcons, loadTheme } from "office-ui-fabric-react";

import { ApiClientProvider } from "./providers/ApiProvider";
import AuthProvider from "./providers/AuthProvider";
import { MessageBarProvider } from "./providers/messageBarProvider";
import { PermissionProvider } from "./providers/validatePermissionsProvider";
import { Provider } from "react-redux";
import { RequesterClientProvider } from "./providers/RequesterProvider";
import Routes from "./routes";
import { WindowDimensionProvider } from "./providers/windowDimensionsProvider";
import { createApiClient } from "./providers/ApiProvider/ApiClient";
import { createRequesterClient } from "./providers/RequesterProvider/RequesterClient";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import WebSocketProvider from './providers/WebSocketContext';

import store from "./store";

const App: React.FC = () => {
  initializeIcons();
  initializeFileTypeIcons();

  const hostname = window.location.hostname;
  // const hostname = "mp.portal.dev.backup365.io";

  const client = createApiClient({ hostname });
  const requesterClient = createRequesterClient({ hostname });

  // Generated from the theme designer
  // https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
  const b3Theme = {
    palette: {
      themePrimary: "#00bf6f",
      themeLighterAlt: "#f2fcf8",
      themeLighter: "#cef5e4",
      themeLight: "#a5ecce",
      themeTertiary: "#57d9a3",
      themeSecondary: "#18c77e",
      themeDarkAlt: "#00ac64",
      themeDark: "#009155",
      themeDarker: "#006b3e",
      neutralLighterAlt: "#f8f8f8",
      neutralLighter: "#edebe9",
      neutralLight: "#e1dfdd",
      neutralQuaternaryAlt: "#dadada",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c8c8",
      neutralTertiary: "#595959",
      neutralSecondary: "#373737",
      neutralPrimaryAlt: "#2f2f2f",
      neutralPrimary: "#000000",
      neutralDark: "#151515",
      black: "#0b0b0b",
      white: "#ffffff",
    },
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client.brandingAssets
      .getTheme()
      .then(result => {
        if (result.isOk()) {
          loadTheme({ palette: result.value });
        } else {
          loadTheme(b3Theme);
        }
        setLoading(false);
      })
      .catch(() => loadTheme(b3Theme));
  }, [client, setLoading, b3Theme]);

  if (loading) {
    return <Provider store={store}></Provider>;
  } else {
    return (
      <Provider store={store}>
        <PermissionProvider>
          <WindowDimensionProvider>
            <MessageBarProvider>
              <ApiClientProvider client={client}>
                <RequesterClientProvider client={requesterClient}>
                  <WebSocketProvider>
                  <AuthProvider>
                    <Routes />
                  </AuthProvider>
                  </WebSocketProvider>
                </RequesterClientProvider>
              </ApiClientProvider>
            </MessageBarProvider>
          </WindowDimensionProvider>
        </PermissionProvider>
      </Provider>
    );
  }
};

export default App;
