import * as React from "react";
import { Panel, IRenderFunction, IPanelProps } from "office-ui-fabric-react";
import { Text } from "office-ui-fabric-react";

export interface IBasePanelProps {
  title?: string;
  isOpen: boolean;
  dismissPanel?: () => void;
}

export const BasePanel: React.FC<IBasePanelProps> = ({
  isOpen,
  dismissPanel,
  title,
  children
}) => {
  const onRenderNavigationContent: IRenderFunction<IPanelProps> = (
    props,
    defaultRender
  ) => (
    <>
      <Text
        variant="xLarge"
        styles={{
          root: {
            margin: "10px",
            height: "auto",
            width: "100%"
          }
        }}
      >
        {title}
      </Text>
      {defaultRender!(props)}
    </>
  );

  return (
    <Panel
      isBlocking={false}
      onRenderNavigationContent={onRenderNavigationContent}
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel="Close"
      styles={{
        root: {
          // TODO move this out of this component
          marginTop: 50 // Top menu height
        }
      }}
    >
      {children}
    </Panel>
  );
};
