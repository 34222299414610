import React, { createContext, useContext } from "react";
import { IRequesterClient, RequesterClient } from "./RequesterClient";

const RequesterClientContext = createContext<IRequesterClient | undefined>(
  undefined
);

export interface IRequesterClientProviderProps {
  client: IRequesterClient;
}

export const RequesterClientProvider: React.FC<IRequesterClientProviderProps> = ({
  client,
  children
}) => {
  return (
    <RequesterClientContext.Provider value={client}>
      {children}
    </RequesterClientContext.Provider>
  );
};

export const useRequesterClient = (): RequesterClient => {
  const client = useContext(RequesterClientContext);

  if (client === undefined) {
    throw new Error("RequesterClient has not been configured");
  }

  return client;
};
