import React from "react";
import {
  Stack,
  PrimaryButton,
  IStackTokens,
  mergeStyles
} from "office-ui-fabric-react";

export interface IAction {
  label: string;
  onClick?: () => void;
}

export interface IActionsProps {
  actions: IAction[];
}

export const Actions: React.FC<IActionsProps> = ({ actions }) => {
  const tokens: IStackTokens = { childrenGap: 20 };

  const stack = mergeStyles({
    paddingBottom: 16
  });

  return (
    <>
      <Stack className={stack} horizontal tokens={tokens}>
        {actions.map(action => (
          <PrimaryButton
            text={action.label}
            key={action.label}
            onClick={() => action.onClick?.()}
          />
        ))}
      </Stack>
    </>
  );
};
