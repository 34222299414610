import React, { useMemo } from "react";
import {
  Stack,
  DatePicker,
  PrimaryButton,
  IStackTokens,
  mergeStyles
} from "office-ui-fabric-react";

export interface IBrowseDatePickerProps {
  value?: Date;
  onSelectValue?: (date?: Date) => void;
}

export const BrowseDatePicker: React.FC<IBrowseDatePickerProps> = ({
  value,
  onSelectValue
}) => {
  const today = useMemo(() => new Date(), []);

  const tokens: IStackTokens = { childrenGap: 8 };

  const button = mergeStyles({
    minWidth: 90
  });

  return (
    <Stack horizontal tokens={tokens}>
      <Stack.Item grow>
        <DatePicker
          value={value}
          onSelectDate={date => {
            if (date !== undefined && date !== null) {
              onSelectValue?.(date);
            }
          }}
          formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
          maxDate={today}
        />
      </Stack.Item>
      <Stack.Item>
        <PrimaryButton className={button} onClick={() => onSelectValue?.()}>
          Clear
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
};
