import {
  Dropdown,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
} from "office-ui-fabric-react";
import React, { useCallback, useState } from "react";

import { IRole } from "../../providers/ApiProvider/ApiClient/models/auth";
import { IRootState } from "../../store";
import { useApiClient } from "../../providers/ApiProvider";
import { useForm } from "../../hooks/useForm";
import { useMessageBar } from "../../providers/messageBarProvider";
import { useSelector } from "react-redux";
import { validateEmail } from "../../utils/validateEmail";

export interface ICustomersPeoplePanelProps {
  isOpen: boolean;
  onDismiss: (shouldReload?: boolean) => void;
  organisationId: string;
  roles?: IRole[];
}

interface IFormState {
  inviteeEmail?: string;
  roleId?: string;
}

export const CustomersPeoplePanel: React.FC<ICustomersPeoplePanelProps> = ({
  isOpen,
  onDismiss,
  organisationId,
  roles,
}) => {
  const api = useApiClient();
  const { sendMessage } = useMessageBar();

  const [isCreateLoading, setCreateLoading] = useState(false);

  const state = useSelector((state: IRootState) => {
    return state.auth;
  });

  const userId = state.userId;

  const [
    { data: form, validation: errorMessages },
    updateForm,
    clearForm,
  ] = useForm<IFormState>({
    initialState: {},
    validation: {
      inviteeEmail: val => {
        if (val !== undefined && !validateEmail(val)) {
          return "Must be a valid email address";
        }
      },
    },
  });

  const createInvite = useCallback(
    async (opts: IFormState) => {
      const { inviteeEmail, roleId } = opts;

      if (inviteeEmail !== undefined && roleId !== undefined) {
        const response = await api.invites.createInvite({
          organisationId,
          roleId,
          inviteeEmail,
          inviterUserId: userId!,
        });

        if (response.isOk()) {
          sendMessage({
            messageType: MessageBarType.success,
            text: `Sent invite to ${inviteeEmail}`,
          });

          onDismiss(true);
        } else {
          sendMessage({
            messageType: MessageBarType.error,
            text: response.message,
          });

          onDismiss(false);
        }
      }
    },
    [organisationId, api.invites, onDismiss, sendMessage, userId]
  );

  if (userId === undefined) {
    throw new Error(`Invalid state: missing userId`);
  }

  const roleOptions = roles?.map(role => ({
    key: role.roleId,
    text: role.name ?? "Default Role",
  }));

  const isDisabled =
    errorMessages.inviteeEmail !== undefined ||
    form.inviteeEmail === undefined ||
    form.roleId === undefined;

  return (
    <Panel
      headerText="Create Invite"
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={() => {
        onDismiss();
        clearForm();
      }}
    >
      <Stack tokens={{ childrenGap: "s1", padding: "s1" }}>
        <TextField
          label="Invitee Email"
          required
          value={form.inviteeEmail ?? ""}
          onChange={(ev, newValue) => {
            let value = newValue;
            if (newValue === "") {
              value = undefined;
            }
            updateForm({ field: "inviteeEmail", value });
          }}
          errorMessage={errorMessages.inviteeEmail}
          disabled={isCreateLoading}
        />
        <Dropdown
          label="Role"
          selectedKey={form.roleId}
          onChange={(ev, option) => {
            updateForm({
              field: "roleId",
              value: option?.key as string | undefined,
            });
          }}
          options={roleOptions ?? []}
          disabled={isCreateLoading}
        />
        <Stack.Item align="end">
          <PrimaryButton
            disabled={isDisabled || isCreateLoading}
            onClick={() => {
              setCreateLoading(true);

              createInvite(form).then(() => {
                setCreateLoading(false);
              });
            }}
          >
            {isCreateLoading ? <Spinner size={SpinnerSize.medium} /> : "Create"}
          </PrimaryButton>
        </Stack.Item>
      </Stack>
    </Panel>
  );
};
