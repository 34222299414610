import React from "react";
import {
  Shimmer,
  VirtualizedComboBox,
  IComboBoxOption
} from "office-ui-fabric-react";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";

export interface IDestinationComboBoxProps {
  serviceAccounts: IServiceAccount[];
  isServiceAccountsLoading?: boolean;
  selectedServiceAccountId?: string;
  setSelectedServiceAccountId?: (serviceId: string) => void;
}

export const DestinationComboBox: React.FC<IDestinationComboBoxProps> = ({
  serviceAccounts,
  isServiceAccountsLoading,
  selectedServiceAccountId,
  setSelectedServiceAccountId
}) => {
  const serviceAccountOptions: IComboBoxOption[] = serviceAccounts
    .map(serviceAccount => {
      const parts = serviceAccount.identifier.split("/");
      const lastPart = parts[parts.length - 1];

      return {
        key: serviceAccount.id,
        text: lastPart
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text));

  return (
    <Shimmer isDataLoaded={!isServiceAccountsLoading}>
      <VirtualizedComboBox
        label="Destination"
        options={serviceAccountOptions}
        selectedKey={selectedServiceAccountId}
        onChange={(_, option?: IComboBoxOption) => {
          if (option) {
            setSelectedServiceAccountId?.(option.key as string);
          }
        }}
        allowFreeform
        autoComplete="on"
        dropdownMaxWidth={300}
      />
    </Shimmer>
  );
};
