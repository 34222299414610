import {
  DatePicker,
  DayOfWeek,
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  IStyle,
  Icon,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
  Toggle,
  classNamesFunction,
  styled,
} from "office-ui-fabric-react";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { getStats, prepareColumns, prepareItems } from "./serviceMapping";

import { ISyncStats } from "../../providers/ApiProvider/ApiClient/models/reporting";
import { RestoreModal } from "./RestoreModal";
import { setTimezoneOffset } from "../../utils/timezones";
import { useApiClient } from "../../providers/ApiProvider";
import { useHistory } from "react-router-dom";

export interface IBaseRestoreProps {
  organisationId: string;
  serviceAccountId: string;
  serviceId: string;
  serviceName: string;
  styles?: IRestoreViewStyles;
}

export interface IItem {
  [key: string]: string;
  startDate: string;
  endDate: string;
  rawDate: string;
  items: string;
  size: string;
  rawSize: string;
}

export interface IRestoreViewColumn extends IColumn {
  key: string;
  name: string;
  isSortable?: true;
}

interface ISyncStatsState {
  syncStats: ISyncStats[];
  cursor?: string;
}

interface IRestoreViewStyles {
  marginTop15: IStyle;
  goToTop: IStyle;
}

const restoreViewStyles = (): IRestoreViewStyles => {
  return {
    marginTop15: {
      marginTop: 15,
    },
    goToTop: {
      position: "fixed",
      right: "5%",
      bottom: "5%",
    },
  };
};

const getClassNames = classNamesFunction<{}, IRestoreViewStyles>();

type TSyncStatsActions = { type: "SET_STATS"; payload: ISyncStatsState };

const syncStatsReducer: React.Reducer<ISyncStatsState, TSyncStatsActions> = (
  prevState: ISyncStatsState,
  action: TSyncStatsActions
) => {
  switch (action.type) {
    case "SET_STATS":
      return { ...prevState, ...action.payload };
    default:
      return prevState;
  }
};

export const BaseRestoreView: React.FC<IBaseRestoreProps> = ({
  styles,
  organisationId,
  serviceAccountId,
  serviceId,
  serviceName,
}) => {
  let fQuery: string | undefined;

  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(new Date().setHours(0))
  );
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());

  const [hideEmptySync, sethideEmptySync] = useState(true);
  const [filterQuery, setFilterQuery] = useState(fQuery);
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const scrollToRef = (ref: React.MutableRefObject<HTMLDivElement | null>) =>
    ref.current?.scrollIntoView();
  const scrollRef = useRef(null as HTMLDivElement | null);
  const executeScroll = () => scrollToRef(scrollRef);

  const tableRef = useRef(null as HTMLDivElement | null);

  const selection = useMemo(
    () =>
      new Selection<any>({
        onSelectionChanged: () => {
          if (selection.getSelection()[0]) {
            setShouldShowModal(true);
          }
        },
      }),
    []
  );

  const [isLoading, setLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [columns, setColumns] = useState(prepareColumns(serviceId));

  const [{ syncStats, cursor }, dispatch] = useReducer(syncStatsReducer, {
    syncStats: [],
  });

  const setStateStartDate = (date: Date | undefined | null) => {
    date?.setHours(0);
    if (date) {
      setTimezoneOffset(date);
      setStartDate(date);
    }
  };

  const setStateEndDate = (date: Date | undefined | null) => {
    date?.setHours(23);
    date?.setMinutes(59);
    if (date) {
      setTimezoneOffset(date);
      setEndDate(date);
    }
  };

  const hideEmptyToggle = (
    ev: React.MouseEvent<HTMLElement>,
    checked: boolean | undefined
  ) => {
    sethideEmptySync(!checked);
  };

  const history = useHistory();

  const onFilterChange = (
    ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string | undefined
  ) => {
    setFilterQuery(value);
  };

  const closeModal = (
    ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    setShouldShowModal(false);
  };

  const api = useApiClient();
  const fetchStats = useCallback(
    async ({ cursor, startDate, endDate }) => {
      const { pageStats, nextCursor } = await getStats({
        reportingApi: api.reporting,
        startDate,
        endDate,
        organisationId,
        serviceAccountId,
        serviceId,
        cursor,
      });

      return { nextCursor, pageStats };
    },
    [api.reporting, organisationId, serviceAccountId, serviceId]
  );

  useEffect(() => {
    setLoading(true);
    setIsDataLoading(true);
    fetchStats({ startDate, endDate, cursor: undefined }).then(
      ({ pageStats, nextCursor }) => {
        dispatch({
          type: "SET_STATS",
          payload: { syncStats: pageStats, cursor: nextCursor },
        });
        setIsDataLoading(false);
        setLoading(false);
      }
    );
  }, [
    fetchStats,
    startDate,
    endDate,
    api.accounts,
    organisationId,
    serviceId,
    serviceAccountId,
  ]);

  const loadNextPage = async () => {
    if (!isLoading) {
      setLoading(true);

      const { pageStats, nextCursor } = await fetchStats({
        cursor,
        startDate,
        endDate,
      });
      dispatch({
        type: "SET_STATS",
        payload: {
          syncStats: [...syncStats, ...pageStats],
          cursor: nextCursor,
        },
      });
      setLoading(false);
    }
  };

  let items: Array<IItem | null> = prepareItems({
    serviceId,
    syncStats,
    filterQuery,
    hideEmptySync,
    columns,
  });

  if (cursor) {
    items.push(null);
  }

  const selectedRestorePoint: IItem = selection.getSelection()[0];

  const classNames = getClassNames(styles);

  let topButton = <></>;
  if (tableRef && tableRef.current && tableRef.current?.clientHeight > 450) {
    topButton = (
      <PrimaryButton onClick={executeScroll} className={classNames.goToTop}>
        <Icon iconName="Up" />
      </PrimaryButton>
    );
  }

  const onSelectColumn = (ev?: any, column?: IRestoreViewColumn) => {
    if (columns && column && column.isSortable) {
      const newCols = columns.map(col => {
        if (col.key === column.key) {
          const isSortedDescending = column.isSorted
            ? !column.isSortedDescending
            : false;

          return {
            ...col,
            isSorted: true,
            isSortedDescending,
          };
        }

        return {
          ...col,
          isSorted: false,
          isSortedDescending: false,
        };
      });

      setColumns(newCols);
    }
  };

  return (
    <>
      <div ref={scrollRef}></div>
      <RestoreModal
        organisationId={organisationId}
        serviceId={serviceId}
        serviceAccountId={serviceAccountId}
        selectedRestorePoint={selectedRestorePoint}
        shouldShowModal={shouldShowModal}
        toggleModal={closeModal}
      />
      <div className="ms-Grid-col ms-md6 ms-sm12">
        <DatePicker
          firstDayOfWeek={DayOfWeek.Sunday}
          placeholder="Select a date..."
          onSelectDate={setStateStartDate}
          maxDate={endDate ?? new Date()}
          isMonthPickerVisible={true}
          value={startDate}
          label="Start Date"
        />
      </div>
      <div className="ms-Grid-col ms-md6 ms-sm12">
        <DatePicker
          firstDayOfWeek={DayOfWeek.Sunday}
          placeholder="Select a date..."
          onSelectDate={setStateEndDate}
          minDate={startDate ?? new Date()}
          maxDate={new Date()}
          isMonthPickerVisible={true}
          value={endDate}
          label="End Date"
        />
      </div>
      <div className="ms-Grid-col ms-md6 ms-sm12">
        <TextField
          styles={{ root: restoreViewStyles().marginTop15 }}
          label="Filter Restore Points"
          onChange={onFilterChange}
        />
      </div>
      <div className="ms-Grid-col ms-md4 ms-sm12">
        {/* For some reason the style does not apply to the same container
        as className so have to inline. */}
        <Toggle
          styles={{ root: restoreViewStyles().marginTop15 }}
          style={{ marginTop: 5 }}
          label="Show empty results"
          onChange={hideEmptyToggle}
        />
      </div>
      <div className="ms-Grid-col ms-sm12">
        <PrimaryButton
          styles={{ root: restoreViewStyles().marginTop15 }}
          label="Show restore history"
          onClick={() => {
            history.push(
              `/customers/${organisationId}/services/${serviceName}/${serviceAccountId}/restore/history`
            );
          }}
        >
          Restore History
        </PrimaryButton>
      </div>
      <div className="ms-Grid-col ms-sm12" ref={tableRef}>
        <ShimmeredDetailsList
          items={items}
          enableShimmer={isDataLoading}
          columns={columns}
          onColumnHeaderClick={onSelectColumn}
          selectionMode={SelectionMode.single}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selection}
          onRenderCustomPlaceholder={(
            rowProps: IDetailsRowProps,
            index?: number,
            defaultRender?: (props: IDetailsRowProps) => React.ReactNode
          ) => {
            loadNextPage?.();
            return defaultRender?.(rowProps);
          }}
        />
      </div>
      {topButton}
    </>
  );
};

export const RestoreView = styled(BaseRestoreView, restoreViewStyles);
