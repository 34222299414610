import React from "react";
import { useParams } from "react-router-dom";
import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../constants";
import { ServiceAccountStats } from "../components/ServiceAccountStats";
import { ServiceAccountBreadCrumbs } from "../components/ServiceAccountBreadCrumbs";
import { NotFound } from "./notFound";

export const ServiceAccountPage: React.FC = () => {
  const { organisationId, serviceName, serviceAccountId } = useParams();

  let serviceId: string;
  if (serviceName === "mailbox") {
    serviceId = EWS_SERVICE_ID;
  } else if (serviceName === "sharepoint" || serviceName === "onedrive") {
    serviceId = SHAREPOINT_SERVICE_ID;
  } else {
    return <NotFound />;
  }

  if (organisationId === undefined || serviceAccountId === undefined) {
    // TODO handle this properly
    throw new Error("Missing required params");
  }

  return (
    <>
      <ServiceAccountBreadCrumbs
        serviceName={serviceName}
        organisationId={organisationId}
        serviceId={serviceId}
        serviceAccountId={serviceAccountId}
      />
      <ServiceAccountStats
        serviceName={serviceName}
        serviceId={serviceId}
        organisationId={organisationId}
        serviceAccountId={serviceAccountId}
      />
    </>
  );
};
