import React from "react";
import {
  ShimmerElementsGroup,
  ShimmerElementType
} from "office-ui-fabric-react";

export const comboBoxElements = (
  <ShimmerElementsGroup
    flexWrap={true}
    shimmerElements={[
      { type: ShimmerElementType.line, width: "50%", height: 12 },
      { type: ShimmerElementType.gap, width: "50%", height: 12 },
      { type: ShimmerElementType.line, width: "100%", height: 30 }
    ]}
  />
);
