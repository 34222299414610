import {
  IPersonaSharedProps,
  Icon,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
} from "office-ui-fabric-react";
import React, { ReactElement, useCallback, useEffect, useState } from "react";

import { IRootState } from "../../store";
import { MenuItem } from "./MenuItem";
import { TPanelState } from "../SidePanel";
import { useApiClient } from "../../providers/ApiProvider";
import { useSelector } from "react-redux";

interface IMenuActionsProps {
  helpUrl?: string;
  panelState?: TPanelState;
  setPanelState: (panelState?: TPanelState) => void;
}

export const MenuActions: React.FC<IMenuActionsProps> = ({
  helpUrl,
  panelState,
  setPanelState,
}) => {
  const togglePanelState = (state: TPanelState) =>
    setPanelState(panelState === state ? undefined : state);

  const api = useApiClient();
  const [image, setImage] = useState<ArrayBuffer>();

  const state = useSelector((state: IRootState) => {
    return state.auth;
  });

  const userId = state.userId;
  const userDetails = state.userDetails;

  const getImage = useCallback(
    async ({ userId }: { userId?: string }) => {
      if (!userId) {
        return;
      }
      return await api.auth.getUserPhoto({ userId });
    },
    [api]
  );

  useEffect(() => {
    getImage({ userId }).then(image => {
      if (image?.isOk()) {
        setImage(image.value);
      }
    });
  }, [getImage, userId]);

  const persona: IPersonaSharedProps = {};

  if (userDetails && userDetails.firstName && userDetails.lastName) {
    persona.imageInitials =
      userDetails.firstName.charAt(0) + userDetails.lastName.charAt(0);
  }

  let imageString: string | undefined;
  if (image) {
    imageString = arrayBufferToBase64(image);
    persona.imageUrl = `data:image/jpeg;base64,${imageString}`;
  }

  let menuItem: ReactElement | undefined = (
    <MenuItem onClick={() => window.open(helpUrl)}>
      <Icon iconName="Help" />
    </MenuItem>
  );

  if (!helpUrl) {
    menuItem = undefined;
  }

  return (
    <>
      {menuItem}
      <MenuItem
        onClick={() => togglePanelState("notifications")}
        selected={panelState === "notifications"}
      >
        <Icon iconName="Ringer" />
      </MenuItem>
      <MenuItem
        onClick={() => togglePanelState("account")}
        selected={panelState === "account"}
      >
        <Persona
          {...persona}
          size={PersonaSize.size32}
          initialsColor={PersonaInitialsColor.black}
          styles={{
            root: {
              paddingLeft: 10,
            },
          }}
        />
      </MenuItem>
    </>
  );
};

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((prev, curr) => {
    return (prev += String.fromCharCode(curr));
  }, "");

  return window.btoa(binary);
};
