import { EWS_SERVICE_ID, SHAREPOINT_SERVICE_ID } from "../../constants";
import {
  IEWSRestore,
  ISharepointRestore,
  TRestoreConfig,
} from "../../providers/ApiProvider/ApiClient/models/restore";
import { IItem, IRestoreViewColumn } from "./RestoreView";
import {
  ISyncStats,
  ReportingModel,
} from "../../providers/ApiProvider/ApiClient/models/reporting";

import { DateTime } from "luxon";
import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";
import { humanReadableBytes } from "../ServiceAccountStats/utils";

export interface IGetStatsQuery {
  startDate: Date;
  endDate: Date;
  reportingApi: ReportingModel;
  organisationId: string;
  serviceAccountId: string;
  serviceId: string;
  cursor?: string;
}

export const getStats = async ({
  reportingApi,
  organisationId,
  serviceAccountId,
  serviceId,
  cursor,
  startDate,
  endDate,
}: IGetStatsQuery) => {
  let pageStats: ISyncStats[] = [];

  if (!cursor) {
    const iSyncRestorePoints = await fetchISyncStats({
      reportingApi,
      organisationId,
      serviceAccountId,
      serviceId,
      startDate,
      endDate,
    });
    pageStats = [...pageStats, ...iSyncRestorePoints];
  }

  const mainSync = await reportingApi.syncStats({
    organisationId,
    serviceAccountId,
    serviceId,
    syncType: "MAIN_SYNC",
    startDate,
    endDate,
    reverse: true,
    cursor,
  });

  let nextCursor: string | undefined;
  if (mainSync.nodes.length) {
    pageStats = [...pageStats, ...mainSync.nodes];
    nextCursor = mainSync.pageInfo.cursor;
  } else {
    nextCursor = undefined;
  }

  return { nextCursor, pageStats };
};

export const isIItem = (x: any, serviceId: string): x is IItem => {
  if (serviceId === EWS_SERVICE_ID || serviceId === SHAREPOINT_SERVICE_ID) {
    if (x.startDate && x.endDate && x.rawDate && x.items && x.size) {
      return true;
    }
  }
  return false;
};

export const prepareItems = ({
  serviceId,
  syncStats,
  hideEmptySync,
  filterQuery,
  columns,
}: {
  serviceId: string;
  syncStats: ISyncStats[];
  hideEmptySync: boolean;
  filterQuery?: string;
  columns?: IRestoreViewColumn[];
}) => {
  let items: IItem[] = [];

  items = syncStats.map(item => {
    let itemsKey = "itemCount";
    let sizeKey = "itemSize";
    if (serviceId === SHAREPOINT_SERVICE_ID) {
      itemsKey = "fileCount";
      sizeKey = "fileSize";
    }
    return {
      startDate: DateTime.fromISO(item.startTime).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      }),
      endDate: DateTime.fromISO(item.endTime).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      }),
      rawDate: item.endTime,
      size: humanReadableBytes(item.stats[sizeKey]),
      rawSize: item.stats[sizeKey].toString(),
      items: item.stats[itemsKey].toString(),
    };
  });

  let finalItems = items;
  if (hideEmptySync) {
    finalItems = items.filter(item => item?.items !== "0");
  }
  if (filterQuery) {
    finalItems = finalItems.filter(item =>
      Object.values(item)
        .map(i => {
          return i.toLowerCase().includes(filterQuery.toLowerCase());
        })
        .includes(true)
    );
  }

  if (columns) {
    for (const column of columns) {
      if (column.isSorted) {
        finalItems = finalItems.sort((a, b) => {
          let res = 0;
          if (
            column.fieldName &&
            ["startDate", "endDate"].includes(column.fieldName)
          ) {
            res = new Date(a.rawDate).getTime() - new Date(b.rawDate).getTime();
          }
          if (column.fieldName === "size") {
            res = parseInt(a.rawSize, 10) - parseInt(b.rawSize, 10);
          }
          if (column.fieldName === "items") {
            res = parseInt(a.items) - parseInt(b.items);
          }

          if (column.isSortedDescending) {
            res *= -1;
          }

          return res;
        });
      }
    }
  }

  return finalItems;
};

export const fetchISyncStats = async ({
  startDate,
  endDate,
  reportingApi,
  organisationId,
  serviceAccountId,
  serviceId,
}: IGetStatsQuery) => {
  let filteredRestorePoints: ISyncStats[] = [];
  let cursor: string | undefined;
  do {
    const initialSync = await reportingApi.syncStats({
      organisationId,
      serviceAccountId,
      serviceId,
      syncType: "INITIAL_SYNC",
      startDate,
      endDate,
      reverse: true,
      cursor,
    });
    if (initialSync) {
      filteredRestorePoints = [...filteredRestorePoints, ...initialSync.nodes];
      cursor = initialSync.pageInfo.cursor;
    } else {
      cursor = undefined;
    }
  } while (cursor);
  return filteredRestorePoints;
};

export const prepareRestoreConfig = ({
  serviceId,
  toServiceAccount,
  restoreTime,
  additionalFields,
}: {
  serviceId: string;
  toServiceAccount: IServiceAccount;
  restoreTime: Date;
  additionalFields: Partial<TRestoreConfig>;
}): TRestoreConfig => {
  if (serviceId === EWS_SERVICE_ID) {
    const config: IEWSRestore["config"] = {
      destinationFolder: "Backup Data",
      restoreTime: restoreTime.toISOString(),
      restoreType: "FULL_RESTORE",
      dstServiceAccountId: toServiceAccount.id,
      ...additionalFields,
    };

    return config;
  } else if (serviceId === SHAREPOINT_SERVICE_ID) {
    const config: ISharepointRestore["config"] = {
      restoreType: "FULL_RESTORE",
      dstServiceAccountId: toServiceAccount.id,
      restoreTime: restoreTime.toISOString(),
      ...additionalFields,
    };

    return config;
  } else {
    throw new Error(
      `Couldn't prepare restore config, invalid serviceId: ${serviceId}`
    );
  }
};

export const prepareColumns = (serviceId: string) => {
  let columns: IRestoreViewColumn[] = [];
  if (serviceId === EWS_SERVICE_ID || SHAREPOINT_SERVICE_ID) {
    columns = [
      {
        key: "startDate",
        name: "Start Date",
        fieldName: "startDate",
        minWidth: 80,
        maxWidth: 200,
        isResizable: true,
        isSortable: true,
        isSorted: true,
        isSortedDescending: true,
      },
      {
        key: "endDate",
        name: "End Date",
        fieldName: "endDate",
        minWidth: 80,
        maxWidth: 200,
        isResizable: true,
        isSortable: true,
        isSorted: false,
      },
      {
        key: "items",
        name: "Items Changed",
        fieldName: "items",
        minWidth: 100,
        maxWidth: 300,
        isResizable: true,
        isSortable: true,
      },
      {
        key: "size",
        name: "Size Changed",
        fieldName: "size",
        minWidth: 100,
        maxWidth: 300,
        isResizable: true,
        isSortable: true,
        isCollapsable: true,
      },
    ];
  }

  return columns;
};

export const prepareComboOptions = (
  serviceAccounts: IServiceAccount[],
  serviceId: string
) => {
  return serviceAccounts.map(serviceAccount => {
    const key = serviceAccount.id;
    let text: string = serviceAccount.identifier;

    if (serviceId === SHAREPOINT_SERVICE_ID) {
      text =
        serviceAccount.identifier.split("/").pop() || serviceAccount.identifier;
    }
    return {
      key,
      text,
    };
  });
};
