import { BaseModel } from "./base";
import { IPaginate } from "./utils";

export interface ISyncStats {
  startTime: string;
  endTime: string;
  stats: { [k: string]: number };
}

type TOwner = "USER" | "ORGANISATION";
type TReportPeriod = "DAILY" | "WEEKLY" | "MONTHLY";
interface IServiceAccount {
  serviceId: string;
  serviceAccountId: string;
}

export interface IScheduledReportConfig {
  ownerId: string;
  ownerType: TOwner;
  reportConfigId: string;
  reportPeriod: TReportPeriod;
  serviceIds: string[];
  serviceAccountIds: IServiceAccount[];
  timeOfDay: string;
  daysOfWeek?: number[];
  daysOfMonth?: number[];
  timezone: string;
  recipientAddresses: string[];
  disabled: boolean;
}

export type TScheduledReportInput = Omit<
  IScheduledReportConfig,
  "ownerId" | "ownerType" | "reportConfigId"
>;

type TEventOwner = "ORGANISATION";
type TEventType = "PROVISION" | "DISCOVER" | "INITIAL_BACKUP" | "RESTORE";

export interface IEventReportConfig {
  ownerId: string;
  ownerType: TEventOwner;
  reportConfigId: string;
  serviceIds: string[];
  eventTypes: TEventType[];
  recipientAddresses: string[];
  timezone: string;
  disabled: boolean;
}

export type TEventReportInput = Omit<
  IEventReportConfig,
  "ownerId" | "ownerType" | "reportConfigId"
>;

export class ReportingModel extends BaseModel {
  // Get the lifetime stats for a serviceAccount
  public async lifetimeStats(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
  }) {
    const response = await this.request({
      url: `/reporting/organisations/${opts.organisationId}/on-demand`,
      method: "post",
      data: {
        serviceId: opts.serviceId,
        timePeriod: "LIFETIME",
        serviceAccountIds: [opts.serviceAccountId],
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    const stats: { [k: string]: number } = response.data.nodes[0]?.stats;

    return stats;
  }

  public async firstSyncTime(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
  }): Promise<Date | undefined> {
    const response = await this.request({
      url: `/reporting/organisations/${opts.organisationId}/on-demand`,
      method: "post",
      params: {
        limit: 1,
      },
      data: {
        serviceId: opts.serviceId,
        timePeriod: "SYNC",
        serviceAccountIds: [opts.serviceAccountId],
        syncType: "INITIAL_SYNC",
        startTime: new Date(0).toISOString(),
        endTime: new Date().toISOString(),
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    const res = response.data?.nodes?.[0];
    if (res === undefined) {
      return res;
    }

    const endTime = new Date(res.endTime);

    return endTime;
  }

  public async lastSyncTime(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
  }): Promise<Date | undefined> {
    const response = await this.request({
      url: `/reporting/organisations/${opts.organisationId}/on-demand`,
      method: "post",
      params: {
        limit: 1,
        reverse: true,
      },
      data: {
        serviceId: opts.serviceId,
        timePeriod: "SYNC",
        serviceAccountIds: [opts.serviceAccountId],
        syncType: "MAIN_SYNC",
        startTime: new Date(0).toISOString(),
        endTime: new Date().toISOString(),
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    const res = response.data?.nodes?.[0];
    if (res === undefined) {
      return res;
    }

    const endTime = new Date(res.endTime);

    return endTime;
  }

  public async syncStats(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    syncType: "INITIAL_SYNC" | "MAIN_SYNC";
    startDate: Date;
    endDate: Date;
    reverse?: boolean;
    cursor?: string;
  }) {
    const params: { cursor?: string; reverse?: boolean } = {};
    if (opts.cursor) {
      params.cursor = opts.cursor;
    }
    if (opts.reverse !== undefined) {
      params.reverse = opts.reverse;
    }
    const data = {
      serviceId: opts.serviceId,
      timePeriod: "SYNC",
      serviceAccountIds: [opts.serviceAccountId],
      syncType: opts.syncType,
      startTime: opts.startDate.toISOString(),
      endTime: opts.endDate.toISOString(),
    };
    const response = await this.request({
      url: `/reporting/organisations/${opts.organisationId}/on-demand`,
      method: "post",
      params: {
        ...params,
        limit: 50,
      },
      data,
    });

    const stats: IPaginate<ISyncStats> = response.data;

    return stats;
  }

  public async getScheduledConfigs({
    organisationId,
    cursor,
    limit,
  }: {
    organisationId: string;
    cursor?: string;
    limit?: number;
  }): Promise<IPaginate<IScheduledReportConfig> | undefined> {
    const response = await this.request({
      url: `/reporting/organisations/${organisationId}/scheduled`,
      method: "get",
      params: {
        cursor,
        limit,
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async getScheduledConfig({
    organisationId,
    reportConfigId,
  }: {
    organisationId: string;
    reportConfigId: string;
  }): Promise<IScheduledReportConfig | undefined> {
    const response = await this.request({
      url: `reporting/organisations/${organisationId}/scheduled/${reportConfigId}`,
      method: "get",
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async createScheduledConfig({
    ownerId,
    config,
  }: {
    ownerId: string;
    config: TScheduledReportInput;
  }): Promise<void> {
    const response = await this.request({
      url: `/reporting/organisations/${ownerId}/scheduled`,
      method: "post",
      data: config,
    });

    if (response.status !== 201) {
      throw new Error(`Failed to create scheduled config`);
    }
  }

  public async updateScheduledConfig({
    ownerId,
    ownerType,
    reportConfigId,
    config,
  }: {
    ownerId: string;
    ownerType: TOwner;
    reportConfigId: string;
    config: Partial<IScheduledReportConfig>;
  }): Promise<IScheduledReportConfig> {
    if (ownerType !== "ORGANISATION") {
      throw new Error(`Invalid report ownerType: ${ownerType}`);
    }
    const response = await this.request({
      url: `/reporting/organisations/${ownerId}/scheduled/${reportConfigId}`,
      method: "put",
      data: config,
    });

    if (response.status !== 200) {
      throw new Error(`Failed to update scheduled config: ${reportConfigId}`);
    }

    return response.data;
  }

  public async deleteScheduledConfig({
    ownerId,
    reportConfigId,
  }: {
    ownerId: string;
    reportConfigId: string;
  }): Promise<void> {
    const response = await this.request({
      url: `/reporting/organisations/${ownerId}/scheduled/${reportConfigId}`,
      method: "delete",
    });

    if (response.status !== 204) {
      throw new Error(`Failed to delete scheduled config: ${reportConfigId}`);
    }
  }

  public async getEventConfigs({
    organisationId,
    cursor,
    limit,
  }: {
    organisationId: string;
    cursor?: string;
    limit?: number;
  }): Promise<IPaginate<IEventReportConfig> | undefined> {
    const response = await this.request({
      url: `/reporting/organisations/${organisationId}/event`,
      method: "get",
      params: {
        cursor,
        limit,
      },
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async getEventConfig({
    organisationId,
    reportConfigId,
  }: {
    organisationId: string;
    reportConfigId: string;
  }): Promise<IEventReportConfig | undefined> {
    const response = await this.request({
      url: `reporting/organisations/${organisationId}/event/${reportConfigId}`,
      method: "get",
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }

  public async createEventConfig({
    ownerId,
    config,
  }: {
    ownerId: string;
    config: TEventReportInput;
  }): Promise<void> {
    const response = await this.request({
      url: `/reporting/organisations/${ownerId}/event`,
      method: "post",
      data: config,
    });

    if (response.status !== 201) {
      throw new Error(`Failed to create event config`);
    }
  }

  public async updateEventConfig({
    ownerId,
    ownerType,
    reportConfigId,
    config,
  }: {
    ownerId: string;
    ownerType: TOwner;
    reportConfigId: string;
    config: Partial<IEventReportConfig>;
  }): Promise<IEventReportConfig> {
    if (ownerType !== "ORGANISATION") {
      throw new Error(`Invalid report ownerType: ${ownerType}`);
    }
    const response = await this.request({
      url: `/reporting/organisations/${ownerId}/event/${reportConfigId}`,
      method: "put",
      data: config,
    });

    if (response.status !== 200) {
      throw new Error(`Failed to update event config: ${reportConfigId}`);
    }

    return response.data;
  }

  public async deleteEventConfig({
    ownerId,
    reportConfigId,
  }: {
    ownerId: string;
    reportConfigId: string;
  }): Promise<void> {
    const response = await this.request({
      url: `/reporting/organisations/${ownerId}/event/${reportConfigId}`,
      method: "delete",
    });

    if (response.status !== 204) {
      throw new Error(`Failed to delete event config: ${reportConfigId}`);
    }
  }
}
