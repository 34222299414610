export const toFirstLetterUpper = (str: string): string => {
  const lowerStr = str.toLowerCase();
  const res = lowerStr[0].toUpperCase() + lowerStr.slice(1);

  return res;
};

export const toCamelCase = (str: string): string => {
  const res = str[0].toUpperCase() + str.slice(1);

  return res;
};
