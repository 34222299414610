import { BaseModel } from "./base";

export interface ISearchResultValue {
  id: string;
  email: string;
  from?: string[] | null;
  to?: string[] | null;
  subject?: string | null;
  date?: string | null;
  cc?: string[] | null;
  bcc?: string[] | null;
  replyTo?: string[] | null;
  htmlBody?: string | null;
  textBody?: string | null;
  messageSize?: number | null;
}

export interface ISearchResults {
  nodes: ISearchResultValue[];
  pageInfo: {
    offset: string;
    count: number;
    totalHits: number;
  };
}

export interface ISearchQuery {
  email?: string;
  from?: string;
  to?: string;
  subject?: string;
  cc?: string;
  bcc?: string;
  replyTo?: string;
  body?: string;
  startDate?: Date;
  endDate?: Date;
  offset?: string;
  limit?: number;
}

export class SearchModel extends BaseModel {
  public async query(opts: {
    serviceId: string;
    organisationId: string;
    serviceAccountId: string;
    query: ISearchQuery;
  }): Promise<ISearchResults | undefined> {
    const response = await this.request({
      url: `/search/organisations/${opts.organisationId}/services/${opts.serviceId}/serviceAccounts/${opts.serviceAccountId}/query`,
      method: "post",
      data: opts.query
    });

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  }
}
