import { INavLink, Nav } from "office-ui-fabric-react";
import {
  IStyle,
  IStyleFunction,
  classNamesFunction,
  styled,
} from "office-ui-fabric-react";

import React from "react";
import { useHistory } from "react-router-dom";

interface ISideBarStyles {
  wrapper?: IStyle;
  wrapperSmall?: IStyle;
}

const sidebarStyles: IStyleFunction<
  ISidebarStyleProps,
  ISideBarStyles
> = () => {
  return {
    wrapper: {
      background: "#f3f2f1",
    },
    wrapperSmall: {
      overflowY: "initial",
      background: "#f3f2f1",
    },
  };
};

interface ISidebarStyleProps {
  styles?: ISideBarStyles;
  renderSmall?: boolean;
}

interface ISidebarProps extends ISidebarStyleProps {
  collapsed?: boolean;
  onSelect?: () => void;
}

const getClassNames = classNamesFunction<ISidebarStyleProps, ISideBarStyles>();

const BaseSideMenu: React.FC<ISidebarProps> = ({
  styles,
  collapsed,
  renderSmall,
  onSelect,
}) => {
  const classNames = getClassNames(styles);
  const history = useHistory();

  const handleClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    if (item) {
      ev?.preventDefault();
      history.push(item.url);
      onSelect?.();
    }
  };

  const links: INavLink[] = [
    {
      name: "Home",
      url: "/",
      icon: "Home",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "Group",
    },
    // {
    //   name: "Reporting",
    //   url: "/reporting",
    //   icon: "ReportDocument"
    // }
  ];

  let wrapper = classNames.wrapper;

  if (collapsed) {
    links.forEach(link => (link.name = ""));
    wrapper = classNames.wrapperSmall;
  }

  const layoutStyles: IStyle = renderSmall
    ? {
        width: "100%",
        position: "absolute",
        boxShadow: "0px 8px 10px #aaa",
      }
    : {
        width: collapsed ? 50 : 208,
        height: "100%",
      };

  return (
    <div className={wrapper}>
      <Nav
        onLinkClick={handleClick}
        styles={{
          root: {
            overflowY: "auto",
            background: "#f3f2f1",
            ...layoutStyles,
            zIndex: 1,
          },
          link: {
            paddingLeft: 13,
          },
          linkText: {
            marginLeft: 15,
          },
          groupContent: {
            marginBottom: 0,
          },
        }}
        groups={[
          {
            links,
          },
        ]}
      />
    </div>
  );
};

export const SideMenu = styled(BaseSideMenu, sidebarStyles);
