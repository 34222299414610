import { PortalError, Result, ok } from "../result";

import { BaseModel } from "./base";
import { IPaginate } from "./utils";

export interface IInvite {
  inviteId: string;
  organisationId: string;
  organisationName?: string;
  roleId: string;
  roleName?: string;
  inviteeUserId?: string;
  inviteeEmail: string;
  inviterUserId: string;
  inviterEmail?: string;
  issueTime: string;
  expiryTime: string;
}

export class InvitesModel extends BaseModel {
  public async createInvite(opts: {
    organisationId: string;
    roleId: string;
    inviteeUserId?: string;
    inviteeEmail: string;
    inviterUserId: string;
  }): Promise<Result<IInvite>> {
    const response = await this.request({
      url: `/auth/organisations/${opts.organisationId}/invites`,
      method: "post",
      data: {
        roleId: opts.roleId,
        inviteeUserId: opts.inviteeUserId,
        inviteeEmail: opts.inviteeEmail,
        inviterUserId: opts.inviterUserId,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 201) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async getInvite(opts: {
    inviteId: string;
    organisationId: string;
  }): Promise<Result<IInvite>> {
    const response = await this.request({
      url: `/auth/organisations/${opts.organisationId}/invites/${opts.inviteId}`,
      method: "get",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async getInvitesByOrgId(opts: {
    organisationId: string;
    cursor?: string;
    limit?: string;
  }): Promise<Result<IPaginate<IInvite>>> {
    const response = await this.request({
      url: `/auth/organisations/${opts.organisationId}/invites`,
      method: "get",
      params: {
        cursor: opts.cursor,
        limit: opts.limit,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async getInvitesByUserId(opts: {
    inviteeUserId: string;
    cursor?: string;
    limit?: string;
  }): Promise<Result<IPaginate<IInvite>>> {
    const response = await this.request({
      url: `/auth/invites`,
      method: "get",
      params: {
        inviteeUserId: opts.inviteeUserId,
        cursor: opts.cursor,
        limit: opts.limit,
      },
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(response.data);
  }

  public async acceptInvite(opts: {
    inviteId: string;
    organisationId: string;
  }): Promise<Result<undefined>> {
    const response = await this.request({
      url: `/auth/organisations/${opts.organisationId}/invites/${opts.inviteId}/accept`,
      method: "post",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(undefined);
  }

  public async cancelInvite(opts: {
    inviteId: string;
    organisationId: string;
  }): Promise<Result<undefined>> {
    const response = await this.request({
      url: `/auth/organisations/${opts.organisationId}/invites/${opts.inviteId}/cancel`,
      method: "post",
    });

    if (response.status === 401 || response.status === 403) {
      return new PortalError("UNAUTHORIZED", response.data);
    } else if (response.status === 404) {
      return new PortalError("NOT_FOUND", response.data);
    } else if (response.status !== 200) {
      return new PortalError("UNKNOWN", "An unexpected error occured");
    }

    return ok(undefined);
  }
}
