import React, { useState, useEffect } from "react";
import {
  classNamesFunction,
  Modal,
  IconButton,
  Text,
  Separator,
  styled,
  PrimaryButton,
  Label,
  Spinner,
  ISpinner,
  IStyle
} from "office-ui-fabric-react";
import { useApiClient } from "../../providers/ApiProvider";
import { IHistoryItem } from "./RestoreHistoryView";
import { TRestoreConfig } from "../../providers/ApiProvider/ApiClient/models/restore";
import { makeTitleCase } from "./helpers";
import WebSocketConfig from '../../providers/WebSocketConfig';

interface IPerformRestoreProps {
  organisationId: string;
  shouldShowModal: boolean;
  selectedRestorePoint: IHistoryItem;
  serviceId: string;
  toggleModal: (
      ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  serviceAccountId: string;
  styles?: IRestoreHistoryModalStyles;
}

interface IRestoreHistoryModalStyles {
  historyItemStat: IStyle;
  fullWidth: IStyle;
  rightFloat: IStyle;
  configHeader: IStyle;
}

const restoreHistoryModalStyles = (): IRestoreHistoryModalStyles => {
  return {
    historyItemStat: {
      overflowWrap: "break-word",
      marginTop: 10
    },
    fullWidth: {
      width: "100%"
    },
    rightFloat: {
      float: "right"
    },
    configHeader: {
      marginTop: 30
    }
  };
};

const getClassNames = classNamesFunction<{}, IRestoreHistoryModalStyles>();

const prepareConfig = ({
                         config,
                         excludedKeys,
                         className
                       }: {
  config: TRestoreConfig;
  className: string;
  excludedKeys?: string[];
}) => {
  const section = Object.keys(config).map((key, index) => {
    if (excludedKeys?.includes(key)) {
      return <></>;
    }
    let friendlyText = makeTitleCase(key.replace(/([A-Z])/g, " $1"));
    let value: any = config[key];
    if (key === "itemKeys") {
      friendlyText = "Restored Items";
      value = value && value.length.toString();
    }
    if (key === "folder") {
      friendlyText = "Restored Folder";
      value = value.path;
    }
    return (
        <div key={index}>
          <div className={className}>
            <b>{friendlyText}</b>
          </div>
          <div className={className}>{value}</div>
        </div>
    );
  });
  return section;
};

export const BaseRestoreHistoryModal: React.FC<IPerformRestoreProps> = ({
                                                                          styles,
                                                                          organisationId,
                                                                          serviceId,
                                                                          serviceAccountId,
                                                                          selectedRestorePoint,
                                                                          shouldShowModal,
                                                                          toggleModal
                                                                        }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<IHistoryItem>();
  const [lastMessage, setLastMessage] = useState<any | null>('');

  const handleNewMessage = (newMessage: any) => {
    setLastMessage(newMessage);
  };
  const classNames = getClassNames(styles);
  const api = useApiClient();

  useEffect(() => {
    if (selectedRestorePoint) {
      setSelected(selectedRestorePoint);
    }
  }, [selectedRestorePoint]);

  if (!selected) {
    return <></>;
  }

  const closeModal = () => {
    toggleModal();
  };

  const retryRestore = async () => {
    setIsLoading(true);

    await api.restore.resume({
      organisationId,
      serviceId,
      serviceAccountId,
      restoreId: selected.restoreId
    });

    // TODO: MessageBar
    // let messageText = "Restore queued successfully.";
    // let messageType = MessageBarType.success;
    // if (response) {
    //   messageText = response;
    //   messageType = MessageBarType.error;
    // }
    setIsLoading(false);
  };

  let restoreButton: ISpinner | undefined = (
      <PrimaryButton className={classNames.fullWidth} onClick={retryRestore}>
        {selected.status === "COMPLETE" ? "Resend download link" : "Retry"}
      </PrimaryButton>
  );
  if (selected.status === "IN_PROGRESS") {
    restoreButton = <></>;
  }
  if (isLoading) {
    restoreButton = <Spinner />;
  }

  const statWrapperClassName = `ms-Grid-col ms-sm6 ${classNames.historyItemStat}`;

  return (
    <>
      <Modal
        styles={{
          main: { padding: 20, maxWidth: 600 }
        }}
        isOpen={shouldShowModal || false}
        onDismiss={closeModal}
        isBlocking={false}
      >
        <div className="ms-Grid-col ms-sm12">
          <IconButton
            className={classNames.rightFloat}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close popup modal"
            onClick={closeModal as any}
          />
          <Text variant="large">Restore History Item</Text>
        </div>
        <div className="ms-Grid-col ms-sm12">
          <Separator />
        </div>
        <div className={statWrapperClassName}>
          <b>Start Date</b>
        </div>
        <div className={statWrapperClassName}>{selected.startDate}</div>
        <div className={statWrapperClassName}>
          <b>End Date</b>
        </div>
        <div className={statWrapperClassName}>
          {selected.endDate || <div>&nbsp;</div>}
        </div>
        <div className={statWrapperClassName}>
          <b>Status</b>
        </div>
        <div className={statWrapperClassName}>{selected.friendlyStatus}</div>
        <div className={statWrapperClassName}>
          <b>Restore Type</b>
        </div>
        <div className={statWrapperClassName}>{selected.restoreType}</div>
        <div className={statWrapperClassName}>
          <b>Retry Attempts</b>
        </div>
        <div className={statWrapperClassName}>{selected.restoreAttempts}</div>
        <div className={statWrapperClassName}>
          <b>Files restored</b>
        </div>
        {selected.status === "IN_PROGRESS" ? (
            <div className={statWrapperClassName}>
              {
                lastMessage && lastMessage.message ? (
                  selected.restoreId === lastMessage.message.restoreId && (
                      <>
                        {lastMessage.message.totalNumberOfFilesRestored &&
                            <span>{lastMessage.message.totalNumberOfFilesRestored}</span>}
                      </>
                  )
                ): <div className={statWrapperClassName}>0</div>
              }
              <WebSocketConfig onMessage={handleNewMessage} />
            </div>
        ) : (
            <div className={statWrapperClassName}>
               0
            </div>
        ) }
        <div className={`ms-Grid-col ms-sm6 ${classNames.configHeader}`}>
          <Text variant="large">Config</Text>
        </div>
        <div className="ms-Grid-col ms-sm12">
          <Separator />
        </div>
        {prepareConfig({
          config: selected.config,
          excludedKeys: ["restoreType"],
          className: statWrapperClassName
        }).map((config, index) => {
          return <div key={index}>{config}</div>;
        })}
        <div className="ms-Grid-col ms-sm12">
          <Label>
            <>&nbsp;</>
          </Label>
          {restoreButton}
        </div>
      </Modal>
    </>
  );
};

export const RestoreHistoryModal = styled(
    BaseRestoreHistoryModal,
    restoreHistoryModalStyles
);
