import {
  Breadcrumb,
  IBreadcrumbItem,
  IRenderFunction,
  IconButton,
  getTheme,
} from "office-ui-fabric-react";

import { IOrganisation } from "../../providers/ApiProvider/ApiClient/models/accounts";
import React from "react";

export interface IParentOrganisationBreadcrumbsProps {
  parentOrganisations: IOrganisation[];
  selectOrganisation?: (organisationId: string) => void;
}

export const rootItemKey = "ROOT";

export const ParentOrganisationBreadcrumbs: React.FC<IParentOrganisationBreadcrumbsProps> = ({
  parentOrganisations,
  selectOrganisation,
}) => {
  const theme = getTheme();

  const rootItem: IBreadcrumbItem = { key: rootItemKey, text: "" };

  const items = parentOrganisations.map<IBreadcrumbItem>(org => ({
    key: org.id,
    text: org.name,
    onClick: (
      ev?: React.MouseEvent<HTMLElement, MouseEvent>,
      item?: IBreadcrumbItem
    ) => item && selectOrganisation?.(item.key),
  }));

  const onRenderItem: IRenderFunction<IBreadcrumbItem> = (
    item,
    defaultRender
  ) => {
    if (item?.key === rootItemKey) {
      return (
        <IconButton
          iconProps={{ iconName: "Home" }}
          styles={{
            root: {
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: 4,
            },
            icon: {
              fontSize: theme.fonts.xLargePlus.fontSize,
            },
          }}
          onClick={() => {
            selectOrganisation?.(rootItemKey);
          }}
        />
      );
    }

    return defaultRender?.(item) ?? null;
  };

  return (
    <Breadcrumb
      items={items.length > 0 ? [rootItem, ...items] : items}
      maxDisplayedItems={3}
      overflowIndex={1}
      onRenderItem={onRenderItem}
    />
  );
};
