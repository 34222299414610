import React, { useState, useMemo } from "react";
import { ISearchResultValue } from "../../../providers/ApiProvider/ApiClient/models/search";
import { IReprSearchResultValue } from "../types";
import {
  ShimmeredDetailsList,
  IDetailsRowProps,
  Link,
  Selection
} from "office-ui-fabric-react";
import { IColumn } from "office-ui-fabric-react";
import { humanReadableBytes } from "../../ServiceAccountStats/utils";
import { DateTime } from "luxon";
import { ResultModal } from "./ResultModal";
import { mergeStyles } from "office-ui-fabric-react";

export interface ISearchResultsProps {
  items: Array<ISearchResultValue | null>;
  isItemsLoading?: boolean;
  loadNextPage?: () => void;
  setSelectedItems?: (items: IReprSearchResultValue[]) => void;
}

const humanReadableDate = (
  date: string | null | undefined
): string | undefined => {
  if (date === null || date === undefined) {
    return undefined;
  }

  const dt = DateTime.fromISO(date);

  return dt.toLocaleString({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  });
};

export const SearchResults: React.FC<ISearchResultsProps> = ({
  isItemsLoading,
  loadNextPage,
  setSelectedItems,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState<IReprSearchResultValue>();
  const [isOpen, setOpen] = useState(false);

  const columns: IColumn[] = [
    {
      key: "from",
      name: "From",
      fieldName: "from",
      minWidth: 120,
      isResizable: true,
      onRender: (item: IReprSearchResultValue) => (
        <Link
          onClick={() => {
            setSelectedItem(item);
            setOpen(true);
          }}
        >
          {item.from}
        </Link>
      )
    },
    {
      key: "to",
      name: "To",
      fieldName: "reprTo",
      minWidth: 120,
      isMultiline: true,
      isResizable: true,
      isCollapsible: true
    },
    {
      key: "subject",
      name: "Subject",
      fieldName: "subject",
      minWidth: 200,
      isMultiline: true,
      isResizable: true,
      isCollapsible: true
    },
    {
      key: "date",
      name: "Date",
      fieldName: "reprDate",
      minWidth: 130,
      isResizable: true,
      isCollapsible: true
    },
    {
      key: "size",
      name: "Size",
      fieldName: "reprMessageSize",
      minWidth: 70,
      isResizable: true,
      isCollapsible: true
    }
  ];

  const items: Array<IReprSearchResultValue | null> = useMemo(
    () =>
      props.items.map(item => {
        if (item === null) {
          return null;
        }

        return {
          ...item,
          reprFrom: item.from?.join(" "),
          reprTo: item.to?.join(" "),
          reprMessageSize:
            item.messageSize !== undefined && item.messageSize !== null
              ? humanReadableBytes(item.messageSize)
              : undefined,
          reprDate: humanReadableDate(item.date),
          reprCc: item.cc?.join(" "),
          reprBcc: item.bcc?.join(" "),
          reprReplyTo: item.replyTo?.join(" ")
        };
      }),
    [props.items]
  );

  const selection = useMemo(() => {
    return new Selection<any>({
      getKey: (item: IReprSearchResultValue) => item.id,
      onSelectionChanged: () => {
        setSelectedItems?.(
          selection.getSelection().filter(item => item !== null)
        );
      }
    });
  }, [setSelectedItems]);

  const container = mergeStyles({
    maxHeight: "75vh",
    overflowY: "scroll"
  });

  return (
    <>
      <div className={container}>
        <ShimmeredDetailsList
          selection={selection}
          enableShimmer={isItemsLoading}
          columns={columns}
          items={items}
          selectionPreservedOnEmptyClick
          onRenderCustomPlaceholder={(
            rowProps: IDetailsRowProps,
            index?: number,
            defaultRender?: (props: IDetailsRowProps) => React.ReactNode
          ) => {
            loadNextPage?.();

            return defaultRender?.(rowProps);
          }}
        />
      </div>
      <ResultModal
        selectedItem={selectedItem}
        isOpen={isOpen}
        onDismiss={() => setOpen(false)}
      />
    </>
  );
};
