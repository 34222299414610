import React from "react";
import { Stack } from "office-ui-fabric-react";
import { Stat } from "./Stat";

export interface IStat {
  title: string;
  metric?: string | number;
  iconName?: string;
  hover?: string;
}

export interface IStatsProps {
  stats: IStat[];
  isLoading?: boolean;
}

export const Stats: React.FC<IStatsProps> = ({ stats, isLoading }) => {
  return (
    <Stack horizontal wrap>
      {stats.map(stat => (
        <Stat
          title={stat.title}
          key={stat.title}
          metric={stat.metric}
          iconName={stat.iconName}
          isLoading={isLoading}
          hover={stat.hover}
        />
      ))}
    </Stack>
  );
};
