import React, { useCallback, useMemo } from "react";
import {
  Stack,
  IStackTokens,
  PrimaryButton,
  IStyle,
  DefaultButton
} from "office-ui-fabric-react";
import { mergeStyleSets } from "office-ui-fabric-react";
import { IFormState, TFormStrings, TFormDates } from "../types";
import { TextItemHOC } from "./TextItem";
import { DateItemHOC } from "./DateItem";
import { pluralise } from "../../ServiceAccountStats/utils";

export interface ISearchFormProps {
  formState: IFormState;
  updateQuery?: (action: IAction) => void;
  onSearch?: (formState: IFormState) => void;
  selectedItemCount?: number;
  onRestore?: () => void;
}

interface IAction {
  fieldName: keyof IFormState;
  value: IFormState[keyof IFormState];
}

export const itemStyle: IStyle = { width: "30%", minWidth: 250 };

export const SearchForm: React.FC<ISearchFormProps> = ({
  formState,
  updateQuery,
  onSearch,
  selectedItemCount,
  onRestore
}) => {
  const tokens: IStackTokens = {
    childrenGap: "s1"
  };

  const classNames = mergeStyleSets({
    container: { padding: 10 },
    item: itemStyle,
    button: { width: "100%", marginTop: 10 }
  });

  const updateText = useCallback(
    (fieldName: keyof IFormState) => (ev: any, newValue?: string) => {
      updateQuery?.({ fieldName, value: newValue });
    },
    [updateQuery]
  );

  const TextItem = useMemo(() => TextItemHOC(updateText), [updateText]);

  const updateDate = useCallback(
    (fieldName: keyof IFormState) => (date?: Date | null) => {
      if (date !== null) {
        updateQuery?.({ fieldName, value: date });
      }
    },
    [updateQuery]
  );

  const DateItem = useMemo(() => DateItemHOC(updateDate), [updateDate]);

  const textFields: { [k in TFormStrings]: string } = {
    from: "From",
    to: "To",
    subject: "Subject",
    body: "Body",
    cc: "Cc",
    bcc: "Bcc",
    replyTo: "Reply-To"
  };

  const textItems = (Object.keys(textFields) as Array<TFormStrings>).map(
    field => {
      const label = textFields[field];

      return (
        <TextItem
          key={field}
          formState={formState}
          label={label}
          fieldName={field}
        />
      );
    }
  );

  const dateFields: { [k in TFormDates]: string } = {
    startDate: "Start Date",
    endDate: "End Date"
  };

  const dateItems = (Object.keys(dateFields) as Array<TFormDates>).map(
    field => {
      const label = dateFields[field];

      return (
        <DateItem
          key={field}
          formState={formState}
          label={label}
          fieldName={field}
        />
      );
    }
  );

  const count = selectedItemCount ?? 0;

  return (
    <Stack className={classNames.container} tokens={tokens} horizontal wrap>
      {textItems}
      {dateItems}
      <Stack.Item className={classNames.item} grow align="end">
        <PrimaryButton
          className={classNames.button}
          onClick={() => onSearch?.(formState)}
        >
          Search
        </PrimaryButton>
      </Stack.Item>
      <Stack.Item className={classNames.item} grow align="end">
        <DefaultButton
          disabled={!selectedItemCount}
          className={classNames.button}
          onClick={() => onRestore?.()}
        >
          {`Restore ${count} ${pluralise("item", count)}`}
        </DefaultButton>
      </Stack.Item>
    </Stack>
  );
};
