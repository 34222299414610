import React, { useCallback } from "react";
import { Breadcrumb, IBreadcrumbItem } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";

export interface IBrowseBreadCrumbsProps {
  parents: { name: string; parentId?: string }[];
}

export const BrowseBreadCrumbs: React.FC<IBrowseBreadCrumbsProps> = ({
  parents
}) => {
  const history = useHistory();
  const query = useQuery();

  const onClick = useCallback(
    (parentId?: string) => {
      if (parentId === undefined || parentId === "") {
        query.delete("parentId");
      } else {
        query.set("parentId", parentId);
      }

      history.push({ search: query.toString() });
    },
    [history, query]
  );

  const items: IBreadcrumbItem[] = parents.map((parent, i) => ({
    text: parent.name,
    key: i.toString(),
    onClick: () => onClick(parent.parentId)
  }));

  return <Breadcrumb items={items} />;
};
