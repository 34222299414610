import React from "react";
import { IFormState, TFormStrings } from "../types";
import { mergeStyles, Stack, TextField } from "office-ui-fabric-react";
import { itemStyle } from ".";

export interface ITextItemProps {
  formState: IFormState;
  label: string;
  fieldName: TFormStrings;
}

export const TextItemHOC = (
  updateText: (fieldName: keyof IFormState) => any
): React.FC<ITextItemProps> => ({ formState, label, fieldName }) => {
  const item = mergeStyles(itemStyle);

  return (
    <Stack.Item className={item} grow>
      <TextField
        label={label}
        value={formState[fieldName] ?? ""}
        onChange={updateText(fieldName)}
      />
    </Stack.Item>
  );
};
