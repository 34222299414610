import CustomerRoles from "../components/CustomerRoles";
import React from "react";

export const CustomerRolesPage: React.FC = () => {
  return (
    <>
      <CustomerRoles />
    </>
  );
};
