import { IServiceAccount } from "../../providers/ApiProvider/ApiClient/models/accounts";

export const statusCodeToString = (
  statusCode: string
): { status?: string; enabled?: boolean } => {
  // serviceAccount status has the format /^[1-5]:[1-5]:[0-5]:[0-5]$/
  const [mainStatus, subStatus, nextMain, nextSub] = statusCode.split(":");

  let status: string | undefined;
  if (nextMain === "5") {
    if (nextSub === "4") {
      status = "Invalid";
    } else if (nextSub === "0") {
      status = "Disabled";
    }
  } else if (mainStatus === "5") {
    if (subStatus === "4") {
      status = "Invalid";
    } else if (subStatus === "0") {
      status = "Disabled";
    }
  } else if (mainStatus === "1") {
    status = "Validating";
  } else if (mainStatus === "2") {
    status = "Initial Sync";
  } else if (mainStatus === "3" || mainStatus === "4") {
    status = "Enabled";
  }

  let enabled: boolean | undefined;
  if (status === "Invalid" || status === "Disabled") {
    enabled = false;
  } else if (status !== undefined) {
    enabled = true;
  }

  return {
    status,
    enabled
  };
};

export const getLicensing = (
  serviceAccount: IServiceAccount
): string | undefined => {
  let licensing: string | undefined;
  if (serviceAccount.tags) {
    for (const tag of serviceAccount.tags) {
      if (tag.value === "licenced") {
        licensing = "Licensed";
      } else if (tag.value === "unlicenced") {
        licensing = "Unlicensed";
      }
    }
  }

  return licensing;
};
